import React, {ReactNode, useEffect, useState} from 'react';
import {BizMenuData, BizMenuLayout} from "../shares/bizMenuLayout/BizMenuLayout";
import {useTranslation} from "react-i18next";
import {I18nKeys} from "../../i18n/i18nKeys";
import {useSelector} from "react-redux";
import {UserState} from "../../redux/User";
import {UserType} from "../../graphql/types";
import {I18nState} from "../../redux/I18n";

export const BoardLayout = (props: {
    children: ReactNode,
    activatedRouterSuffix: string,
}) => {
    const [t] = useTranslation('common');
    const user = useSelector<any, UserState>(state => state.user).user;
    const langCode = useSelector<any, I18nState>(state => state.i18n).langCode;
    const [data, setData] = useState<BizMenuData[]>([]);

    useEffect(() => {
        const nextData: BizMenuData[] = [
            {title: t(I18nKeys.board.asklist.key), routerSuffix: '/board/list'},
        ];
        if (user.userType === UserType.Company) {
            nextData.push({title: t(I18nKeys.board.answerlist.key), routerSuffix: '/company/board/list'})
        }

        setData(nextData);

    }, [user.userType, langCode, t]);


    return (
        <BizMenuLayout
            title={t(I18nKeys.layout.board.key)}
            routerPrefix='/ask'
            activatedRouterSuffix={props.activatedRouterSuffix}
            data={data}>
            {props.children}
        </BizMenuLayout>
    )
}
