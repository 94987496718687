import React, {useEffect, useState} from 'react';
import styles from './ChatV1Tab.module.scss';
import classNames from "classnames";
import {OnChangeValue} from "../../../../Intefaces";
import {useTranslation} from "react-i18next";

export const ChatV1Tab = (props: {
    tabData: ChatV1TabData[],
    value: string,
    onChangeValue: OnChangeValue<string>,
}) => {
    const [tabWidth, setTabWidth] = useState(0);
    const [activatedIdx, setActivatedIdx] = useState(0);
    const [t] = useTranslation('common');


    useEffect(() => {
        setTabWidth(100 / props.tabData.length);
        const idx = props.tabData.findIndex(value => {
            return value.value === props.value;
        });
        setActivatedIdx(idx);
    }, [props.tabData, props.value]);


    return (
        <div className={styles.contTab}>
            {props.tabData.map((value, index) => (
                <div
                    key={index}
                    style={{width: `${tabWidth}%`}}
                    className={classNames(styles.tab, index === activatedIdx ? styles.activate : '')}
                    onClick={() => props.onChangeValue(value.value)}>
                    {t(value.tLabel)}
                </div>
            ))}

        </div>
    )
}


export interface ChatV1TabData {
    value: string;
    tLabel: string;
}
