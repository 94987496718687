import React, {useEffect, useState} from 'react';
import styles from './BizAskBoardModal.module.scss';
import {InputReactQuill} from "../../mypage/share/inputs/inputReactQuill/InputReactQuill";
import {OnVoidFunction} from "../../Intefaces";
import {BizModal} from "../bizModal/BizModal";
import {BizSmTitle} from "../bizTitle/BizTitle";
import {Tooltip} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import {CreateAskBoard, Mutation, MutationCreateMyAskBoardArgs} from "../../../graphql/types";
import {DataUtil} from "../../../utils/data/DataUtil";
import {CopyUtil} from "../../../utils/copyUtil/copyUtil";
import {gql, useMutation} from "@apollo/client";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {I18nKeys} from "../../../i18n/i18nKeys";
import {useTranslation} from "react-i18next";
import imgJPG from '../../../assets/svg/fileExtension/jpg.svg'
import imgPNG from '../../../assets/svg/fileExtension/png.svg'
import imgSVG from '../../../assets/svg/fileExtension/svg.svg'
import imgPDF from '../../../assets/svg/fileExtension/pdf.svg'
import imgZIP from '../../../assets/svg/fileExtension/zip.svg'


export const BizAskBoardModal = (props: {
    isShow: boolean,
    onCancel: OnVoidFunction,
    companyId: number,
    goodsId?: number,
    goodsInfoId?: number,
}) => {
    const [createAskBoard, setCreateAskBoard] = useState<CreateAskBoard>({
        attachFiles: [],
        companyId: props.companyId,
        contents: "",
        title: "",
        goodsId: props.goodsId,
        goodsInfoId: props.goodsInfoId,
        contact: "",
    });

    const [t] = useTranslation('common');

    useEffect(() => {
        setCreateAskBoard({
            attachFiles: [],
            companyId: props.companyId,
            contents: "",
            title: "",
            goodsId: props.goodsId,
            goodsInfoId: props.goodsInfoId,
            contact: "",
        });
    }, [props.companyId, props.goodsId, props.goodsInfoId]);


    const [mutCreate] = useMutation<Mutation, MutationCreateMyAskBoardArgs>(gqlCreateAskBoard);

    const handler = {
        onAddFile: () => {
            if (2 < createAskBoard.attachFiles.length) {
                SwalUtil.ok({
                    msg: t(I18nKeys.board.askBoardMaxFileLength.key),
                    icon: 'warning'
                });
                return;
            }

            DataUtil
                .uploadFile({
                    accept: ".jpg, .png, .svg, .pdf, .zip"
                })
                .then(res => {
                    const copy = CopyUtil.copyAll(createAskBoard);
                    for (let file of res) {
                        copy.attachFiles.push({
                            nm: file.nm,
                            src: file.src,
                        })
                    }
                    setCreateAskBoard(copy);
                });
        },
        onDeleteFile: (idx: number) => {
            SwalUtil.ynPromise({
                msg: t(I18nKeys.board.askBoardAskDelete.key),
            }).then(() => {
                const copy = CopyUtil.copyAll(createAskBoard);
                copy.attachFiles = [...copy.attachFiles.slice(0, idx), ...copy.attachFiles.slice(idx + 1, copy.attachFiles.length)];
                setCreateAskBoard(copy);
            }).catch(ApolloCatch({}));
        },
        onDownloadFile: DataUtil.downloadFile,
        onCreate: () => {
            if (!createAskBoard.title) {
                SwalUtil.ok({
                    msg: t(I18nKeys.board.swalBlankTitle.key),
                    icon: 'warning'
                });
                return;
            }

            if (!createAskBoard.contents) {
                SwalUtil.ok({
                    msg: t(I18nKeys.board.swalBlankContent.key),
                    icon: 'warning'
                });
                return;
            }

            SwalUtil.ynPromise({
                msg: t(I18nKeys.board.swalApplyQuestion.key)
            }).then(() => {
                return mutCreate({
                    variables: {input: createAskBoard}
                });
            }).then(() => {
                SwalUtil.ok({
                    msg: t(I18nKeys.board.swalApplyQuestionOK.key),
                    icon: 'success'
                });
                props.onCancel();
                return;
            }).catch(ApolloCatch({}));
        }
    }

    return (
        <BizModal
            title={t(I18nKeys.layout.board.key)}
            isShow={props.isShow}
            onCancel={props.onCancel}>
            <div style={{padding: '0 10px 10px 10px'}}>

                <BizSmTitle>{t(I18nKeys.board.titleContent.key)}</BizSmTitle>
                <input
                    value={createAskBoard.title}
                    style={{marginBottom: 10}}
                    placeholder={t(I18nKeys.board.swalBlankTitle.key)}
                    className='form-control form-control-sm'
                    onChange={ev => setCreateAskBoard({...createAskBoard, title: ev.target.value})}/>
                <InputReactQuill
                    disableHTML
                    value={createAskBoard.contents}
                    onChangeValue={v => setCreateAskBoard({...createAskBoard, contents: v})}/>


                <BizSmTitle
                    actions={
                        <button

                            className='btn btn-sm btn-outline-success'
                            onClick={handler.onAddFile}>
                            {t(I18nKeys.board.btnAddFile.key)}
                        </button>
                    }
                >{t(I18nKeys.board.attachment.key)}</BizSmTitle>
                <div className={styles.contFile}>
                    <div className={styles.boxFile}>
                        {createAskBoard.attachFiles.length === 0 &&
                        <div className={styles.infoContents}>{t(I18nKeys.board.noAttachment.key)}</div>}
                        {createAskBoard.attachFiles.map((value, index) => (
                            <div className={styles.rowFile}>

                                <div
                                    className={styles.attachFile}>
                                    <span
                                        onClick={() => handler.onDownloadFile(value.src)}>
                                        <FileIcon fileNm={value.nm}/>{index + 1}. {value.nm}
                                    </span>
                                </div>
                                <div className={styles.space}/>
                                <Tooltip
                                    title={t(I18nKeys.board.ttDelete.key) || ""}
                                    onClick={() => handler.onDeleteFile(index)}>
                                    <Close/>
                                </Tooltip>
                            </div>
                        ))}
                    </div>

                    <div className={styles.infoContents}>{t(I18nKeys.board.askBoardModalFileSizeInfo.key)}</div>
                    <div className={styles.infoContents}>{t(I18nKeys.board.askBoardFileExtensionInfo.key)}</div>
                </div>


                <div style={{height: 15}}/>
                <BizSmTitle>{t(I18nKeys.board.contact.key)}</BizSmTitle>
                <input
                    value={createAskBoard.contact}
                    style={{marginBottom: 10}}
                    placeholder={t(I18nKeys.board.swalBlankContact.key)}
                    className='form-control form-control-sm'
                    onChange={ev => setCreateAskBoard({...createAskBoard, contact: ev.target.value})}/>
                <div className={styles.infoContents}>{t(I18nKeys.board.askBoardModalContactInfo.key)}</div>
                <div style={{height: 20}}/>
                <hr/>
                <div className={styles.infoContents}>{t(I18nKeys.board.questionDescription.key)}</div>
                <div className='text-right'>
                    <button
                        style={{marginRight: 10}}
                        className='btn btn-sm btn-outline-success'
                        onClick={props.onCancel}>
                        {t(I18nKeys.board.cancelAskBoardModal.key)}
                    </button>
                    <button
                        className='btn btn-sm btn-success'
                        onClick={handler.onCreate}>
                        {t(I18nKeys.board.btnApplyQuestion.key)}
                    </button>
                </div>
            </div>
        </BizModal>
    )
}

const FileIcon = (props: {
    fileNm: string
}) => {
    const split = props.fileNm.split('.');
    const fileExtension = split[split.length - 1].toLowerCase();

    return (
        <>
            {fileExtension === 'jpg' && <img src={imgJPG} className={styles.fileExtensionImg} alt={fileExtension}/>}
            {fileExtension === 'png' && <img src={imgPNG} className={styles.fileExtensionImg} alt={fileExtension}/>}
            {fileExtension === 'svg' && <img src={imgSVG} className={styles.fileExtensionImg} alt={fileExtension}/>}
            {fileExtension === 'pdf' && <img src={imgPDF} className={styles.fileExtensionImg} alt={fileExtension}/>}
            {fileExtension === 'zip' && <img src={imgZIP} className={styles.fileExtensionImg} alt={fileExtension}/>}

        </>

    )
}


const gqlCreateAskBoard = gql`
    mutation Create($input: CreateAskBoard!) {
        createMyAskBoard(input: $input)
    }
`;
