import React, {Fragment, ReactNode} from 'react';
import {OnChangeValue} from "../../../../Intefaces";
import {useSelector} from "react-redux";
import {DataState} from "../../../../../redux/Data";
import style from './InputNationPicker.module.scss'
import classNamesBind from "classnames/bind";

const cx = classNamesBind.bind(style);

export const InputNationPicker = (props: {
    title: ReactNode,
    value: string,
    onChange: OnChangeValue<string>
}) => {
    const data = useSelector<any, DataState>(state => state.data);

    return (
        <Fragment>
            <div className={cx('title')}>{props.title}</div>
            <div className={cx('box')}>
                <div className={cx('label')}/>
                <select
                    className='form-control form-control-sm'
                    value={props.value}
                    onChange={event => {
                        props.onChange(event.target.value)
                    }}>
                    {data.nationCode.map((value, index) => {
                        return (
                            <option key={index} value={value.code}>
                                [{value.code}] {value.shortNm}
                            </option>
                        )
                    })}
                </select>
            </div>
            <div style={{height: 20}}/>
        </Fragment>
    )
}
