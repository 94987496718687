import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './ShutDown.module.scss';
import {CenterContents} from "../../shares/centerContents/CenterContents";
import imgWarning from '../../../assets/svg/warning.svg';
import {useTranslation} from "react-i18next";

const cx = classNamesBind.bind(styles);

export const ShutDown = () => {
    const [t] = useTranslation('common');

    return (
        <CenterContents animatedContents backgroundColor={'#ececec'}>
            <div className={cx('cont')}>
                <div className='text-center'>
                    <img src={imgWarning} alt='warning'/>
                    <h3>{t('system.shutdown')}</h3>
                </div>

                <p className={cx('desc')}
                   ref={ref => {
                       if (ref) {
                           ref.innerHTML = t('system.shutdownDesc')
                       }
                   }}/>

            </div>
        </CenterContents>

    )
}
