import React, {Fragment, ReactNode} from 'react';
import classNamesBind from "classnames/bind";
import styles from './InputTotalSales.module.scss';
import {useTranslation} from "react-i18next";
import NumberFormat from "react-number-format";
import {CopyUtil} from "../../../../../utils/copyUtil/copyUtil";
import {Tooltip} from "@material-ui/core";
import {AddCircleOutline, Close} from "@material-ui/icons";
import {TotalSales} from '../../../../../graphql/customScalars';

const cx = classNamesBind.bind(styles);

export const InputTotalSales = (props: {
    title: ReactNode,
    value: TotalSales,
    onChangeValue: (value: TotalSales) => void,
}) => {
    const [t] = useTranslation('common');

    return (
        <Fragment>
            <div className={cx('title')}>{props.title}</div>
            <p className={cx('info')}>{t('mypageJoin.totalSalesDesc')}</p>

            <div className={cx('table')}>
                <div className={cx('header', 'col-delete')}/>
                <div className={cx('header', 'col-year')}>{t('mypageJoin.year')}</div>
                <div className={cx('header', 'col-sales')}>{t('mypageJoin.sales')}</div>
            </div>

            {props.value.map((value, index) => {
                return (
                    <div key={index} className={cx('table', 'hover')}>
                        <div className={cx('col', 'col-delete')}>
                            <Tooltip title={t('mypageJoin.btnDelete') || ''} onClick={event => {
                                let copy = CopyUtil.copy(props.value);
                                copy = [...copy.slice(0, index), ...copy.slice(index + 1, copy.length)]
                                props.onChangeValue(copy);
                            }}>
                                <Close className={cx('btn-add')} style={{marginTop: -8}}/>
                            </Tooltip>
                        </div>
                        <div className={cx('col', 'col-input', 'col-year')}>
                            <NumberFormat
                                className='form-control form-control-sm text-center'
                                value={value.year}
                                allowNegative={false}
                                decimalScale={0}
                                onValueChange={values => {
                                    const copy = CopyUtil.copy(props.value);
                                    copy[index].year = values.floatValue || 0;
                                    props.onChangeValue(copy);
                                }}/>
                        </div>
                        <div className={cx('col', 'col-input', 'col-usage')}>
                            <NumberFormat
                                thousandSeparator
                                className='form-control form-control-sm text-right'
                                value={value.sales}
                                displayType='input'
                                decimalScale={0}
                                onValueChange={values => {
                                    const copy = CopyUtil.copy(props.value);
                                    copy[index].sales = values.floatValue || 0;
                                    props.onChangeValue(copy);
                                }}/>
                        </div>
                    </div>
                )
            })}


            <div className={cx('table')}>
                <div className={cx('col', 'col-delete')}/>
                <div className={cx('col', 'col-add')}>
                    <Tooltip title={t('mypageJoin.btnAdd') || ''} onClick={event => {
                        const copy = CopyUtil.copy(props.value);
                        copy.push({year: new Date().getFullYear(), sales: 0});
                        props.onChangeValue(copy);
                    }}>
                        <AddCircleOutline className={cx('btn-add')}/>
                    </Tooltip>
                </div>
            </div>

            <div style={{height: 15}}/>

        </Fragment>
    )
}
