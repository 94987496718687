import React, {useEffect, useState} from 'react';
import classNamesBind from "classnames/bind";
import styles from './BizGoodsDataViewDefInfoInteraction.module.scss';
import {GoodsData} from "../../../../../graphql/customScalars";
import {useSelector} from "react-redux";
import {UserState} from "../../../../../redux/User";
import {gql} from "@apollo/client/core";
import {useLazyQuery, useMutation} from "@apollo/client";
import {
    Mutation,
    MutationDislikeGoodsArgs,
    MutationLikeGoodsArgs,
    Query,
    QueryIsLikeGoodsArgs
} from "../../../../../graphql/types";
import {BizSocialShareModal} from "../../../bizSocialShareModal/BizSocialShareModal";
import {navigate, usePath} from "hookrouter";
import {Button} from "@material-ui/core";
import {AssignmentIndOutlined, Favorite, FavoriteBorder, ShareOutlined} from "@material-ui/icons";
import {ApolloCatch} from "../../../../../utils/apollo/apolloCatch";
import {useTranslation} from "react-i18next";
import {SwalUtil} from "../../../../../utils/swal/swalUtil";
import {I18nKeys} from "../../../../../i18n/i18nKeys";
import {BizAskBoardModal} from "../../../bizAskBoardModal/BizAskBoardModal";
import {OnChangeValue} from "../../../../Intefaces";

const cx = classNamesBind.bind(styles);

export const BizGoodsDataViewDefInfoInteraction = (props: {
    goodsId?: number,
    goodsInfoId?: number,
    companyUserId?: number,
    goodsData: GoodsData,
    onCreateChatRoomWithGoodsInfoId?: OnChangeValue<number>
}) => {
    const user = useSelector<any, UserState>(state => state.user);
    const [getLike, resLike] = useLazyQuery<Query, QueryIsLikeGoodsArgs>(gqlData);
    const [mutLike] = useMutation<Mutation, MutationLikeGoodsArgs>(gqlLike);
    const [mutDislike] = useMutation<Mutation, MutationDislikeGoodsArgs>(gqlDislike);
    const [like, setLike] = useState(false);
    const [shareModal, setShareModal] = useState(false);
    const [askBoardModal, setAskBoardModal] = useState(false);
    const path = usePath();
    const [t] = useTranslation('common');


    useEffect(() => {
        if (!(props.goodsId && user.isLogin)) {
            return;
        }

        getLike({
            variables: {
                goodsId: props.goodsId
            }
        });
    }, [props.goodsId, user.isLogin, getLike]);


    useEffect(() => {
        if (!resLike.data) {
            return;
        }
        setLike(resLike.data.isLikeGoods);
    }, [resLike]);

    const handler = {
        onClickShare: () => {
            setShareModal(!shareModal);
        },
        onClickDislike: () => {
            if (!props.goodsId) {
                return;
            }

            if (!resLike.refetch) {
                return;
            }

            mutDislike({
                variables: {
                    goodsId: props.goodsId
                }
            }).then(() => {
                return resLike.refetch()
            }).catch(ApolloCatch({}))
        },
        onClickLike: () => {
            if (!props.goodsId) {
                return;
            }

            if (!resLike.refetch) {
                return;
            }

            mutLike({
                variables: {
                    goodsId: props.goodsId
                }
            }).then(() => {
                return resLike.refetch()
            }).catch(ApolloCatch({}))
        },
        onClickChat: () => {
            SwalUtil.ok({
                msg: t(I18nKeys.swal.preparing.key),
                icon: 'success',
            })
        },
        onClickAskBoard: () => {
            setAskBoardModal(true);
        },
        onClickNeedLogin: () => {
            SwalUtil.ynPromise({
                msg: t(I18nKeys.swal.needLogin.key),
                okBtn: t(I18nKeys.swal.btnLogin.key),
                cancelBtn: t(I18nKeys.swal.btnClose.key),
            }).then(() => {
                navigate('/auth/login')
            }).catch(ApolloCatch({}));
        },
    }

    return (
        <div className='text-right'>
            <BizSocialShareModal
                shareData={{
                    nm: props.goodsData.nm,
                    url: `${window.location.protocol}//${window.location.host}${path}`,
                    desc: props.goodsData.catchphrase,
                    imgPath: props.goodsData.goodsImage[0].src,
                }}
                open={shareModal}
                onClose={() => setShareModal(false)}/>

            {(props.companyUserId && props.goodsId && props.goodsInfoId) &&
            <BizAskBoardModal
                isShow={askBoardModal}
                onCancel={() => setAskBoardModal(false)}
                goodsId={props.goodsId}
                goodsInfoId={props.goodsInfoId}
                companyId={props.companyUserId}/>
            }


            {/* 로그아웃 상태 */}
            {(!user.isLogin && Boolean(props.goodsId)) &&
            <>
                <Button
                    variant="outlined"
                    size='small'
                    style={{marginLeft: 10}}
                    classes={{
                        root: cx('btn-root'),
                        label: cx('btn-color')
                    }}
                    startIcon={<FavoriteBorder color='secondary'/>}
                    onClick={handler.onClickNeedLogin}>
                    {t("goods.ttCancelFavoriteGoods")}
                </Button>

                <Button
                    variant='outlined'
                    size='small'
                    style={{marginLeft: 10}}
                    classes={{
                        root: cx('btn-root'),
                        label: cx('btn-color')
                    }}
                    startIcon={<AssignmentIndOutlined/>}
                    onClick={handler.onClickNeedLogin}>
                    {t(I18nKeys.layout.board.key)}
                </Button>
            </>
            }


            {/* 로그인 상태 */}
            {(user.isLogin && Boolean(props.goodsId)) &&
            <>
                {like &&
                <Button
                    variant="outlined"
                    size='small'
                    style={{marginLeft: 10}}
                    classes={{
                        root: cx('btn-root'),
                        label: cx('btn-color')
                    }}
                    startIcon={<Favorite color='secondary'/>}
                    onClick={handler.onClickDislike}>
                    {t("goods.ttCancelFavoriteGoods")}
                </Button>
                }

                {!like &&
                <Button
                    variant="outlined"
                    size='small'
                    style={{marginLeft: 10}}
                    classes={{
                        root: cx('btn-root'),
                        label: cx('btn-color')
                    }}
                    startIcon={<FavoriteBorder/>}
                    onClick={handler.onClickLike}>
                    {t("goods.ttAllowFavoriteGoods")}
                </Button>
                }

                {/* 채팅기능 주석처리 - 20230406 daekyu.ju */}
                {/*
                    props.goodsInfoId
                    && props.onCreateChatRoomWithGoodsInfoId
                    &&
                    <Button
                        variant='outlined'
                        size='small'
                        style={{marginLeft: 10}}
                        classes={{
                            root: cx('btn-root'),
                            label: cx('btn-color')
                        }}
                        startIcon={<ChatBubble/>}
                        onClick={() => props.onCreateChatRoomWithGoodsInfoId!(props.goodsInfoId!)}>
                        {t(I18nKeys.company.chat.key)}
                    </Button>
                */}

                <Button
                    variant='outlined'
                    size='small'
                    style={{marginLeft: 10}}
                    classes={{
                        root: cx('btn-root'),
                        label: cx('btn-color')
                    }}
                    startIcon={<AssignmentIndOutlined/>}
                    onClick={handler.onClickAskBoard}>
                    {t(I18nKeys.layout.board.key)}
                </Button>
            </>
            }

            <Button
                variant="outlined"
                size='small'
                style={{marginLeft: 10}}
                classes={{
                    root: cx('btn-root'),
                    label: cx('btn-color')
                }}
                startIcon={<ShareOutlined/>}
                onClick={handler.onClickShare}>
                {t("goods.ttShareGoods")}
            </Button>
        </div>
    )
}

const gqlData = gql`
    query isLikeGoods($goodsId: Int!) {
        isLikeGoods(goodsId: $goodsId)
    }
`;

const gqlLike = gql`
    mutation likeGoods($goodsId: Int!) {
        likeGoods(goodsId: $goodsId)
    }
`;

const gqlDislike = gql`
    mutation dislikeGoods($goodsId: Int!) {
        dislikeGoods(goodsId: $goodsId)
    }
`;

