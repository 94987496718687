import React, {ReactNode} from 'react';
import classNamesBind from "classnames/bind";
import styles from './MyPageGoodsTabs.module.scss';
import {Spring} from "react-spring/renderprops-universal";
import {OnChangeValue} from "../../../../Intefaces";

const cx = classNamesBind.bind(styles);

export const MyPageGoodsTabs = (props: {
    langCode: string;
    onChangeLangCode: OnChangeValue<string>;
    data: MyPageGoodsTabsData;
}) => {
    const langCodeList = Object.keys(props.data);
    const activeFontColor = 'rgb(255,255,255)';
    const inactiveFontColor = 'rgb(64,64,64)';
    const activeBarColor = 'rgba(13,179,230,1)';
    const inactiveBarColor = 'rgba(13,179,230,0)';

    return (
        <>
            <div className={cx('cont-tab')}>
                {langCodeList.map((value, index) => {
                    const isActivate = value === props.langCode;
                    return (
                        <Spring
                            key={index}
                            from={{
                                color: inactiveFontColor,
                                bar: inactiveBarColor
                            }}
                            to={{
                                color: isActivate ? activeFontColor : inactiveFontColor,
                                bar: isActivate ? activeBarColor : inactiveBarColor,
                            }}>
                            {params => (
                                <>
                                    <div
                                        style={{background: params.bar, color: params.color}}
                                        className={cx('btn-tab')}
                                        onClick={() => {
                                            props.onChangeLangCode(value);
                                        }}>
                                        {props.data[value].title}
                                        {/*<div*/}
                                        {/*    style={{background: params.bar}}*/}
                                        {/*    className={cx('bar')}/>*/}
                                    </div>
                                </>

                            )}
                        </Spring>
                    )
                })}
            </div>

            {Object.keys(props.data).map((value, index) => (
                <div key={index}>
                    {props.langCode === value && props.data[props.langCode].children}
                </div>
            ))}
            {/*{props.data[props.langCode] && props.data[props.langCode].children}*/}
        </>

    )
}

export type MyPageGoodsTabsData = {
    [langCode: string]: { title: ReactNode, children: ReactNode}
}
