import React, {ReactNode, useEffect, useRef, useState} from 'react';
import classNamesBind from "classnames/bind";
import styles from './YoutubeViewer.module.scss';
import YouTube from "react-youtube";
import {BizTitle} from "../bizTitle/BizTitle";
import Decimal from "decimal.js";
import {Validator} from "../../../utils/validator/Validator";

const cx = classNamesBind.bind(styles);

interface BoxYoutubeSize {
    width: number;
    height: number;
}

export const YoutubeViewer = (props: {
    title: ReactNode,
    link: string,
}) => {
    const contRef = useRef<HTMLDivElement | null>(null);
    const [boxYoutubeSize, setBoxYouTubeSize] = useState<BoxYoutubeSize>({
        width: 0,
        height: 0
    })

    useEffect(() => {
        const {current} = contRef;
        if (!current) {
            return;
        }

        const {offsetWidth} = current;

        setBoxYouTubeSize({
            width: offsetWidth,
            height: new Decimal(offsetWidth).mul(3).divToInt(5).toNumber(),
        })

    }, [contRef]);

    let videoId = Validator.extractYoutubeIdWithinLink(props.link);

    return (
        <>

            {videoId &&
            <div className={cx('cont')}
                 ref={contRef}>
                <BizTitle>{props.title}</BizTitle>
                <div style={{height: 20}}/>
                <YouTube
                    videoId={videoId}
                    opts={{
                        width: `${boxYoutubeSize.width}px`,
                        height: `${boxYoutubeSize.height}px`
                    }}/>
            </div>
            }
        </>
    )
}
