import React, {useEffect, useState} from 'react';
import {gql, useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {
    AskBoardStatus,
    CreateAskBoardReply,
    Mutation,
    MutationCreateCompanyAskBoardReplyArgs,
    MutationDeleteCompanyAskBoardReplyArgs,
    MutationUpdateCompanyAskBoardStatusArgs,
    Query,
    QueryMyGoodsArgs
} from "../../../../graphql/types";
import {BoardLayout} from "../../BoardLayout";
import {BoardViewContent} from "./content/BoardViewContent";
import {BoardViewReply} from "./reply/BoardViewReply";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import {ApolloCatch} from "../../../../utils/apollo/apolloCatch";
import {BizGoodsModal, BizGoodsModalData} from "../../../shares/bizGoodsModal/BizGoodsModal";
import {DataUtil} from "../../../../utils/data/DataUtil";
import {CopyUtil} from "../../../../utils/copyUtil/copyUtil";
import {useTranslation} from "react-i18next";
import {I18nKeys} from "../../../../i18n/i18nKeys";

export const BoardView = (props: {
    askBoardId: number,
}) => {
    const {data, refetch} = useQuery<Query, any>(gqlData, {
        variables: props
    });
    const [t] = useTranslation('common');

    const [getGoodsData] = useLazyQuery<Query, QueryMyGoodsArgs>(gqlMyGoods, {
        onCompleted: res => {
            setGoodsData({
                categoryId: res.myGoods.categoryId,
                companyDataMap: res.myGoods.company,
                goodsDataMap: res.myGoods.data
            });
        }
    });

    const [input, setInput] = useState<CreateAskBoardReply>({
        askBoardId: props.askBoardId,
        attachFiles: [],
        contents: "",
    });
    const [goodsData, setGoodsData] = useState<BizGoodsModalData | undefined>(undefined);
    const [mutUpdateStatus] = useMutation<Mutation, MutationUpdateCompanyAskBoardStatusArgs>(gqlUpdateStatus);
    const [mutCreateReply] = useMutation<Mutation, MutationCreateCompanyAskBoardReplyArgs>(gqlCreateReply);
    const [mutDeleteReply] = useMutation<Mutation, MutationDeleteCompanyAskBoardReplyArgs>(gqlDeleteReply);

    useEffect(() => {
        setInput({
            askBoardId: props.askBoardId,
            attachFiles: [],
            contents: "",
        });
    }, [props.askBoardId]);

    const askBoard = data?.companyAskBoardWithAskBoardId;
    const askBoardReply = data?.companyAskBoardReplyWithAskBoardId;

    const handler = {
        onUpdateStatus: (args: MutationUpdateCompanyAskBoardStatusArgs) => {
            SwalUtil.ynPromise({
                msg: args.askBoardStatus === AskBoardStatus.Done ? t(I18nKeys.board.swalAskStatusDone.key) : t(I18nKeys.board.swalAskStatusReask.key)
            }).then(() => {
                return mutUpdateStatus({
                    variables: args
                })
            }).then(() => {
                return refetch();
            }).then(() => {
                SwalUtil.ok({
                    msg: t(I18nKeys.board.swalChangeStatusDone.key),
                    icon: 'success'
                })
            }).catch(ApolloCatch({}));
        },
        onShowGoods: () => {
            if (!askBoard) {
                return;
            }

            if (!askBoard.goodsId) {
                return;
            }

            getGoodsData({
                variables: {
                    goodsId: askBoard.goodsId
                }
            });
        },
        onUploadAttachFile: () => {
            DataUtil.uploadFile().then(res => {
                const copy = CopyUtil.copyAll(input);
                for (let file of res) {
                    copy.attachFiles.push({
                        nm: file.nm,
                        src: file.src,
                    })
                }
                setInput(copy);
            })
        },
        onCreateReply: () => {
            if (!input.contents) {
                SwalUtil.ok({
                    msg: t(I18nKeys.board.swalBlankContent.key),
                    icon: 'warning'
                });
                return;
            }

            SwalUtil.ynPromise({
                msg: t(I18nKeys.board.swalCreateReply.key)
            }).then(() => {
                return mutCreateReply({
                    variables: {input}
                });
            }).then(() => {
                return refetch();
            }).then(() => {
                SwalUtil.ok({
                    msg: t(I18nKeys.board.swalCreateReplyOk.key),
                    icon: 'success'
                });
                setInput({
                    askBoardId: props.askBoardId,
                    attachFiles: [],
                    contents: "",
                });
            }).catch(ApolloCatch({}));
        },
        onDeleteAskBoardReply: (askBoardReplyId: number) => {
            SwalUtil.ynPromise({
                msg: t(I18nKeys.board.swalDeleteReply.key)
            }).then(() => {
                return mutDeleteReply({
                    variables: {askBoardReplyId}
                })
            }).then(() => {
                return refetch();
            }).then(() => {
                SwalUtil.ok({
                    msg: t(I18nKeys.board.swalDeleteReplyOk.key),
                    icon: 'success'
                })
            }).catch(ApolloCatch({}));
        },
        onHistoryBack: () => {
            window.history.back();
        }
    };

    return (
        <BoardLayout
            activatedRouterSuffix={'/company/board/list'}>
            {(askBoard && askBoardReply) &&
            <>
                {goodsData && <BizGoodsModal value={goodsData} onClose={() => setGoodsData(undefined)}/>}
                <BoardViewContent
                    {...handler}
                    askBoard={askBoard}/>
                <div style={{height: 30}}/>

                <BoardViewReply
                    {...handler}
                    value={input}
                    onChange={setInput}
                    askBoard={askBoard}
                    askBoardReply={askBoardReply}/>

                <hr/>
                <div style={{height: 50}}/>
                <div className='text-right'>
                    <button
                        onClick={handler.onHistoryBack}
                        className='btn btn-sm btn-outline-secondary'>{t(I18nKeys.board.btnHistoryBack.key)}</button>
                </div>

                <div style={{height: 100}}/>
            </>
            }
        </BoardLayout>
    )
}

const gqlData = gql`
    query DATA($askBoardId: Int!) {
        companyAskBoardWithAskBoardId(askBoardId: $askBoardId) {
            id
            allEduCode
            companyNm
            content
            createdAt
            goodsData
            goodsInfoId
            goodsId
            lastReplyWriter
            status
            contact
            title
            userCompanyNm
            userEmail
            attachFiles {
                nm
                src
            }
        }
        companyAskBoardReplyWithAskBoardId(askBoardId: $askBoardId) {
            attachFiles {
                nm
                src
            }
            content
            createdAt
            id
            writer
        }
    }
`;

const gqlUpdateStatus = gql`
    mutation UpdateStatus($askBoardId: Int!, $askBoardStatus: AskBoardStatus!) {
        updateCompanyAskBoardStatus(askBoardId: $askBoardId, askBoardStatus: $askBoardStatus)
    }
`;

const gqlMyGoods = gql`
    query MyGoods($goodsId: Int!) {
        myGoods(goodsId: $goodsId) {
            categoryId
            data
            company
        }
    }
`;

const gqlCreateReply = gql`
    mutation CreateReply($input: CreateAskBoardReply!) {
        createCompanyAskBoardReply(input: $input)
    }
`;

const gqlDeleteReply = gql`
    mutation DeleteReply($askBoardReplyId: Int!) {
        deleteCompanyAskBoardReply(askBoardReplyId: $askBoardReplyId)
    }
`;
