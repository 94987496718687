import {GoodsFindOptions, UpdateUserInfo} from "../../graphql/types";
import {ReduxStore} from "../../index";
import * as querystring from "querystring";
import {CopyUtil} from "../copyUtil/copyUtil";

export type CompanyListParam = { title: string, idxes: string[], idx: number };

export class Validator {
    static AllNameIdx = [
        'A', 'B', 'C',
        'D', 'E', 'F',
        'G', 'H', 'I',
        'J', 'K', 'L',
        'M', 'N', 'O',
        'P', 'Q', 'R',
        'S', 'T', 'U',
        'V', 'W', 'X',
        'Y', 'Z'
    ];

    static AllNumberIdx = [
        '1', '2', '3', '4', '5', '6', '7', '8', '9', '0'
    ]

    static NameIdxList: CompanyListParam[] = [
        {title: 'All', idxes: Validator.AllNameIdx, idx: 0},
        {title: 'A, B, C', idxes: ['A', 'B', 'C'], idx: 1},
        {title: 'D, E, F', idxes: ['D', 'E', 'F'], idx: 2},
        {title: 'G, H, I', idxes: ['G', 'H', 'I'], idx: 3},
        {title: 'J, K, L', idxes: ['J', 'K', 'L'], idx: 4},
        {title: 'M, N, O', idxes: ['M', 'N', 'O'], idx: 5},
        {title: 'P, Q, R', idxes: ['P', 'Q', 'R'], idx: 6},
        {title: 'S, T, U', idxes: ['S', 'T', 'U'], idx: 7},
        {title: 'V, W, X', idxes: ['V', 'W', 'X'], idx: 8},
        {title: 'Y, Z', idxes: ['Y', 'Z'], idx: 9},
    ]


    static getType(value: any): string {
        return Object.prototype.toString.call(value).slice(8, -1);
    }

    static copyAtoB<T>(a: T, b: T): T {
        const origin = a as any;
        const dest = b as any;

        for (let key in dest) {
            switch (Validator.getType(dest[key])) {
                case "Array":
                    if (dest[key].length === 0) {
                        dest[key] = origin[key]
                    }
                    break;
                default:
                    if (!dest[key]) {
                        dest[key] = origin[key]
                    }
            }
        }

        return dest;
    }

    static isRegion(value: string): string {
        const regionList = ReduxStore.getState().data.region;
        const idx = regionList.findIndex(v => {
            return v.region === value;
        })

        if (idx !== -1) {
            return value
        } else {
            return 'ASIA'
        }
    }


    // 인덱스에 사용되는 숫자인지 판단하고, 숫자가 아니면 0을 리턴한다.
    static isNumber(idx: any): number {
        if (!isNaN(idx)
            && (0 <= idx)) {
            return Number(idx)
        } else {
            return 0
        }
    }

    static isCompanyListParamIdx(idx: any): CompanyListParam {
        const numb = this.isNumber(idx);

        if (this.NameIdxList.length < numb) {
            return this.NameIdxList[0];
        } else {
            return this.NameIdxList[numb];
        }
    }

    static isCompanyListParamFistLetter(idx: any, letter: string): string[] {
        const companyListParam = this.isCompanyListParamIdx(idx);

        if (companyListParam.idxes.includes(letter)) {
            return [letter];
        } else {
            return companyListParam.idxes
        }
    }

    static isGoodsFindOptions(value?: string): GoodsFindOptions {
        const def: GoodsFindOptions = {
            langCode: ReduxStore.getState().i18n.langCode
        };

        if (!value) {
            return def;
        }

        const parsed = querystring.parse(window.location.href);

        debugger;

        for (let key in def) {
            if (parsed.hasOwnProperty(key)) {
                (def as any)[key] = parsed[key];
            }
        }
        return def;
    }

    static makeFindOptions(value: any): GoodsFindOptions {
        const def: GoodsFindOptions = {
            langCode: ReduxStore.getState().i18n.langCode
        };

        for (let key in def) {
            if (value.hasOwnProperty(key)) {
                (def as any)[key] = value[key];
            }
        }

        return def;
    }

    static validateUpdateUserInfo(value: UpdateUserInfo): boolean {
        const anyValue = CopyUtil.copyAll(value) as any;
        for (let key in anyValue) {
            if (!anyValue[key].hasOwnProperty('en')) {
                return false;
            }

            if (anyValue[key]['en'] === '') {
                return false;
            }
        }
        return true;
    }

    static isStartWithEnglishAndNumber(value: string): boolean {
        const firstLetterList = [
            ...this.AllNumberIdx,
            ...this.AllNameIdx,
        ];

        const idx = firstLetterList.findIndex(v => {
            return value.toLowerCase().startsWith(v.toLowerCase())
        })

        return idx !== -1
    }

    static extractYoutubeIdWithinLink(link: string): string | null {
        let videoId = ""
        if (link.startsWith("https://www.youtube.com/watch?v=")) {
            videoId = link.replaceAll("https://www.youtube.com/watch?v=", "")
        }

        if (link.startsWith("https://youtu.be/")) {
            videoId = link.replaceAll("https://youtu.be/", "")
        }

        return videoId ? videoId : null;

    }
}
