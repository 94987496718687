import {createActionCreators, createReducerFunction, ImmerReducer} from "immer-reducer";


export interface LayoutMobileState {
    showSidebar: boolean
}

const initState: LayoutMobileState = {
    showSidebar: false,
};

class LayoutMobile extends ImmerReducer<typeof initState> {
    setSidebar(swt: boolean) {
        this.draftState.showSidebar = swt;
    }
}


export const LayoutMobileAction = createActionCreators(LayoutMobile);
export const LayoutMobileReducer = createReducerFunction(LayoutMobile, initState);
