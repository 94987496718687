import React, { useState } from 'react';
import classNamesBind from "classnames/bind";
import styles from './MyPageGoodsListResult.module.scss';
import { GoodsPermission, Mutation, MutationRequestGoodsCertificateArgs, MutationToggleGoodsDisplayArgs, MyGoods, MyGoodsList, Scalars } from "../../../../../graphql/types";
import { BizNoDataTable } from '../../../../shares/bizNoDataTable/BizNoDataTable';
import { BizGoodsIconInTable } from "../../../../shares/bizGoodsIconInTable/BizGoodsIconInTable";
import { Button } from "@material-ui/core";
import { Assignment, Edit, History, SupervisorAccount, Visibility, VisibilityOff, DoneOutlineOutlined, CallMadeOutlined, CloudDownloadOutlined } from "@material-ui/icons";
import { navigate } from "hookrouter";
import { useTranslation } from "react-i18next";
import { OnChangeValue } from "../../../../Intefaces";
import { useSelector } from "react-redux";
import { I18nState } from "../../../../../redux/I18n";
import { BizSmTitle } from "../../../../shares/bizTitle/BizTitle";
import { I18nUtil } from '../../../../../utils/i18n/i18n';
import { BizExtendTable } from "../../../../shares/bizExtendTable/BizExtendTable";
import { BizGoodsModalData } from "../../../../shares/bizGoodsModal/BizGoodsModal";
import moment from "moment";
import { GoodsListResultProcessBar } from "./processBar/GoodsListResultProcessBar";
import { SwalUtil } from '../../../../../utils/swal/swalUtil';
import { gql } from '@apollo/client';
import { Apollo } from '../../../../../utils/apollo/apollo';
import { PDFDocument, rgb } from 'pdf-lib';
import download from "downloadjs";
import fontkit from '@pdf-lib/fontkit';


const cx = classNamesBind.bind(styles);

interface Props {
    myGoodsList: MyGoodsList,
    onToggleGoodsDisplay: OnChangeValue<MutationToggleGoodsDisplayArgs>
    onShowGoodsData: OnChangeValue<BizGoodsModalData>,
    onRequestGoodsCertificate: OnChangeValue<MutationRequestGoodsCertificateArgs>,
}

export const MyPageGoodsListResult = (props: Props) => {
    return (
        <>
            {props.myGoodsList.list.map((value, index) => (
                <BizExtendTable key={index} data={{
                    header: <Summary value={value} {...props} />,
                    contents: <Detail value={value} {...props} />
                }} />
            ))}

        </>
    )
}

export const Summary = (props: {
    value: MyGoods,
    onShowGoodsData: OnChangeValue<BizGoodsModalData>,
}) => {
    const [t] = useTranslation('common');

    return (
        <>

            <div className={cx('cont-summary')}>
                <div
                    style={{ cursor: 'pointer' }}
                    className={cx('box-goods')}
                    onClick={() => props.onShowGoodsData({
                        categoryId: props.value.categoryId,
                        companyDataMap: props.value.companyData,
                        goodsDataMap: props.value.goodsData

                    })}>
                    <BizGoodsIconInTable
                        goodsDataMap={props.value.goodsData}
                        categoryId={props.value.categoryId} />
                </div>
                <div className={cx('box-alleducode')}>
                    {`${props.value.allEduCode}-${props.value.goodsInfoId}`}
                </div>
                <div className={cx('box-info')}>
                    {props.value.isActivate &&
                        <>
                            <Visibility className={cx('green')} /> {t("mypageGoods.badgeIsActivate")}
                        </>
                    }

                    {!props.value.isActivate &&
                        <>
                            <VisibilityOff className={cx('grey')} /> {t("mypageGoods.badgeIsNotActivate")}
                        </>
                    }
                </div>
                <div className={cx('box-info')}>
                    <SupervisorAccount /> {props.value.consultant.length} {t("mypageGoods.person")}
                </div>
                <div className={cx('box-info')}>
                    <History /> {props.value.history.length} {t("mypageGoods.value")}
                </div>
            </div>

            {!props.value.isActivate &&
                <>
                    <GoodsListResultProcessBar
                        status={props.value.isActivate ? 'Done' : 'Ready'} />
                    <div style={{ height: 5 }} />
                </>
            }


        </>
    )
}

export const Detail = (props: {
    value: MyGoods
    onToggleGoodsDisplay: OnChangeValue<MutationToggleGoodsDisplayArgs>
    onShowGoodsData: OnChangeValue<BizGoodsModalData>,
    onRequestGoodsCertificate: OnChangeValue<MutationRequestGoodsCertificateArgs>
}) => {
    const consultant = props.value.consultant;
    const history = props.value.history;
    const langCode = useSelector<any, I18nState>(state => state.i18n).langCode;
    const [t] = useTranslation('common');

    const [certificationRequestButtonDisabled, isCertificationRequestButtonDisabled] = useState<boolean>(
        props.value.productWarranty !== "" ? false : true
    );

    //
    // 품질보증서 업로드
    ///////////////////////////////////////////////////////////////////////
    interface IProductWarranty {
        file: Scalars['Upload'],
        onUpload: Function,
        display: Function
    };

    const productWarranty: IProductWarranty = {
        file: undefined,
        onUpload: (btnId: string) => {
            let fileInput = document.createElement('input');
            fileInput.type = 'file';
            fileInput.accept = "application/pdf, image/jpg, image/png, image/gif";
            fileInput.onchange = () => {
                let files = fileInput.files;

                // 업로드 파일의 유효성을 검사한다.
                function validate() {
                    if(files == null) return false;                    

                    let file = files![0];
                    if ((10 * 1024 * 1024) < file.size) {
                        return false;
                    }

                    return true;
                }

                // 업로드 파일을 서버로 전송한다.
                function upload() {
                    Apollo.client.mutate<Mutation, Scalars['Upload']>({
                        mutation: gql`
                            mutation UploadProductWarrantyFile($goodsId: Int!, $input: Upload!) {
                                uploadProductWarrantyFile(goodsId: $goodsId, input: $input) {
                                    id
                                    nm
                                    src
                                    createdAt
                                }
                            }
                        `,
                        variables: {
                            goodsId: props.value.id,
                            input: files![0]
                        }
                    }).then(res => {
                        displayLink(res.data?.uploadProductWarrantyFile);
                        disableCertificationRequestButton(res.data?.uploadProductWarrantyFile);
                    }).catch(reason => {
                        if(reason.message === "INVALID_FILE_TYPE") {
                            SwalUtil.ok({
                                msg: t('common.swalInvalidFileType'),
                                icon: "error"
                            })
                        }
                    });
                }

                // 업로드 파일의 링크를 생성하여 표시한다.
                function displayLink(uploadedFile: Scalars['Upload']) {
                    const btnProductWarrantyRegistration = document.getElementById(btnId);
                    const br = document.createElement("br");
                    const a = document.createElement("a");

                    let element;
                    while(true) {
                        element = btnProductWarrantyRegistration?.nextSibling
                        if(element === null || element === undefined) {
                            break;
                        }
                        element.remove();
                    }

                    a.href = "#";
                    a.text = uploadedFile.nm;
                    a.onclick = () => {
                        let win = window.open(uploadedFile.src, '_blank');
                        win?.focus();
                        return false;
                    }

                    btnProductWarrantyRegistration?.after(br)
                    br?.after(a);
                }

                // [인증신청서]파일 존재유무에 따라 상품인증요청 버튼을 활성/비활성화 한다.
                function disableCertificationRequestButton(uploadedFile: Scalars['Upload']) {
                    isCertificationRequestButtonDisabled(
                        uploadedFile ? false : true
                    ); 
                }

                // 파일이 유효하지 않을 경우 에러 메시지 출력한다.
                if(!validate()) {
                    SwalUtil.ok({
                        msg: t('common.swalUploadFileError'),
                        icon: "error"
                    })
                    return;
                }

                upload();
            }

            fileInput.click();
        },
        display: (url: string) => {
            let tokens = url.split('/');
            let fileName = tokens[tokens.length - 1];

            return (
                <>
                    <br/>
                    <a href="#!" onClick={() => {
                        let win = window.open(url, '_blank');
                        win?.focus();
                        return false;  
                    }}>{fileName}</a>
                </>
            );
        }
    };

    //
    // 상품인증서 
    ///////////////////////////////////////////////////////////////////////
    const goodsCertificate = {
        //
        // 다운로드 버튼 활성화
        //
        disableButton: () => {
            return props.value.isCertificated === "Y" ? false : true;
        },

        //
        // 다운로드
        //
        downloadPdf: () => {
            if(props.value.isCertificated !== "Y") return;

            const allEduCode = props.value.allEduCode + "-" + props.value.goodsInfoId;
            const companyName = props.value.companyData[langCode].companyNm;
            let goodsName = props.value.goodsData[langCode].nm;
            if(goodsName === "") {
                goodsName = props.value.goodsData["en"].nm;
            }

            const beginDate: Date | undefined | null = props.value.certificateBeginDate;
            const endDate: Date | undefined | null = props.value.certificateEndDate;

            const templateUrl = "/te-statics/goods_certificate_mark/goods_certificate_template.pdf";
            const fontUrl = "/te-statics/font/nato-sans/NotoSansKR-Light.otf";

            // Date를 문자열로 변환
            const convertDateToString = (d: Date | undefined | null) => {
                if(d === undefined || d === null) return "";
                return moment(d).format("YYYY년 MM월 DD일");
            }
    
            // PDF인증서 생성 및 다운로드
            const generateAndDownload = async (
                templateContent: Promise<ArrayBuffer>, fontContent: Promise<ArrayBuffer>
            ) => {
                const pdfDoc = await PDFDocument.load(await templateContent);
                pdfDoc.setTitle(goodsName + "[" + allEduCode + "]");
                pdfDoc.registerFontkit(fontkit);
    
                const font = await pdfDoc.embedFont(await fontContent);
                const pages = pdfDoc.getPages();
                const firstPage = pages[0];
    
                const textInfo = {
                    x: 455,
                    y: 365,
                    size: 12,
                    font: font,
                    color: rgb(0, 0, 0),
                    lineHeight: 30
                };
    
                firstPage.setFont(textInfo.font);
                firstPage.setFontColor(textInfo.color);
                firstPage.setFontSize(textInfo.size)
    
                firstPage.drawText(allEduCode, {
                    x: textInfo.x,
                    y: textInfo.y
                });
    
                firstPage.drawText(companyName, {
                    x: textInfo.x,
                    y: textInfo.y - (textInfo.lineHeight * 1)
                });
    
                firstPage.drawText(goodsName, {
                    x: textInfo.x,
                    y: textInfo.y - (textInfo.lineHeight * 2) - 4
                });
    
                /*
                firstPage.drawText(t("mypageGoods.certificationType"), {
                    x: textInfo.x,
                    y: textInfo.y - (textInfo.lineHeight * 3) - 7
                });
                */
    
                firstPage.setFontSize(11);
                firstPage.drawText(convertDateToString(beginDate), {
                    x: 370,
                    y: 146
                });
    
                firstPage.drawText(convertDateToString(endDate), {
                    x: 558,
                    y: 146
                });
    
                const pdfBytes = await pdfDoc.save();
                download(pdfBytes, pdfDoc.getTitle() + ".pdf", "application/pdf");
            };
    
            // 인증서 PDF템플릿 다운로드, 한글 폰트 다운로드
            fetch(templateUrl).then(response => {
                response.blob().then(blob => {        //    const fileUrl = window.URL.createObjectURL(blob);
                    const templateContent = blob.arrayBuffer();
                    
                    fetch(fontUrl).then(response => {
                        response.blob().then(blob => {
                            const fontContent = blob.arrayBuffer();
                            generateAndDownload(templateContent, fontContent);
                        });
                    });
                });
            })
        }
    }
    


    return (
        <div className={cx('cont-detail')}>
            <div className={cx('box-full')}>
                <div className={cx('table')}>
                    <div className={cx('col-full', 'text-right')}>
                        {props.value.goodsPermission.includes(GoodsPermission.Display) &&
                            <>
                                {props.value.isActivate &&
                                    <Button
                                        variant="outlined"
                                        startIcon={<VisibilityOff />}
                                        onClick={() => {
                                            props.onToggleGoodsDisplay({
                                                goodsId: props.value.id,
                                                swt: !props.value.isActivate
                                            })
                                        }}>
                                        {t("mypageGoods.ttInvisible")}
                                    </Button>
                                }

                                {!props.value.isActivate &&
                                    <Button
                                        variant="outlined"
                                        startIcon={<Visibility />}
                                        onClick={() => {
                                            props.onToggleGoodsDisplay({
                                                goodsId: props.value.id,
                                                swt: !props.value.isActivate
                                            })
                                        }}>
                                        {t("mypageGoods.ttVisible")}
                                    </Button>
                                }
                            </>
                        }

                    </div>
                </div>
            </div>


            <div className={cx('box-full')}>
                <BizSmTitle>{t("mypageGoods.titleConsultant")}</BizSmTitle>
                <div className={cx('table', 'table-consultant')}>
                    <div className={cx('header', 'col-position')}>{t("mypageGoods.position")}</div>
                    <div className={cx('header', 'col-nm')}>{t("mypageGoods.nm")}</div>
                    <div className={cx('header', 'col-email')}>{t("mypageGoods.email")}</div>
                    <div className={cx('header', 'col-tel')}>{t("mypageGoods.tel")}</div>
                    <div className={cx('header', 'col-lang')}>{t("mypageGoods.language")}</div>
                </div>

                {consultant.length === 0 && <BizNoDataTable />}

                {consultant.map((consultant, consultantIdx) => (
                    <div key={consultantIdx}
                        className={cx('table', 'table-consultant', 'hover')}>
                        <div
                            className={cx('col', 'col-position', consultant.consultant.isActivate ? '' : 'line-through')}>
                            {I18nUtil.value(langCode, consultant.consultant.position)}
                        </div>
                        <div
                            className={cx('col', 'col-nm', consultant.consultant.isActivate ? '' : 'line-through')}>
                            {I18nUtil.value(langCode, consultant.consultant.nm)}
                        </div>
                        <div
                            className={cx('col', 'col-email', consultant.consultant.isActivate ? '' : 'line-through')}>
                            {consultant.consultant.email}
                        </div>
                        <div
                            className={cx('col', 'col-tel', consultant.consultant.isActivate ? '' : 'line-through')}>
                            {I18nUtil.value(langCode, consultant.consultant.tel)}
                        </div>
                        <div className={cx('col', 'col-lang')}>{consultant.langCode}</div>
                    </div>
                ))}

                <div className={cx('table')}>
                    <div className={cx('col-full', 'text-right')}>
                        <Button
                            variant="outlined"
                            startIcon={<Assignment />}
                            onClick={() => navigate(`/mypage/goods/bind/${props.value.id}`)}>
                            {t("mypageGoods.btnAddConsultant")}
                        </Button>
                    </div>
                </div>
            </div>

            <div className={cx('box-full')}>
                <BizSmTitle>{t("mypageGoods.titleChangedLog")}</BizSmTitle>
                <div className={cx('table', 'table-history')}>
                    <div className={cx('header', 'col-goods')}>{t("mypageGoods.goods")}</div>
                    <div className={cx('header', 'col-code')}>{t("mypageGoods.alleduCode")}</div>
                    <div className={cx('header', 'col-func')}>{t("mypageGoods.registryAt")}</div>
                </div>

                {history.length === 0 && <BizNoDataTable />}

                {history.map((history, historyIdx) => (
                    <div key={historyIdx} className={cx('table', 'table-history', 'hover')}>
                        <div
                            className={cx('col-goods', 'pointer')}
                            onClick={() =>
                                props.onShowGoodsData({
                                    companyDataMap: props.value.companyData,
                                    goodsDataMap: history.goodsData,
                                    categoryId: history.categoryId,
                                })
                            }>
                            <BizGoodsIconInTable
                                goodsDataMap={history.goodsData}
                                categoryId={history.categoryId} />
                        </div>
                        <div className={cx('col', 'col-code')}>
                            {`${props.value.allEduCode}-${history.goodsInfoId}`}
                        </div>
                        <div className={cx('col', 'col-func')}>
                            {moment(`${history.createdAt}`).format('YY.MM.DD')}
                        </div>
                    </div>
                ))}

                <div className={cx('table')}>
                    <div className={cx('space', 'text-right')}>
                        {props.value.goodsPermission.includes(GoodsPermission.Update) &&
                            <Button
                                variant="outlined"
                                startIcon={<Edit />}
                                onClick={() => {
                                    navigate(`/mypage/goods/update/${props.value.id}`);
                                }}>
                                {t("mypageGoods.ttUpdate")}
                            </Button>
                        }
                    </div>
                </div>
            </div>

            <div className={cx('box-full')}>
                <BizSmTitle>{t("mypageGoods.goodsCertification")}</BizSmTitle>
                <div className={cx('table', 'table-certificate')}>
                    <div className={cx('header', 'col-product-warranty')}>{t("mypageGoods.productWarranty")}</div>
                    <div className={cx('header', 'col-certification-request')}>{t("common.certification")}</div>
                    <div className={cx('header', 'col-certificate')}>{t("mypageGoods.certificate")}</div>
                </div>

                <div className={cx('table', 'table-certificate')}>
                    <div className={cx('col', 'col-product-warranty')}>
                        <Button
                            id={"btn-productwarranty-registration" + props.value.id}
                            variant="outlined"
                            startIcon={<DoneOutlineOutlined />}
                            onClick={(e) => {
                                productWarranty.onUpload(e.currentTarget.id);
                            }}>
                            {t("goods.registeredGoods")}
                        </Button>
                        {props.value.productWarranty && productWarranty.display(props.value.productWarranty)}
                    </div>
                    <div className={cx('col', 'col-certification-request')}>
                        {props.value.goodsPermission.includes(GoodsPermission.Update) &&
                            <Button
                                id="btn-certification-request"
                                disabled={certificationRequestButtonDisabled}
                                variant="outlined"
                                startIcon={<CallMadeOutlined />}
                                onClick={() => {
                                    props.onRequestGoodsCertificate({ goodsId: props.value.id })
                                }}>
                                {t("common.request")}
                            </Button>
                        }
                    </div>
                    <div className={cx('col', 'col-certificate')}>
                        {props.value.goodsPermission.includes(GoodsPermission.Update) &&
                            <Button
                                disabled={goodsCertificate.disableButton()}
                                variant="outlined"
                                startIcon={<CloudDownloadOutlined />}
                                onClick={() => {
                                    goodsCertificate.downloadPdf();
                                }}>
                                {t("goods.download")}
                            </Button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}