import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './BizGoodsDataViewBreadcrumbs.module.scss';
import {CategUtil} from "../../../layout/web/categ/CategUtil";
import {useSelector} from "react-redux";
import {I18nState} from "../../../../redux/I18n";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {A, navigate} from "hookrouter";
import {I18nUtil} from "../../../../utils/i18n/i18n";
import {DataState} from "../../../../redux/Data";
import { OnChangeValue } from '../../../Intefaces';

const cx = classNamesBind.bind(styles);

export const BizGoodsDataViewBreadcrumbs = (props: {
    categoryId: number
    onChangeMenu?: OnChangeValue<string>
}) => {
    const category = useSelector<any, DataState>(state => state.data).category;
    const superSubs = CategUtil.getSuperCategory({parentId: props.categoryId, category});
    const i18n = useSelector<any, I18nState>(state => state.i18n);

    return (
        <>
            <Breadcrumbs
                separator=">"
                classes={{
                    root: cx('root'),
                    li: cx('li'),
                    ol: cx('ol'),
                    separator: cx('separator')
                }}>
                {superSubs.map((value, index) => {
                    return (
                        <A href="#!"
                           className={cx('item')}
                           key={index}
                           onClick={() => {
                                if(!props.onChangeMenu) {
                                    navigate(`/goods/category/${value.id}`);
                                } else {
                                    props.onChangeMenu(value.id.toString());
                                }
                           }}
                        >
                            {I18nUtil.value(i18n.langCode, value.nm)}
                        </A>
                    )
                })}

            </Breadcrumbs>
        </>
    )
}
