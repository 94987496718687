import React, {Fragment} from 'react';
import classNamesBind from "classnames/bind";
import styles from './BizCompanyDataViewLikes.module.scss';
import {ViewSelector} from "../../viewSelector/ViewSelector";
import {Pipe} from "../../../../utils/pipe/pipe";
import {CompanyLikes} from "../../../../graphql/types";
import {useTranslation} from "react-i18next";

const cx = classNamesBind.bind(styles);

interface Props {
    companyLikes: CompanyLikes
}

export const BizCompanyDataViewLikes = (props: Props) => {
    return (
        <Fragment>
            <ViewSelector
                web={<Web {...props}/>}
                mobile={<Mobile {...props}/>}/>
        </Fragment>
    )
}

const Web = (props: Props) => {
    const [t] = useTranslation('common');

    return (
        <div className={cx('cont')}>
            <div className={cx('box')}>
                <div className={cx('label')}>{t('company.likeCompany')}</div>
                <div className={cx('value')}>{Pipe.toSeparatorNumber(props.companyLikes.likes)}</div>
            </div>

            <div className={cx('box')}>
                <div className={cx('label')}>{t('company.viewCompany')}</div>
                <div className={cx('value')}>{Pipe.toSeparatorNumber(props.companyLikes.views)}</div>
            </div>

            <div className={cx('box')}>
                <div className={cx('label')}>{t('company.likeGoods')}</div>
                <div className={cx('value')}>{Pipe.toSeparatorNumber(props.companyLikes.goodsLikes)}</div>
            </div>
        </div>
    )
}

const Mobile = (props: Props) => {
    return (
        <><Web {...props}/></>
    )
}
