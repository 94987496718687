import React from "react";
import {CompanyListParam, Validator} from "../../../utils/validator/Validator";
import {gql, useQuery} from "@apollo/client";
import {navigate} from "hookrouter";
import {BizMenuLayout} from "../../shares/bizMenuLayout/BizMenuLayout";
import {useTranslation} from "react-i18next";
import {Pager} from "../../shares/pager/Pager";
import {BizTitle} from "../../shares/bizTitle/BizTitle";
import {Query, QueryCompanyWithFirstLetterArgs} from "../../../graphql/types";
import {BizScrollTop} from "../../shares/bizScrollTop/BizScrollTop";
import {BizCompanyDataIdx} from "../../shares/bizCompanyDataIdx/BizCompanyDataIdx";

export const CompanyList = (props: {
    idx: CompanyListParam,
    page: number,
    letter: string[],
}) => {
    const {data} = useQuery<Query, QueryCompanyWithFirstLetterArgs>(gqlData, {
        variables: {
            firstLetter: props.letter,
            page: props.page,
            size: 20
        }
    });

    const [t] = useTranslation('common');

    const handler = {
        onChangePage: (page: number) => {
            if (1 < props.letter.length) {
                navigate(`/company/list/${props.idx.idx}/${page}`);
            } else {
                navigate(`/company/list/${props.idx.idx}/${page}/${props.letter[0]}`);
            }
        },
        onChangeFirstLetter: (firstLetter: string) => {
            if (firstLetter === "") {
                navigate(`/company/list/${props.idx.idx}/0`);
            } else {
                navigate(`/company/list/${props.idx.idx}/0/${firstLetter}`);
            }

        },
    }

    return (
        <BizScrollTop>
            {data &&
            <BizMenuLayout
                title={t('company.findName')}
                routerPrefix={'/company'}
                activatedRouterSuffix={`/list/${props.idx.idx}`}
                data={
                    Validator.NameIdxList.map((value, index) => {
                        return {title: value.title, routerSuffix: `/list/${index}`}
                    })
                }>
                <BizTitle
                    tooltip={t("company.ttCompanyList") || ""}
                    desc={
                        <select
                            value={1 < props.letter.length ? "" : props.letter[0]}
                            onChange={ev => {
                                handler.onChangeFirstLetter(ev.target.value);
                            }}
                            className='form-control form-control-sm'>
                            <option value={""}>{t("company.wholeList")}</option>
                            {props.idx.idxes.map((value, index) => {
                                return (
                                    <option key={index} value={value}>{value}</option>
                                )
                            })}
                        </select>
                    }>{props.idx.title}</BizTitle>

                <BizCompanyDataIdx
                    companyList={data.companyWithFirstLetter.list}/>

                <Pager
                    page={data.companyWithFirstLetter.page}
                    size={data.companyWithFirstLetter.size}
                    total={data.companyWithFirstLetter.total}
                    onChange={handler.onChangePage}/>
                <div style={{height: 30}}/>
            </BizMenuLayout>
            }

        </BizScrollTop>
    )
}

const gqlData = gql`
    query Company($firstLetter: [String!]!, $page: Int!, $size: Int!) {
        companyWithFirstLetter(firstLetter: $firstLetter, page: $page, size: $size) {
            page
            size
            total
            list {
                id
                isPaid
                company
            }
        }
    }
`;
