import React, {useState} from 'react';
import classNamesBind from "classnames/bind";
import styles from './BizGoodsDataViewImage.module.scss';
import {GoodsData} from "../../../../graphql/customScalars";
import { GoodsCertificateImage } from '../../goodsCertificate/GoodsCertificateImage';

const cx = classNamesBind.bind(styles);

export const BizGoodsDataViewImage = (props: {
    goodsData: GoodsData,
    isCertificated: string
}) => {
    const [index, setIndex] = useState(0);

    return (
        <>
            <div className={cx('cont')}>
                <div className={cx('index')}>
                    {props.goodsData.goodsImage.map((value, index) => (
                        <img 
                            key={index}
                            src={value.src}
                            alt={""}
                            onClick={() => setIndex(index)}
                        />
                    ))}
                </div>
                <div className={cx('content')}>
                    <img src={props.goodsData.goodsImage[index].src} alt='img'/>

                    <GoodsCertificateImage
                        isCertificated={props.isCertificated}
                        width="140px"
                    />
                </div>
            </div>

        </>
    )
}
