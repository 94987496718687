import React, {useState} from 'react';
import {gql, useMutation, useQuery} from "@apollo/client";
import {
    BindConsultant,
    CompanyConsultant,
    InputCompanyConsultant,
    Mutation,
    MutationBindMyCompanyWithConsultantArgs,
    MutationCreateCompanyConsultantArgs,
    MutationToggleCompanyConsultantArgs,
    MutationUpdateCompanyConsultantArgs,
    Query
} from "../../../../graphql/types";
import {MyPageLayout} from "../../layout/MyPageLayout";
import {BizTitle} from "../../../shares/bizTitle/BizTitle";
import {Pager} from "../../../shares/pager/Pager";
import {navigate} from "hookrouter";
import {MyPageConsultantList} from "./list/MyPageConsultantList";
import {BizModal} from "../../../shares/bizModal/BizModal";
import {MyPageConsultantCreateModal} from "./createModal/MyPageConsultantCreateModal";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import {useTranslation} from "react-i18next";
import {ApolloCatch} from "../../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../../graphql/errors";
import {MyPageConsultantUpdateModal} from "./updateModal/MyPageConsultantUpdateModal";
import {InputConsultant} from "../../share/inputs/inputConsultant/InputConsultant";

export const MyPageConsultant = (props: {
    page: number
}) => {
    const {data, refetch} = useQuery<Query, typeof props>(gqlData, {
        variables: props
    });

    const [createModal, setCreateModal] = useState(false);
    const [updateConsultant, setUpdateConsultant] = useState<CompanyConsultant | undefined>();

    const [t] = useTranslation('common');
    const [mutCreate] = useMutation<Mutation, MutationCreateCompanyConsultantArgs>(gqlCreate);
    const [mutUpdate] = useMutation<Mutation, MutationUpdateCompanyConsultantArgs>(gqlUpdate);
    const [mutToggle] = useMutation<Mutation, MutationToggleCompanyConsultantArgs>(gqlToggle);
    const [mutBind] = useMutation<Mutation, MutationBindMyCompanyWithConsultantArgs>(galBind);

    const handler = {
        onChangePage: (page: number) => {
            navigate(`/mypage/consultant/list/${page}`);
        },
        onClickCreate: () => {
            setCreateModal(true);
        },
        onClickUpdate: (args: CompanyConsultant) => {
            setUpdateConsultant(args);
        },
        onCreate: (args: InputCompanyConsultant) => {
            const res = InputValidate(args);
            if (!res.isOk) {
                SwalUtil.ok({
                    msg: t(res.tValue),
                    icon: 'warning'
                });
                return;
            }

            SwalUtil.ynPromise({
                msg: t("mypageConsultant.swalCreateOkQuestion"),
            }).then(() => {
                return mutCreate({
                    variables: {
                        input: [args]
                    }
                })
            }).then(() => {
                return refetch()
            }).then(() => {
                SwalUtil.ok({
                    msg: t("mypageConsultant.swalCreateOkQuestionOk"),
                    icon: 'success'
                });
                setCreateModal(false);
            }).catch(ApolloCatch({
                [BizErrors.swalCancel]: () => {

                }
            }));
        },
        onUpdate: (args: MutationUpdateCompanyConsultantArgs) => {
            const res = InputValidate(args.input);
            let text = "";

            if (res.tValue === "nm") {
                text = t("mypageConsultant.validateNm");
            } else if (res.tValue === "tel") {
                text = t("mypageConsultant.validateTel");
            } else if (res.tValue === "position") {
                text = t("mypageConsultant.validatePosition");
            }

            if (!res.isOk) {
                SwalUtil.ok({
                    msg: text,
                    icon: 'warning'
                });
                return;
            }

            SwalUtil.ynPromise({
                msg: t("mypageConsultant.swalUpdateOkQuestion"),
            }).then(() => {
                return mutUpdate({
                    variables: args
                })
            }).then(() => {
                return refetch()
            }).then(() => {
                SwalUtil.ok({
                    msg: t("mypageConsultant.swalUpdateOkQuestionOk"),
                    icon: 'success'
                });
                setUpdateConsultant(undefined);
            }).catch(ApolloCatch({
                [BizErrors.swalCancel]: () => {
                }
            }));

        },
        onToggle: (args: MutationToggleCompanyConsultantArgs) => {
            SwalUtil.ynPromise({
                msg: args.swt ? t("mypageConsultant.swalToggleActivateQuestion") : t("mypageConsultant.swalToggleInactivateQuestion")
            }).then(() => {
                return mutToggle({variables: args})
            }).then(() => {
                return refetch();
            }).then(() => {
                SwalUtil.ok({
                    msg: t("mypageConsultant.swalToggleOk"),
                    icon: 'success'
                })
            }).catch(ApolloCatch({}));
        },
        onBind: (args: BindConsultant[]) => {
            SwalUtil.ynPromise({
                msg: t("mypageConsultant.swalBindQuestion")
            }).then(() => {
                return mutBind({
                    variables: {
                        input: args
                    }
                })
            }).then(() => {
                return refetch()
            }).then(() => {
                SwalUtil.ok({
                    msg: t("mypageConsultant.swalBindOk"),
                    icon: 'success'
                })
            }).catch(ApolloCatch({
                [BizErrors.swalCancel]: () => {

                }
            }));
        },
        onReload: () => {
            refetch()
                .catch(ApolloCatch({}));
        }
    };

    return (
        <MyPageLayout activatedSuffixRouter={'/consultant/list'}>
            <BizModal
                title={t("mypageConsultant.modalCreateTitle")}
                isShow={createModal}
                onCancel={() => setCreateModal(false)}>
                <MyPageConsultantCreateModal
                    {...handler}/>
            </BizModal>

            <BizModal
                title={t("mypageConsultant.modalUpdateTitle")}
                isShow={Boolean(updateConsultant)}
                onCancel={() => setUpdateConsultant(undefined)}>
                {updateConsultant &&
                <MyPageConsultantUpdateModal
                    {...handler}
                    consultant={updateConsultant}/>
                }
            </BizModal>

            {data &&
            <>

                <BizTitle tooltip={t("mypageConsultant.ttInputConsultant")}>
                    {t("mypageConsultant.inputConsultantTitle")}
                </BizTitle>
                <InputConsultant
                    allConsultant={data.myCompanyConsultantList.list}
                    boundConsultant={data.boundConsultantWithMyCompany}
                    onSave={handler.onBind}/>

                <div style={{height: 27}}/>
                <BizTitle tooltip={t("mypageConsultant.ttInputConsultant")}>
                    {t("mypageConsultant.consultantListTitle")}
                </BizTitle>
                <MyPageConsultantList
                    {...handler}
                    {...data}/>


                <Pager
                    page={data.myCompanyConsultantList.page}
                    size={data.myCompanyConsultantList.size}
                    total={data.myCompanyConsultantList.total}
                    onChange={handler.onChangePage}/>

            </>
            }

        </MyPageLayout>
    )
}

const InputValidate = (input: InputCompanyConsultant): { isOk: boolean, tValue: string } => {
    const defaultLangCode = 'en';

    if ((!input.position.hasOwnProperty(defaultLangCode)) || (input.position[defaultLangCode] === '')) {
        return {isOk: false, tValue: "position"}
    }

    if ((!input.nm.hasOwnProperty(defaultLangCode)) || (input.nm[defaultLangCode] === '')) {
        return {isOk: false, tValue: "nm"}
    }

    if ((!input.tel.hasOwnProperty(defaultLangCode)) || (input.tel[defaultLangCode] === '')) {
        return {isOk: false, tValue: "tel"}
    }

    if (input.email === '') {
        return {isOk: false, tValue: "email"}
    } else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(input.email)) {
        return {isOk: false, tValue: "Invalid Email Address"}
    }

    return {isOk: true, tValue: ''}
}

const gqlData = gql`
    query DATA($page: Int!) {
        myCompanyConsultantList(page: $page, size: 20) {
            page
            size
            total
            list {
                id
                isActivate
                nm
                email
                tel
                position
                permission
                createdAt
            }
        }
        boundConsultantWithMyCompany {
            langCode
            consultant {
                id
                isActivate
                nm
                email
                tel
                position
                permission
                createdAt
            }
        }
    }
`;
const galBind = gql`
    mutation BindConsultant($input: [BindConsultant!]!) {
        bindMyCompanyWithConsultant(input: $input)
    }
`;

const gqlCreate = gql`
    mutation Create($input: [InputCompanyConsultant!]!) {
        createCompanyConsultant(input: $input)
    }
`;

const gqlUpdate = gql`
    mutation Update($companyConsultantId: Int!, $input: InputCompanyConsultant!) {
        updateCompanyConsultant(companyConsultantId: $companyConsultantId, input: $input)
    }
`;

const gqlToggle = gql`
    mutation Toggle($companyConsultantId: Int!, $swt: Boolean!) {
        toggleCompanyConsultant(companyConsultantId: $companyConsultantId, swt: $swt)
    }
`;
