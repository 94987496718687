import React, {Fragment} from 'react';
import styles from './ChatV1List.module.scss';
import {Badge} from "@material-ui/core";
import {ChatRoom, ChatRoomList} from "../../../../../graphql/types";
import {useSelector} from "react-redux";
import {I18nState} from "../../../../../redux/I18n";
import {I18nUtil} from "../../../../../utils/i18n/i18n";
import moment from "moment";
import {OnChangeValue} from "../../../../Intefaces";
import {PersonOutline} from "@material-ui/icons";
import {useTranslation} from "react-i18next";
import {I18nKeys} from "../../../../../i18n/i18nKeys";


export const ChatV1List = (props: {
    chatRoomList: ChatRoomList,
    onOpenChatRoom: OnChangeValue<number>,
}) => {

    return (
        <Fragment>
            <div className={styles.cont}>
                {props.chatRoomList.list.map((value, index) =>
                    <div key={index} onClick={() => props.onOpenChatRoom(value.id)}>
                        <UserBtn value={value}/>
                    </div>
                )}

                {props.chatRoomList.list.length === 0 && <NoData/>}
            </div>
        </Fragment>
    )
}

export const ChatV1ConsultList = (props: {
    chatRoomConsultList: ChatRoomList,
    onOpenChatRoom: OnChangeValue<number>,
}) => {
    return (
        <Fragment>
            <div className={styles.cont}>
                {props.chatRoomConsultList.list.map((value, index) =>
                    <div key={index} onClick={() => props.onOpenChatRoom(value.id)}>
                        <CompanyBtn key={index} value={value}/>
                    </div>
                )}
                {props.chatRoomConsultList.list.length === 0 && <NoData/>}
            </div>
        </Fragment>
    )
}

export const NoData = () => {
    const [t] = useTranslation('common');
    return (
        <div className={styles.chatRoomBtn}>
            {t(I18nKeys.chat.noData.key)}
        </div>
    )
}

const CompanyBtn = (props: {
    value: ChatRoom
}) => {
    const value = props.value;
    const langCode = useSelector<any, I18nState>(state => state.i18n).langCode;
    const imgSrc = I18nUtil.value(langCode, props.value.imgSrc);

    return (
        <div className={styles.chatRoomBtn}>
            <div className={styles.logo}>
                <Badge badgeContent={value.notice}
                       color={"error"}
                       anchorOrigin={{
                           vertical: "bottom",
                           horizontal: "right"
                       }}>
                    {/*{value.companyData}*/}
                    {imgSrc !== "" && <img src={imgSrc} className={styles.dummyLogo} alt='logo'/>}
                    {!imgSrc && <UnknownUser/>}
                </Badge>
            </div>
            <div className={styles.info}>
                <div className={styles.nm}>{I18nUtil.value(langCode, props.value.nm)}</div>
                <div className={styles.content}>{value.lastChatContent}</div>
                <div className={styles.date}>{moment(value.updatedAt).format('YY.MM.DD - hh:mm a')}</div>
            </div>
        </div>
    )
}

const UserBtn = (props: {
    value: ChatRoom
}) => {
    const value = props.value;
    const langCode = useSelector<any, I18nState>(state => state.i18n).langCode;
    const imgSrc = I18nUtil.value(langCode, props.value.imgSrc);

    return (
        <div className={styles.chatRoomBtn}>
            <div className={styles.logo}>
                <Badge
                    badgeContent={value.notice}
                    color={"error"}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}>
                    {/*{value.companyData}*/}
                    {imgSrc !== "" && <img src={imgSrc} className={styles.dummyLogo} alt='logo'/>}
                    {!imgSrc && <UnknownUser/>}


                </Badge>
            </div>
            <div className={styles.info}>
                <div className={styles.nm}>{I18nUtil.value(langCode, props.value.nm)}</div>
                <div className={styles.content}>{value.lastChatContent}</div>
                <div className={styles.date}>{moment(value.updatedAt).format('YY.MM.DD - hh:mm a')}</div>

            </div>
        </div>
    )
}

const UnknownUser = () => {
    return (
        <div className={styles.unknownLogo}>
            <PersonOutline/>
        </div>
    )
}
