import React, {Fragment} from 'react';
import styles from './ChatV1Web.module.scss';
import {ChatRoomList} from "../../../../graphql/types";
import {OnChangeValue, OnVoidFunction} from "../../../Intefaces";
import {ChatV1Writer} from "../share/writer/ChatV1Writer";
import {ChatV1Tab, ChatV1TabData} from "../share/tab/ChatV1Tab";
import {BizTitle} from "../../../shares/bizTitle/BizTitle";
import {Pager} from "../../../shares/pager/Pager";
import {ChatV1ConsultList, ChatV1List} from '../share/list/ChatV1List';
import {ChatV1SearchBar} from "../share/searchBar/ChatV1SearchBar";
import {ChatV1PageQueryParams} from "../ChatV1";
import {useTranslation} from "react-i18next";
import {I18nKeys} from "../../../../i18n/i18nKeys";

interface TabData {
    tabData: ChatV1TabData[],
    onChangeTabValue: OnChangeValue<string>
}


export const ChatV1Web = (props: {
    params: ChatV1PageQueryParams,
    chatRoomList: ChatRoomList,
    chatRoomConsultList: ChatRoomList,
    onChangeFindWord: OnChangeValue<string>,
    onClear: OnVoidFunction,
    onChangePage: OnChangeValue<number>,
    onChangeConsultPage: OnChangeValue<number>,
    onOpenChatRoom: OnChangeValue<number>,
} & TabData) => {
    const [t] = useTranslation('common');

    return (
        <Fragment>
            <div className={styles.contChat}>
                <div className={styles.boxList}>
                    <ChatV1Tab
                        tabData={props.tabData}
                        value={props.params.tab}
                        onChangeValue={props.onChangeTabValue}
                    />

                    <ChatV1SearchBar
                        {...props}
                    />

                    {props.params.tab === 'user' &&
                    <>
                        <ChatV1List
                            {...props}/>
                        <Pager
                            paginationSize="small"
                            page={props.chatRoomList.page}
                            size={props.chatRoomList.size}
                            total={props.chatRoomList.total}
                            onChange={props.onChangePage}/>
                    </>
                    }

                    {props.params.tab === 'consult' &&
                    <>
                        <ChatV1ConsultList
                            {...props}
                            chatRoomConsultList={props.chatRoomConsultList}/>
                        <Pager
                            paginationSize="small"
                            page={props.chatRoomConsultList.page}
                            size={props.chatRoomConsultList.size}
                            total={props.chatRoomConsultList.total}
                            onChange={props.onChangeConsultPage}/>
                    </>
                    }

                </div>

                <div className={styles.boxChat}>
                    <BizTitle>{t(I18nKeys.chat.chatTitle.key)}</BizTitle>

                    {props.params.chatRoomId &&
                    <ChatV1Writer
                        tab={props.params.tab}
                        chatRoomIdx={props.params.chatRoomId}/>
                    }

                    {!props.params.chatRoomId &&
                    <div className={styles.contNoChatRoom}>
                        {t(I18nKeys.chat.clickChatRoom.key)}
                    </div>
                    }

                    <div style={{height: 50}}/>
                </div>
            </div>
        </Fragment>
    )
}
