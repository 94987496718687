import React from 'react';
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {GetEnv} from "../../../env/env";
import {OnVoidFunction} from "../../Intefaces";
import copy from "copy-to-clipboard";
import {BizSocialShareBtn} from "./btnShare/BizSocialShareBtn";
import imgNaver from '../../../assets/img/logo/png/naver.png';
import imgKakao from '../../../assets/img/logo/png/kakao.png';
import imgChrome from '../../../assets/img/logo/png/chrome.png';
import {BizModal} from "../bizModal/BizModal";
import {useTranslation} from "react-i18next";


interface Props {
    shareData: {
        nm: string,
        desc: string,
        url: string,
        imgPath: string,
    },
    open: boolean,
    onClose: OnVoidFunction
}

export const BizSocialShareModal = (props: Props) => {
    const [t] = useTranslation('common');

    const handler = {
        onShareNaver: () => {
            let url = encodeURI(encodeURIComponent(props.shareData.url));
            let shareURL = "https://share.naver.com/web/shareView?url=" + url + "&title=" + props.shareData.nm;
            window.open(shareURL, "Share to Naver", "width=500, height=500");
            props.onClose();
        },
        onShareKakao: () => {
            const {Kakao} = (window as any);

            if (!Kakao.isInitialized()) {
                Kakao.init(GetEnv().kakaoToken);
            }

            Kakao.Link.sendDefault({
                objectType: 'feed',
                content: {
                    title: props.shareData.nm,
                    description: props.shareData.desc,
                    imageUrl: props.shareData.imgPath,
                    link: {
                        mobileWebUrl: props.shareData.url,
                        webUrl: props.shareData.url,
                    },
                },
                // social: {
                //     likeCount: props.company.companyLikes,
                //     commentCount: props.company.views,
                //     sharedCount: props.company.goodsLikes,
                // },
                buttons: [
                    {
                        title: 'Show',
                        link: {
                            mobileWebUrl: props.shareData.url,
                            webUrl: props.shareData.url,
                        },
                    },
                ],
            })

            props.onClose();
        },
        onCopyUrl: () => {
            SwalUtil.ok({
                msg: t("common.swalCopyUrlOk"),
                icon: 'success'
            })
            copy(props.shareData.url);
            props.onClose();
        }
    }

    return (
        <BizModal
            size={'sm'}
            title={t("common.shareTitle")}
            isShow={props.open}
            onCancel={props.onClose}>
            <BizSocialShareBtn
                label={t("common.shareToNaver")}
                onClick={handler.onShareNaver}
                img={imgNaver}/>

            <BizSocialShareBtn
                label={t("common.shareToKakao")}
                onClick={handler.onShareKakao}
                img={imgKakao}/>

            <BizSocialShareBtn
                label={t("common.shareToClipboard")}
                onClick={handler.onCopyUrl}
                img={imgChrome}/>
        </BizModal>
    )
}
