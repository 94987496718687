import React, {Fragment, useEffect, useState} from 'react';
import styles from './GoodsListResultProcessBar.module.scss';
import {GoodsCreateRequestStatus, GoodsUpdateRequestStatus} from "../../../../../../graphql/types";
import {BizProgressBar, BizProgressBarData} from "../../../../../shares/bizProgressBar/BizProgressBar";
import {LocalShippingOutlined} from "@material-ui/icons";
import {I18nKeys} from "../../../../../../i18n/i18nKeys";


export const GoodsListResultProcessBar = (props: {
    status: GoodsCreateRequestStatus | GoodsUpdateRequestStatus | string,
}) => {
    const [valueList, setValueList] = useState<BizProgressBarData[]>([]);


    useEffect(() => {
        if (props.status !== GoodsCreateRequestStatus.AdminCancel) {
            setValueList([
                {
                    status: 'Requested',
                    tLabel: I18nKeys.mypageGoods.progRequested.key,
                    tTooltip: I18nKeys.mypageGoods.progConfirmTooltip.key
                },
                {
                    status: 'AdminConfirm',
                    tLabel: I18nKeys.mypageGoods.progConfirm.key,
                    tTooltip: I18nKeys.mypageGoods.progUserConfirmTooltip.key
                },
                {
                    status: 'Ready',
                    tLabel: I18nKeys.mypageGoods.progUserConfirm.key,
                    tTooltip: I18nKeys.mypageGoods.progShowTooltip.key
                },
                {
                    status: 'A1',
                    tLabel: I18nKeys.mypageGoods.progShow.key,
                    tTooltip: I18nKeys.mypageGoods.progShowTooltip.key
                },
                {status: 'Done', tLabel: I18nKeys.mypageGoods.progDone.key, tTooltip: ''},
            ])
        } else {
            setValueList([
                {
                    status: 'Requested',
                    tLabel: I18nKeys.mypageGoods.progRequested.key,
                    tTooltip: I18nKeys.mypageGoods.progRequestedTooltip.key
                },
                {
                    status: 'AdminCancel',
                    tLabel: I18nKeys.mypageGoods.progAdminCancel.key,
                    tTooltip: I18nKeys.mypageGoods.progAdminCancelTooltip.key
                },
                {
                    status: 'AdminConfirm',
                    tLabel: I18nKeys.mypageGoods.progUpdate.key,
                    tTooltip: I18nKeys.mypageGoods.progShowTooltip.key
                },
                {
                    status: 'Ready',
                    tLabel: I18nKeys.mypageGoods.progShow.key,
                    tTooltip: I18nKeys.mypageGoods.progUserConfirmTooltip.key
                },
                {status: 'Done', tLabel: I18nKeys.mypageGoods.progDone.key, tTooltip: ''},
            ])
        }
    }, [props.status]);
    return (
        <Fragment>
            <div className={styles.progressBar}>
                <BizProgressBar
                    icon={<LocalShippingOutlined/>}
                    valueList={valueList}
                    status={props.status}/>
            </div>
        </Fragment>
    )
}
