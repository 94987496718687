import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './MyPageGoodsRequestCreateList.module.scss';
import {ViewSelector} from "../../../../shares/viewSelector/ViewSelector";
import {
    GoodsCreateRequestList,
    GoodsCreateRequestStatus,
    MutationAllowCreateGoodsRequestArgs,
    MutationCancelCreateGoodsRequestArgs
} from "../../../../../graphql/types";
import moment from "moment";
import {BadgeGoodsCreateRequestStatus} from "../../../../shares/badgeGoodsCreateRequestStatus/GoodsCreateRequestStatus";
import {IconButton, Tooltip, withStyles} from "@material-ui/core";
import {DeleteOutlined, Done, SpellcheckOutlined, SupervisorAccount} from "@material-ui/icons";
import {OnChangeValue} from "../../../../Intefaces";
import {CompanyDataMap} from "../../../../../graphql/customScalars";
import {useTranslation} from "react-i18next";
import {Pipe} from "../../../../../utils/pipe/pipe";
import {BizNoDataTable} from "../../../../shares/bizNoDataTable/BizNoDataTable";
import {BizGoodsIconInTable} from "../../../../shares/bizGoodsIconInTable/BizGoodsIconInTable";
import {navigate} from "hookrouter";
import {BizGoodsModalData} from "../../../../shares/bizGoodsModal/BizGoodsModal";
import {GoodsListResultProcessBar} from "../../list/result/processBar/GoodsListResultProcessBar";

const cx = classNamesBind.bind(styles);

interface Props {
    myCompany: CompanyDataMap,
    goodsCreateRequestList: GoodsCreateRequestList,
    onAllowGoodsCreateRequest: OnChangeValue<MutationAllowCreateGoodsRequestArgs>,
    onCancelGoodsCreateRequest: OnChangeValue<MutationCancelCreateGoodsRequestArgs>,
    onShowGoodsData: OnChangeValue<BizGoodsModalData>,
}

export const MyPageGoodsRequestCreateList = (props: Props) => {
    return (
        <>
            <ViewSelector
                web={<Web {...props}/>}
                mobile={<Mobile {...props}/>}/>
        </>
    )
}

const Web = (props: Props) => {
    const [t] = useTranslation('common');

    const MemoTooltip = withStyles({
        tooltip: {
          fontSize: "14px",
          backgroundColor: "#ffffff",
          border: "dotted 2px",
          borderColor: "red",
          color: "#E77306",
          padding: "15px",
          textAlign: "left"
        }
    })(Tooltip);

    return (
        <>
            <div className={cx('table', 'table-list', 'mb-none')}>
                <div className={cx('header', "box-created-at")}>{t('mypageGoods.registryAt')}</div>
                <div className={cx('header', "box-status")}>{t('mypageGoods.status')}</div>
                <div className={cx('header', "box-goods")}>{t('mypageGoods.goodsNm')}</div>
                <div className={cx('header', "box-fee")}>{t("mypageGoods.chargedPoint")}</div>
                <div className={cx('header', "box-func")}>{t("mypageGoods.function")}</div>
            </div>

            {props.goodsCreateRequestList.total === 0 && <BizNoDataTable/>}

            {props.goodsCreateRequestList.list.map((value, index) => {
                return (
                    <div key={index} className={cx('table', 'table-list', 'hover')}>
                        <div className={cx('col', 'box-created-at')}>{moment(value.createdAt).format('YY.MM.DD')}</div>
                        <div className={cx('col', 'box-status')}>
                            <BadgeGoodsCreateRequestStatus
                                status={value.status}/>
                        </div>
                        <div
                            className={cx('box-goods')}
                            onClick={() => {
                                props.onShowGoodsData({
                                    goodsDataMap: value.data,
                                    categoryId: value.categoryId,
                                    companyDataMap: props.myCompany
                                });
                            }}>
                            <BizGoodsIconInTable
                                goodsDataMap={value.data}
                                categoryId={value.categoryId}/>
                        </div>

                        <div className={cx('col', 'box-fee', 'text-right')}>
                            {Pipe.toSeparatorNumber(-value.chargedPoint)} P
                        </div>

                        <div className={cx('col', 'box-func')}>
                            {value.status === GoodsCreateRequestStatus.Requested &&
                            <>
                                <Tooltip title={t("mypageGoods.ttCancelCreateGoods") || ""}>
                                    <IconButton onClick={() => {
                                        props.onCancelGoodsCreateRequest({createGoodsRequestId: value.id})
                                    }}>
                                        <DeleteOutlined/>
                                    </IconButton>
                                </Tooltip>
                            </>}

                            {value.status === GoodsCreateRequestStatus.AdminConfirm &&
                            <>
                                <Tooltip title={t("mypageGoods.ttConfirmCreateGoods") || ""}>
                                    <IconButton onClick={() => {
                                        props.onAllowGoodsCreateRequest({createGoodsRequestId: value.id});
                                    }}>
                                        <Done/>
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title={t("mypageGoods.ttCancelCreateGoods") || ""}>
                                    <IconButton onClick={() => {
                                        props.onCancelGoodsCreateRequest({createGoodsRequestId: value.id})
                                    }}>
                                        <DeleteOutlined/>
                                    </IconButton>
                                </Tooltip>

                            </>}

                            {value.status === GoodsCreateRequestStatus.AdminCancel &&
                            <>
                                <MemoTooltip title={value.memo}>
                                    <IconButton onClick={() => {
                                        navigate(`/mypage/goods/registry/create/${value.id}`)
                                    }}>
                                        <SpellcheckOutlined/>
                                    </IconButton>
                                </MemoTooltip>

                                <Tooltip title={t("mypageGoods.ttCancelCreateGoods") || ""}>
                                    <IconButton onClick={() => {
                                        props.onCancelGoodsCreateRequest({createGoodsRequestId: value.id})
                                    }}>
                                        <DeleteOutlined/>
                                    </IconButton>
                                </Tooltip>

                            </>}

                            {value.status === GoodsCreateRequestStatus.Done && Boolean(value.goodsId) &&
                            <>
                                <Tooltip title={t("mypageGoods.ttConsultant") || ""}>
                                    <IconButton
                                        onClick={() => navigate(`/mypage/goods/bind/${value.goodsId}`)}>
                                        <SupervisorAccount/>
                                    </IconButton>
                                </Tooltip>
                            </>
                            }
                        </div>
                        <div className={cx("box-progress")}>
                            <GoodsListResultProcessBar
                                status={value.status}/>
                        </div>
                    </div>
                )
            })}

        </>
    )
}

const Mobile = (props: Props) => {
    return (<><Web {...props}/></>)
}
