import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './MyPageConsultantInput.module.scss';
import {ViewSelector} from '../../../../shares/viewSelector/ViewSelector';
import {CompanyConsultantPermission, InputCompanyConsultant} from "../../../../../graphql/types";
import {OnChangeValue} from "../../../../Intefaces";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import {CopyUtil} from "../../../../../utils/copyUtil/copyUtil";
import {InputMultiLang} from '../../../share/inputs/inputMultiLang/InputMultiLang';
import {useTranslation} from "react-i18next";
import {BizSmTitle} from "../../../../shares/bizTitle/BizTitle";

const cx = classNamesBind.bind(styles);

interface Props {
    input: InputCompanyConsultant,
    onChangeInput: OnChangeValue<InputCompanyConsultant>
}

type CompProps = Props & {
    onTogglePermission: OnChangeValue<CompanyConsultantPermission>
}

export const MyPageConsultantInput = (props: Props) => {
    const handler = {
        onTogglePermission: (permission: CompanyConsultantPermission) => {
            if (permission === CompanyConsultantPermission.Position) {
                return;
            }


            const idx = props.input.permission.findIndex(value => {
                return permission === value
            });

            if (idx !== -1) {
                // 존재할땐 삭제
                const copy = CopyUtil.copyAll(props.input);
                copy.permission = [...copy.permission.slice(0, idx), ...copy.permission.slice(idx + 1, copy.permission.length)];
                props.onChangeInput(copy);
            } else {
                // 존재하지 않을떄 추가
                const copy = CopyUtil.copyAll(props.input);
                copy.permission.push(permission);
                props.onChangeInput(copy);
            }
        }
    };
    return (
        <>
            <ViewSelector
                web={<Web {...props} {...handler}/>}
                mobile={<Mobile {...props} {...handler}/>}/>
        </>
    )
}

const Web = (props: CompProps) => {
    const [t] = useTranslation('common');

    return (
        <div className={cx('cont')}>
            <BizSmTitle>{t("mypageConsultant.openInfoTitle")}</BizSmTitle>
            <div className={cx('table')}>
                <div className={cx('col', 'col-full', 'text-left')}>
                    <FormControlLabel
                        classes={{
                            root: cx('p-0', 'm-0'),
                        }}
                        control={
                            <Checkbox
                                color='primary'
                                size='small'
                                checked={props.input.permission.includes(CompanyConsultantPermission.Nm)}
                                onChange={() => props.onTogglePermission(CompanyConsultantPermission.Nm)}/>
                        }
                        label={t("mypageConsultant.nm")}/>

                    <FormControlLabel
                        classes={{
                            root: cx('p-0', 'm-0'),
                        }}
                        control={
                            <Checkbox
                                color='primary'
                                size='small'
                                checked={props.input.permission.includes(CompanyConsultantPermission.Tel)}
                                onChange={() => props.onTogglePermission(CompanyConsultantPermission.Tel)}/>
                        }
                        label={t("mypageConsultant.tel")}/>

                    
                    <FormControlLabel
                        classes={{
                            root: cx('p-0', 'm-0'),
                        }}
                        control={
                            <Checkbox
                                color='primary'
                                size='small'
                                checked={props.input.permission.includes(CompanyConsultantPermission.Email)}
                                onChange={() => props.onTogglePermission(CompanyConsultantPermission.Email)}/>
                        }
                        label={t("mypageConsultant.email")}/>
                </div>
            </div>


            <div style={{height: 30}}/>
            <BizSmTitle>{t("mypageConsultant.position")}</BizSmTitle>
            <hr/>
            <InputMultiLang
                value={props.input.position}
                onChange={v => {
                    const copy = CopyUtil.copyAll(props.input);
                    copy.position = v;
                    props.onChangeInput(copy);
                }}/>


            <div style={{height: 30}}/>
            <BizSmTitle>{t("mypageConsultant.nm")}</BizSmTitle>
            <hr/>
            <InputMultiLang
                value={props.input.nm}
                onChange={v => {
                    const copy = CopyUtil.copyAll(props.input);
                    copy.nm = v;
                    props.onChangeInput(copy);
                }}/>


            <div style={{height: 30}}/>
            <BizSmTitle>{t("mypageConsultant.tel")}<span className={cx("red")}>*</span></BizSmTitle>
            <hr/>
            <div className={cx('cmm-cont')}>
                <div className={cx('label')}></div>
                <div className={cx('input')}>
                    <input
                        className='form-control form-control-sm'
                        type='text'
                        value={props.input.tel['en']}
                        onChange={e => {
                            const copy = CopyUtil.copyAll(props.input);
                            copy.tel['en'] = e.target.value;
                            props.onChangeInput(copy);
                        }}/>
                </div>
            </div>

            <div style={{height: 30}}/>
            <BizSmTitle>{t("mypageConsultant.email")}<span className={cx("red")}>*</span></BizSmTitle>
            <hr/>
            <div className={cx('cmm-cont')}>
                <div className={cx('label')}></div>
                <div className={cx('input')}>
                    <input
                        className='form-control form-control-sm'
                        type='text'
                        value={props.input.email}
                        onChange={e => {
                            const copy = CopyUtil.copyAll(props.input);
                            copy.email = e.target.value;
                            props.onChangeInput(copy);
                        }}/>
                </div>
            </div>
        </div>
    )
}

const Mobile = (props: CompProps) => {
    return (
        <><Web {...props}/></>
    )
}
