import React, {Fragment} from 'react';
import classNamesBind from "classnames/bind";
import styles from './MyPageGoodsListFindOptions.module.scss';
import {GoodsFindOptions} from "../../../../../graphql/types";
import {OnChangeValue, OnVoidFunction} from "../../../../Intefaces";
import {useSelector} from "react-redux";
import {DataState} from "../../../../../redux/Data";
import {CopyUtil} from "../../../../../utils/copyUtil/copyUtil";
import {useTranslation} from "react-i18next";

const cx = classNamesBind.bind(styles);

interface Props {
    findOptions: GoodsFindOptions,
    onChangeFindOptions: OnChangeValue<GoodsFindOptions>,
    onFind: OnVoidFunction,
    onInit: OnVoidFunction,
}

export const MyPageGoodsListFindOptions = (props: Props) => {
    return (
        <Fragment>
            <div className='web-view'><Web {...props}/></div>
            <div className='mobile-view'><Mobile {...props}/></div>
        </Fragment>
    )
}

const Web = (props: Props) => {
    const [t] = useTranslation('common');
    const data = useSelector<any, DataState>(state => state.data);

    return (
        <form onSubmit={ev => {
            ev.preventDefault();
            props.onFind();
        }}>
            <div className={cx('table')}>
                <div className={cx('col', 'col-label', 'text-center')}>{t("mypageGoods.language")}</div>
                <div className={cx('col', 'col-value', 'col-input')}>
                    <select
                        className={cx('form-control', 'form-control-sm', 'text-center')}
                        value={props.findOptions.langCode}
                        onChange={ev => {
                            const copy = CopyUtil.copyAll(props.findOptions);
                            copy.langCode = ev.target.value;
                            props.onChangeFindOptions(copy);
                        }}>
                        {data.langCode.map((value, index) => (
                            <option key={index} value={value.code}>{value.nm}</option>
                        ))}
                    </select>
                </div>

                <div className={cx('col', 'col-label', 'text-center')}>{t("mypageGoods.goodsNm")}</div>
                <div className={cx('col', 'col-value', 'col-input')}>
                    <input
                        className={cx('form-control', 'form-control-sm', 'text-center')}
                        value={props.findOptions.nm || ''}
                        onChange={ev => {
                            const copy = CopyUtil.copyAll(props.findOptions);
                            copy.nm = ev.target.value === "" ? undefined : ev.target.value;
                            props.onChangeFindOptions(copy);
                        }}/>
                </div>

            </div>

            <div className={cx('table')}>
                <div className='text-right' style={{width: '100%'}}>
                    <button
                        type='button'
                        className='btn btn-sm btn-outline-success'
                        style={{marginRight: 10}}
                        onClick={props.onInit}>{t("mypageGoods.btnReset")}</button>

                    <button
                        type='submit'
                        className='btn btn-sm btn-success'>{t("mypageGoods.search")}
                    </button>
                </div>
            </div>
        </form>
    )
}

const Mobile = (props: Props) => {
    return (
        <><Web {...props}/></>
    )
}
