import {ReduxStore} from "../index";

export type GoodsDataMap = { [langCode: string]: GoodsData };
export type MultiLang = { [langCode: string]: string };
export type Time = Date;
export type AllEduCode = string;

export type CompanyDataMap = { [langCode: string]: CompanyData };

export interface CompanyData {
    nationCode: string;
    companyTypeId: number;
    logoUrl: string;
    companyNm: string;
    ceo: string;
    category: string;
    catchphrase: string;
    addr: string;
    nationSales: NationSales;
    nationUsage: NationUsage;
    totalSales: TotalSales;
    hp: string;
    foundation: Date;
    member: number;
    companyFiles: CompanyFile;
    priceUnit: string;
    introduceYoutubeUrl: string;
    companyDetail: string,
}

export type CompanyFile = {
    src: string;
    nm: string;
    createdAt: Date
}[]

export type NationSales = {
    nationCode: string;
    sales: number;
}[];

export type NationUsage = {
    nationCode: string;
    usage: number;
}[]

export type TotalSales = {
    year: number;
    sales: number;
}[]


export interface GoodsData {
    nm: string;
    goodsCode: string;
    originNationCode: string;
    youtubeUrl: string;
    purchaseUrl: string;
    tryGoodsUrl: string;
    certification: string;
    award: string;
    contents: string;
    catchphrase: string;
    goodsInfo: GoodsInfo[];
    goodsFile: GoodsFile[];
    goodsImage: GoodsImage[];
    goodsPrice: GoodsPrice;


}

export interface GoodsPrice {
    noPrice: boolean;
    price: number;
    priceUnit: string;
}

export interface GoodsInfo {
    key: string;
    value: string;
}

export interface GoodsFile {
    nm: string;
    src: string;
    createdAt: Time;
}

export interface GoodsImage {
    src: string;
    createdAt: Time;
}

export class MultiLangUtil {
    static newMultiLangValue(): MultiLang {
        const langCode = ReduxStore.getState().data.langCode;

        const multiLang: MultiLang = {};
        for (let lang of langCode) {
            multiLang[lang.code] = ""
        }


        return multiLang;

    }
}

/*
*
* 		Nm               string       `json:"nm"`
		GoodsCode        string       `json:"goodsCode"`
		OriginNationCode string       `json:"nationCode"`
		YoutubeUrl       string       `json:"youtubeUrl"`
		PurchaseUrl      string       `json:"purchaseUrl"`
		TryGoodsUrl      string       `json:"tryGoodsUrl"`
		Certification    string       `json:"certification"`
		Award            string       `json:"award"`
		Contents         string       `json:"contents"`
		Catchphrase      string       `json:"catchphrase"`
		GoodsInfo        []GoodsInfo  `json:"goodsInfo"`
		GoodsFiles       []GoodsFile  `json:"goodsFiles"`
		GoodsImage       []GoodsImage `json:"goodsImage"`
		HasPrice         bool
		Price            int
		PriceUnit        string
* */
