import {createHmac} from "crypto";
import {GetEnv} from "../../env/env";

export class CryptoUtil {
    static encryptPw(prefix: CryptoUtilPrefix, pw: string): string {
        return createHmac('sha256', GetEnv().pwHashedKey)
            .update(`${prefix}-${pw}`)
            .digest("hex");
    }

    static adminPw(pw: string): string {
        return createHmac('sha256', "e8e12c97-db63-4006-ab9d-735d416991a6")
            .update(pw)
            .digest("hex");
    }
}


export enum CryptoUtilPrefix {
    Email = 'EMAIL',
    Kakao = 'Kakao',
    Google = 'Google',
    Naver = 'Naver',
    Facebook = "Facebook",
}
