import React from 'react';
import {CompanyList, GoodsList} from "../../../../graphql/types";
import {BizCompanyDataIdx} from "../../../shares/bizCompanyDataIdx/BizCompanyDataIdx";
import {BizGoodsIdxList} from "../../../shares/bizGoodsIdxList/BizGoodsIdxList";
import {BizTitle} from "../../../shares/bizTitle/BizTitle";
import {Pipe} from "../../../../utils/pipe/pipe";
import {Pager} from "../../../shares/pager/Pager";
import {Element} from "react-scroll";
import {useTranslation} from "react-i18next";
import { navigate } from 'hookrouter';

export const IndexFindDefault = (props: {
    companyWithNm: CompanyList,
    goodsWithNm: GoodsList,
    word: string
}) => {
    const [t] = useTranslation('common');

    const onChangeCompanyPage = (page: number) => {
        navigate(`/index/find/${page}/${props.goodsWithNm.page}/${props.word}`);
    }
    const onChangeGoodsPage = (page: number) => {
        navigate(`/index/find/${props.companyWithNm.page}/${page}/${props.word}`);
    }

    return (
        <div>
            <Element name={'company'}/>
            <BizTitle>
                {t("find.findResultCompanyTitle")} ({Pipe.toSeparatorNumber(props.companyWithNm.total)} {t("find.cases")})
            </BizTitle>
            <BizCompanyDataIdx
                companyList={props.companyWithNm.list}/>

            <Pager
                scrollTag={'company'}
                offset={-100}
                page={props.companyWithNm.page}
                size={props.companyWithNm.size}
                total={props.companyWithNm.total}
                onChange={onChangeCompanyPage}/>

            <Element name={'goods'}/>
            <div style={{height: 30}}/>
            <BizTitle>
                {t("find.findResultGoodsTitle")} ({Pipe.toSeparatorNumber(props.goodsWithNm.total)} {t("find.cases")})
            </BizTitle>
            <BizGoodsIdxList
                goods={props.goodsWithNm.list}/>

            <Pager
                scrollTag='goods'
                offset={-70}
                page={props.goodsWithNm.page}
                size={props.goodsWithNm.size}
                total={props.goodsWithNm.total}
                onChange={onChangeGoodsPage}/>
            <div style={{height: 30}}/>
        </div>
    )
};
