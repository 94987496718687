import React from "react";
import {Query} from "../../../../graphql/types";
import {gql, useQuery} from "@apollo/client";
import {navigate} from "hookrouter";
import {BizScrollTop} from "../../../shares/bizScrollTop/BizScrollTop";
import {BizGoodsIdxList} from "../../../shares/bizGoodsIdxList/BizGoodsIdxList";
import {Element} from "react-scroll";
import {useTranslation} from "react-i18next";
import {NotFound} from "../../../system/notFound/NotFound";
import { EdutechKoreaFair2024CompanyDataView } from "./bizCompanyDataView/EdutechKoreaFair2024CompanyDataView";


export const EdutechKoreaFair2024CompanyDetail = (props: {
    id: number,
    goodsPage: number
}) => {
    const [t] = useTranslation('common');

    const {data, error} = useQuery<Query, typeof props>(gqlData, {
        variables: props
    });

    const onChangePage = (page: number) => {
        navigate(`/edutechkoreafair/2024/detail/${props.id}/${page}`);
    }


    return (
        <BizScrollTop>
            {data &&
            <div style={{paddingTop: 30}}>
                <EdutechKoreaFair2024CompanyDataView
                    companyUserId={props.id}
                    edutechKoreaFair2024CompanyCategory={data.edutechKoreaFair2024CompanyCategory}
                    companyDataMap={data.companyWithId.company}
                    companyLikes={data.companyLikes}
                    consultant={data.companyConsultantWithId}/>
                <Element name={'pager'}/>
                <div style={{height: 60}}/>

                <BizGoodsIdxList
                    title={t("company.companyGoodsListTitle")}
                    goods={data.goodsWithCompanyUserId.list}
                    pager={{
                        page: data.goodsWithCompanyUserId.page,
                        size: data.goodsWithCompanyUserId.size,
                        total: data.goodsWithCompanyUserId.total,
                        onChangePage: onChangePage
                    }}/>
                <div style={{height: 60}}/>
            </div>
            }

            {error && <NotFound/>}
        </BizScrollTop>
    )
}

const gqlData = gql`
    query CompanyWithId($id: Int!, $goodsPage: Int!){
        companyWithId(companyUserId: $id) {
            id
            company
        }
        edutechKoreaFair2024CompanyCategory(id: $id) {
            categoryName
            boothNumber
        }
        companyLikes(companyUserId: $id) {
            likes
            goodsLikes
            views
        }
        kotraWithId(companyUserId: $id) {
            data
        }
        companyConsultantWithId(companyUserId: $id) {
            id
            email
            tel
            nm
            position
            isRegisteredAccount
        }
        goodsWithCompanyUserId(companyUserId: $id, page: $goodsPage, size: 20) {
            page
            size
            total
            list {
                id
                allEduCode
                data
                company
                isPaid
            }
        }
    }
`;