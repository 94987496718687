import React, {Fragment, ReactNode} from 'react';
import classNamesBind from "classnames/bind";
import styles from './InputCompanyType.module.scss';
import {OnChangeValue} from "../../../../Intefaces";
import {useSelector} from "react-redux";
import {DataState} from "../../../../../redux/Data";
import {I18nState} from "../../../../../redux/I18n";
import { I18nUtil } from '../../../../../utils/i18n/i18n';

const cx = classNamesBind.bind(styles);

export const InputCompanyType = (props: {
    title: ReactNode,
    value: number,
    onChange: OnChangeValue<number>
}) => {
    const data = useSelector<any, DataState>(state => state.data);
    const i18n = useSelector<any, I18nState>(state => state.i18n);

    return (
        <Fragment>
            <div className={cx('title')}>{props.title}</div>
            <div className={cx('box')}>
                <div className={cx('label')}/>
                <select
                    className='form-control form-control-sm'
                    value={props.value}
                    onChange={event => {
                        props.onChange(Number(event.target.value || 0))
                    }}>
                    {data.companyType.map((value, index) => {
                        return (
                            <option key={index} value={value.id}>
                                {value.id}. {I18nUtil.value(i18n.langCode, value.nm)}
                            </option>
                        )
                    })}
                </select>
            </div>
            <div style={{height: 20}}/>
        </Fragment>
    )
};
