import {createActionCreators, createReducerFunction, ImmerReducer} from "immer-reducer";
import {CompanyType, LangCode, NationCode, Category, Region, I18nContents} from "../graphql/types";
import { CategoryGoodsListParams } from "../components/goods/category/GoodsCategory";


export interface DataState {
    langCode: LangCode[];
    category: Category[];
    currentSelectedGoodsCategory: CategoryGoodsListParams,
    nationCode: NationCode[],
    companyType: CompanyType[],
    region: Region[],
    priceUnit: string[],
    i18n: I18nContents[],
}

const initState: DataState = {
    langCode: [],
    category: [],
    currentSelectedGoodsCategory: {
        id: -1, 
        listPage: {
            pageStartId: 0,
            currentPage: 0,
            page: 0
        }
    },
    nationCode: [],
    companyType: [],
    region: [],
    priceUnit: ['$ (USD)'],
    i18n: []

};

class Data extends ImmerReducer<typeof initState> {
    setLangCode(list: LangCode[]) {
        this.draftState.langCode = list;
    }

    setCategory(list: Category[]) {
        this.draftState.category = list;
    }

    setCurrentSelectedGoodsCategory(currentSelectedGoodsCategory: CategoryGoodsListParams) {
        this.draftState.currentSelectedGoodsCategory = currentSelectedGoodsCategory;
        // 페이지가 새로고침 되었을 경우 카테고리의 초기값이 적용되기 때문에
        // 현재 보고 있던 페이지가 유지되지 않는다. 따라서 카테고리가 변경될 때마다
        // 해당 카테고리의 id를 localStorage에 저장한다.
        localStorage.setItem("CURRENT_SELECTED_GOODS_CATEGORY_ID", currentSelectedGoodsCategory.id.toString());
    }

    getCurrentSelectedGoodsCategory() : number {
        return this.draftState.currentSelectedGoodsCategory.id;
    }

    setNationCode(list: NationCode[]) {
        this.draftState.nationCode = list;
    }

    setCompanyType(list: CompanyType[]) {
        this.draftState.companyType = list;
    }

    setCompanyRegionIdx(list: Region[]) {
        this.draftState.region = list;
    }

    setPriceUnit(list: string[]) {
        this.draftState.priceUnit = list;
    }

    setI18nList(list: I18nContents[]) {
        this.draftState.i18n = list;
    }
}


export const DataAction = createActionCreators(Data);
export const DataReducer = createReducerFunction(Data, initState);
