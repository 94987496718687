import React, {ReactNode, useEffect, useRef, useState} from 'react';
import classNamesBind from "classnames/bind";
import styles from './BizExtendTable.module.scss';
import {Close, ExpandMore} from "@material-ui/icons";
import {IconButton, Tooltip} from "@material-ui/core";
import {useTranslation} from "react-i18next";

const cx = classNamesBind.bind(styles);

export const BizExtendTable = (props: {
    data: BizExtendTableData
}) => {
    const [data, setData] = useState<Data>({
        isActivate: false,
        header: '',
        contents: ''
    });

    const [t] = useTranslation('common');
    const contentsRef = useRef(null);

    useEffect(() => {
        setData({
            isActivate: data.isActivate,
            ...props.data,
        });
    }, [props.data, data.isActivate]);

    const handler = {
        toggle: () => {
            setData({
                ...data,
                isActivate: !data.isActivate,
            })
        }
    }


    return (
        <div className={cx(data.isActivate ? 'card' : 'table', 'cont')}>
            <div className={cx('header')}>
                <div className={cx('contents')}>
                    {data.header}
                </div>
                <div className={cx('btn')}>
                    {!data.isActivate &&
                    <Tooltip title={t("common.ttOpen") || ""}>
                        <IconButton onClick={() => handler.toggle()}>
                            <ExpandMore/>
                        </IconButton>
                    </Tooltip>
                    }

                    {data.isActivate &&
                    <Tooltip title={t("common.ttClose") || ""}>
                        <IconButton onClick={() => handler.toggle()}>
                            <Close/>
                        </IconButton>
                    </Tooltip>
                    }

                </div>
            </div>

            {data.isActivate &&
            <div className={cx('cont-contents')} ref={contentsRef}>
                {data.contents}
            </div>
            }
        </div>
    )
}

export interface Data extends BizExtendTableData {
    isActivate: boolean;
}

export interface BizExtendTableData {
    header: ReactNode,
    contents: ReactNode,
}
