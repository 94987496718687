import i18n, {Resource} from "i18next";
import {initReactI18next} from "react-i18next";
import {LocalStorage} from "../localstorage/LocalStorage";
import {I18nContents} from "../../graphql/types";

export class I18nUtil {
    static initI18n(resources: Resource) {
        i18n.use(initReactI18next)
            .init({
                resources: resources,
                lng: LocalStorage.getLangCode(),
                keySeparator: '.',
                interpolation: {
                    escapeValue: false
                }
            });
        return i18n;
    }

    static chgLang(langCode: string) {
        i18n.changeLanguage(langCode).then();
    }

    static t(key: string): string {
        return i18n.t(key, {ns: 'common'});
    }

    static value(langCode: string, value: any): string {
        if ((value.hasOwnProperty(langCode)) && (value[langCode] !== "")) {
            return value[langCode];
        } else if (value.hasOwnProperty("en")) {
            return value["en"];
        } else {
            return ""
        }
    }

    static parseWithI18nContents(list: I18nContents[]): Resource {
        const resource: any = {};
        const ns = "common";

        for (let i18nContent of list) {
            const {mainKey, subKey, contents} = i18nContent;

            for (let language of Object.keys(contents)) {
                if (!resource.hasOwnProperty(language)) {
                    resource[language] = {};
                }

                if (!resource[language].hasOwnProperty(ns)) {
                    resource[language][ns] = {}
                }

                if (!resource[language][ns].hasOwnProperty(mainKey)) {
                    resource[language][ns][mainKey] = {}
                }

                resource[language][ns][mainKey][subKey] = contents[language];
            }
        }

        return resource;
    }

    static parse(origin: I18nResource) {
        const resource: any = {};
        const ns = "common";

        for (let nsKey in origin) {
            const namespace = origin[nsKey];

            for (let key in namespace) {
                const value = namespace[key];

                for (let langCode in value) {
                    const str = value[langCode];

                    if (!resource.hasOwnProperty(langCode)) {
                        resource[langCode] = {};
                    }

                    if (!resource[langCode].hasOwnProperty(ns)) {
                        resource[langCode][ns] = {};
                    }

                    if (!resource[langCode][ns].hasOwnProperty(nsKey)) {
                        resource[langCode][ns][nsKey] = {}
                    }

                    resource[langCode][ns][nsKey][key] = str;
                }
            }
        }


        return resource;
    }
}

export interface I18nResource {
    [namespace: string]: I18nNamespace
}

export interface I18nNamespace {
    [key: string]: I18nValue
}

export interface I18nValue {
    [langCode: string]: string
}
