import React, {Fragment, useEffect, useState} from "react";
import {AppStatus, SystemAction, SystemState, ViewMode} from "../redux/System";
import {HookRouter, useRoutes} from "hookrouter";
import {useSelector} from "react-redux";
import {UserState} from "../redux/User";
import {LayoutMobile} from "./layout/mobile/LayoutMobile";
import {NotFound} from "./system/notFound/NotFound";
import {LayoutWeb} from "./layout/web/LayoutWeb";
import {LoadingView} from "./shares/loadingView/LoadingView";
import {ReduxStore} from "../index";
import {ShutDown} from "./system/shutDown/ShutDown";
import {IndexMain} from "./index/main/IndexMain";
import {NationMain} from "./nation/main/NationMain";
import {CompanyList} from "./company/list/CompanyList";
import {CompanyDetail} from "./company/detail/CompanyDetail";
import {Validator} from "../utils/validator/Validator";
import {GoodsDetail} from "./goods/detail/GoodsDetail";
import {AuthLogin} from "./auth/login/AuthLogin";
import {AuthJoin} from "./auth/join/AuthJoin";
import {MyPageUpdateCompany} from "./mypage/company/update/MyPageUpdateCompany";
import {Debug} from "./system/debug/Debug";
import {CompanyPermission, UserType} from "../graphql/types";
import {AuthNaverCallback} from "./auth/naverCallback/AuthNaverCallback";
import {useReactiveVar} from "@apollo/client";
import {GlobalVars} from "../globalVars/globalVars";
import {BizBackdrop} from "./shares/bizBackdrop/BizBackdrop";
import {MyPageGoodsRegistry} from "./mypage/goods/registry/new/MyPageGoodsRegistry";
import {MyPageMyInfo} from "./mypage/myinfo/MyPageMyInfo";
import {MyPageGoodsList} from "./mypage/goods/list/MyPageGoodsList";
import {MyPageCompanyCreate} from "./mypage/company/create/MyPageCompanyCreate";
import {MyPageCompanyCreateRequest} from "./mypage/company/createRequest/MyPageCompanyCreateRequest";
import {MyPageCompanyUpdateRequest} from "./mypage/company/updateRequest/MyPageCompanyUpdateRequest";
import {MyPageGoodsUpdate} from "./mypage/goods/update/MyPageGoodsUpdate";
import {MyPageConsultant} from "./mypage/consultant/list/MyPageConsultant";
import {MyPageGoodsBindConsultant} from "./mypage/goods/bindConsultant/MyPageGoodsBindConsultant";
import {GoodsCategory} from "./goods/category/GoodsCategory";
import {IndexFind} from "./index/find/IndexFind";
import {CsNoticeList} from "./cs/notice/list/CsNoticeList";
import {CsNoticeView} from "./cs/notice/view/CsNoticeView";
import {CsFaqList} from "./cs/faq/list/CsFaqList";
import {CsQnaList} from "./cs/qna/list/CsQnaList";
import {CsFaqView} from "./cs/faq/view/CsFaqView";
import {CsQnaView} from "./cs/qna/view/CsQnaView";
import {CsQnaCreate} from "./cs/qna/create/CsQnaCreate";
import {BizCheckUserInfo} from "./shares/bizCheckUserInfo/BizCheckUserInfo";
import {MyPageConsultantDetail} from "./mypage/consultant/detail/MyPageConsultantDetail";
import {MyPageGoodsRegistryUpdate} from "./mypage/goods/registry/update/MyPageGoodsRegistryUpdate";
import {MyPageGoodsRegistryCreate} from "./mypage/goods/registry/create/MyPageGoodsRegistryCreate";
import {AuthBackdoor} from "./auth/backdoor/AuthBackdoor";
import {MyPageAskBoardList} from "./board/askBoardUser/list/MyPageAskBoardList";
import {MyPageAskBoardView} from "./board/askBoardUser/view/MyPageAskBoardView";
import {BoardList} from "./board/askBoardCompany/list/BoardList";
import {BoardView} from "./board/askBoardCompany/view/BoardView";
import {AskBoardOutLink} from "./board/askBoardOutLink/AskBoardOutLink";
import {ChatV1} from "./chat/v1/ChatV1";
import {TermsTerms} from "./terms/terms/TermsTerms";
import {TermsPrivacy} from "./terms/privacy/TermsPrivacy";
import { CsNewsletterList } from "./cs/newsletter/list/CsNewsletterList";
import { CsNewsletterView } from "./cs/newsletter/view/CsNewsletterView";
import { GoodsConferenceCategory } from "./goods/conference/GoodsConferenceCategory";
import {EdutechKoreaFair2024CompanyList} from "./edutechkoreafair/2024/list/EdutechKoreaFair2024CompanyList";
import { EdutechKoreaFair2024CompanyDetail } from "./edutechkoreafair/2024/detail/EdutechKoreaFair2024CompanyDetail";

export const App = () => {
    const user = useSelector<any, UserState>(state => state.user);
    const system = useSelector<any, SystemState>(state => state.system);
    const [router, setRouter] = useState<HookRouter.RouteObject>({});
    const refreshWindow = useReactiveVar(GlobalVars.system.refreshWindow);
    const loadingView = useReactiveVar(GlobalVars.system.loadingView);


    // 화면전체를 리프레시 한다.
    useEffect(() => {
        if (refreshWindow) {
            window.location.href = '/';
        }
    }, [refreshWindow]);

    useEffect(() => {
        let next: HookRouter.RouteObject;

        if (user.isLogin) {
            next = {
                ...CommonRouter,
                ...AuthRouter,
                // '/chat/user':
            }

            if (user.user.userType === UserType.Company) {
                next = {
                    ...next,
                    '/ask/company/board/list': () =>
                        <BoardList page={0}/>,
                    '/ask/company/board/list/:page': ({page}) =>
                        <BoardList page={Validator.isNumber(page)}/>,
                    '/ask/company/board/view/:boardId': ({boardId}) =>
                        <BoardView askBoardId={Validator.isNumber(boardId)}/>
                    // '/chat/company':
                }
            }

            if (user.user.userType !== UserType.Consultant) {
                next = {
                    ...next,
                    '/mypage/info': () =>
                        <MyPageMyInfo
                            tabs={""}
                            companyPage={0}
                            goodsPage={0}/>,
                    '/mypage/info/:tabs': ({tabs}) => <
                        MyPageMyInfo
                        tabs={tabs}
                        companyPage={0}
                        goodsPage={0}/>,
                    '/mypage/info/:tabs/:companyPage/:goodsPage': ({tabs, companyPage, goodsPage}) =>
                        <MyPageMyInfo
                            tabs={tabs}
                            companyPage={Validator.isNumber(companyPage)}
                            goodsPage={Validator.isNumber(goodsPage)}/>,

                }
            }


            if (user.user.companyPermission.includes(CompanyPermission.CreateCompany)) {
                next = {
                    ...next,
                    '/mypage/company/create': () => <MyPageCompanyCreate/>,
                }
            }

            if (user.user.companyPermission.includes(CompanyPermission.CreateWait)) {
                next = {
                    ...next,
                    '/mypage/company/create/request': () => <MyPageCompanyCreateRequest/>,
                }
            }

            if (user.user.companyPermission.includes(CompanyPermission.UpdateCompany)) {
                next = {
                    ...next,
                    '/mypage/company/update': () => <MyPageUpdateCompany/>,
                }
            }

            if (user.user.companyPermission.includes(CompanyPermission.MngConsultant)) {
                next = {
                    ...next,
                    '/mypage/consultant/list': () =>
                        <MyPageConsultant
                            page={0}/>,
                    '/mypage/consultant/list/:page': ({page}) =>
                        <MyPageConsultant
                            page={Validator.isNumber(page)}/>,
                    '/mypage/consultant/detail/:id': ({id}) =>
                        <MyPageConsultantDetail
                            companyConsultantId={Validator.isNumber(id)} page={0}/>,
                    '/mypage/consultant/detail/:id/:page': ({id, page}) =>
                        <MyPageConsultantDetail
                            companyConsultantId={Validator.isNumber(id)} page={Validator.isNumber(page)}/>
                }
            }

            if (user.user.companyPermission.includes(CompanyPermission.UpdateWait)) {
                next = {
                    ...next,
                    '/mypage/company/update/request': () =>
                        <MyPageCompanyUpdateRequest page={0}/>,
                    '/mypage/company/update/request/:page': ({page}) =>
                        <MyPageCompanyUpdateRequest page={Validator.isNumber(page)}/>,
                }
            }

            if (user.user.companyPermission.includes(CompanyPermission.RegistryGoods)) {
                next = {
                    ...next,
                    '/mypage/goods/registry': () => <MyPageGoodsRegistry/>,
                    // '/mypage/goods/request': () => <MyPageGoodsRequest createPage={0} updatePage={0}/>,
                    // '/mypage/goods/request/:createPage/:updatePage': ({createPage, updatePage}) =>
                    //     <MyPageGoodsRequest
                    //         createPage={Validator.isNumber(createPage)}
                    //         updatePage={Validator.isNumber(updatePage)}/>,
                    '/mypage/goods/registry/update/:id': ({id}) =>
                        <MyPageGoodsRegistryUpdate
                            goodsUpdateRequestId={Validator.isNumber(id)}/>,
                    '/mypage/goods/registry/create/:id': ({id}) =>
                        <MyPageGoodsRegistryCreate
                            goodsCreateRequestId={Validator.isNumber(id)}/>,
                    '/mypage/goods/list': () =>
                        <MyPageGoodsList
                            goodsPage={0}
                            createPage={0}
                            updatePage={0}/>,
                    '/mypage/goods/list/:goodsPage/:createPage/:updatePage': ({goodsPage, createPage, updatePage}) =>
                        <MyPageGoodsList
                            goodsPage={Validator.isNumber(goodsPage)}
                            createPage={Validator.isNumber(createPage)}
                            updatePage={Validator.isNumber(updatePage)}/>,
                    '/mypage/goods/update/:goodsId': ({goodsId}) =>
                        <MyPageGoodsUpdate
                            goodsId={Validator.isNumber(goodsId)}/>,
                    '/mypage/goods/bind/:goodsId': ({goodsId}) =>
                        <MyPageGoodsBindConsultant goodsId={Validator.isNumber(goodsId)}/>
                }
            }


            if (user.user.companyPermission.includes(CompanyPermission.AuctionCompany)) {
                next = {
                    ...next,
                    // '/mypage/auction/company': () => <MyPageAuctionCompany page={0}/>,
                    // '/mypage/auction/company/:page': ({page}) =>
                    //     <MyPageAuctionCompany page={Validator.isNumber(page)}/>,
                }
            }

            if (user.user.companyPermission.includes(CompanyPermission.AuctionGoods)) {
                next = {
                    ...next,
                    // '/mypage/auction/goods': () => <MyPageAuctionGoodsGoods page={0}/>,
                    // '/mypage/auction/goods/:page': ({page}) =>
                    //     <MyPageAuctionGoodsGoods page={Validator.isNumber(page)}/>,
                }
            }

        } else {
            next = {
                ...NotAuthRouter,
                ...CommonRouter
            }
        }
        setRouter(next);
    }, [user.isLogin, user.user.companyPermission, user.user.userType])


    const page = useRoutes(router);


    return (
        <Fragment>
            {/*------------------------------------------------------------*/}
            {/* daekyu.ju@t-ime.com 2023/10/17 채팅 체크 로직 주석처리       */}
            {/*------------------------------------------------------------*/}
            {/*user.isLogin && <ChatChecker/>*/}
            {/*------------------------------------------------------------*/}


            {system.appStatus === AppStatus.Ok &&
            <BizCheckUserInfo>
                {system.viewMode === ViewMode.Web &&
                <LayoutWeb>
                    {page || <NotFound/>}
                </LayoutWeb>
                }

                {system.viewMode === ViewMode.Mobile &&
                <LayoutMobile>
                    {page || <NotFound/>}
                </LayoutMobile>
                }

                <BizBackdrop
                    loading={loadingView}/>
            </BizCheckUserInfo>
            }

            {system.appStatus === AppStatus.Loading &&
            <LoadingView isActivate={true}/>
            }

            {system.appStatus === AppStatus.ServerShutdown &&
            <ShutDown/>
            }
        </Fragment>
    );
}

const CommonRouter: HookRouter.RouteObject = {
    '/': () => <IndexMain/>,
    '/terms': () => <TermsTerms/>,
    '/privacy': () => <TermsPrivacy/>,
    '/out/link/askboard/:chatWriter/:askBoardId': ({chatWriter, askBoardId}) =>
        <AskBoardOutLink
            askBoardId={Validator.isNumber(askBoardId)}
            chatWriter={chatWriter}/>,
    '/index/find/:companyPage/:goodsPage/:word': ({companyPage, goodsPage, word}) =>
        <IndexFind
            companyPage={Validator.isNumber(companyPage)}
            goodsPage={Validator.isNumber(goodsPage)}
            word={decodeURI(word)}/>,
    '/debug': () => <Debug/>,
    '/nation': () =>
        <NationMain
            region={'ASIA'}
            page={0}/>,
    '/nation/:region': ({region}) =>
        <NationMain
            page={0}
            region={Validator.isRegion(region)}/>,
    '/nation/:region/:page': ({region, page}) =>
        <NationMain
            region={Validator.isRegion(region)}
            page={Validator.isNumber(page)}/>,
    '/nation/:region/:page/:nationCode': ({region, page, nationCode}) =>
        <NationMain
            region={Validator.isRegion(region)}
            page={Validator.isNumber(page)}
            nationCode={nationCode}/>,
    '/company/list': () =>
        <CompanyList
            idx={Validator.isCompanyListParamIdx(0)}
            page={0}
            letter={Validator.isCompanyListParamIdx(0).idxes}/>,
    '/company/list/:idx': ({idx}) =>
        <CompanyList
            idx={Validator.isCompanyListParamIdx(idx)}
            page={0}
            letter={Validator.isCompanyListParamIdx(idx).idxes}/>,
    '/company/list/:idx/:page': ({idx, page}) =>
        <CompanyList
            idx={Validator.isCompanyListParamIdx(idx)}
            page={Validator.isNumber(page)}
            letter={Validator.isCompanyListParamIdx(idx).idxes}/>,
    '/company/list/:idx/:page/:letter': ({idx, page, letter}) =>
        <CompanyList
            idx={Validator.isCompanyListParamIdx(idx)}
            page={Validator.isNumber(page)}
            letter={Validator.isCompanyListParamFistLetter(idx, letter)}/>,
    '/company/detail/:id': ({id}) =>
        <CompanyDetail
            companyUserId={Validator.isNumber(id)}
            goodsPage={0}/>,
    '/company/detail/:id/:page': ({id, page}) =>
        <CompanyDetail
            companyUserId={Validator.isNumber(id)}
            goodsPage={Validator.isNumber(page)}/>,
    '/goods/detail/:id': ({id}) =>
        <GoodsDetail
            allEduCode={id}/>,
    '/goods/category/:id': ({id}) =>
        <GoodsCategory id={Validator.isNumber(id)} page={0}/>,
    '/goods/category/:id/:page': ({id, page}) =>
        <GoodsCategory id={Validator.isNumber(id)} page={Validator.isNumber(page)}/>,

    ////////////////////////////////////////////////////////////////////////////////
    // 2023/09 에듀테크 박람회용
    ////////////////////////////////////////////////////////////////////////////////
    '/goods/conference/:id': ({id}) =>
        <GoodsConferenceCategory id={Validator.isNumber(id)} page={0}/>,
    '/goods/conference/:id/:page': ({id, page}) =>
        <GoodsConferenceCategory id={Validator.isNumber(id)} page={Validator.isNumber(page)}/>,
    ////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////////
    // 2024/09 에듀테크 박람회용
    ////////////////////////////////////////////////////////////////////////////////
    '/edutechkoreafair/2024/list/:id': ({id}) =>
        <EdutechKoreaFair2024CompanyList id={Validator.isNumber(id)}/>,
    '/edutechkoreafair/2024/detail/:id': ({id}) =>
        <EdutechKoreaFair2024CompanyDetail 
            id={Validator.isNumber(id)}
            goodsPage={0}/>,
    '/edutechkoreafair/2024/detail/:id/:page': ({id, page}) =>
        <EdutechKoreaFair2024CompanyDetail
            id={Validator.isNumber(id)}
            goodsPage={Validator.isNumber(page)}/>,
    ////////////////////////////////////////////////////////////////////////////////

    '/cs/notice/list': () =>
        <CsNoticeList page={0}/>,
    '/cs/notice/list/:page': ({page}) =>
        <CsNoticeList page={Validator.isNumber(page)}/>,
    '/cs/notice/view/:noticeId': ({noticeId}) =>
        <CsNoticeView noticeId={Validator.isNumber(noticeId)}/>,
    '/cs/faq/list': () =>
        <CsFaqList page={0}/>,
    '/cs/faq/list/:page': ({page}) =>
        <CsFaqList page={Validator.isNumber(page)}/>,
    '/cs/faq/view/:id': ({id}) =>
        <CsFaqView faqId={Validator.isNumber(id)}/>,
    '/cs/newsletter/list': () =>
        <CsNewsletterList page={0}/>,
    '/cs/newsletter/list/:page': ({page}) =>
        <CsNewsletterList page={Validator.isNumber(page)}/>,
    '/cs/newsletter/view/:newsletterId': ({newsletterId}) =>
        <CsNewsletterView newsletterId={Validator.isNumber(newsletterId)}/>,
    '/auth/backdoor/:id': ({id}) => <AuthBackdoor userId={Validator.isNumber(id)}/>
}

const NotAuthRouter: HookRouter.RouteObject = {
    '/auth/login': () => <AuthLogin/>,
    '/auth/join': () => <AuthJoin/>,
    '/auth/naver/callback': () => <AuthNaverCallback/>,
}

const AuthRouter: HookRouter.RouteObject = {
    '/ask/board/list': () =>
        <MyPageAskBoardList
            page={0}/>,
    '/ask/board/list/:page': ({page}) =>
        <MyPageAskBoardList
            page={Validator.isNumber(page)}/>,
    '/ask/board/view/:askBoardId': ({askBoardId}) =>
        <MyPageAskBoardView
            askBoardId={Validator.isNumber(askBoardId)}/>,
    '/cs/qna/list': () =>
        <CsQnaList page={0}/>,
    '/cs/qna/list/:page': ({page}) =>
        <CsQnaList page={Validator.isNumber(page)}/>,
    '/cs/qna/create': () =>
        <CsQnaCreate/>,
    '/cs/qna/view/:id': ({id}) =>
        <CsQnaView qnaId={Validator.isNumber(id)}/>,
    '/chat': () =>
        <ChatV1/>,

}

export class LoadingViewUtil {
    static change(swt: boolean) {
        ReduxStore.dispatch(SystemAction.setLoadingView(swt));
    }

    static promise(promise: Promise<any>) {
        ReduxStore.dispatch(SystemAction.setLoadingView(true));
        promise.then(value => {
            ReduxStore.dispatch(SystemAction.setLoadingView(false));
        }).catch(e => {
            ReduxStore.dispatch(SystemAction.setLoadingView(false));
        })
    }
}
