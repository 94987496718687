import React, {ReactNode} from 'react';
import classNamesBind from "classnames/bind";
import styles from './InputTextarea.module.scss';
import {OnChangeValue} from "../../../../Intefaces";

const cx = classNamesBind.bind(styles);

export const InputTextarea = (props: {
    title: ReactNode,
    value: string,
    onChange: OnChangeValue<string>,
}) => {


    return (
        <>
            <div className={cx('title')}>{props.title}</div>
            <div className={cx('box')}>
                <div className={cx('label')}/>
                <textarea
                    style={{
                        resize: 'none',
                        height: 200,
                    }}
                    className='form-control form-control-sm'
                    value={props.value}
                    onChange={event => props.onChange(event.target.value)}/>
            </div>
        </>

    )
}
