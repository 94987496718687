import React from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const GoodsCertificateImage = (props: {
    isCertificated: string,
    width: string
}) => {
    if(props.isCertificated !== "Y") {
        return <></>;
    }

    return (
        <div style={{
            width: props.width,
            height: "auto",
            position: "absolute",
            bottom: 0,
            right: 0
        }}>                
            <img alt="certificate-mark"
                src={process.env.REACT_APP_ENV_GOODS_CERTIFICATE_IMAGE}
                style={{ 
                    marginLeft: "1px",
                    marginBottom: "1px",
                    border: 0
                }}
                />
        </div>
    )
}
