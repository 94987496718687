import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './EdutechKoreaFair2024CompanyDataViewHeader.module.scss';
import { CompanyData } from '../../../../../../graphql/customScalars';
import { EdutechKoreaFair2024Area, Kotra, ViewMode } from '../../../../../../graphql/types';
import { OnChangeValue } from '../../../../../Intefaces';
import { BizCompanyDataViewHeaderInteraction } from '../../../../../shares/bizCompanyDataView/header/interaction/BizCompanyDataViewHeaderInteraction';
import { BizTitle } from '../../../../../shares/bizTitle/BizTitle';
import { BizCompanyDataHeaderTable } from '../../../../../shares/bizCompanyDataView/header/BizCompanyDataViewHeader';
import { SystemState } from '../../../../../../redux/System';
import { useSelector } from 'react-redux';

const cx = classNamesBind.bind(styles);

interface Props {
    edutechKoreaFair2024CompanyCategory: EdutechKoreaFair2024Area,
    companyData: CompanyData,
    kotra?: Kotra
    companyUserId?: number,
    onCreateChatRoomWithCompanyId?: OnChangeValue<number>
}

export const EdutechKoreaFair2024CompanyDataViewHeader = (props: Props) => {
    const system = useSelector<any, SystemState>(state => state.system);

    return (
        <>
            <div className={cx('box-img', 'p-r-30', 'm-b-30')} style={{marginBottom: 30}}>
                <div className={cx('img-logo')} >
                    <img src={props.companyData.logoUrl} alt=""/>
                </div>
                <BizCompanyDataViewHeaderInteraction
                    onCreateChatRoomWithCompanyId={props.onCreateChatRoomWithCompanyId}
                    companyUserId={props.companyUserId}
                    companyData={props.companyData}/>
            </div>
            <div className={cx('box-info', 'm-b-30')} style={{marginBottom: 30}}>
                <BizTitle>
                    {props.companyData.companyNm}

                    {system.viewMode === ViewMode.Web &&
                        <div 
                            style={{
                                display:"inline-block",
                                fontWeight: "bold",
                                marginLeft: "50px",
                                color: "black"
                            }}
                        >
                            {props.edutechKoreaFair2024CompanyCategory.boothNumber &&
                                props.edutechKoreaFair2024CompanyCategory.categoryName &&
                                <h5>
                                    {props.edutechKoreaFair2024CompanyCategory.boothNumber}
                                    &nbsp;|&nbsp;
                                    {props.edutechKoreaFair2024CompanyCategory.categoryName}
                                </h5>
                            }

                            {props.edutechKoreaFair2024CompanyCategory.categoryName &&
                                <h5>
                                    |&nbsp;
                                    {props.edutechKoreaFair2024CompanyCategory.categoryName}
                                </h5>
                            }
                        </div>
                    }

                    {system.viewMode === ViewMode.Mobile &&
                        <div 
                            style={{
                                fontWeight: "bold",
                                color: "black",
                                fontSize: "14px"
                            }}
                        >
                            {props.edutechKoreaFair2024CompanyCategory.boothNumber}
                            &nbsp;/&nbsp;
                            {props.edutechKoreaFair2024CompanyCategory.categoryName}
                        </div>
                    }
                </BizTitle>

                <BizCompanyDataHeaderTable {...props}/>
            </div>

        </>
    )
}
