import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './BizCompanyDataIdx.module.scss';
import {useSelector} from "react-redux";
import {I18nState} from "../../../redux/I18n";
import {CompanyDataUtil} from "../../../utils/companyDataUtil/CompanyDataUtil";
import {navigate} from "hookrouter";
import {Pipe} from "../../../utils/pipe/pipe";
import {Company} from "../../../graphql/types";
import {BizNoDataTable} from "../bizNoDataTable/BizNoDataTable";

const cx = classNamesBind.bind(styles);

interface Props {
    companyList: Company[]
}

export const BizCompanyDataIdx = (props: Props) => {
    const langCode = useSelector<any, I18nState>(state => state.i18n).langCode;


    return (
        <>
            {props.companyList.length === 0 && <BizNoDataTable/>}

            <div className={cx('cont')}>

                {props.companyList.map((value, index) => {
                    const companyData = CompanyDataUtil.getValue(langCode, value.company);
                    return (
                        <div key={index}
                             className={cx(value.isPaid ? 'box-full' : 'box-half', 'p-5')}
                             onClick={() => navigate(`/company/detail/${value.id}`)}>

                            <div className={cx('btn-company')}>
                                <img src={companyData.logoUrl} alt='logo'/>
                                <div className={cx('info')}>
                                    <div className={cx('company-nm')}>{companyData.companyNm}</div>
                                    <div className={cx('space')}/>
                                    <div className={cx('nation')}>
                                        <span className={cx('flag', 'flag-icon', `flag-icon-${companyData.nationCode.toLowerCase()}`)}/>
                                        <span className={cx('nm')}>{Pipe.nationNm(companyData.nationCode)}</span>
                                    </div>
                                    <div className={cx('catchphrase')}>
                                        {companyData.catchphrase}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </>

    )
}
