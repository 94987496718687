import React, {Fragment, ReactNode, useEffect} from 'react';

export const BizScrollTop = (props: {
    children: ReactNode,
}) => {
    useEffect(() => {
        return () => {
            window.scrollTo(0, 0);
        }
    }, [props.children])

    return (
        <Fragment>
            {props.children}
        </Fragment>
    )
}
