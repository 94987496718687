import React from 'react';
import {gql, useQuery} from "@apollo/client";
import {Query, QueryNoticeWithIdArgs} from "../../../../graphql/types";
import style from './CsNoticeView.module.scss';
import classNamesBind from "classnames/bind";
import {CsLayout} from "../../layout/CsLayout";
import {BizTitle} from "../../../shares/bizTitle/BizTitle";
import {FadeIn} from "../../../shares/fadeIn/FadeIn";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {BoardFileView} from "../../../shares/boardFileView/BoardFileView";

const cx = classNamesBind.bind(style);

export const CsNoticeView = (props: {
    noticeId: number,
}) => {
    const {data} = useQuery<Query, QueryNoticeWithIdArgs>(gqlData, {
        variables: props
    });
    const [t] = useTranslation('common');

    return (
        <CsLayout activatedSuffixRouter={'/notice/list'}>
            {data &&
            <FadeIn>
                <BizTitle>{data.noticeWithId.title}</BizTitle>
                <div className={cx('table-fluid')}>
                    <div className={cx('col', 'col-label')}>{t("cs.createDate")}</div>
                    <div
                        className={cx('col', 'col-value')}>
                        {moment(data.noticeWithId.createdAt).format('YY.MM.DD - hh:mm a')}
                    </div>
                    <div className={cx('col', 'col-label')}>{t("cs.finalUpdateDate")}</div>
                    <div className={cx('col', 'col-value')}>
                        {moment(data.noticeWithId.updatedAt).format('YY.MM.DD - hh:mm a')}
                    </div>
                </div>

                <BoardFileView
                    files={data.noticeWithId.fileList}/>

                <div className={cx('contents')}
                     ref={ref => {
                         if (ref) {
                             ref.innerHTML = data.noticeWithId.contents
                         }
                     }}/>

            </FadeIn>
            }


        </CsLayout>
    )
}

const gqlData = gql`
    query DATA($noticeId: Int!) {
        noticeWithId(noticeId: $noticeId) {
            id
            title
            contents
            createdAt
            updatedAt
            fileList {
                fileNm
                fileSrc
            }
        }
    }
`;
