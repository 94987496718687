import React, {useEffect} from 'react';
import {gql, useQuery} from "@apollo/client";
import {Query} from "../../../graphql/types";
import {TermsView} from "../shared/TermsView";
import {useSelector} from "react-redux";
import {I18nState} from "../../../redux/I18n";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {useTranslation} from "react-i18next";
import {I18nKeys} from "../../../i18n/i18nKeys";
import {BizScrollTop} from "../../shares/bizScrollTop/BizScrollTop";

export const TermsTerms = () => {
    const {data, refetch} = useQuery<Query, any>(gqlData);
    const [t] = useTranslation("common");
    const langCode = useSelector<any, I18nState>(state => state.i18n).langCode;

    useEffect(() => {
        refetch().catch(ApolloCatch({}))
    }, [langCode, refetch]);

    return (
        <BizScrollTop>
            {data &&
            <TermsView
                title={t(I18nKeys.terms.terms.key) || ''}
                body={data.terms}/>}
        </BizScrollTop>
    )
}

const gqlData = gql`
    query DATA {
        terms(key: "terms")
    }
`;
