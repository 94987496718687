import React from 'react';
import {CompanyConsultViews} from "../../../../../graphql/types";
import {ApexOptions} from "apexcharts";
import moment from "moment";
import Chart from "react-apexcharts";

export const MyPageConsultantDetailGraphViews = (props: {
    views: CompanyConsultViews[]
}) => {
    const options: ApexOptions = {
        chart: {
            type: 'bar',
            toolbar: {
                show: false,
            },
            animations: {
                enabled: false
            },
        },
        legend: {
            show: true,
            position: 'bottom'
        },
        labels: props.views.map(value => {
            return moment(value.date).format('MM.DD')
        })
    }

    const series = [
        {
            name: 'views',
            type: 'column',
            data: props.views.map(value => {
                return value.view;
            })
        }
    ];

    return (
        <>
            <Chart
                type='bar'
                height={300}
                options={options}
                series={series}/>
        </>
    )
}
