import React, { Fragment, ReactNode } from 'react';
import classNamesBind from "classnames/bind";
import styles from './LayoutWeb.module.scss';
import { NavTop } from "./navTop/NavTop";
import { NavBtm } from "./navBtm/NavBtm";
import { FooterWeb } from "./footer/FooterWeb";
import { CategWeb } from "./categ/CategWeb";
import { useDispatch, useSelector } from "react-redux";
import { LayoutWebAction, LayoutWebState } from "../../../redux/LayoutWeb";

const cx = classNamesBind.bind(styles);

export const LayoutWeb = (props: {
    children: ReactNode
}) => {
    const layoutWeb = useSelector<any, LayoutWebState>(state => state.layoutWeb);
    const dispatch = useDispatch();
    return (
        <Fragment>
            <div className={cx('box-nav')}>
                <NavTop />
                <NavBtm />
            </div>
            {layoutWeb.showCategory &&
                <div className={cx('box-categ')}
                    onClick={() => {
                        dispatch(LayoutWebAction.setCategory(false));
                    }}>
                    <CategWeb />
                </div>}



            <div className={cx('contents')}>
                {props.children}
            </div>
            <FooterWeb />
        </Fragment>
    )
}
