import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './MyPageConsultantList.module.scss';
import {ViewSelector} from '../../../../shares/viewSelector/ViewSelector';
import {
    CompanyConsultant,
    CompanyConsultantList,
    CompanyConsultantPermission,
    MutationToggleCompanyConsultantArgs
} from "../../../../../graphql/types";
import {Checkbox, IconButton, Tooltip} from "@material-ui/core";
import {BizNoDataTable} from "../../../../shares/bizNoDataTable/BizNoDataTable";
import {OnChangeValue, OnVoidFunction} from "../../../../Intefaces";
import {useSelector} from "react-redux";
import {I18nState} from "../../../../../redux/I18n";
import {I18nUtil} from "../../../../../utils/i18n/i18n";
import {Assignment, Edit} from "@material-ui/icons";
import {useTranslation} from "react-i18next";
import {navigate} from "hookrouter";

const cx = classNamesBind.bind(styles);

interface Props {
    myCompanyConsultantList: CompanyConsultantList,
    onClickCreate: OnVoidFunction,
    onToggle: OnChangeValue<MutationToggleCompanyConsultantArgs>,
    onClickUpdate: OnChangeValue<CompanyConsultant>
    onReload: OnVoidFunction,
}

export const MyPageConsultantList = (props: Props) => {
    return (
        <>
            <ViewSelector
                web={<Web {...props}/>}
                mobile={<Mobile {...props}/>}/>
        </>
    )
}

const Web = (props: Props) => {
    const langCode = useSelector<any, I18nState>(state => state.i18n).langCode;
    const [t] = useTranslation('common');

    return (
        <>
            <div className={cx('table')}>
                <div className={cx('header', 'box-activate')}>{t("mypageConsultant.activate")}</div>
                <div className={cx('header', 'box-position')}>{t("mypageConsultant.position")}</div>
                <div className={cx('header', 'box-nm')}>{t("mypageConsultant.nm")}</div>
                <div className={cx('header', 'box-tel')}>{t("mypageConsultant.tel")}</div>
                <div className={cx('header', 'box-email')}>{t("mypageConsultant.email")}</div>
                {/*<div className={cx('header', 'box-created-at')}>{t("mypageConsultant.createDate")}</div>*/}
                <div className={cx('header', 'box-edit')}>{t("mypageConsultant.function")}</div>
            </div>

            {props.myCompanyConsultantList.total === 0 && <BizNoDataTable/>}

            {props.myCompanyConsultantList.list.map((value, index) => (
                <div key={index} className={cx('table', 'hover')}>
                    <div className={cx('col', 'box-activate')}>
                        <Checkbox
                            color='primary'
                            checked={value.isActivate}
                            classes={{
                                root: 'p-0'
                            }}
                            onChange={ev => {
                                props.onToggle({
                                    companyConsultantId: value.id,
                                    swt: ev.target.checked,
                                })
                            }}/>
                    </div>
                    <div className={cx('col', 'box-position',
                        value.isActivate ? '' : 'line-through',
                        value.permission.includes(CompanyConsultantPermission.Position) ? 'activate' : 'inactivate')}>
                        {I18nUtil.value(langCode, value.position)}</div>

                    <div className={cx('col', 'box-nm',
                        value.isActivate ? '' : 'line-through',
                        value.permission.includes(CompanyConsultantPermission.Nm) ? 'activate' : 'inactivate')}>
                        {I18nUtil.value(langCode, value.nm)}</div>

                    <div className={cx('col', 'box-tel',
                        value.isActivate ? '' : 'line-through',
                        value.permission.includes(CompanyConsultantPermission.Tel) ? 'activate' : 'inactivate')}>
                        {I18nUtil.value(langCode, value.tel)}</div>

                    <div className={cx('col', 'box-email',
                        value.isActivate ? '' : 'line-through',
                        value.permission.includes(CompanyConsultantPermission.Email) ? 'activate' : 'inactivate')}>
                        {value.email}</div>

                    {/*<div className={cx('col', 'box-created-at')}>{moment(value.createdAt).format('YY.MM.DD')}</div>*/}
                    <div className={cx('col', 'box-edit', 'text-center')}>
                        <Tooltip title={t("mypageConsultant.ttUpdate") || ""}>
                            <IconButton size='small' onClick={() => props.onClickUpdate(value)}>
                                <Edit/>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={t("mypageConsultant.ttConsultantDetail") || ""}>
                            <IconButton onClick={() => navigate(`/mypage/consultant/detail/${value.id}`)}>
                                <Assignment/>
                            </IconButton>
                        </Tooltip>

                        {/* 채팅기능 주석처리 - 20230406 daekyu.ju */}
                        {/*
                        <MyPageConsultantListRegisterChatAccount
                            onReload={props.onReload}
                            consultantId={value.id}/>
                        */}
                    </div>
                </div>
            ))}

            <div className={cx('table')}>
                <div className={cx('col', 'col-full', 'text-right')}>
                    <button
                        className='btn btn-sm btn-success'
                        onClick={props.onClickCreate}>{t("mypageConsultant.btnCreateInput")}
                    </button>
                </div>
            </div>
        </>
    )
}

const Mobile = (props: Props) => {
    return (
        <><Web {...props}/></>
    )
}
