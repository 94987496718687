import {createActionCreators, createReducerFunction, ImmerReducer} from "immer-reducer";


export interface ScrollState {
    nationList: number,
    companyList: number,
}

const initState: ScrollState = {
    nationList: 0,
    companyList: 0,
};

class Scroll extends ImmerReducer<typeof initState> {
    setNationList(scrollY: number) {
        this.draftState.nationList = scrollY;
    }

    setCompanyList(scrollY: number) {
        this.draftState.companyList = scrollY;
    }
}


export const ScrollAction = createActionCreators(Scroll);
export const ScrollReducer = createReducerFunction(Scroll, initState);
