import React, {Fragment, useEffect, useState} from 'react';
import classNamesBind from "classnames/bind";
import styles from './InputConsultant.module.scss';
import {
    BindConsultant,
    BoundConsultant,
    CompanyConsultant,
    CompanyConsultantPermission
} from "../../../../../graphql/types";
import {OnChangeValue} from "../../../../Intefaces";
import {CopyUtil} from "../../../../../utils/copyUtil/copyUtil";
import {BizNoDataTable} from "../../../../shares/bizNoDataTable/BizNoDataTable";
import {useSelector} from "react-redux";
import {I18nState} from "../../../../../redux/I18n";
import {I18nUtil} from "../../../../../utils/i18n/i18n";
import {IconButton, Tooltip} from "@material-ui/core";
import {Add, Close} from "@material-ui/icons";
import {BizModal} from "../../../../shares/bizModal/BizModal";
import {ConfirmConsultant, InputConsultantAddModal} from "./addModal/InputConsultantAddModal";
import {SwalUtil} from "../../../../../utils/swal/swalUtil";
import {useTranslation} from "react-i18next";
import {DataState} from "../../../../../redux/Data";

const cx = classNamesBind.bind(styles);

interface Props {
    allConsultant: CompanyConsultant[],
    boundConsultant: BoundConsultant[],
    onSave: OnChangeValue<BindConsultant[]>,
}

export const InputConsultant = (props: Props) => {
    const [consultantList, setConsultantList] = useState<BoundConsultant[]>(CopyUtil.copyAll(props.boundConsultant));
    const [addModalConsultant, setAddModalConsultant] = useState<{ langCode: string, consultant: CompanyConsultant[] } | undefined>();
    const [t] = useTranslation('common');
    const langCode = useSelector<any, I18nState>(state => state.i18n).langCode;
    const data = useSelector<any, DataState>(state => state.data);

    useEffect(() => {
        setConsultantList(CopyUtil.copyAll(props.boundConsultant))
    }, [props.boundConsultant]);

    const handler = {
        consultantList: consultantList,
        setConsultantList: setConsultantList,
        getClass: (permission: CompanyConsultantPermission, list: CompanyConsultantPermission[]): string => {
            return list.includes(permission) ? 'activate' : 'inactivate';
        },
        onDeleteConsultant: (consultantId: number, lang: string) => {
            const idx = consultantList.findIndex(v => {
                return ((v.consultant.id === consultantId) && (v.langCode === lang));
            });

            if (idx === -1) {
                return;
            }

            let copy = CopyUtil.copyAll(consultantList);
            copy = [...copy.slice(0, idx), ...copy.slice(idx + 1, copy.length)]
            setConsultantList(copy);
        },
        onShowAddModal: (langCode: string) => {

            // 등록된 담당자
            const registedConsultant = consultantList.filter(v => {
                return v.langCode === langCode;
            })

            // 전체 담당자에서 이미 등록된 담당자를 뺀다.
            const modalConsultant: CompanyConsultant[] = [];
            for (let companyConsultant of props.allConsultant) {
                const idx = registedConsultant.findIndex(v => {
                    return v.consultant.id === companyConsultant.id
                })

                if (idx !== -1) {
                    continue;
                }

                modalConsultant.push(companyConsultant);
            }


            // 등록할 담당자가 없을때 알림을 주고 추가 모달을 켜지 않는다.
            if (modalConsultant.length === 0) {
                SwalUtil.ok({
                    msg: t("mypageConsultant.swalAddConsultantOk"),
                    icon: 'warning'
                })
                return;
            }

            setAddModalConsultant({langCode, consultant: modalConsultant});
        },
        onAddConsultant: (args: ConfirmConsultant[]) => {
            const copy = CopyUtil.copyAll(consultantList);

            // 같은언어 중복검사
            for (let boundConsultant of copy) {
                const idx = args.findIndex(input => {
                    return ((input.langCode === boundConsultant.langCode) && (input.id === boundConsultant.consultant.id))
                })

                if (idx !== -1) {
                    SwalUtil.ok({
                        msg: t("mypageConsultant.swalBoundLanguageError"),
                        icon: 'error'
                    });
                    return
                }
            }


            for (let elem of args) {
                copy.push({langCode: elem.langCode, consultant: {...elem}})
            }
            setConsultantList(copy);
            setAddModalConsultant(undefined);
        },
        onSave: () => {
            const data: BindConsultant[] = [];

            for (let i = 0; i < consultantList.length; i++) {
                const elem = consultantList[i];
                data.push({
                    langCode: elem.langCode,
                    companyConsultantId: elem.consultant.id,
                    order: i
                });
            }
            props.onSave(data);
        }
    }

    return (
        <>
            <div className={cx('table', 'cont-consultant')}>
                <div className={cx('header', 'box-lang')}>{t("mypageConsultant.language")}</div>
                <div className={cx('box-consultant')}>
                    <div className={cx('table', 'table-consultant')}>
                        <div className={cx('header', 'col-position')}>{t("mypageConsultant.position")}</div>
                        <div className={cx('header', 'col-nm')}>{t("mypageConsultant.nm")}</div>
                        <div className={cx('header', 'col-email')}>{t("mypageConsultant.email")}</div>
                        <div className={cx('header', 'col-delete')}>{t("mypageConsultant.delete")}</div>
                    </div>
                </div>
                <div className={cx('header', 'box-add')}>{t("mypageConsultant.add")}</div>
            </div>

            {data.langCode.map((lang, idx) => (
                <div key={idx} className={cx('table', 'cont-consultant')}>
                    <div className={cx('col', 'box-lang')}>{lang.code}</div>
                    <div className={cx('box-consultant')}>
                        {consultantList.filter(v => {
                            return v.langCode === lang.code
                        }).length === 0 && <BizNoDataTable borderNone/>}

                        {consultantList.filter(v => {
                            return v.langCode === lang.code
                        }).map((consultant, conIdx) => (
                            <Fragment key={conIdx}>
                                <div className={cx('table', 'hover', 'table-consultant', 'border-left-right')}>
                                    <div
                                        className={cx(
                                            'col',
                                            'col-nm',
                                            consultant.consultant.isActivate ? '' : 'line-through',
                                            handler.getClass(CompanyConsultantPermission.Position, consultant.consultant.permission))}>
                                        {I18nUtil.value(langCode, consultant.consultant.position)}
                                    </div>
                                    <div className={cx(
                                        'col',
                                        'col-position',
                                        consultant.consultant.isActivate ? '' : 'line-through',
                                        handler.getClass(CompanyConsultantPermission.Nm, consultant.consultant.permission))}>
                                        {I18nUtil.value(langCode, consultant.consultant.nm)}
                                    </div>
                                    <div className={cx(
                                        'col',
                                        'col-email',
                                        consultant.consultant.isActivate ? '' : 'line-through',
                                        handler.getClass(CompanyConsultantPermission.Email, consultant.consultant.permission)
                                    )}>
                                        {consultant.consultant.email}
                                    </div>
                                    <div className={cx('col', 'col-delete')}>
                                        <Tooltip title={t("mypageConsultant.delete") || ""}>
                                            <IconButton
                                                size='small'
                                                onClick={() => handler.onDeleteConsultant(consultant.consultant.id, lang.code)}>
                                                <Close/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>
                            </Fragment>
                        ))
                        }
                    </div>
                    <div className={cx('col', 'box-add')}>
                        <Tooltip title={t("mypageConsultant.add") || ''}>
                            <IconButton onClick={() => handler.onShowAddModal(lang.code)}>
                                <Add/>
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            ))}

            {/*<div className={cx('table')}>*/}
            {/*    <div className={cx('col', 'col-full')}>*/}
            {/*        <IconButton size='small' onClick={handler.onShowAddModal}>*/}
            {/*            <AddCircleOutline/>*/}
            {/*        </IconButton>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className={cx('table')}>
                <div className={cx('col', 'col-full', 'text-right')}>
                    <button
                        className='btn btn-sm btn-success'
                        onClick={handler.onSave}>{t("mypageConsultant.btnSave")}
                    </button>
                </div>
            </div>

            <BizModal
                title={t("mypageConsultant.modalCreateTitle")}
                isShow={Boolean(addModalConsultant)}
                onCancel={() => setAddModalConsultant(undefined)}>
                {addModalConsultant &&
                <InputConsultantAddModal
                    langCode={addModalConsultant.langCode}
                    consultant={addModalConsultant.consultant}
                    onConfirm={handler.onAddConsultant}/>
                }
            </BizModal>
        </>
    )
}
