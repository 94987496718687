import React, {useState} from 'react';
import classNamesBind from "classnames/bind";
import styles from './InputLogo.module.scss';
import {OnChangeValue} from "../../../../Intefaces";
import {useTranslation} from "react-i18next";
import {CompanyData} from "../../../../../graphql/customScalars";
import {DataUtil} from "../../../../../utils/data/DataUtil";
import {CopyUtil} from "../../../../../utils/copyUtil/copyUtil";
import {SwalUtil} from "../../../../../utils/swal/swalUtil";

const cx = classNamesBind.bind(styles);

export const InputLogo = (props: {
    input: CompanyData,
    onChangeInput: OnChangeValue<CompanyData>
}) => {
    const [t] = useTranslation('common');
    const [fileNm, setFileNm] = useState("");


    const onChangeLogo = () => {
        DataUtil.uploadFile({image: true})
            .then(res => {
                if (res.length === 0) {
                    return;
                }

                const copy = CopyUtil.copyAll(props.input);
                copy.logoUrl = res[0].src;
                setFileNm(res[0].nm);
                props.onChangeInput(copy);
            })
            .catch(() => {
                SwalUtil.ok({
                    msg: t("mypageGoods.swalUploadFileError"),
                    icon: 'error',
                })
            })

    }

    return (
        <div className={cx('cont')}>
            <div className={cx('img')}>
                {props.input.logoUrl && <img src={props.input.logoUrl} alt={'logo'}/>}
            </div>
            <div className={cx('input')}>
                <div className={cx('title')}>{t('mypageJoin.logo')}</div>
                <div className={cx('input-img')}>
                    <div className='form-control form-control-sm'>
                        {fileNm}
                    </div>
                    <button
                        className='btn btn-sm btn-success btn-block'
                        onClick={onChangeLogo}>{t('mypageJoin.btnUpload')}
                    </button>
                </div>
                <p>{t('mypageJoin.logoDesc')}</p>
            </div>
        </div>
    )
}
