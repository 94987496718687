import {BizErrors} from "../../graphql/errors";
import {SwalUtil} from "../swal/swalUtil";
import {GraphQLError} from "graphql";
import {GlobalVars} from "../../globalVars/globalVars";
import {ReduxStore} from "../../index";
import {UserAction} from "../../redux/User";
import {LocalStorage} from "../localstorage/LocalStorage";
import {navigate} from "hookrouter";

export type BizErrorReactor = { [key: string]: (reason: any) => void };
type Catch = (e: any) => void;

export const ApolloCatch = (reactor: BizErrorReactor): Catch => {
    return e => {
        DefaultCatch(e, reactor);
    }
}

// todo 이곳에 파이어베이스 에러를 계속 추가한다.
export const ApolloWithFbCatch = (reactor: BizErrorReactor): Catch => {
    return e => {
        if (e instanceof GraphQLError) {
            DefaultCatch(e, reactor);
            return;
        }

        const {code} = e;

        switch (code) {
            case "auth/wrong-password":
                DefaultCatch(new GraphQLError(BizErrors.failLoginFb), reactor);
                return;
            default:
                DefaultCatch(new GraphQLError(BizErrors.failLoginFb), reactor);
        }
    }
}


const DefaultCatch = (e: any, reactor: BizErrorReactor) => {
        GlobalVars.system.loadingView(false);

        if (!Boolean(e)) {
            SwalUtil.ok({
                msg: '실패 하였습니다.',
                icon: 'error'
            })
            return;
        }

        let {networkError, graphQLErrors} = e;


        if (e instanceof GraphQLError) {
            graphQLErrors = [e];
        }

        if (networkError) {
            SwalUtil.ok({
                msg: '서버와 접속이 불안정 합니다.<br/>다시 시도하여 주십시오.',
                icon: "error"
            });
            return;
        }

        if (!graphQLErrors) {
            return;
        }

        if (!reactor) {
            return;
        }

        let runDefault = true;
        for (let err of graphQLErrors) {
            if (reactor.hasOwnProperty(err.message)) {
                runDefault = false;
                reactor[err.message](e);
                continue;
            }

            if (err.message === BizErrors.swalCancel) {
                continue;
            }

            if (err.message === BizErrors.swalBackdrop) {
                continue;
            }

            if ((err.message === BizErrors.needLogin) && (ReduxStore.getState().user.isLogin)) {
                SwalUtil.ok({
                    msg: "로그인이 필요한 서비스 입니다.",
                    icon: "error"
                });
                ReduxStore.dispatch(UserAction.logout());
                LocalStorage.clearSession();
                navigate("/auth/login");
                return;
            }

            if (runDefault && (reactor.hasOwnProperty(BizErrors.default))) {
                reactor[BizErrors.default](e);
            } else if (runDefault) {
                SwalUtil.ok({
                    msg: e.message,
                    icon: 'error'
                })
            }
        }
}


