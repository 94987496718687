import {Apollo} from "../apollo/apollo";
import {ApolloClient, ApolloError, gql, HttpLink, InMemoryCache} from "@apollo/client";
import {Mutation, MutationUploadFileArgs, Query, UploadedFile, User} from "../../graphql/types";
import {ReduxStore} from "../../index";
import {DataAction} from "../../redux/Data";
import {UserAction} from "../../redux/User";
import {LocalStorage} from "../localstorage/LocalStorage";
import {AppStatus, SystemAction} from "../../redux/System";
import {ApolloCatch} from "../apollo/apolloCatch";
import {BizErrors} from "../../graphql/errors";
import {SwalUtil} from "../swal/swalUtil";
import {GraphQLError} from "graphql";
import {FB} from "../fb/FB";
import {I18nUtil} from "../i18n/i18n";
import fetch from "cross-fetch";

export class DataUtil {
    static init() {
        DataUtil.getDefaultData()
            .then(() => {
                ReduxStore.dispatch(SystemAction.setAppStatus(AppStatus.Ok))
                return DataUtil.refreshSession()

            })
            .catch(ApolloCatch({
                [BizErrors.default]: () => {
                    ReduxStore.dispatch(SystemAction.setAppStatus(AppStatus.ServerShutdown));
                },
                [BizErrors.default]: () => {
                    ReduxStore.dispatch(UserAction.logout());
                    LocalStorage.clearSession();
                }
            }))
    }

    static refreshSession(): Promise<User> {
        return Apollo.client.query<Query>({
            query: gql`
                query User {
                    user {
                        id
                        email
                        joinType
                        userType
                        joinAt
                        hasUserInfo
                        companyPermission
                    }
                }
            `
        }).then(res => {
            if (res.data) {
                ReduxStore.dispatch(UserAction.login(res.data.user));
                return res.data.user
            } else {
                throw new GraphQLError(BizErrors.needLogin);
            }
        });
    }

    static logout(): Promise<boolean> {
        FB.clearAllCookie();
        return Apollo.client.mutate<{ logout: boolean }>({
            mutation: gql`
                mutation Logout {
                    logout
                }
            `
        }).then(res => {
            ReduxStore.dispatch(UserAction.logout());
            LocalStorage.clearSession();
            return res.data!.logout
        })
    }

    static getDefaultData(): Promise<Query> {
        // 페이지가 새로고침 되었을 경우 localStorage에 저장되어 있던
        // 카테고리를 읽어들여 해당 카테고리에 상품리스트를 표시한다.
        const setCurrentDisplayedCategory = () => {
            const CURRENT_SELECTED_GOODS_CATEGORY_ID = localStorage.getItem("CURRENT_SELECTED_GOODS_CATEGORY_ID");
            const currentSelectedGoodsCategoryId = (
                CURRENT_SELECTED_GOODS_CATEGORY_ID === null ? -1 : parseInt(CURRENT_SELECTED_GOODS_CATEGORY_ID, 10)
            );
            
            if(currentSelectedGoodsCategoryId > -1) {
                ReduxStore.dispatch(
                    DataAction.setCurrentSelectedGoodsCategory({
                        id: currentSelectedGoodsCategoryId, 
                        listPage: {
                            pageStartId: 0,
                            currentPage: 0,
                            page: 0
                        }
                    })
                );
            }
        };
        setCurrentDisplayedCategory();

        
        const envFileName = process.env.REACT_APP_ENV_FILE_NAME;
        let uri = '';

        switch(envFileName) {
            case 'dev':
                uri = 'https://apidev.alledu.shop/graphql/user';
                break;
            case 'local':
                uri = 'http://localhost:2000/graphql/user';
                break;
            case 'local.ipaddress':
                uri = 'http://211.219.7.231:2000/graphql/user';
                break;
            case 'local.dev':
                uri = 'http://211.219.7.230:2000/graphql/user';
                break;
            case 'prod':
                uri = 'https://api.alledu.shop/graphql/user';
                break;
            case 'stage':
                uri = 'https://stage.alledu.shop/graphql/user';
                break;
            case 'aws':
                uri = 'https://www.alledu.shop/graphql/user';
                break;
        }

        const prodApollo = new ApolloClient({
            link: new HttpLink({uri: uri, fetch}),
            cache: new InMemoryCache(),
        })

        return prodApollo.query<Query>({
            query: gql`
                query Data {
                    i18n {
                        id
                        mainKey
                        subKey
                        contents
                    }
                }
            `
        }).then(res => {
            ReduxStore.dispatch(DataAction.setI18nList(res.data.i18n));

            // i18n 초기화
            I18nUtil.initI18n(I18nUtil.parseWithI18nContents(res.data.i18n));

            return Apollo.client.query<Query>({
                query: gql`
                    query user {
                        nationCode {
                            code
                            nm
                            shortNm
                            region
                        }
                        companyType {
                            id
                            nm
                        }
                        category {
                            depth
                            id
                            img
                            nm
                            order
                            parentId
                            superId
                        }
                        langCode {
                            nm
                            code
                        }
                        region {
                            id
                            region
                        }
                        priceUnit
                    }
                `
            })
        }).then(res => {
            ReduxStore.dispatch(DataAction.setLangCode(res.data.langCode));
            ReduxStore.dispatch(DataAction.setCategory(res.data.category));
            ReduxStore.dispatch(DataAction.setNationCode(res.data.nationCode));
            ReduxStore.dispatch(DataAction.setCompanyType(res.data.companyType));
            ReduxStore.dispatch(DataAction.setCompanyRegionIdx(res.data.region));
            ReduxStore.dispatch(DataAction.setPriceUnit(res.data.priceUnit));

            return res.data;
        })
    }

    static uploadFile(options?: { image?: boolean, multiple?: boolean, size?: number, accept?: string }): Promise<UploadedFile[]> {
        return new Promise<UploadedFile[]>((resolve, reject) => {
            const input = document.createElement('input');
            input.setAttribute('hidden', 'true');
            input.setAttribute('type', 'file');

            if (options) {
                if (options.image) {
                    input.setAttribute('accept', 'image/*');
                }

                if (options.multiple) {
                    input.setAttribute('multiple', 'true');
                }

                if (options.accept) {
                    input.setAttribute("accept", options.accept);
                }
            }


            input.click();
            input.onchange = () => {
                if (!input.files) {
                    return;
                }

                if (options) {
                    if ((options.size) && (options.size < input.files.length)) {
                        SwalUtil.ok({
                            msg: `업로드 할 수 없습니다.`,
                            icon: "error"
                        })
                        return;
                    }
                }

                const list: File[] = [];
                for (let i = 0; i < input.files.length; i++) {
                    const file = input.files[i];
                    if (!file) {
                        continue;
                    }

                    if ((10 * 1024 * 1024) < file.size) {
                        reject(new ApolloError({
                            graphQLErrors: [
                                new GraphQLError(BizErrors.overMaxSize)
                            ]
                        }))
                        return;
                    }

                    list.push(file);
                }


                Apollo.client.mutate<Mutation, MutationUploadFileArgs>({
                    mutation: gql`
                        mutation UploadFile($input: [Upload!]!) {
                            uploadFile(input: $input) {
                                id
                                nm
                                src
                                createdAt
                            }
                        }
                    `,
                    variables: {
                        input: list
                    }
                }).then(res => {
                    resolve(res.data!.uploadFile);
                }).catch(reason => {
                    reject(reason);
                });
            }
        });
    }

    static downloadFile(src: string) {
        const tag = document.createElement("a");
        tag.href = src;
        tag.target = "_blank";
        tag.setAttribute('hidden', 'true');
        tag.click();
    }
}
