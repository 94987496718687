import {createActionCreators, createReducerFunction, ImmerReducer} from "immer-reducer";

export enum ViewMode {
    Web='web', Mobile='mobile'
}

export interface WindowState {
    viewMode: ViewMode,
    loadingView: boolean
}

const initState: WindowState = {
    viewMode: ViewMode.Web,
    loadingView: false,
};

class Window extends ImmerReducer<typeof initState> {
    setViewMode(viewMode: ViewMode) {
        this.draftState.viewMode = viewMode;
    }

    setWindowWidth(width: number) {
        if (width < 998) {
            this.draftState.viewMode = ViewMode.Mobile;
        } else {
            this.draftState.viewMode = ViewMode.Web;
        }
    }

    setLoadingView(isView: boolean) {
        this.draftState.loadingView = isView;
    }


}


export const WindowAction = createActionCreators(Window);
export const WindowReducer = createReducerFunction(Window, initState);

