import React, {Fragment, ReactNode, useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './BizTabs.module.scss'
import {navigate} from "hookrouter";
import {Spring} from "react-spring/renderprops-universal";

const cx = classNamesBind.bind(styles);

export const BizTabs = (props: {
    data: BizTabsData,
    routerPrefix: string,
    activatedRouterSuffix: string,
}) => {
    const [idx, setIdx] = useState(0);
    const activeFontColor = 'rgb(255,255,255)';
    const inactiveFontColor = 'rgb(88,88,88)';
    const activeBarColor = 'rgba(13,179,230,1)';
    const inactiveBarColor = 'rgba(13,179,230,0)';

    useEffect(() => {
        const nextIdx = props.data.findIndex(value => {
            return props.activatedRouterSuffix === value.routerSuffix
        });

        if (nextIdx === -1) {
            setIdx(0);
        } else {
            setIdx(nextIdx);
        }
    }, [props.data, props.activatedRouterSuffix]);

    return (
        <Fragment>
            {props.data &&
            <Fragment>
                <div className={cx('cont-tab')}>
                    {props.data.map((value, index) => {
                        const isActivate = index === idx;
                        return (
                            <Spring
                                key={index}
                                from={{
                                    color: inactiveFontColor,
                                    bar: inactiveBarColor
                                }}
                                to={{
                                    color: isActivate ? activeFontColor : inactiveFontColor,
                                    bar: isActivate ? activeBarColor : inactiveBarColor,
                                }}>
                                {params => (
                                    <Fragment>
                                        <div
                                            onClick={() => {
                                                navigate(`${props.routerPrefix}${value.routerSuffix}`);
                                            }}
                                            style={{
                                                background: params.bar,
                                                color: params.color,
                                            }}
                                            className={cx('btn-tab')}>
                                            {value.title}
                                        </div>
                                    </Fragment>

                                )}
                            </Spring>
                        )
                    })}
                </div>

                <div>
                    {idx < props.data.length && props.data[idx].contents}
                </div>
            </Fragment>

            }
        </Fragment>
    )
}

export type BizTabsData = {
    title: ReactNode,
    contents: ReactNode,
    routerSuffix: string,
}[];
