import React, {useState} from 'react';
import classNamesBind from "classnames/bind";
import styles from './BizGoodsDataViewDefInfo.module.scss';
import {AllEduCode, CompanyDataMap, GoodsData} from "../../../../graphql/customScalars";
import {IconButton, Tooltip} from "@material-ui/core";
import {useSelector} from "react-redux";
import {I18nState} from "../../../../redux/I18n";
import {Pipe} from "../../../../utils/pipe/pipe";
import {useTranslation} from "react-i18next";
import {CompanyDataUtil} from "../../../../utils/companyDataUtil/CompanyDataUtil";
import {BizSmTitle, BizTitle} from "../../bizTitle/BizTitle";
import {BizGoodsDataViewDefInfoInteraction} from "./interaction/BizGoodsDataViewDefInfoInteraction";
import {CloudDownloadRounded} from "@material-ui/icons";
import isUrl from 'is-url';
import {I18nKeys} from "../../../../i18n/i18nKeys";
import {OnChangeValue} from "../../../Intefaces";

const cx = classNamesBind.bind(styles);

export const BizGoodsDataViewDefInfo = (props: {
    goodsId?: number,
    allEduCode?: AllEduCode,
    goodsInfoId?: number,
    goodsData: GoodsData,
    companyDataMap: CompanyDataMap,
    disableInteraction?: boolean,
    companyUserId?: number,
    onCreateChatRoomWithGoodsInfoId?: OnChangeValue<number>
}) => {
    const i18n = useSelector<any, I18nState>(state => state.i18n);
    const companyData = CompanyDataUtil.getValue(i18n.langCode, props.companyDataMap);
    const [goodsFileIdx] = useState(0);
    const [t] = useTranslation('common');

    const onDownload = () => {
        const tag = document.createElement("a");
        tag.href = props.goodsData.goodsFile[goodsFileIdx].src;
        tag.target = "_blank";
        tag.setAttribute('hidden', 'true');
        tag.click();
    }

    return (
        <>
            <div className={cx('box-title')}>
                <div className={cx('company-nm')}>{companyData.companyNm}</div>
                <BizTitle>{props.goodsData.nm}</BizTitle>
            </div>

            <div className={cx('price')} style={{marginBottom: 20}}>
                {props.goodsData.goodsPrice.noPrice && <div>{t('goods.noPrice')}</div>}
                {!props.goodsData.goodsPrice.noPrice &&
                <div>{Pipe.toSeparatorNumber(props.goodsData.goodsPrice.price)} {props.goodsData.goodsPrice.priceUnit}</div>}
            </div>

            <div>
                <div className={cx('catchphrase')}>{props.goodsData.catchphrase}</div>
                <hr/>
                <BizGoodsDataViewDefInfoInteraction
                    onCreateChatRoomWithGoodsInfoId={props.onCreateChatRoomWithGoodsInfoId}
                    goodsData={props.goodsData}
                    goodsId={props.goodsId}
                    goodsInfoId={props.goodsInfoId}
                    companyUserId={props.companyUserId}/>

            </div>


            <BizSmTitle>{t("goods.goodsInfo")}</BizSmTitle>
            {/*<PerfectScrollbar style={{height: 200}}>*/}
            {/*    <>*/}

            {(props.allEduCode && props.goodsInfoId) &&
            <div className={cx('table')}>
                <div>{t('goods.allEduCode')}</div>
                <div>{`${props.allEduCode}-${props.goodsInfoId}`}</div>
            </div>
            }


            <div className={cx('table')}>
                <div>{t('goods.origin')}</div>
                <div>
                        <span
                            style={{marginRight: 15}}
                            className={`flag-icon flag-icon-${props.goodsData.originNationCode.toLowerCase()}`}/>
                    {Pipe.nationNm(props.goodsData.originNationCode)}
                </div>
            </div>

            {props.goodsData.goodsCode &&
            <div className={cx('table')}>
                <div>{t('goods.goodsCode')}</div>
                <div>{props.goodsData.goodsCode}</div>
            </div>
            }

            {props.goodsData.award &&
            <div className={cx('table')}>
                <div>{t('goods.award')}</div>
                <div>{props.goodsData.award}</div>
            </div>
            }

            {props.goodsData.certification &&
            <div className={cx('table')}>
                <div>{t('goods.certification')}</div>
                <div>{props.goodsData.certification}</div>
            </div>
            }

            {(props.goodsData.youtubeUrl && isUrl(props.goodsData.youtubeUrl)) &&
            <div className={cx('table')}>
                <div>{t(I18nKeys.goods.youtubeUrl.key)}</div>
                <div>
                    <a href={props.goodsData.youtubeUrl} target='_blank' rel="noreferrer">
                        {props.goodsData.youtubeUrl}
                    </a>
                </div>
            </div>
            }

            {(props.goodsData.purchaseUrl && isUrl(props.goodsData.purchaseUrl)) &&
            <div className={cx('table')}>
                <div>{t(I18nKeys.goods.purchaseUrl.key)}</div>
                <div>
                    <a href={props.goodsData.purchaseUrl} target='_blank' rel="noreferrer">
                        {props.goodsData.purchaseUrl}
                    </a>
                </div>
            </div>
            }

            {(props.goodsData.tryGoodsUrl && isUrl(props.goodsData.tryGoodsUrl)) &&
            <div className={cx('table')}>
                <div>{t(I18nKeys.goods.tryGoodsUrl.key)}</div>
                <div>
                    <a href={props.goodsData.tryGoodsUrl} target='_blank' rel="noreferrer">
                        {props.goodsData.tryGoodsUrl}
                    </a>
                </div>
            </div>
            }

            {props.goodsData.goodsFile.length !== 0 &&
            <div className={cx('table')}>
                <div>{t('goods.files')}</div>
                <div className={cx('select')}>
                    {props.goodsData.goodsFile.map((value, index) => {
                        return (
                            <div key={index} className={cx("fileList")}>
                                {index + 1}. {value.nm}
                                <Tooltip title={t('goods.download') || ''}>
                                    <IconButton
                                        className={cx("btnDownload")}
                                        onClick={() => {
                                            onDownload();
                                        }}>
                                        <CloudDownloadRounded/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                        );
                    })}
                </div>
            </div>
            }
        </>
    )
}
