import {GoodsData, GoodsDataMap} from "../../graphql/customScalars";
import {GenerateCreateGoods} from "../../components/mypage/goods/registry/new/MyPageGoodsRegistry";
import {Validator} from "../validator/Validator";
import {CopyUtil} from "../copyUtil/copyUtil";

export class GoodsDataMapUtil {
    static getGoodsData(langCode: string, value: GoodsDataMap): GoodsData {

        const copy = CopyUtil.copyAll(value);

        if (!copy.hasOwnProperty('en')) {
            return GenerateCreateGoods().data['en'];
        }


        if (!copy.hasOwnProperty(langCode)) {
            return copy['en'];
        }

        const enValue = copy['en'] as any;
        const resValue = copy[langCode] as any;

        for (let key in enValue) {
            switch (Validator.getType(resValue[key])) {
                case "Array":
                    // 다음 조건은 카테고리의 상품 리스트를 출력할 때 사용한다.
                    // resValue[key][0]["src"] === "")
                    if (resValue[key].length === 0 || resValue[key][0]["src"] === "") {
                        resValue[key] = enValue[key]
                    }
                    break;
                default:
                    if (!resValue[key]) {
                        resValue[key] = enValue[key]
                    }
            }
        }

        return resValue
    }

    static validate(value: GoodsDataMap): { isOk: boolean, tValue: string } {
        if (!value.hasOwnProperty('en')) {
            return {isOk: false, tValue: 'mypageGoods.notFound'};
        }

        const enValue = value['en'];

        if (!enValue.nm) {
            return {isOk: false, tValue: 'mypageGoods.errNm'}
        }

        if (enValue.goodsImage.length === 0) {
            return {isOk: false, tValue: 'mypageGoods.errImage'}
        }

        if (!enValue.goodsPrice.noPrice) {
            if (enValue.goodsPrice.price === 0) {
                return {isOk: false, tValue: 'mypageGoods.errPrice'}
            }
        }

        if (!enValue.contents) {
            return {isOk: false, tValue: 'mypageGoods.errContents'}
        }

        return {isOk: true, tValue: ''}
    }
}
