import React, {Fragment} from 'react';
import {BizSmTitle, BizTitle} from "../../../shares/bizTitle/BizTitle";
import styles from './MyPageAskBoardList.module.scss';
import classNames from "classnames";
import {navigate} from "hookrouter";
import {gql, useQuery} from "@apollo/client";
import {AskBoard, ChatWriter, Query} from "../../../../graphql/types";
import {FadeIn} from "../../../shares/fadeIn/FadeIn";
import {Pager} from "../../../shares/pager/Pager";
import {BizNoDataTable} from "../../../shares/bizNoDataTable/BizNoDataTable";
import moment from "moment";
import {useSelector} from "react-redux";
import {I18nState} from "../../../../redux/I18n";
import {I18nUtil} from "../../../../utils/i18n/i18n";
import {GoodsDataMapUtil} from "../../../../utils/goodsDataMapUtil/goodsDataMapUtil";
import {BoardLayout} from "../../BoardLayout";
import {I18nKeys} from "../../../../i18n/i18nKeys";
import {useTranslation} from "react-i18next";

export const MyPageAskBoardList = (props: {
    page: number,
}) => {
    const [t] = useTranslation('common');

    const {data} = useQuery<Query, any>(gqlData, {
        variables: {
            page: props.page
        }
    });

    const handler = {
        onChangePage: (p: number) => {
            navigate(`/ask/board/list/${p}`);
        }
    }

    return (
        <BoardLayout
            activatedRouterSuffix={'/board/list'}>
            {data &&
            <FadeIn>
                <BizTitle>{t(I18nKeys.board.asklist.key)}</BizTitle>
                <div style={{height: 10}}/>
                <BizSmTitle>{t(I18nKeys.board.statusProceeding.key)}</BizSmTitle>
                <List list={data.myAskBoardInProcessing}/>

                <div style={{height: 30}}/>
                <BizSmTitle>{t(I18nKeys.board.statusComplete.key)}</BizSmTitle>
                <List
                    isDone
                    list={data.myAskBoardDone.list}/>
                <Pager
                    page={data.myAskBoardDone.page}
                    size={data.myAskBoardDone.size}
                    total={data.myAskBoardDone.total}
                    onChange={handler.onChangePage}/>
            </FadeIn>
            }
        </BoardLayout>
    )
}

const List = (props: {
    isDone?: boolean,
    list: AskBoard[],
}) => {
    const langCode = useSelector<any, I18nState>(state => state.i18n).langCode;
    const [t] = useTranslation('common');

    return (
        <>
            <div className={classNames(styles.table, styles.tableAskBoard)}>
                <div className={classNames(styles.header, styles.colId)}>{t(I18nKeys.board.number.key)}</div>
                <div className={classNames(styles.header, styles.colCreatedAt)}>{t(I18nKeys.board.createDate.key)}</div>
                <div className={classNames(styles.header, styles.colStatus)}>{t(I18nKeys.board.status.key)}</div>
                <div className={classNames(styles.header, styles.colTitle)}>{t(I18nKeys.board.subject.key)}</div>
                <div className={classNames(styles.header, styles.colCompanyNm)}>{t(I18nKeys.board.companyName.key)}</div>
                <div className={classNames(styles.header, styles.colGoods)}>{t(I18nKeys.board.productName.key)}</div>
            </div>

            {props.list.length === 0 && <BizNoDataTable/>}

            {props.list.map((value, index) => (
                <div key={index}
                     className={classNames(styles.table, styles.tableAskBoard, styles.hover, styles.pointer)}
                     onClick={() => navigate(`/ask/board/view/${value.id}`)}>
                    <div className={classNames(styles.col, styles.colId)}>{value.id}</div>
                    <div
                        className={classNames(styles.col, styles.colCreatedAt)}>{moment(value.createdAt).format('YY.MM.DD')}</div>
                    <div className={classNames(styles.col, styles.colStatus)}>
                        {props.isDone && <span className='badge badge-secondary'>{t(I18nKeys.board.statusComplete.key)}</span>}
                        {!props.isDone && <BadgeAskBoardStatus status={value.lastReplyWriter}/>}
                    </div>
                    <div className={classNames(styles.col, styles.ellipsis, styles.colTitle)}>{value.title}</div>
                    <div
                        className={classNames(styles.col, styles.colCompanyNm)}>{I18nUtil.value(langCode, value.companyNm)}</div>
                    <div className={classNames(styles.colGoods)}>
                        {(value.allEduCode && value.goodsInfoId && value.goodsData) &&
                        <>
                            <div className={styles.alleduCode}>{value.allEduCode}-{value.goodsInfoId}</div>
                            <div
                                className={styles.goodsNm}>{GoodsDataMapUtil.getGoodsData(langCode, value.goodsData).nm}</div>
                        </>
                        }

                        {!(value.allEduCode && value.goodsInfoId && value.goodsData) &&
                        <div className={styles.alleduCode}>-</div>
                        }
                    </div>
                </div>
            ))}
        </>
    );
}

const BadgeAskBoardStatus = (props: {
    status: ChatWriter
}) => {
    const [t] = useTranslation('common');

    return (
        <Fragment>
            {props.status === ChatWriter.Company && <span className='badge badge-primary'>{t(I18nKeys.board.badgeAnswer.key)}</span>}
            {props.status === ChatWriter.User && <span className='badge badge-warning'>{t(I18nKeys.board.badgeQuestion.key)}</span>}
            {props.status === ChatWriter.None && <span className='badge badge-success'>{t(I18nKeys.board.badgeWaiting.key)}</span>}
        </Fragment>
    )
}

const gqlData = gql`
    query Data($page: Int!) {
        myAskBoardInProcessing {
            id
            allEduCode
            companyNm
            content
            createdAt
            goodsData
            goodsInfoId
            lastReplyWriter
            status
            title
            userCompanyNm
            userEmail
        }
        myAskBoardDone(page: $page, size: 20) {
            page
            size
            total
            list {
                id
                allEduCode
                companyNm
                content
                createdAt
                goodsData
                goodsInfoId
                lastReplyWriter
                status
                title
                userCompanyNm
                userEmail
            }
        }
    }
`;
