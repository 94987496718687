import React from "react";
import {BizMenuLayout} from "../../shares/bizMenuLayout/BizMenuLayout";
import {useSelector} from "react-redux";
import {DataState} from "../../../redux/Data";
import {useTranslation} from "react-i18next";
import {NationMainList} from "./list/NationMainList";
import {BizScrollTop} from "../../shares/bizScrollTop/BizScrollTop";

export const NationMain = (props: {
    page: number,
    region: string,
    nationCode?: string,
}) => {
    const data = useSelector<any, DataState>(state => state.data);
    const [t] = useTranslation('common');


    return (
        <BizScrollTop>
            <BizMenuLayout
                title={t('company.findNation')}
                routerPrefix={'/nation'}
                activatedRouterSuffix={`/${props.region}`}
                data={
                    data.region.map((region) => {
                        return {title: region.region.replace("_", " "), routerSuffix: `/${region.region}`}
                    })
                }>
                <NationMainList
                    region={props.region}
                    page={props.page}
                    nationCode={props.nationCode}
                    size={20}/>
            </BizMenuLayout>
        </BizScrollTop>
    )
}
