import React from 'react';
import Kakao from "react-kakao-login";
import {GetEnv} from "../../../../../env/env";
import {SwalUtil} from "../../../../../utils/swal/swalUtil";
import {JoinType} from "../../../../../graphql/types";
import {OnChangeValue} from "../../../../Intefaces";
import {CryptoUtil, CryptoUtilPrefix} from "../../../../../utils/crypto/CryptoUtil";
import {useTranslation} from "react-i18next";

export const AuthLoginFormKakao = (props: {
    onLogin: OnChangeValue<{ email: string, pw: string, joinType: JoinType }>
}) => {
    const [t] = useTranslation('common');

    return (
        <>
            <Kakao
                needProfile
                persistAccessToken={false}
                token={GetEnv().kakaoToken}
                onSuccess={response => {
                    if (!response.profile) {
                        SwalUtil.ok({
                            msg: t('auth.kakaoUserNotFound'),
                            icon: 'error'
                        })
                        return;
                    }

                    const email = response.profile.kakao_account.email || `KAKAO-${response.profile.id}`;
                    const pw = CryptoUtil.encryptPw(CryptoUtilPrefix.Kakao, `${response.profile.id}`);


                    props.onLogin({email, pw, joinType: JoinType.Kakao});
                }}
                onFail={error => {
                    SwalUtil.ok({
                        msg: t('auth.failKakaoLogin'),
                        icon: 'error'
                    })
                    console.log(error);
                }}
                render={({onClick}) => (
                    <button
                        type='button'
                        className='btn'
                        style={{background: '#ffe812', marginBottom: 10, width: '100%'}}
                        onClick={onClick}>{t('auth.btnKakao')}</button>
                )}
                style={{width: '100%'}}
            />
        </>
    )
}
