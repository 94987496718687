import React from 'react';
import {CompanyUpdateStatus} from "../../../graphql/types";
import {I18nKeys} from "../../../i18n/i18nKeys";
import {useTranslation} from "react-i18next";

export const BadgeCompanyUpdateStatus = (props: {
    status: CompanyUpdateStatus
}) => {
    const [t] = useTranslation('common');

    return (
        <>
            {props.status === CompanyUpdateStatus.Accepted && <span className='badge badge-primary'>{t(I18nKeys.company.badgeCompanyRequestStatusAccepted.key)}</span>}
            {props.status === CompanyUpdateStatus.Requested && <span className='badge badge-success'>{t(I18nKeys.company.badgeCompanyRequestStatusRequested.key)}</span>}
            {props.status === CompanyUpdateStatus.Denied && <span className='badge badge-danger'>{t(I18nKeys.company.badgeCompanyRequestStatusCancel.key)}</span>}
            {props.status === CompanyUpdateStatus.Cancel && <span className='badge badge-secondary'>{t(I18nKeys.company.badgeCompanyRequestStatusDnied.key)}</span>}
        </>
    )
}
