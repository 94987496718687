import React, {Fragment, ReactNode} from 'react';
import {Modal} from "react-bootstrap";
import {BizTitle} from "../bizTitle/BizTitle";
import PerfectScrollbar from "react-perfect-scrollbar";
import 'react-perfect-scrollbar/dist/css/styles.css'
import {IconButton, Tooltip} from "@material-ui/core";
import {Close} from "@material-ui/icons";

export const BizModal = (props: {
    title?: ReactNode,
    children: ReactNode,
    isShow: boolean,
    onCancel: () => void,
    size?: 'sm' | 'lg' | 'xl',
}) => {
    return (
        <Fragment>
            <Modal
                show={props.isShow}
                onHide={props.onCancel}
                size={props.size ? props.size : 'lg'}
                centered translate={""}>
                {props.title &&
                <BizTitle
                    marginLeft
                    desc={
                        <Tooltip title={'닫기'}>
                            <IconButton
                                style={{marginRight: 10}}
                                size='small'
                                onClick={props.onCancel}>
                                <Close/>
                            </IconButton>
                        </Tooltip>
                    }>{props.title}</BizTitle>
                }
                <PerfectScrollbar style={{maxHeight: '60vh'}}>
                    <div>
                        {props.children}
                    </div>
                </PerfectScrollbar>
            </Modal>
        </Fragment>
    )
}
