import React, {useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './MyPageMyInfo.module.scss'
import {MyPageLayout} from "../layout/MyPageLayout";
import {BizSmTitle, BizTitle} from "../../shares/bizTitle/BizTitle";
import moment from "moment";
import {JoinType, Mutation, MutationUpdateUserInfoArgs, Query, UpdateUserInfo, UserInfo} from "../../../graphql/types";
import {useDispatch, useSelector} from "react-redux";
import {UserAction, UserState} from "../../../redux/User";
import {useTranslation} from "react-i18next";
import {MyInfoCompany} from "./company/MyInfoCompany";
import {BizTabs} from "../../shares/bizTabs/BizTabs";
import {gql} from "@apollo/client/core";
import {useMutation, useQuery} from "@apollo/client";
import {navigate} from "hookrouter";
import {MyInfoGoods} from "./goods/MyInfoGoods";
import {Element} from "react-scroll";
import {FB} from "../../../utils/fb/FB";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {MyInfoInput} from "./input/MyInfoInput";
import {Validator} from "../../../utils/validator/Validator";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {MyPageMyInfoProcessBar} from "./processBar/MyPageMyInfoProcessBar";
import {I18nKeys} from "../../../i18n/i18nKeys";
import { MyInfoCertificateApplication } from "./input/MyInfoCertificateApplication";

const cx = classNamesBind.bind(styles);

export const MyPageMyInfo = (props: {
    tabs: string,
    companyPage: number,
    goodsPage: number,
}) => {
    const {data, refetch} = useQuery<Query, {
        companyPage: number,
        goodsPage: number,
    }>(gqlData, {
        variables: {
            companyPage: props.companyPage,
            goodsPage: props.goodsPage,
        }
    });
    const [mutUpdate] = useMutation<Mutation, MutationUpdateUserInfoArgs>(gqlUpdate);
    const user = useSelector<any, UserState>(state => state.user);
    const [t] = useTranslation('common');
    const dispatch = useDispatch();
    const [userInfo, setUserInfo] = useState<UserInfo>({
        nm: {},
        position: {},
        department: {},
        tel: {},
        certificateInfo: {
            businessLicense: "",
            financialStatements: "",
        }
    });
    const [updateUserInfo, setUpdateUserInfo] = useState<UpdateUserInfo>({
        nm: {},
        position: {},
        department: {},
        tel: {},
        certificateInfo: {}
    });

    useEffect(() => {
        if (!data) {
            return;
        }

        if (!data.userInfo) {
            return;
        }

        setUserInfo({
            department: data.userInfo.department,
            nm: data.userInfo.nm,
            position: data.userInfo.position,
            tel: data.userInfo.tel,
            certificateInfo: {
                businessLicense: data.userInfo.certificateInfo?.businessLicense,
                financialStatements: data.userInfo.certificateInfo?.financialStatements,
            }
        });
        setUpdateUserInfo({
            department: data.userInfo.department,
            nm: data.userInfo.nm,
            position: data.userInfo.position,
            tel: data.userInfo.tel,
            certificateInfo: {
                businessLicense: File,
                financialStatements: File,
            }
        });
    }, [data]);

    const handler = {
        onChangeCompanyPage: (p: number) => {
            navigate(`/mypage/info/company/${p}/${props.goodsPage}`)
        },
        onChangeGoodsPage: (p: number) => {
            navigate(`/mypage/info/goods/${props.companyPage}/${p}`)
        },
        onSendResetPw: () => {
            FB.sendResetPwEmail(user.user.email)
                .then(() => {
                    SwalUtil.ok({
                        msg: t("mypage.swalSendResetPwEmail"),
                        icon: 'success'
                    })
                })
        },
        onUpdateMyInfo: (args: UpdateUserInfo) => {
            // userInfo의 변경된 값을 updateUserInfo로
            // 입력한다.
            function getChangedUserInfo() {
                args.department = userInfo.department;
                args.nm = userInfo.nm;
                args.position = userInfo.position;
                args.tel = userInfo.tel;
            }
            getChangedUserInfo();

            // 인증신청파일은 'en'키를 갖고 있지 않으므로
            // 유효성 검사에서 제외시킨 후, 유효성 검사가
            // 끝난 뒤에 다시 args변수에 입력한다.
            let certificateInfo = args.certificateInfo;
            delete args.certificateInfo;

            if (!Validator.validateUpdateUserInfo(args)) {
                SwalUtil.ok({
                    msg: t("mypage.swalValidateUpdateUserInfoError"),
                    icon: 'error'
                })
                return;
            }

            // 유효성 검사 전에 삭제한 인증신청파일 정보를
            // 다시 args변수에 입력한다.
            args['certificateInfo'] = certificateInfo;

            SwalUtil.ynPromise({
                msg: t("mypage.swalUpdateuserInfoQuestion")
            }).then(() => {
                // 회원 정보 입력기능을 잠시 속이기 위해서 임시값 입력.
                dispatch(UserAction.login({
                    ...user.user,
                    hasUserInfo: true,
                }))
                
                return mutUpdate({
                    variables: {
                        input: args,
                        certificateInfoData: userInfo.certificateInfo
                    }
                })
            }).then(() => {
                refetch();
            }).catch(reason => {
                if(reason.message === "INVALID_FILE_TYPE") {
                    SwalUtil.ok({
                        msg: t('common.swalInvalidFileType'),
                        icon: "error"
                    })
                } else {
                    ApolloCatch({});
                }
            })
        }
    }

    return (
        <MyPageLayout activatedSuffixRouter={'/myinfo'}>
            {data &&
            <>
                <BizTitle>{t('mypage.userInfo')}</BizTitle>
                <BizSmTitle>{t(I18nKeys.mypage.myInfoProgress.key)}</BizSmTitle>
                <MyPageMyInfoProcessBar
                    process={data.myPageProcess}/>

                <BizSmTitle>{t(I18nKeys.mypage.defaultInfo.key)}</BizSmTitle>
                <div className={cx('table-fluid')}>
                    <div className={cx('col', 'col-label')}>{t('mypage.email')}</div>
                    <div className={cx('col', 'col-value')}>{user.user.email}</div>

                    <div className={cx('col', 'col-label')}>{t('mypage.joinAt')}</div>
                    <div className={cx('col', 'col-value')}>{moment(user.user.joinAt).format('YYYY.MM.DD')}</div>

                    <div className={cx('col', 'col-label')}>{t('mypage.joinType')}</div>
                    <div className={cx('col', 'col-value')}>
                        {user.user.joinType}
                    </div>

                    <div className={cx('col', 'col-label')}>{t('mypage.companyStatus')}</div>
                    <div className={cx('col', 'col-value')}>
                        {user.user.userType}
                    </div>

                    {user.user.joinType === JoinType.Email &&
                    <>
                        <div className={cx('col', 'col-label')}>{t('mypage.changePw')}</div>
                        <div className={cx('col', 'col-full')}>
                            <button
                                className='btn btn-sm btn-outline-primary'
                                onClick={() => handler.onSendResetPw()}>{t('mypage.btnChangePw')}
                            </button>
                        </div>
                    </>
                    }
                </div>

                <div style={{height: 10}}/>
                <BizTitle>{t("mypage.titleUserInfoDetail")} <span
                    className={cx("red")}>{t("mypage.required")}</span></BizTitle>
                <MyInfoInput
                    {...handler}
                    value={userInfo}
                    onChangeValue={setUserInfo}/>

                <div style={{height: 10}}/>
                <BizTitle>
                    {t("mypage.companyInfoFiles")}
                    <span className={cx("red")}>(* {t("mypage.registerForProductCertification")})</span>
                </BizTitle>
                
                <MyInfoCertificateApplication
                    {...handler}
                    value={userInfo}
                    updateUserInfo={updateUserInfo}
                    onChangeValue={setUserInfo}/>
                
                <Element name={'mypage'}/>
                <div style={{height: 40}}/>
                <BizTabs
                    routerPrefix={'/mypage/info'}
                    activatedRouterSuffix={`/${props.tabs}`}
                    data={[
                        {
                            title: t('mypage.likeCompany'),
                            routerSuffix: '/company',
                            contents: <MyInfoCompany
                                companyList={data.userLikeCompany}
                                onChangePage={handler.onChangeCompanyPage}/>
                        },
                        {
                            title: t('mypage.likeGoods'),
                            routerSuffix: '/goods',
                            contents: <MyInfoGoods
                                goodsList={data.userLikeGoods}
                                onChangePage={handler.onChangeGoodsPage}
                            />
                        },
                    ]}
                />
            </>

            }
        </MyPageLayout>
    )
}

const gqlData = gql`
    query Data($companyPage: Int!, $goodsPage: Int!) {
        userInfo {
            nm
            department
            position
            tel
            certificateInfo
        }
        userLikeCompany(page: $companyPage, size: 20) {
            page
            size
            total
            list {
                id
                isPaid
                company

            }
        }
        userLikeGoods(page: $goodsPage, size: 20) {
            page
            size
            total
            list {
                id
                allEduCode
                isPaid
                data
                company
                companyUserId
            }
        }
        myPageProcess {
            registerCompany
            registerConsultant
            registerGoods
            registerUserInfo
        }
        myPageCreateCompanyRequestStatus
        myPageUpdateCompanyRequestStatus
    }
`;

const gqlUpdate = gql`
    mutation Update($input: UpdateUserInfo!, $certificateInfoData: CertificateInfo) {
        updateUserInfo(input: $input, certificateInfoData: $certificateInfoData)
    }
`;
