import React from 'react';
import {gql, useQuery} from "@apollo/client";
import {Query, QueryNewsletterWithIdArgs} from "../../../../graphql/types";
import style from './CsNewsletterView.module.scss';
import classNamesBind from "classnames/bind";
import {CsLayout} from "../../layout/CsLayout";
import {BizTitle} from "../../../shares/bizTitle/BizTitle";
import {FadeIn} from "../../../shares/fadeIn/FadeIn";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {BoardFileView} from "../../../shares/boardFileView/BoardFileView";

const cx = classNamesBind.bind(style);

export const CsNewsletterView = (props: {
    newsletterId: number,
}) => {
    const {data} = useQuery<Query, QueryNewsletterWithIdArgs>(gqlData, {
        variables: props
    });
    const [t] = useTranslation('common');

    return (
        <CsLayout activatedSuffixRouter={'/newsletter/list'}>
            {data &&
            <FadeIn>
                <BizTitle>{data.newsletterWithId.title}</BizTitle>
                <div className={cx('table-fluid')}>
                    <div className={cx('col', 'col-label')}>{t("cs.createDate")}</div>
                    <div
                        className={cx('col', 'col-value')}>
                        {moment(data.newsletterWithId.createdAt).format('YY.MM.DD - hh:mm a')}
                    </div>
                    <div className={cx('col', 'col-label')}>{t("cs.finalUpdateDate")}</div>
                    <div className={cx('col', 'col-value')}>
                        {moment(data.newsletterWithId.updatedAt).format('YY.MM.DD - hh:mm a')}
                    </div>
                </div>

                <BoardFileView
                    files={data.newsletterWithId.fileList}/>

                <div className={cx('contents')}
                     ref={ref => {
                         if (ref) {
                             ref.innerHTML = data.newsletterWithId.contents
                         }
                     }}/>

            </FadeIn>
            }
        </CsLayout>
    )
}

const gqlData = gql`
    query DATA($newsletterId: Int!) {
        newsletterWithId(newsletterId: $newsletterId) {
            id
            title
            contents
            createdAt
            updatedAt
            fileList {
                fileNm
                fileSrc
            }
        }
    }
`;
