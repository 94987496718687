import withReactContent from "sweetalert2-react-content";
import Swal, {SweetAlertIcon} from "sweetalert2";
import {I18nUtil} from "../i18n/i18n";
import {GraphQLError} from "graphql";
import {BizErrors} from "../../graphql/errors";

export class SwalUtil {
    private static swal = withReactContent(Swal);

    static yn(param: {
        msg: string, icon: SweetAlertIcon, ok?: () => void, cancel?: () => void,
        text?: string
    }) {
        this.swal.fire({
            title: param.msg,
            text: param.text,
            icon: param.icon,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: I18nUtil.t("common.btnOk"),
            cancelButtonText: I18nUtil.t("common.btnCancel")
        }).then(value => {
            if (value.value) {
                if (param.ok) {
                    param.ok();
                }
            } else {
                if (param.cancel) {
                    param.cancel();
                }
            }
        });
    }

    static ynWithPromise(param: {
        msg: string, icon: SweetAlertIcon
    }): Promise<boolean> {
        return this.swal.fire({
            title: param.msg,
            icon: param.icon,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: I18nUtil.t("common.btnOk"),
            cancelButtonText: I18nUtil.t("common.btnCancel")
        }).then(value => {
            return !!value.value;
        });
    }

    static ok(param: {
        msg: string,
        icon: SweetAlertIcon,
        ok?: () => void
    }) {
        this.swal.fire({
            title: param.msg,
            icon: param.icon,
            confirmButtonText: I18nUtil.t("common.btnOk"),
        }).then(value => {
            if (param.ok) {
                param.ok();
            }
        });
    }

    static pw(ok: (pw: string) => void) {
        this.swal.fire({
            title: '비밀번호를 입력하여 주십시오',
            input: 'password',
            inputPlaceholder: '비밀번호',
            inputAttributes: {
                autocapitalize: 'off',
                autocorrect: 'off'
            },
            backdrop: false,
            showCancelButton: true
        }).then(value => {
            if (value.dismiss !== Swal.DismissReason.cancel) {
                const password = value.value as string;
                if (password) {
                    ok(password);
                } else {
                    this.ok({msg: '비밀번호를 입력하여 주십시오.', icon: 'error'});
                }
            }
        });
    }

    static ynPromise<T>(param: {
        msg: string,
        okBtn?: string,
        cancelBtn?: string,
        value?: T
    }): Promise<T | undefined> {
        return this.swal.fire({
            title: param.msg,
            icon: "question",
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: param.okBtn || I18nUtil.t("common.btnOk"),
            cancelButtonText: param.cancelBtn || I18nUtil.t("common.btnCancel")
        }).then(res => {
            if (res.isConfirmed) {
                return param.value;
            }

            switch (res.dismiss) {
                case Swal.DismissReason.backdrop:
                    throw new GraphQLError(BizErrors.swalBackdrop);
                case Swal.DismissReason.cancel:
                    throw new GraphQLError(BizErrors.swalCancel);
                default:
                    throw new GraphQLError(BizErrors.swalBackdrop);
            }
        })
    }

    static stringPromise(param: {
        msg: string,
        text?: string,
    }): Promise<string> {
        return this.swal.fire({
            title: param.msg,
            text: param.text,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: I18nUtil.t("common.btnOk"),
            cancelButtonText: I18nUtil.t("common.btnCancel"),
            input: "text"
        }).then(res => {
            if (!res.isConfirmed) {
                throw new GraphQLError(BizErrors.swalCancel);
            }

            return String(res.value) || '';
        })
    }

    static error() {
        this.ok({
            msg: I18nUtil.t("common.defaultError"),
            icon: "error"
        });
    }

    static apolloCatch(param: {
        msg: string,
        icon: SweetAlertIcon,
        ok?: () => void
    }): () => void {
        return () => {
            this.ok(param);
        }
    }

    static apolloError(): () => void {
        return () => {
            SwalUtil.error();
        }
    }
}
