import React, {ReactNode} from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, {registerLocale} from "react-datepicker";
import ko from 'date-fns/locale/ko';
import en from 'date-fns/locale/en-US';
import moment from "moment";
import styles from './BizPeriodPicker.module.scss'
import classNamesBind from "classnames/bind";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {OnChangeValue, OnVoidFunction} from "../../Intefaces";
import {useSelector} from "react-redux";
import {I18nState} from "../../../redux/I18n";
import {useTranslation} from "react-i18next";

registerLocale('ko', ko);
registerLocale('en', en);

const cx = classNamesBind.bind(styles);

export const BizPeriodPicker = (props: {
    period: Period,
    onChange: OnChangeValue<Period>,
    onError?: OnVoidFunction,
    children?: ReactNode
}) => {
    const langCode = useSelector<any, I18nState>(state => state.i18n).langCode;
    const [t] = useTranslation('common');

    return (
        <>
            <div className={cx('cont')}>
                <div className={cx('label', 'def-text')}>{t("common.startDate")}</div>
                <div className={cx('picker', 'text-right')}>
                    <DatePicker
                        className='form-control form-control-sm text-center'
                        locale={langCode}
                        dateFormat={"yyyy/MM/dd"}
                        selectsStart
                        selected={props.period.stDate}
                        endDate={props.period.edDate}
                        onChange={date => {
                            if (date instanceof Date) {
                                if (moment(props.period.edDate).toDate().getTime() < date.getTime()) {
                                    SwalUtil.ok({
                                        msg: t("common.swalGetEndDateError"),
                                        icon: 'error',
                                    })
                                    if (props.onError) {
                                        props.onError();
                                    }
                                    return;
                                }

                                const copy = Object.assign({}, props.period);
                                copy.stDate = date;
                                props.onChange(copy);
                            }
                        }}/>
                </div>
                <div className={cx('label', 'def-text')}>{t("common.endDate")}</div>
                <div className={cx('picker', 'text-right')}>
                    <DatePicker
                        className='form-control form-control-sm text-center'
                        locale={langCode}
                        dateFormat={"yyyy/MM/dd"}
                        selectsEnd
                        selected={props.period.edDate}
                        startDate={props.period.stDate}
                        onChange={date => {
                            if (date instanceof Date) {
                                if (date.getTime() < moment(props.period.stDate).toDate().getTime()) {
                                    SwalUtil.ok({
                                        msg: t("common.swalgetStartDateError"),
                                        icon: 'error',
                                    })
                                    if (props.onError) {
                                        props.onError();
                                    }
                                    return;
                                }

                                const copy = Object.assign({}, props.period);
                                copy.edDate = date;
                                props.onChange(copy);
                            }
                        }}/>
                </div>
                <div style={{flexGrow: 1, paddingRight: 10}} className='text-right'>
                    {props.children}
                </div>
            </div>
        </>
    )
}

export type Period = {
    stDate: Date;
    edDate: Date;
}
