import React, {useEffect} from 'react';
import {gql, useMutation} from "@apollo/client";
import {Mutation, MutationLoginWithSocialArgs} from "../../../../graphql/types";
import {LocalStorage} from "../../../../utils/localstorage/LocalStorage";
import {GlobalVars} from "../../../../globalVars/globalVars";
import {DataUtil} from "../../../../utils/data/DataUtil";
import {ApolloCatch} from "../../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../../graphql/errors";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import {useTranslation} from "react-i18next";
import {AuthLoginFormKakao} from "./kakao/AuthLoginFormKakao";
import {AuthLoginNaver} from "./naver/AuthLoginNaver";
import {AuthLoginGoogle} from "./google/AuthLoginGoogle";

export const AuthSocialLogin = (props: {
    title: string
}) => {
    const [t] = useTranslation('common');
    const [mutLoginWithSocial] = useMutation<Mutation, MutationLoginWithSocialArgs>(gqlLoginWithSocial);

    // 네이버 로그인 타이머.
    const timer = setInterval(() => {
        if (LocalStorage.getIsNaverLogined()) {
            LocalStorage.setIsNaverLogined(false);
            window.history.back();
        }
    }, 1000);

    useEffect(() => {
        return () => {
            clearInterval(timer);
        }
    /*
    // 다음과 같은 경고 메시지를 처리하기 위해서 useEffect파라미터로 timer을 넘겨주었으나
    // 운영서버에서 제대로 동작하기 않고, 로컬 개발환경에서 테스트하기 위해서는 테스트 환경을 
    // 구성해야 하므로, 일단 소스를 원상복구시키고 eslint-disable-next-line으로 처리하기로 한다.
    // ---------------------------------------------------------------------------------
    // Line 34:8:  React Hook useEffect has a missing dependency: 'timer'. 
    // Either include it or remove the dependency array  react-hooks/exhaustive-deps
    // ---------------------------------------------------------------------------------
    */
    // eslint-disable-next-line
    }, [])


    const handler = {
        onLoginWithSocial: (args: MutationLoginWithSocialArgs) => {
            GlobalVars.system.loadingView(true);
            mutLoginWithSocial({variables: args})
                .then(res => {
                    LocalStorage.setSession(res.data!.loginWithSocial);
                    return DataUtil.refreshSession()
                })
                .then(res => {
                    window.history.back();
                }).catch(ApolloCatch({
                [BizErrors.dontActBizErrors]: () => {
                },
                [BizErrors.notFoundUser]: SwalUtil.apolloCatch({
                    msg: t('auth.notFoundUser'),
                    icon: 'error'
                }),
                [BizErrors.alreadyJoinedEmail]: SwalUtil.apolloCatch({
                    msg: t('auth.alreadyJoinedEmail'),
                    icon: 'error',
                }),
            }))
        },
        onLoginWithGoogle: (args: MutationLoginWithSocialArgs) => {
            GlobalVars.system.loadingView(true);
            mutLoginWithSocial({variables: args})
                .then(res => {
                    LocalStorage.setSession(res.data!.loginWithSocial);
                    return DataUtil.refreshSession()
                })
                .then(() => {
                    window.history.back();
                }).catch(ApolloCatch({
                [BizErrors.dontActBizErrors]: () => {
                },
                [BizErrors.failGoogleLogin]: SwalUtil.apolloCatch({
                    msg: t('auth.failGoogleLogin'),
                    icon: 'error',
                }),
                [BizErrors.notFoundUser]: SwalUtil.apolloCatch({
                    msg: t('auth.notFoundUser'),
                    icon: 'error'
                }),
                [BizErrors.alreadyJoinedEmail]: SwalUtil.apolloCatch({
                    msg: t('auth.alreadyJoinedEmail'),
                    icon: 'error',
                })
            }))
        },
    }

    return (
        <>
            <p>{props.title}</p>
            <div style={{height: 20}}/>
            <AuthLoginFormKakao
                onLogin={handler.onLoginWithSocial}/>
            <AuthLoginNaver/>
            <AuthLoginGoogle
                onLoginWithGoogle={handler.onLoginWithGoogle}/>
        </>
    )
}


const gqlLoginWithSocial = gql`
    mutation LoginWithSocial($email: String!, $pw: String!, $joinType: JoinType!) {
        loginWithSocial(email: $email, pw: $pw, joinType: $joinType)
    }
`;
