import React from "react";
import {gql, useQuery} from "@apollo/client";
import {BizMenuLayout} from "../../../shares/bizMenuLayout/BizMenuLayout";
import {BizTitle} from "../../../shares/bizTitle/BizTitle";
import {Query} from "../../../../graphql/types";
import {BizScrollTop} from "../../../shares/bizScrollTop/BizScrollTop";
import { EdutechKoreaFair2024CompanyData } from "./EdutechKoreaFair2024CompanyData/EdutechKoreaFair2024CompanyData";

export const EdutechKoreaFair2024CompanyList = (props: {
    id: number
}) => {
    const {data} = useQuery<Query>(gqlData, {
        variables: {
            subCategoryId: props.id
        }
    });

    const edutechKoreaFair2024Category = data?.edutechKoreaFair2024Category;
    const edutechKoreaFair2024Company = data?.edutechKoreaFair2024Company;

    // 서브 카테고리의 이름을 얻는다.
    const getSubCategoryName = (id: number) => {
        for(let i = 0; i < edutechKoreaFair2024Category!.length; i++) {
            const category = edutechKoreaFair2024Category![i];
            if(category.id === id) {
                return category.name;
            }
        }

        return "";
    }


    return (
        <BizScrollTop>
            {data &&
            <BizMenuLayout
                title={"2024에듀테크코리아페어"}
                routerPrefix={'/edutechkoreafair/2024'}
                activatedRouterSuffix={`/list/${props.id}`}
                data={
                    edutechKoreaFair2024Category!.map((value, index) => {
                        return {title: value.name, routerSuffix: `/list/${value.id}`}
                    })
                }>
                <BizTitle>{getSubCategoryName(props.id)}</BizTitle>

                <EdutechKoreaFair2024CompanyData
                    companyList={edutechKoreaFair2024Company!}/>
                    
                <div style={{height: 30}}/>
            </BizMenuLayout>
            }

        </BizScrollTop>
    )
}

const gqlData = gql`
    query EdutechKoreaFair2024($subCategoryId: Int!) {
        edutechKoreaFair2024Category {
            id
            name
        }
        edutechKoreaFair2024Company(subCategoryId: $subCategoryId) {
            id
            categoryId
            companyData
        }
    }
`;
