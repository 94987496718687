import React, {Fragment} from 'react';
import classNamesBind from "classnames/bind";
import styles from './BizCompanyDataInTable.module.scss';
import {CompanyDataMap} from "../../../graphql/customScalars";
import {useSelector} from "react-redux";
import {I18nState} from "../../../redux/I18n";
import {CompanyDataUtil} from "../../../utils/companyDataUtil/CompanyDataUtil";
import {ViewSelector} from "../viewSelector/ViewSelector";

const cx = classNamesBind.bind(styles);

interface Props {
    companyDataMap: CompanyDataMap
}

export const BizCompanyDataInTable = (props: Props) => {
    return (
        <Fragment>
            <ViewSelector
                web={<Web {...props}/>}
                mobile={<Mobile {...props}/>}/>
        </Fragment>
    )
}

const Web = (props: Props) => {
    const i18n = useSelector<any, I18nState>(state => state.i18n);
    const companyData = CompanyDataUtil.getValue(i18n.langCode, props.companyDataMap);

    return (
        <>
            <div className={cx('cont')}>
                <div className={cx('img')}>
                    <img src={companyData.logoUrl} alt=""/>
                </div>
                <div className={cx('info')}>
                    <div className={cx('nm')}>{companyData.companyNm}</div>
                    <div>
                        <span style={{marginRight: 10}}
                              className={`flag-icon flag-icon-${companyData.nationCode.toLowerCase()}`}/>
                        {companyData.nationCode}
                    </div>
                </div>
            </div>

        </>
    )
}

const Mobile = (props: Props) => {
    return (
        <><Web {...props}/></>
    )
}
