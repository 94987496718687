import {CompanyData, CompanyDataMap} from "../../graphql/customScalars";
import {CopyUtil} from "../copyUtil/copyUtil";
import {Validator} from "../validator/Validator";
import {CreateCompanyInput} from "../../components/mypage/company/create/MyPageCompanyCreate";
import {ReduxStore} from "../../index";

export class CompanyDataUtil {
    static DEFAULT_LANG_CDOE = 'en';

    static getValue(langCode: string, value: CompanyDataMap): CompanyData {

        const copy = CopyUtil.copyAll(value);

        if (!copy.hasOwnProperty('en')) {
            return CreateCompanyInput.prod().input['en'];
        }

        if (!copy.hasOwnProperty(langCode)) {
            return copy['en'];
        }

        const enValue = copy['en'] as any;
        const resValue = copy[langCode] as any;


        return Validator.copyAtoB(enValue, resValue);
    }

    static addNoLangs(value: CompanyDataMap): CompanyDataMap {
        const copy = CopyUtil.copyAll(value);
        const langCodeList = ReduxStore.getState().data.langCode;

        for (let langCode of langCodeList) {
            if (copy.hasOwnProperty(langCode.code)) {
                continue;
            }
            copy[langCode.code] = this.newCompanyData();
        }

        return copy;
    }

    static newCompanyData(): CompanyData {
        return {
            nationCode: 'KR',
            companyTypeId: 1,
            logoUrl: "",
            companyNm: "",
            ceo: "",
            category: "",
            catchphrase: "",
            addr: "",
            nationSales: [],
            nationUsage: [],
            totalSales: [],
            hp: "",
            foundation: new Date(),
            member: 0,
            companyFiles: [],
            priceUnit: '$ (USD)',
            introduceYoutubeUrl: '',
            companyDetail: '',
        }
    }

    static sync(value: CompanyDataMap): CompanyDataMap {
        const enValue = value[this.DEFAULT_LANG_CDOE];

        const otherLangCodeList = Object.keys(value).filter(v => {
            return v !== this.DEFAULT_LANG_CDOE;
        })

        const copy = CopyUtil.copyAll(value);
        for (let langCode of otherLangCodeList) {
            copy[langCode].member = enValue.member;
            copy[langCode].foundation = enValue.foundation;
            copy[langCode].nationCode = enValue.nationCode;
            copy[langCode].companyTypeId = enValue.companyTypeId;
            copy[langCode].priceUnit = enValue.priceUnit;
            copy[langCode].companyFiles = enValue.companyFiles;
            copy[langCode].nationSales = enValue.nationSales;
            copy[langCode].nationUsage = enValue.nationUsage;
            copy[langCode].totalSales = enValue.totalSales;
        }

        return copy;
    }

    static validate(value: CompanyDataMap): { isOk: boolean, tValue: string } {
        const syncData = this.sync(value);
        const enValue = syncData[this.DEFAULT_LANG_CDOE];

        if (!enValue.nationCode) {
            return {isOk: false, tValue: 'mypageJoin.errNationCode'}
        }

        if (!enValue.logoUrl) {
            return {isOk: false, tValue: 'mypageJoin.errLogo'}
        }

        if (!enValue.companyTypeId) {
            return {isOk: false, tValue: 'mypageJoin.errCompanyTypeId'}
        }

        if (!enValue.companyNm) {
            return {isOk: false, tValue: 'mypageJoin.errNm'}
        }

        if (!enValue.ceo) {
            return {isOk: false, tValue: 'mypageJoin.errCeo'}
        }

        if (!Validator.isStartWithEnglishAndNumber(enValue.companyNm)) {
            return {isOk: false, tValue: 'mypageJoin.companyNameNoStartWithEnglish'}
        }

        if (!enValue.category) {
            return {isOk: false, tValue: 'mypageJoin.errCategory'}
        }

        if (!enValue.catchphrase) {
            return {isOk: false, tValue: 'mypageJoin.errCatchphrase'}
        }

        if (!enValue.addr) {
            return {isOk: false, tValue: 'mypageJoin.errAddr'}
        }

        if (!enValue.hp) {
            return {isOk: false, tValue: 'mypageJoin.errHp'}
        }

        if (!enValue.foundation) {
            return {isOk: false, tValue: 'mypageJoin.errFoundation'}
        }

        if (!enValue.member) {
            return {isOk: false, tValue: 'mypageJoin.errMember'}
        }

        if (!enValue.priceUnit) {
            return {isOk: false, tValue: 'mypageJoin.errPriceUnit'}
        }

        if (!enValue.companyDetail) {
            return {isOk: false, tValue: 'mypageJoin.errCompanyDetail'}
        }

        // 국가별 판매량 중복검사
        const dupNationSale: any = {};
        for (let nationSale of enValue.nationSales) {
            if (dupNationSale.hasOwnProperty(nationSale.nationCode)) {
                return {isOk: false, tValue: 'mypageJoin.dupNationSales'}
            } else {
                dupNationSale[nationSale.nationCode] = true;
            }
        }

        // 국가별 사용량 중복검사
        const dupNationUsage: any = {};
        for (let nationUsage of enValue.nationUsage) {
            if (dupNationUsage.hasOwnProperty(nationUsage.nationCode)) {
                return {isOk: false, tValue: 'mypageJoin.dupNationUsage'}
            } else {
                dupNationUsage[nationUsage.nationCode] = true;
            }
        }

        // 매출 년도 중복 검사
        const dupTotalSales: any = {};
        for (let totalSales of enValue.totalSales) {
            if (dupTotalSales.hasOwnProperty(totalSales.year)) {
                return {isOk: false, tValue: 'mypageJoin.dupTotalSales'}
            } else {
                dupTotalSales[totalSales.year] = true;
            }
        }

        // 매출년도 정렬
        enValue.totalSales = enValue.totalSales.sort((a, b) => {
            return a.year - b.year
        })


        return {isOk: true, tValue: ''}
    }
}
