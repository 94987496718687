import React, {Fragment} from 'react';
import styles from './BoardViewReply.module.scss';
import {BizSmTitle} from "../../../../shares/bizTitle/BizTitle";
import {Button} from "@material-ui/core";
import {Add, AttachFile, DeleteOutline} from "@material-ui/icons";
import {AskBoard, AskBoardReply, AskBoardStatus, ChatWriter, CreateAskBoardReply} from "../../../../../graphql/types";
import {OnChangeValue, OnVoidFunction} from "../../../../Intefaces";
import classNames from "classnames";
import moment from "moment";
import {DataUtil} from "../../../../../utils/data/DataUtil";
import {BizNoDataTable} from "../../../../shares/bizNoDataTable/BizNoDataTable";
import {I18nKeys} from "../../../../../i18n/i18nKeys";
import {useTranslation} from "react-i18next";

export const BoardViewReply = (props: {
    value: CreateAskBoardReply,
    onChange: OnChangeValue<CreateAskBoardReply>,
    onUploadAttachFile: OnVoidFunction,
    onCreateReply: OnVoidFunction,
    askBoard: AskBoard,
    askBoardReply: AskBoardReply[],
    onDeleteAskBoardReply: OnChangeValue<number>,
}) => {
    const [t] = useTranslation('common');

    return (
        <Fragment>
            <BizSmTitle>{t(I18nKeys.board.titleReply.key)} ({props.askBoardReply.length})</BizSmTitle>
            {props.askBoardReply.length === 0 && <BizNoDataTable/>}
            {props.askBoardReply.map((value, index) => (
                <Fragment key={index}>
                    {value.writer === ChatWriter.User &&
                    <UserComment
                        userEmail={props.askBoard.userEmail}
                        askBoardReply={value}
                    />}

                    {value.writer === ChatWriter.Company &&
                    <CompanyComment
                        onDeleteAskBoardReply={props.onDeleteAskBoardReply}
                        isDone={props.askBoard.status === AskBoardStatus.Done}
                        askBoardReply={value}
                    />
                    }

                </Fragment>

            ))}


            {props.askBoard.status === AskBoardStatus.InProcessing &&
            <div className={classNames(styles.table, styles.tableReply)}>
                <div className={styles.colLabel}>
                    <div className={styles.email}>{t(I18nKeys.board.userAdmin.key)}</div>
                </div>
                <div className={classNames(styles.colValue)}>
                    <textarea
                        className='form-control'
                        value={props.value.contents}
                        onChange={ev => props.onChange({...props.value, contents: ev.target.value})}/>
                </div>
                <div className={classNames(styles.col, styles.colFunc)}>
                    <Button
                        onClick={props.onCreateReply}
                        startIcon={<Add/>}>
                        {t(I18nKeys.board.btnAdd.key)}
                    </Button>
                    <Button
                        onClick={props.onUploadAttachFile}
                        startIcon={<AttachFile/>}>
                        {t(I18nKeys.board.btnFile.key)}
                    </Button>
                </div>
            </div>
            }

            {props.value.attachFiles.length !== 0 &&
            <div className={classNames(styles.table, styles.tableReply)}>
                <div className={styles.colLabel}>
                    <div className={styles.email}>{t(I18nKeys.board.attachment.key)}</div>
                </div>
                <div className={styles.colValue}>
                    {props.value.attachFiles.map((value, index) => (
                        <div className={styles.fileItem}
                             key={index}>
                            <span onClick={() => DataUtil.downloadFile(value.src)}>{index + 1}. {value.nm}</span>
                        </div>
                    ))}
                </div>
            </div>
            }

        </Fragment>
    )
}


const UserComment = (props: {
    userEmail: string,
    askBoardReply: AskBoardReply,
}) => {
    const [t] = useTranslation('common');

    return (
        <div className={classNames(styles.table, styles.tableReply)}>
            <div className={styles.colLabel}>
                {/*<div className={styles.email}>{props.userEmail}</div>*/}
                <div className={classNames(styles.email, styles.colorBlue)}>{t(I18nKeys.board.replyWriterUser.key)}</div>
                <div className={styles.companyNm}>
                    {moment(props.askBoardReply.createdAt).format('YY.MM.DD hh:mm a')}
                </div>
            </div>
            <div className={styles.colValue}>
                <div
                    className={styles.boxContents}
                    ref={ref => {
                        if (ref) {
                            ref.innerHTML = props.askBoardReply.content;
                        }
                    }}/>
                {props.askBoardReply.attachFiles.length !== 0 &&
                <>
                    <div className={styles.boxAttachFile}>
                        <div className={styles.fileTitle}>{t(I18nKeys.board.attachment.key)}</div>
                        {props.askBoardReply.attachFiles.map((value, index) => (
                            <div className={styles.fileItem}
                                 key={index}>
                                <span onClick={() => DataUtil.downloadFile(value.src)}>{index + 1}. {value.nm}</span>
                            </div>
                        ))}
                    </div>
                </>
                }
            </div>
        </div>
    );
}

const CompanyComment = (props: {
    askBoardReply: AskBoardReply,
    onDeleteAskBoardReply: OnChangeValue<number>,
    isDone?: boolean,
}) => {
    const [t] = useTranslation('common');

    return (
        <div className={classNames(styles.table, styles.tableReply)}>
            <div className={styles.colLabel}>
                {/*<div className={styles.email}>{t(I18nKeys.board.userAdmin.key)}</div>*/}
                <div className={classNames(styles.email)}>{t(I18nKeys.board.replyWriterCompany.key)}</div>
                <div className={styles.companyNm}>{moment(props.askBoardReply.createdAt).format('YY.MM.DD hh:mm a')}</div>
            </div>
            <div className={styles.colValue}>
                <div
                    className={styles.boxContents}
                    ref={ref => {
                        if (ref) {
                            ref.innerText = props.askBoardReply.content
                        }
                    }}/>

                {props.askBoardReply.attachFiles.length !== 0 &&
                <>
                    <div className={styles.boxAttachFile}>
                        <div className={styles.fileTitle}>{t(I18nKeys.board.attachment.key)}</div>
                        {props.askBoardReply.attachFiles.map((value, index) => (
                            <div
                                key={index}
                                className={styles.fileItem}>
                                <span onClick={() => DataUtil.downloadFile(value.src)}>{index + 1}. {value.nm}</span>
                            </div>
                        ))
                        }
                    </div>
                </>
                }
            </div>

            {!props.isDone &&
            <div className={styles.colFunc}>
                <Button
                    onClick={() => props.onDeleteAskBoardReply(props.askBoardReply.id)}
                    size='small'
                    startIcon={<DeleteOutline/>}>
                    {t(I18nKeys.board.btnDelete.key)}
                </Button>
            </div>
            }
        </div>
    );
}
