import {ApolloClient, ApolloLink, from, InMemoryCache} from "@apollo/client";
import {LocalStorage} from "../localstorage/LocalStorage";
import {setContext} from '@apollo/client/link/context';
import {createUploadLink} from 'apollo-upload-client';
import {ReduxStore} from "../../index";
import {GlobalVars} from "../../globalVars/globalVars";


const apolloLink = from([
    setContext((operation, prevContext) => {
        return {
            headers: {
                ...prevContext.headers,
                langCode: ReduxStore.getState().i18n.langCode,
                Authorization: LocalStorage.getSession(),
            }
        }
    }),
    new ApolloLink((operation, forward) => {
        if (operation.getContext().hasOwnProperty('loadingView')) {
            GlobalVars.system.loadingView(operation.getContext().loadingView);
        } else {
            GlobalVars.system.loadingView(true);
        }

        if (operation.getContext().hasOwnProperty('header')) {
            operation.setContext({
                header: {
                    ...operation.getContext().header,
                }
            })
        }

        return forward(operation).map((data) => {
            GlobalVars.system.loadingView(false);
            return data
        })
    }),
    createUploadLink({
        uri: process.env.REACT_APP_FRONT_GRAPHQL || '',
    }) as any,
]);

export class Apollo {
    static client = new ApolloClient({
        // link: from([upload, authLink, errLink]),
        link: apolloLink,
        connectToDevTools: true,
        cache: new InMemoryCache(),
        defaultOptions: {
            watchQuery: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
                nextFetchPolicy: 'network-only'
            },
            query: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
            }
        }
    })
}
