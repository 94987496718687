import React, {ReactNode} from "react";
import {useSelector} from "react-redux";
import {UserState} from "../../../redux/User";
import {useTranslation} from "react-i18next";
import {BizMenuData, BizMenuLayout} from "../../shares/bizMenuLayout/BizMenuLayout";
import {CompanyPermission, UserType} from "../../../graphql/types";
import {BizRefreshSession} from "../../shares/bizRefreshSession/BizRefreshSession";

export const MyPageLayout = (props: {
    children: ReactNode,
    activatedSuffixRouter: string,
}) => {
    const user = useSelector<any, UserState>(state => state.user);
    const [t] = useTranslation('common');


    let menu: BizMenuData[] = [];

    if (user.user.userType !== UserType.Consultant) {
        menu = [...menu,
            {title: t('mypage.userInfo'), routerSuffix: '/info'},
        ];
    }

    if (user.user.companyPermission.includes(CompanyPermission.CreateCompany)
        && user.user.userType !== UserType.Consultant
    ) {
        menu = [
            ...menu,
            {title: t('mypage.join'), routerSuffix: '/company/create'}
        ]
    }

    ////////////////////////////////////////////////////////////////////////////////////////////
    //
    // [마이페이지 > 기관 등록 심사 현황]메뉴 비표시 처리
    // (daekyu.ju@t-ime.com, 2023/01/25)
    //
    ////////////////////////////////////////////////////////////////////////////////////////////
    // if (user.user.companyPermission.includes(CompanyPermission.CreateWait)) {
    //     menu = [
    //         ...menu,
    //         {title: t('mypage.companyCreateRequest'), routerSuffix: '/company/create/request'}
    //     ]
    // }
    ////////////////////////////////////////////////////////////////////////////////////////////

    if (user.user.companyPermission.includes(CompanyPermission.UpdateCompany)) {
        menu = [
            ...menu,
            {title: t('mypage.updateCompany'), routerSuffix: '/company/update'}
        ]
    }

    if (user.user.companyPermission.includes(CompanyPermission.MngConsultant)) {
        menu = [
            ...menu,
            {title: t('mypage.consultant'), routerSuffix: '/consultant/list'}
        ]
    }

    ////////////////////////////////////////////////////////////////////////////////////////////
    //
    // [마이페이지 > 기관 갱신 심사]메뉴 비표시 처리
    // (daekyu.ju@t-ime.com, 2024/06/25)
    //
    ////////////////////////////////////////////////////////////////////////////////////////////
    // if (user.user.companyPermission.includes(CompanyPermission.UpdateWait)) {
    //     menu = [
    //         ...menu,
    //         {title: t('mypage.companyUpdateRequest'), routerSuffix: '/company/update/request'}
    //     ]
    // }
    ////////////////////////////////////////////////////////////////////////////////////////////

    if (user.user.companyPermission.includes(CompanyPermission.RegistryGoods)) {
        menu = [
            ...menu,
            {title: t('mypage.registryGoods'), routerSuffix: '/goods/registry'},
        ]
    }

    if (user.user.companyPermission.includes(CompanyPermission.MngGoods)) {
        menu = [
            ...menu,
            {title: t('mypage.goodsList'), routerSuffix: '/goods/list'}
        ]
    }

    return (

        <BizMenuLayout
            title={t('mypage.mypage')}
            routerPrefix='/mypage'
            activatedRouterSuffix={props.activatedSuffixRouter}
            data={menu}>

            <BizRefreshSession>
                {props.children}
            </BizRefreshSession>
        </BizMenuLayout>


    )
}
