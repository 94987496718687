import {combineReducers} from "redux";
import {I18nReducer} from "./I18n";
import {WindowReducer} from "./Window";
import {UserReducer} from "./User";
import {SystemReducer} from "./System";
import {LayoutReducer} from "./Layout";
import {LayoutWebReducer} from "./LayoutWeb";
import {DataReducer} from "./Data";
import {LayoutMobileReducer} from "./LayoutMobile";
import {ScrollReducer} from "./Scroll";
import {ChatReducer} from "./Chat";


export const RootReducer = combineReducers({
    i18n: I18nReducer,
    window: WindowReducer,
    user: UserReducer,
    system: SystemReducer,
    layout: LayoutReducer,
    layoutWeb: LayoutWebReducer,
    layoutMobile: LayoutMobileReducer,
    data: DataReducer,
    scroll: ScrollReducer,
    chat: ChatReducer,
});
