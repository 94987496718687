import React, {Fragment} from 'react';
import {GoodsUpdateRequestStatus} from "../../../graphql/types";
import {useTranslation} from "react-i18next";

export const BadgeGoodsUpdateRequestStatus = (props: {
    status: GoodsUpdateRequestStatus
}) => {
    const [t] = useTranslation('common');

    return (
        <Fragment>
            {props.status === GoodsUpdateRequestStatus.Requested && <span className='badge badge-warning'>{t('mypageGoods.badgeRequested')}</span>}
            {props.status === GoodsUpdateRequestStatus.AdminConfirm && <span className='badge badge-primary'>{t('mypageGoods.badgeAdminConfirm')}</span>}
            {props.status === GoodsUpdateRequestStatus.AdminCancel && <span className='badge badge-danger'>{t('mypageGoods.badgeAdminCancel')}</span>}
            {props.status === GoodsUpdateRequestStatus.Done && <span className='badge badge-success'>{t('mypageGoods.badgeDone')}</span>}
            {props.status === GoodsUpdateRequestStatus.UserCancel && <span className='badge badge-secondary'>{t('mypageGoods.badgeUserCancel')}</span>}
        </Fragment>
    )
}
