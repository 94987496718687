import React, {useState} from 'react';
import {gql, useMutation, useQuery} from "@apollo/client";
import {
    CompanyCreateRequest,
    Mutation,
    MutationAllowCreateCompanyArgs,
    MutationCancelCreateCompanyArgs,
    Query
} from "../../../../graphql/types";
import {MyPageLayout} from "../../layout/MyPageLayout";
import {BizTitle} from "../../../shares/bizTitle/BizTitle";
import {FadeIn} from "../../../shares/fadeIn/FadeIn";
import {MyPageCompanyCreateRequestList} from "./list/MyPageCompanyCreateRequestList";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import {ApolloCatch} from "../../../../utils/apollo/apolloCatch";
import {navigate} from "hookrouter";
import {BizModal} from "../../../shares/bizModal/BizModal";
import {BizCompanyDataView} from "../../../shares/bizCompanyDataView/BizCompanyDataView";
import {useTranslation} from "react-i18next";

export const MyPageCompanyCreateRequest = (props: {}) => {
    const [t] = useTranslation('common');

    const {data} = useQuery<Query, any>(gqlData);
    const [mutAllow] = useMutation<Mutation, MutationAllowCreateCompanyArgs>(gqlAllow);
    const [mutCancel] = useMutation<Mutation, MutationCancelCreateCompanyArgs>(gqlCancel);
    const [selectedCompanyCreateRequest, setSelectedCompanyCreateRequest] = useState<CompanyCreateRequest | undefined>();

    const handler = {
        onAllow: (args: MutationAllowCreateCompanyArgs) => {
            SwalUtil.yn({
                msg: t("mypageCompany.swalCreateOkQuestion"),
                icon: 'question',
                ok: () => {
                    mutAllow({variables: args})
                        .then(() => {
                            SwalUtil.ok({
                                msg: t("mypageCompany.swalCreateOkQuestionOk"),
                                icon: 'success',
                            })
                            navigate('/mypage/info')
                        })
                        .catch(ApolloCatch({}))
                }
            })

        },
        onCancel: (args: MutationCancelCreateCompanyArgs) => {
            SwalUtil.yn({
                msg: t("mypageCompany.swalCreateCancelQuestion"),
                icon: 'question',
                ok: () => {
                    mutCancel({variables: args})
                        .then(() => {
                            SwalUtil.ok({
                                msg: t("mypageCompnay.swalCreateCancelQuestionOk"),
                                icon: 'success',
                            })
                            navigate('/mypage/info')
                        })
                        .catch(ApolloCatch({}))
                }
            })
        },
        onClickData: (args: CompanyCreateRequest) => {
            setSelectedCompanyCreateRequest(args);
        }
    };

    return (
        <MyPageLayout activatedSuffixRouter={'/company/create/request'}>
            {data &&
            <FadeIn>
                <BizModal
                    size="xl"
                    isShow={Boolean(selectedCompanyCreateRequest)}
                    onCancel={() => setSelectedCompanyCreateRequest(undefined)}>
                    {selectedCompanyCreateRequest &&
                    <BizCompanyDataView
                        companyDataMap={selectedCompanyCreateRequest.companyDataMap}
                        companyLikes={{likes: 0, goodsLikes: 0, views: 0}}/>
                    }
                </BizModal>

                <BizTitle>{t("mypage.companyCreateRequest")}</BizTitle>
                <MyPageCompanyCreateRequestList
                    {...handler}
                    {...data}/>
            </FadeIn>
            }
        </MyPageLayout>
    )
}

const gqlAllow = gql`
    mutation Allow($companyCreateRequestId: Int!){
        allowCreateCompany(companyCreateRequestId: $companyCreateRequestId)
    }
`;

const gqlCancel = gql`
    mutation Cancel($companyCreateRequestId: Int!){
        cancelCreateCompany(companyCreateRequestId:$companyCreateRequestId)
    }
`;

const gqlData = gql`
    query DATA{
        companyCreateRequestList{
            id
            status
            memo
            chargedPoint
            companyDataMap
        }
    }
`;
