import React, {ReactNode, useState} from 'react';
import classNamesBind from "classnames/bind";
import styles from './BizGoodsDataView.module.scss';
import {AllEduCode, CompanyDataMap, GoodsData, GoodsDataMap} from "../../../graphql/customScalars";
import {BizCol12} from "../fadeInContainer/FadeInContainer";
import {BizGoodsDataViewImage} from "./image/BizGoodsDataViewImage";
import {BizGoodsDataViewDefInfo} from "./defInfo/BizGoodsDataViewDefInfo";
import {Element} from "react-scroll";
import {BizGoodsDataViewCompany} from "./company/BizGoodsDataViewCompany";
import {BizGoodsDataViewTabs} from "./tabs/BizGoodsDataViewTabs";
import {BizNoDataTable} from "../bizNoDataTable/BizNoDataTable";
import {GoodsDataMapUtil} from "../../../utils/goodsDataMapUtil/goodsDataMapUtil";
import {useSelector} from "react-redux";
import {I18nState} from "../../../redux/I18n";
import {BizGoodsDataViewBreadcrumbs} from "./breadcrumbs/BizGoodsDataViewBreadcrumbs";
import {BizCompanyDataConsultant} from "../bizCompanyDataView/consultant/BizCompanyDataConsultant";
import {CompanyConsultant} from "../../../graphql/types";
import isUrl from "is-url";
import {YoutubeViewer} from "../youtubeViewer/YoutubeViewer";
import {useTranslation} from "react-i18next";
import {BizAskBoardModal} from "../bizAskBoardModal/BizAskBoardModal";
import {OnChangeValue} from "../../Intefaces";

const cx = classNamesBind.bind(styles);

export const BizGoodsDataView = (props: {
    goodsId?: number,
    allEduCode?: AllEduCode,
    goodsInfoId?: number,
    companyDataMap: CompanyDataMap,
    categoryId: number,
    goodsDataMap: GoodsDataMap,
    consultant?: CompanyConsultant[],
    companyUserId?: number,
    disableInteraction?: boolean,
    onCreateChatRoomWithGoodsInfoIdAndConsultantId?: OnChangeValue<number>,
    onCreateChatRoomWithGoodsInfoId?: OnChangeValue<number>,
    isCertificated: string
}) => {
    const langCode = useSelector<any, I18nState>(state => state.i18n).langCode;
    const goodsData = GoodsDataMapUtil.getGoodsData(langCode, props.goodsDataMap);
    const [t] = useTranslation('common');

    const [askBoardModal, setAskBoardModal] = useState(false);

    const handler = {
        onShowAskBoardModal: () => {
            setAskBoardModal(true);
        },
    }

    return (
        <div className={cx('cont')}>


            {props.companyUserId &&
            <BizAskBoardModal
                isShow={askBoardModal}
                onCancel={() => setAskBoardModal(false)}
                companyId={props.companyUserId}/>
            }


            <div className={cx('box-full')}>
                <BizGoodsDataViewBreadcrumbs
                    categoryId={props.categoryId}/>
            </div>

            <div className={cx('box-half', 'p-r-30', 'm-r-30')}>
                <BizGoodsDataViewImage
                    goodsData={goodsData}
                    {...props}/>
            </div>

            <div className={cx('box-half', 'm-r-30')}>
                <BizGoodsDataViewDefInfo
                    onCreateChatRoomWithGoodsInfoId={props.onCreateChatRoomWithGoodsInfoId}
                    goodsData={goodsData}
                    {...props}/>
            </div>

            {props.consultant && props.consultant.length !== 0 &&
            <div className={cx('box-full', 'm-r-30')}>
                <BizCompanyDataConsultant
                    {...handler}
                    onCreateChatRoomWithGoodsInfoIdAndConsultantId={props.onCreateChatRoomWithGoodsInfoIdAndConsultantId}
                    consultant={props.consultant}/>
            </div>
            }

            {(goodsData.youtubeUrl && isUrl(goodsData.youtubeUrl)) &&
            <div className={cx('box-full', 'm-r-30')}>
                <YoutubeViewer
                    title={t("goods.youtubeUrl")}
                    link={goodsData.youtubeUrl}/>
            </div>
            }

            <div className={cx('box-full', 'm-r-30')}>
                <BizGoodsDataViewTabs {...props} idx={0}/>
                <div className={cx('contents')} ref={ref => {
                    if (ref) {
                        ref.innerHTML = goodsData.contents
                    }
                }}/>
            </div>


            <div className={cx('box-full', 'm-r-30')}>
                <Element name={'info'}/>
                <BizGoodsDataViewTabs {...props} idx={1}/>
                {goodsData.goodsInfo.map((value, index) => (
                    <div key={index} className={cx('table')}>
                        <div className={cx('col', 'col-header', 'col-info-label')}>{value.key}</div>
                        <div className={cx('col', 'col-info-value')}>{value.value}</div>
                    </div>
                ))}
                {goodsData.goodsInfo.length === 0 && <BizNoDataTable/>}
            </div>


            <div className={cx('box-full', 'm-r-30')}>
                <Element name={'company'}/>
                <BizGoodsDataViewTabs {...props} idx={2}/>
                <BizGoodsDataViewCompany {...props}/>
            </div>
        </div>
    )
}


export const BizGoodsDataShortInfo = (props: {
    companyDataMap: CompanyDataMap,
    categoryId: number,
    goodsData: GoodsData,
    disableInteraction?: boolean,
}) => {
    return (
        <>
            <BizCol12>
                <BizGoodsDataViewBreadcrumbs categoryId={props.categoryId}/>
            </BizCol12>

            <BizHalf>
                <BizGoodsDataViewImage
                    {...props}/>
            </BizHalf>

            <BizHalf>
                <BizGoodsDataViewDefInfo
                    {...props}/>
            </BizHalf>
        </>
    )
}

export const BizGoodsDataContents = (props: {
    companyDataMap: CompanyDataMap,
    categoryId: number,
    goodsData: GoodsData,
    disableInteraction?: boolean,
}) => {
    return (
        <>
            <BizCol12>
                <BizGoodsDataViewTabs {...props} idx={0}/>
                <div className={cx('contents')} ref={ref => {
                    if (ref) {
                        ref.innerHTML = props.goodsData.contents
                    }
                }}/>
            </BizCol12>

            <BizCol12>
                <Element name={'info'}/>
                <BizGoodsDataViewTabs {...props} idx={1}/>
                {props.goodsData.goodsInfo.map((value, index) => (
                    <div key={index} className={cx('table')}>
                        <div style={{width: '33%'}}>{value.key}</div>
                        <div className='text-left' style={{width: '67%'}}>{value.value}</div>
                    </div>
                ))}
                {props.goodsData.goodsInfo.length === 0 && <BizNoDataTable/>}
            </BizCol12>
            <BizCol12>
                <Element name={'company'}/>
                <BizGoodsDataViewTabs {...props} idx={2}/>
                <BizGoodsDataViewCompany {...props}/>
            </BizCol12>
        </>
    )
}

const BizHalf = (props: {
    children?: ReactNode
}) => {
    return (
        <div className='col-12 col-lg-6' style={{marginBottom: 30}}>
            {props.children}
        </div>
    )
}
