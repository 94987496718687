import React, {Fragment, ReactNode} from 'react';
import classNamesBind from "classnames/bind";
import styles from './InputNationUsage.module.scss';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import NumberFormat from "react-number-format";
import {CopyUtil} from "../../../../../utils/copyUtil/copyUtil";
import {Tooltip} from "@material-ui/core";
import {AddCircleOutline, Close} from "@material-ui/icons";
import {DataState} from "../../../../../redux/Data";
import {OnChangeValue} from "../../../../Intefaces";
import {NationUsage} from "../../../../../graphql/customScalars";

const cx = classNamesBind.bind(styles);

export const InputNationUsage = (props: {
    title: ReactNode,
    value: NationUsage
    onChangeValue: OnChangeValue<NationUsage>
}) => {
    const data = useSelector<any, DataState>(state => state.data);
    const [t] = useTranslation('common');

    return (
        <>
            <div className={cx('title')}>{props.title}</div>
            <p className={cx('info')}>{t('mypageJoin.nationUsageDesc')}</p>
            <div className={cx('table')}>
                <div className={cx('header', 'col-delete')}/>
                <div className={cx('header', 'col-nation')}>{t('mypageJoin.nation')}</div>
                <div className={cx('header', 'col-usage')}>{t('mypageJoin.usage')}</div>
            </div>

            {props.value.map((value, index) => {
                return (
                    <div key={index} className={cx('table', 'hover')}>
                        <div className={cx('col', 'col-delete')}>
                            <Tooltip title={t('mypageJoin.btnDelete') || ''} onClick={event => {
                                let copy = CopyUtil.copy(props.value);
                                copy = [...copy.slice(0, index), ...copy.slice(index + 1, copy.length)]
                                props.onChangeValue(copy);
                            }}>
                                <Close className={cx('btn-add')} style={{marginTop: -8}}/>
                            </Tooltip>
                        </div>
                        <div className={cx('col', 'col-input', 'col-nation')}>
                            <select
                                className='form-control form-control-sm'
                                value={value.nationCode}
                                onChange={event => {
                                    const copy = CopyUtil.copy(props.value);
                                    copy[index].nationCode = event.target.value;
                                    props.onChangeValue(copy);
                                }}>
                                {data.nationCode.map((nationCode, idx) => {
                                    return <option
                                        key={idx}
                                        value={nationCode.code}>({nationCode.code.toUpperCase()}) {nationCode.shortNm}</option>
                                })}
                            </select>
                        </div>
                        <div className={cx('col', 'col-input', 'col-usage')}>
                            <NumberFormat
                                thousandSeparator
                                className='form-control form-control-sm text-right'
                                value={value.usage}
                                displayType='input'
                                decimalScale={0}
                                onValueChange={values => {
                                    const copy = CopyUtil.copy(props.value);
                                    copy[index].usage = values.floatValue || 0;
                                    props.onChangeValue(copy);
                                }}/>
                        </div>
                    </div>
                )
            })}

            <div className={cx('table')}>
                <div className={cx('col', 'col-delete')}/>
                <div className={cx('col', 'col-add')}>
                    <Tooltip title={t('mypageJoin.btnAdd') || ''} onClick={() => {
                        const copy = CopyUtil.copy(props.value);
                        copy.push({nationCode: "KR", usage: 0});
                        props.onChangeValue(copy);
                    }}>
                        <AddCircleOutline className={cx('btn-add')}/>
                    </Tooltip>
                </div>
            </div>

            <div style={{height: 15}}/>

        </>
    )
}
