import React, {useState} from 'react';
import classNamesBind from "classnames/bind";
import styles from './EdutechKoreaFair2024CompanyDataView.module.scss';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import { CompanyDataMap } from '../../../../../graphql/customScalars';
import { CompanyConsultant, CompanyLikes, EdutechKoreaFair2024Area, Kotra } from '../../../../../graphql/types';
import { I18nState } from '../../../../../redux/I18n';
import { CompanyDataUtil } from '../../../../../utils/companyDataUtil/CompanyDataUtil';
import { BizAskBoardModal } from '../../../../shares/bizAskBoardModal/BizAskBoardModal';
import { YoutubeViewer } from '../../../../shares/youtubeViewer/YoutubeViewer';
import { I18nKeys } from '../../../../../i18n/i18nKeys';
import { BizCompanyDataViewLikes } from '../../../../shares/bizCompanyDataView/likes/BizCompanyDataViewLikes';
import { BizCompanyDataViewGraph } from '../../../../shares/bizCompanyDataView/graph/BizCompanyDataViewGraph';
import { EdutechKoreaFair2024CompanyDataConsultant } from './consultant/EdutechKoreaFair2024CompanyDataConsultant';
import { BizCompanyDataViewDetail } from '../../../../shares/bizCompanyDataView/detail/BizCompanyDataViewDetail';
import { BizCompanyDataViewCatchphrase } from '../../../../shares/bizCompanyDataView/catchphrase/BizCompanyDataViewCatchphrase';
import { EdutechKoreaFair2024CompanyDataViewHeader } from './header/EdutechKoreaFair2024CompanyDataViewHeader';

const cx = classNamesBind.bind(styles);

interface Props {
    edutechKoreaFair2024CompanyCategory: EdutechKoreaFair2024Area,
    companyDataMap: CompanyDataMap,
    companyLikes: CompanyLikes
    kotra?: Kotra,
    consultant?: CompanyConsultant[]
    companyUserId?: number,
}

export const EdutechKoreaFair2024CompanyDataView = (props: Props) => {
    const [askBoardModal, setAskBoardModal] = useState(false);

    const i18n = useSelector<any, I18nState>(state => state.i18n);
    const companyData = CompanyDataUtil.getValue(i18n.langCode, props.companyDataMap);
    const [t] = useTranslation('common');

    const handler = {
        onShowAskBoardModal: () => {
            setAskBoardModal(true);
        },

    }

    return (
        <div className={cx('cont')}>
            {props.companyUserId &&
            <BizAskBoardModal
                isShow={askBoardModal}
                onCancel={() => setAskBoardModal(false)}
                companyId={props.companyUserId}/>
            }

            <EdutechKoreaFair2024CompanyDataViewHeader
                {...props}
                companyData={companyData}/>

            <BizCompanyDataViewLikes
                companyLikes={props.companyLikes}/>

            <BizCompanyDataViewGraph
                companyData={companyData}/>

            <BizCompanyDataViewCatchphrase
                companyData={companyData}/>

            {props.consultant &&
            <EdutechKoreaFair2024CompanyDataConsultant
                {...handler}
                consultant={props.consultant}/>
            }

            {companyData.introduceYoutubeUrl &&
            <YoutubeViewer
                title={t(I18nKeys.company.introduceYoutube.key)}
                link={companyData.introduceYoutubeUrl}/>
            }

            <BizCompanyDataViewDetail
                companyData={companyData}/>

        </div>
    )
}
