export const GetEnv = (): Environment => {
    return envMap[process.env.REACT_APP_FB || 'dev']
};

interface Environment {
    env: string,
    pwHashedKey: string,
    kakaoToken: string,
    naverToken: string,
    naverCallbackUrl: string,
    naverServiceUrl: string,
    googleOauthToken: string,
}

const envMap: { [key: string]: Environment } = {
    'dev': {
        env: 'dev',
        pwHashedKey: 'PMin9A0Tpoaot64SfkchbavyqBr1',
        kakaoToken: '80110fa3167a03560e59bfda7eb2e745',
        naverToken: 'YcNiSkv2NQwja4Td7Xkk',
        naverCallbackUrl: 'http://localhost:3000/auth/naver/callback',
        naverServiceUrl: 'http://localhost',
        googleOauthToken: '775173309737-k55ehjtn88mq22n7saq06javiojgruu8.apps.googleusercontent.com',
    },
    'prod': {
        env: 'prod',
        pwHashedKey: 'PMin9A0T123DvdaEkchbavyqBr1',
        kakaoToken: '80110fa3167a03560e59bfda7eb2e745',
        naverToken: 'YcNiSkv2NQwja4Td7Xkk',
        naverCallbackUrl: 'https://www.alledu.shop/auth/naver/callback',
        naverServiceUrl: 'https://alledu.shop',
        googleOauthToken: '775173309737-k55ehjtn88mq22n7saq06javiojgruu8.apps.googleusercontent.com',
    },
    'aws': {
        env: 'aws',
        pwHashedKey: 'PMin9A0T123DvdaEkchbavyqBr1',
        kakaoToken: '80110fa3167a03560e59bfda7eb2e745',
        naverToken: 'YcNiSkv2NQwja4Td7Xkk',
        naverCallbackUrl: 'https://www.alledu.shop/auth/naver/callback',
        naverServiceUrl: 'https://alledu.shop',
        googleOauthToken: '775173309737-k55ehjtn88mq22n7saq06javiojgruu8.apps.googleusercontent.com',
    }
}
