import React from 'react';
import styles from './ChatV1Mobile.module.scss';
import {ChatV1Tab, ChatV1TabData} from "../share/tab/ChatV1Tab";
import {OnChangeValue, OnVoidFunction} from "../../../Intefaces";
import {ChatV1PageQueryParams} from "../ChatV1";
import {ChatRoomList} from "../../../../graphql/types";
import {ChatV1SearchBar} from "../share/searchBar/ChatV1SearchBar";
import {ChatV1ConsultList, ChatV1List} from "../share/list/ChatV1List";
import {Pager} from "../../../shares/pager/Pager";
import {ChatV1Writer} from "../share/writer/ChatV1Writer";
import {BizTitle} from "../../../shares/bizTitle/BizTitle";
import {Close} from "@material-ui/icons";
import {IconButton} from "@material-ui/core";

export const ChatV1Mobile = (props: {
    tabData: ChatV1TabData[],
    onChangeTabValue: OnChangeValue<string>,
    params: ChatV1PageQueryParams,
    chatRoomList: ChatRoomList,
    chatRoomConsultList: ChatRoomList,
    onChangeFindWord: OnChangeValue<string>,
    onClear: OnVoidFunction,
    onBackList: OnVoidFunction,
    onChangePage: OnChangeValue<number>,
    onChangeConsultPage: OnChangeValue<number>,
    onOpenChatRoom: OnChangeValue<number>,
}) => {
    return (
        <div className={styles.cont}>
            {!props.params.chatRoomId &&
            <>
                <ChatV1Tab
                    tabData={props.tabData}
                    value={props.params.tab}
                    onChangeValue={props.onChangeTabValue}
                />

                <ChatV1SearchBar
                    {...props}
                />

                {props.params.tab === 'user' &&
                <>
                    <ChatV1List
                        {...props}/>
                    <Pager
                        paginationSize="small"
                        page={props.chatRoomList.page}
                        size={props.chatRoomList.size}
                        total={props.chatRoomList.total}
                        onChange={props.onChangePage}/>
                </>
                }

                {props.params.tab === 'consult' &&
                <>
                    <ChatV1ConsultList
                        {...props}
                        chatRoomConsultList={props.chatRoomConsultList}/>
                    <Pager
                        paginationSize="small"
                        page={props.chatRoomConsultList.page}
                        size={props.chatRoomConsultList.size}
                        total={props.chatRoomConsultList.total}
                        onChange={props.onChangeConsultPage}/>
                </>
                }
            </>
            }

            {props.params.chatRoomId &&
            <>
                <BizTitle desc={
                    <IconButton size='small' onClick={props.onBackList}>
                        <Close/>
                    </IconButton>
                }>
                    채팅
                </BizTitle>
                <div style={{height: 10}}/>
                <ChatV1Writer
                    tab={props.params.tab}
                    chatRoomIdx={props.params.chatRoomId}/>
            </>
            }
        </div>
    )
}
