import React, {Fragment, ReactNode} from 'react';
import classNamesBind from "classnames/bind";
import styles from './InputGoodsPrice.module.scss';
import {GoodsPrice} from "../../../../../graphql/customScalars";
import {OnChangeValue} from "../../../../Intefaces";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import {CopyUtil} from "../../../../../utils/copyUtil/copyUtil";
import NumberFormat from "react-number-format";
import {useSelector} from "react-redux";
import {DataState} from "../../../../../redux/Data";
import {useTranslation} from "react-i18next";

const cx = classNamesBind.bind(styles);

export const InputGoodsPrice = (props: {
    title: ReactNode,
    value: GoodsPrice,
    onChange: OnChangeValue<GoodsPrice>
}) => {
    const [t] = useTranslation('common');

    const data = useSelector<any, DataState>(state => state.data);

    return (
        <Fragment>
            <div className={cx('cont-title')}>
                <div className={cx('title')}>{props.title}</div>
                <div className={cx('checkbox')}>
                    <FormControlLabel
                        classes={{
                            root: cx('m-0')
                        }}
                        control={
                            <Checkbox
                                color='primary'
                                checked={props.value.noPrice}
                                onChange={ev => {
                                    const copy = CopyUtil.copyAll(props.value);
                                    copy.noPrice = ev.target.checked;
                                    props.onChange(copy);
                                }}/>
                        }
                        label={t("mypageGoods.negotiable")}/>
                </div>
            </div>

            <div className={cx('box')}>
                <div>
                    <NumberFormat
                        disabled={props.value.noPrice}
                        value={props.value.price}
                        className='form-control form-control-sm'
                        allowNegative={false}
                        decimalScale={2}
                        onValueChange={values => {
                            const copy = CopyUtil.copyAll(props.value);
                            copy.price = values.floatValue || 0;
                            props.onChange(copy);
                        }}/>
                </div>
                <div>
                    <select
                        disabled={props.value.noPrice}
                        className='form-control form-control-sm'
                        value={props.value.priceUnit}
                        onChange={ev => {
                            const copy = CopyUtil.copyAll(props.value);
                            copy.priceUnit = ev.target.value;
                            props.onChange(copy);
                        }}>
                        {data.priceUnit.map((value, index) => (
                            <option key={index} value={value}>{value}</option>
                        ))}
                    </select>
                </div>


            </div>
        </Fragment>
    )
}
