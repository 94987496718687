import React, { useEffect, useState } from 'react';
import classNamesBind from "classnames/bind";
import styles from './CsQnaList.module.scss';
import {CsLayout} from "../../layout/CsLayout";
import {gql, useQuery} from '@apollo/client';
import {Qna, Query} from "../../../../graphql/types";
import {FadeIn} from "../../../shares/fadeIn/FadeIn";
import {BizSmTitle, BizTitle} from "../../../shares/bizTitle/BizTitle";
import {BizNoDataTable} from "../../../shares/bizNoDataTable/BizNoDataTable";
import {Pager} from "../../../shares/pager/Pager";
import {navigate} from "hookrouter";
import moment from "moment";
import {useTranslation} from "react-i18next";
import { ApolloCatch } from '../../../../utils/apollo/apolloCatch';
import { useSelector } from 'react-redux';
import { I18nState } from '../../../../redux/I18n';


const cx = classNamesBind.bind(styles);

export const CsQnaList = (props: {
    page: number
}) => {
    const langCode = useSelector<any, I18nState>(state => state.i18n).langCode;
    
    let [listStartNumber, setListStartNumber] = useState<number>(0);
    const {data, refetch} = useQuery<Query>(gqlData, {
        variables: props
    });
    const [t] = useTranslation('common');

    useEffect(() => {
        if (!refetch) {
            return;
        }

        refetch({
            page: 0
        }).catch(ApolloCatch({}));

        if(data?.qnaList) {
            const getListStartNumber = () => {
                let total = data.qnaList.total;
                let page = data.qnaList.page;
                let size = data.qnaList.size;
                return total - (page * size);
            }

            setListStartNumber(getListStartNumber());
        }
    }, [langCode, data, refetch, setListStartNumber]);

    const handler = {
        onChangePage: (p: number) => {
            navigate(`/cs/qna/list/${p}`);
        },
        onClickCreate: () => {
            navigate('/cs/qna/create')
        }
    }

    
    return (
        <CsLayout activatedSuffixRouter={'/qna/list'}>
            {data &&
            <FadeIn>
                <BizTitle>{t("cs.qnaTitle")}</BizTitle>
                <div style={{height: 10}}/>
                <BizSmTitle>{t("cs.inProgressTitle")}</BizSmTitle>

                <QnaList list={data.openedQna} listStartNumber={listStartNumber}/>
                {data.openedQna.length === 0 && <BizNoDataTable/>}
                <div className={cx('table')}>
                    <div className={cx('col-full', 'text-right')}>
                        <button
                            className='btn btn-sm btn-success'
                            onClick={handler.onClickCreate}>{t("cs.btnApplyQnA")}
                        </button>
                    </div>
                </div>


                <BizSmTitle>{t("cs.qnaList")}</BizSmTitle>
                <QnaList list={data.qnaList.list} listStartNumber={listStartNumber}/>
                {data.qnaList.total === 0 && <BizNoDataTable/>}

                <Pager
                    page={data.qnaList.page}
                    size={data.qnaList.size}
                    total={data.qnaList.total}
                    onChange={handler.onChangePage}/>

            </FadeIn>
            }
        </CsLayout>
    )
}

const QnaList = (props: {
    list: Qna[],
    listStartNumber: number
}) => {
    const [t] = useTranslation('common');

    return (
        <>
            <div className={cx('table')}>
                <div className={cx('col-header', 'col-id')}>{t("cs.idx")}</div>
                <div className={cx('col-header', 'col-is-open')}>{t("cs.status")}</div>
                <div className={cx('col-header', 'col-title')}>{t("cs.title")}</div>
                <div className={cx('col-header', 'col-created-at')}>{t("cs.questionDate")}</div>
            </div>

            {props.list.map((value, index) => (
                <div key={index}
                     className={cx('table', 'hover', 'pointer')}
                     onClick={() => navigate(`/cs/qna/view/${value.id}`)}>
                    <div className={cx('col', 'col-id')}>{props.listStartNumber--}</div>
                    <div className={cx('col', 'col-is-open')}>
                        {value.isOpened && <span className='badge badge-warning'>{t("cs.badgeInProgress")}</span>}
                        {!value.isOpened && <span className='badge badge-success'>{t("cs.badgeClose")}</span>}
                    </div>
                    <div className={cx('col', 'col-title')}>{value.title}</div>
                    <div className={cx('col', 'col-created-at')}>{moment(value.createdAt).format('YY.MM.DD')}</div>
                </div>
            ))}
        </>
    )
}


const gqlData = gql`
    query Data($page: Int!) {
        openedQna {
            id
            title
            createdAt
            isOpened
        }
        qnaList(page: $page, size: 20) {
            page
            size
            total
            list {
                id
                title
                isOpened
                createdAt
            }
        }
    }
`
