import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './BizGoodsDataViewCompany.module.scss';
import {Kotra} from "../../../../graphql/types";
import {useSelector} from "react-redux";
import {I18nState} from "../../../../redux/I18n";
import {useTranslation} from "react-i18next";
import {I18nUtil} from "../../../../utils/i18n/i18n";
import moment from "moment";
import {CompanyDataMap} from "../../../../graphql/customScalars";
import {CompanyDataUtil} from "../../../../utils/companyDataUtil/CompanyDataUtil";
import {A} from 'hookrouter';
import {Launch} from "@material-ui/icons";

const cx = classNamesBind.bind(styles);

export const BizGoodsDataViewCompany = (props: {
    companyUserId?: number,
    companyDataMap: CompanyDataMap,
    kotra?: Kotra,
}) => {

    const i18n = useSelector<any, I18nState>(state => state.i18n);
    const [t] = useTranslation('common');
    const companyData = CompanyDataUtil.getValue(i18n.langCode, props.companyDataMap);

    const handler = {
        openCompanyHp: () => {
            const hp = companyData.hp;
            if (hp.startsWith('http')) {
                window.open(hp, '_blank');
            } else {
                window.open('http://' + hp, '_blank')
            }
        }
    };


    return (
        <>
            <div className={cx('table-fluid')}>
                <div className={cx('col', 'col-header', 'ellipsis')}>{t('company.nm')}</div>
                <div className={cx('col', 'col-value', 'ellipsis')}>
                    {props.companyUserId &&
                    <A style={{color: '#0db3e6'}} href={`/company/detail/${props.companyUserId}`} target='_blank'>
                        {companyData.companyNm} <Launch/>
                    </A>
                    }

                    {!props.companyUserId && companyData.companyNm}

                </div>

                <div className={cx('col', 'col-header', 'ellipsis')}>{t('company.ceo')}</div>
                <div className={cx('col', 'col-value', 'ellipsis')}>{companyData.ceo}</div>


                <div className={cx('col', 'col-header', 'ellipsis')}>
                    {t('company.foundation')}
                </div>
                <div className={cx('col', 'col-value', 'ellipsis')}>
                    {moment(companyData.foundation).format('YY.MM.DD')}
                </div>

                <div className={cx('col', 'col-header', 'ellipsis')}>{t('company.hp')}</div>
                <div className={cx('col', 'col-value', 'ellipsis', 'link')} onClick={handler.openCompanyHp}>
                    {companyData.hp}
                </div>

                <div className={cx('col', 'col-header', 'ellipsis')}>{t('company.addr')}</div>
                <div className={cx('col', 'col-full-value', 'ellipsis')}>
                    {companyData.addr}
                </div>

                {props.kotra &&
                <>
                    <div className={cx('col', 'col-header', 'ellipsis')}>{t('company.kotra')}</div>
                    <div
                        className={cx('col', 'col-full-value', 'ellipsis')}
                        ref={ref => {
                            if (ref) {
                                ref.innerText = I18nUtil.value(i18n.langCode, props.kotra?.data)
                            }
                        }}/>
                </>
                }
            </div>
        </>
    )
}
