import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './MyInfoInput.module.scss';
import {UserInfo} from "../../../../graphql/types";
import {OnChangeValue} from "../../../Intefaces";
import {BizSmTitle} from "../../../shares/bizTitle/BizTitle";
import {InputMultiLang} from "../../share/inputs/inputMultiLang/InputMultiLang";
import {CopyUtil} from "../../../../utils/copyUtil/copyUtil";
import {useTranslation} from "react-i18next";

const cx = classNamesBind.bind(styles);

export const MyInfoInput = (props: {
    value: UserInfo,
    onChangeValue: OnChangeValue<UserInfo>,
}) => {
    const [t] = useTranslation('common');

    return (
        <>
            <div className={cx('cont')}>
                <div className={cx('box')}>
                    <BizSmTitle>{t("mypage.nm")}</BizSmTitle>
                    <InputMultiLang
                        value={props.value.nm}
                        onChange={v => {
                            const copy = CopyUtil.copyAll(props.value);
                            copy.nm = v;
                            props.onChangeValue(copy);
                        }}/>

                </div>

                <div className={cx('box')}>
                    <BizSmTitle>{t("mypage.tel")}</BizSmTitle>
                    <InputMultiLang
                        value={props.value.tel}
                        onChange={v => {
                            const copy = CopyUtil.copyAll(props.value);
                            copy.tel = v;
                            props.onChangeValue(copy);
                        }}/>
                </div>

                <div className={cx('box')}>
                    <BizSmTitle>{t("mypage.agency")}</BizSmTitle>
                    <InputMultiLang
                        value={props.value.department}
                        onChange={v => {
                            const copy = CopyUtil.copyAll(props.value);
                            copy.department = v;
                            props.onChangeValue(copy);
                        }}/>

                </div>
                <div className={cx('box')}>
                    <BizSmTitle>{t("mypage.position")}</BizSmTitle>
                    <InputMultiLang
                        value={props.value.position}
                        onChange={v => {
                            const copy = CopyUtil.copyAll(props.value);
                            copy.position = v;
                            props.onChangeValue(copy);
                        }}/>
                </div>
            </div>
        </>
    )
};
