import React, {Fragment, ReactNode} from "react";
import {Pagination} from "@material-ui/lab";
import {Element, scroller} from "react-scroll";

export const Pager = (props: {
    page: number,
    size: number,
    total: number,
    onChange: (page: number) => void,
    children?: ReactNode
    offset?: number,
    scrollTag?: string,
    paginationSize?: 'small' | 'medium' | 'large'
}) => {
    let count = Math.floor(props.total / props.size);
    count += props.total % props.size === 0 ? 0 : 1;

    const onChangePage = (page: number) => {
        scroller.scrollTo(props.scrollTag || 'pager', {
            duration: 500,
            delay: 0,
            smooth: true,
            offset: props.offset ? props.offset : 0,
        })
        props.onChange(page - 1);
    }

    return (
        <Fragment>
            <Element name={'pager-top'}/>
            {props.children}
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 50,
                marginBottom: 50,
            }}>
                <Pagination
                    size={props.paginationSize}
                    count={count}
                    page={props.page + 1}
                    shape='rounded'
                    onChange={(event, page) => {
                        onChangePage(page);
                    }}/>
            </div>
        </Fragment>

    )
}
