export const I18nKeys = {
  auth: {
    phConfirm: { value: '비밀번호를 다시 입력하여 주십시오.', key: 'auth.phConfirm' },
    phTerms: { value: '약관 동의', key: 'auth.phTerms' },
    btnJoin: { value: '회원가입', key: 'auth.btnJoin' },
    email: { value: '이메일', key: 'auth.email' },
    btnReset: { value: '재설정', key: 'auth.btnReset' },
    btnLogin: { value: '로그인', key: 'auth.btnLogin' },
    goLogin: { value: '이미 회원가입을 하셨습니까?', key: 'auth.goLogin' },
    enterAllField: { value: '모든 값을 입력하여 주십시오.', key: 'auth.enterAllField' },
    checkTerms: { value: '약관을 동의하여 주십시오.', key: 'auth.checkTerms' },
    goReset: { value: '비밀번호를 잊으셨습니까?', key: 'auth.goReset' },
    confirm: { value: '비밀번호 재확인', key: 'auth.confirm' },
    password: { value: '비밀번호', key: 'auth.password' },
    btnKakao: { value: 'Kakao 로그인', key: 'auth.btnKakao' },
    btnGoogle: { value: 'Google 로그인', key: 'auth.btnGoogle' },
    checkEmail: {
      value: '회원가입이 완료되었습니다.<br />인증 메일을 확인해 주세요.',
      key: 'auth.checkEmail'
    },
    notSamePw: { value: '비밀번호가 일치하지 않습니다.', key: 'auth.notSamePw' },
    btnNaver: { value: 'NAVER 로그인', key: 'auth.btnNaver' },
    btnResend: { value: '전송', key: 'auth.btnResend' },
    onResendEmailVerified: { value: '인증 메일 재전송', key: 'auth.onResendEmailVerified' },
    sendPwReset: {
      value: '비밀번호 재설정을 위한 이메일이<br />발송되었습니다.<br />이메일을 확인하여 주십시오.',
      key: 'auth.sendPwReset'
    },
    notFoundUser: { value: '등록된 사용자가 없습니다.', key: 'auth.notFoundUser' },
    notFoundData: { value: '등록된 데이터가 없습니다.', key: 'auth.notFoundData' },
    noJoinTypeEmail: { value: '이메일로 등록한 사용자가 아닙니다.', key: 'auth.noJoinTypeEmail' },
    failLoginFb: {
      value: '인증 메일 발송이 실패되었습니다.<br />메일 주소가 정확한지<br />확인해 주십시오.',
      key: 'auth.failLoginFb'
    },
    alreadyJoinedEmail: {
      value: '이미 이메일 방식으로 등록된<br />사용자입니다.',
      key: 'auth.alreadyJoinedEmail'
    },
    noHasEmailVerified: {
      value: '이메일 인증이 진행되지 않았습니다.<br />메일 인증을 진행해 주십시오.',
      key: 'auth.noHasEmailVerified'
    },
    kakaoUserNotFound: { value: '카카오 사용자를 찾을 수 없습니다.', key: 'auth.kakaoUserNotFound' },
    failGoogleLogin: { value: '구글 로그인이 실패되었습니다.', key: 'auth.failGoogleLogin' },
    failKakaoLogin: { value: '카카오 로그인이 실패되었습니다.', key: 'auth.failKakaoLogin' },
    failNaverLogin: { value: '네이버 로그인이 실패되었습니다.', key: 'auth.failNaverLogin' },
    resentEmailVerified: {
      value: '인증 메일이 재발송 되었습니다.<br />메일을 확인해 주십시오.',
      key: 'auth.resentEmailVerified'
    },
    nowSamePw: { value: '비밀번호가 일치하지 않습니다.', key: 'auth.nowSamePw' },
    snsLogin: { value: '간편 로그인', key: 'auth.snsLogin' },
    snsJoin: { value: '간편 로그인으로 회원가입', key: 'auth.snsJoin' },
    swalCheckInputError: {
      value: '이메일과 비밀번호를<br />입력하여 주십시오.',
      key: 'auth.swalCheckInputError'
    },
    phEmail: { value: '이메일을 입력하여 주십시오.', key: 'auth.phEmail' },
    phPw: { value: '비밀번호를 입력하여 주십시오.', key: 'auth.phPw' },
    secondPassword: { value: '2차 비밀번호', key: 'auth.secondPassword' },
    swalLoginOk: { value: '로그인 되었습니다.', key: 'auth.swalLoginOk' },
    wrongPassword: {
      value: '아이디(이메일) / 비밀번호가<br /> 잘못 입력 되었습니다.<br />아이디(이메일) / 비밀번호를<br />정확히 입력해 주세요.',
      key: 'auth.wrongPassword'
    }
  },
  common: {
    siteUrl: {
      value: 'AllEdu.Shop - Asia Edtech Marketplace',
      key: 'common.siteUrl'
    },
    copyright: {
      value: 'Copyright ⓒ 2021 AllEdu.Shop. All Rights Reserved.',
      key: 'common.copyright'
    },
    swalCopyUrlOk: { value: '클립보드에 복사되었습니다.', key: 'common.swalCopyUrlOk' },
    shareToNaver: { value: '네이버 블로그 공유하기', key: 'common.shareToNaver' },
    shareToKakao: { value: '카카오톡 공유하기', key: 'common.shareToKakao' },
    shareToClipboard: { value: '클립보드에 복사하기', key: 'common.shareToClipboard' },
    AppendFileWarningText: {
      value: '* 파일은 최대 3개, 최대 용량 10MB',
      key: 'common.AppendFileWarningText'
    },
    shareTitle: { value: '공유하기', key: 'common.shareTitle' },
    btnToggleEditHtmlCode: { value: 'HTML 입력', key: 'common.btnToggleEditHtmlCode' },
    btnToggleEditor: { value: 'EDITOR 입력', key: 'common.btnToggleEditor' },
    filename: { value: '파일명', key: 'common.filename' },
    swalAppendFile: { value: '파일은 최대 3개까지 등록하실 수 있습니다.', key: 'common.swalAppendFile' },
    btnOk: { value: '확인', key: 'common.btnOk' },
    less5mb: { value: '10MB 이상의 파일은 업로드 할수 없습니다.', key: 'common.less5mb' },
    btnCancel: { value: '취소', key: 'common.btnCancel' },
    btnDelete: { value: '삭제하기', key: 'common.btnDelete' },
    askPw: { value: '비밀번호를 입력하여 주십시오.', key: 'common.askPw' },
    btnAdd: { value: '추가하기', key: 'common.btnAdd' },
    defaultError: { value: '에러. 다시 시도하여 주십시오.', key: 'common.defaultError' },
    startDate: { value: '시작일', key: 'common.startDate' },
    endDate: { value: '종료일', key: 'common.endDate' },
    noDataTable: { value: '데이터가 없습니다.', key: 'common.noDataTable' },
    swalGetEndDateError: { value: '시작일이 종료일보다 뒤입니다.', key: 'common.swalGetEndDateError' },
    swalgetStartDateError: { value: '종료일이 시작일보다 앞입니다.', key: 'common.swalgetStartDateError' },
    ttOpen: { value: '열기', key: 'common.ttOpen' },
    ttClose: { value: '닫기', key: 'common.ttClose' }
  },
  company: {
    nm: { value: '기관명', key: 'company.nm' },
    foundation: { value: '설립일', key: 'company.foundation' },
    hp: { value: '홈페이지', key: 'company.hp' },
    sales: { value: '매출액', key: 'company.sales' },
    chat: { value: '채팅 문의', key: 'company.chat' },
    ceo: { value: '대표자', key: 'company.ceo' },
    nation: { value: '국가', key: 'company.nation' },
    kotra: { value: '무역관', key: 'company.kotra' },
    addr: { value: '기업 주소', key: 'company.addr' },
    likeCompany: { value: '관심 기관', key: 'company.likeCompany' },
    viewCompany: { value: '기관 조회', key: 'company.viewCompany' },
    written: { value: '작성일', key: 'company.written' },
    like: { value: '관심', key: 'company.like' },
    totalSales: { value: '총매출 (단위: $[USD])', key: 'company.totalSales' },
    likeGoods: { value: '관심 상품', key: 'company.likeGoods' },
    table: { value: '표', key: 'company.table' },
    unit: { value: '단위', key: 'company.unit' },
    profit: { value: '이익', key: 'company.profit' },
    year: { value: '년도', key: 'company.year' },
    nationUsage: { value: '국가별 사용 인원 (단위: 명)', key: 'company.nationUsage' },
    nationSales: { value: '국가별 매출 (단위: $[USD])', key: 'company.nationSales' },
    notFound: { value: '기관을 찾을 수 없습니다.', key: 'company.notFound' },
    usage: { value: '사용 인원', key: 'company.usage' },
    catchphrase: { value: '기관 소개', key: 'company.catchphrase' },
    nmTitle: { value: '기관명 색인', key: 'company.nmTitle' },
    nmDesc: { value: '* 기관명은 영문명 기준으로 정렬되어 있습니다.', key: 'company.nmDesc' },
    nationTitle: { value: '국가별 색인', key: 'company.nationTitle' },
    nameSortDesc: { value: '기관명은 영문 기준으로 작성되어 있습니다.', key: 'company.nameSortDesc' },
    findName: { value: '기관명 색인', key: 'company.findName' },
    notFoundCompany: { value: '지정된 국가에 등록된 기관이 없습니다.', key: 'company.notFoundCompany' },
    findNation: { value: '지역별 색인', key: 'company.findNation' },
    companyGoodsListTitle: { value: '기관 상품 목록', key: 'company.companyGoodsListTitle' },
    ttCompanyList: { value: '영문 기관명 기준으로 정렬됩니다.', key: 'company.ttCompanyList' },
    ttOpenConsultantInfo: {
      value: '개인 정보 보호를 위하여 조회 요청 시에만 화면에 표시됩니다.',
      key: 'company.ttOpenConsultantInfo'
    },
    wholeList: { value: '전체 보기', key: 'company.wholeList' },
    companyGoodsList: { value: '기관 상품 목록', key: 'company.companyGoodsList' },
    consultantInfo: { value: '담당자 정보 조회', key: 'company.consultantInfo' },
    consultantName: { value: '이름', key: 'company.consultantName' },
    consultantEmail: { value: '이메일', key: 'company.consultantEmail' },
    cosultantTel: { value: '전화번호', key: 'company.cosultantTel' },
    consultantPosition: { value: '직책', key: 'company.consultantPosition' },
    consultantFunction: { value: '기능', key: 'company.consultantFunction' },
    ttViewing: { value: '조회', key: 'company.ttViewing' },
    ttAllowFavoriteCompany: { value: '관심 기관', key: 'company.ttAllowFavoriteCompany' },
    ttCancelFavoriteCompany: { value: '관심 기관', key: 'company.ttCancelFavoriteCompany' },
    ttShareCompany: { value: '공유 하기', key: 'company.ttShareCompany' },
    companyDetail: { value: '기관 상세 정보', key: 'company.companyDetail' },
    ttJoinCompanyChat: { value: '채팅 문의', key: 'company.ttJoinCompanyChat' },
    share: { value: '공유', key: 'company.share' },
    noChartData: { value: '정보가 없습니다.', key: 'company.noChartData' },
    companyType: { value: '기관 형태', key: 'company.companyType' },
    member: { value: '직원 수', key: 'company.member' },
    category: { value: '업종', key: 'company.category' },
    introduceYoutube: { value: '기관 소개 영상', key: 'company.introduceYoutube' },
    notShowConsultant: {
      value: '※ 로그인 후 담당자 정보를 조회하실 수 있습니다.',
      key: 'company.notShowConsultant'
    },
    attachment: { value: '첨부 파일', key: 'company.attachment' },
    showConsultant: {
      value: '※ 비공개 처리된 담당자 정보는 보실 수 없습니다.',
      key: 'company.showConsultant'
    },
    chatWithConsultant: { value: '문의', key: 'company.chatWithConsultant' },
    badgeCompanyRequestStatusAccepted: {
      value: '승인 완료',
      key: 'company.badgeCompanyRequestStatusAccepted'
    },
    badgeCompanyRequestStatusRequested: {
      value: '심사 요청',
      key: 'company.badgeCompanyRequestStatusRequested'
    },
    badgeCompanyRequestStatusCancel: { value: '등록 반려', key: 'company.badgeCompanyRequestStatusCancel' },
    badgeCompanyRequestStatusDnied: { value: '등록 취소', key: 'company.badgeCompanyRequestStatusDnied' },
    deniedReason: { value: '반려 사유', key: 'company.deniedReason' },
    needLoginWithChat: {
      value: '로그인을 하셔야만 1:1 문의를 하실 수 있습니다.',
      key: 'company.needLoginWithChat'
    }
  },
  cs: {
    title: { value: '제목', key: 'cs.title' },
    noticeTitle: { value: '공지사항', key: 'cs.noticeTitle' },
    inProgressTitle: { value: '진행 중 목록', key: 'cs.inProgressTitle' },
    qnaTitle: { value: '문의하기', key: 'cs.qnaTitle' },
    qnaList: { value: '문의 목록', key: 'cs.qnaList' },
    questionDate: { value: '문의날짜', key: 'cs.questionDate' },
    idx: { value: '번호', key: 'cs.idx' },
    status: { value: '상태', key: 'cs.status' },
    btnApplyQnA: { value: '문의하기', key: 'cs.btnApplyQnA' },
    badgeClose: { value: '종료됨', key: 'cs.badgeClose' },
    swalNoReplyContent: { value: '댓글 내용을 입력하여 주십시오.', key: 'cs.swalNoReplyContent' },
    swalResolveQuestion: {
      value: '문제가 해결되어<br />상담 종료하시겠습니까?',
      key: 'cs.swalResolveQuestion'
    },
    swalRegistReplyOk: { value: '추가되었습니다.', key: 'cs.swalRegistReplyOk' },
    badgeInProgress: { value: '진행 중', key: 'cs.badgeInProgress' },
    swalRegistReplyQuestion: { value: '댓글을 등록 하시겠습니까?', key: 'cs.swalRegistReplyQuestion' },
    swalResolveOk: { value: '문의 상담이 종료 되었습니다.', key: 'cs.swalResolveOk' },
    swalDeleteReplyQuestion: { value: '댓글을 삭제 하시겠습니까?', key: 'cs.swalDeleteReplyQuestion' },
    replyTitle: { value: '댓글', key: 'cs.replyTitle' },
    btnQnaResolve: { value: '문의 종료하기', key: 'cs.btnQnaResolve' },
    swalDeleteReplyOk: { value: '삭제 되었습니다.', key: 'cs.swalDeleteReplyOk' },
    qnaContentsTitle: { value: '문의 내용', key: 'cs.qnaContentsTitle' },
    cases: { value: '건', key: 'cs.cases' },
    badgeAdmin: { value: '관리자', key: 'cs.badgeAdmin' },
    badgeUser: { value: '사용자', key: 'cs.badgeUser' },
    ttDelete: { value: '삭제하기', key: 'cs.ttDelete' },
    btnReplyAdd: { value: '댓글 등록', key: 'cs.btnReplyAdd' },
    swalCreateQnaNotTitle: { value: '제목을 입력하여 주십시오.', key: 'cs.swalCreateQnaNotTitle' },
    swalCreateQnaNotContents: { value: '내용을 입력하여 주십시오.', key: 'cs.swalCreateQnaNotContents' },
    swalCreateQnaQuestion: { value: '문의를 등록 하시겠습니까?', key: 'cs.swalCreateQnaQuestion' },
    contents: { value: '내용', key: 'cs.contents' },
    swalCreateQnaOk: { value: '문의가 등록 되었습니다.', key: 'cs.swalCreateQnaOk' },
    btnAdd: { value: '등록하기', key: 'cs.btnAdd' },
    viewCount: { value: '조회수', key: 'cs.viewCount' },
    updateDate: { value: '수정일', key: 'cs.updateDate' },
    createDate: { value: '작성일', key: 'cs.createDate' },
    finalUpdateDate: { value: '최종 수정일', key: 'cs.finalUpdateDate' },
    faqTitle: { value: '자주하는 질문', key: 'cs.faqTitle' },
    allEduAdmin: { value: '올에듀.샵 운영자', key: 'cs.allEduAdmin' }
  },
  find: {
    cases: { value: '건', key: 'find.cases' },
    findResultCompanyTitle: { value: '기관 검색 결과', key: 'find.findResultCompanyTitle' },
    findResultGoodsTitle: { value: '상품 검색 결과', key: 'find.findResultGoodsTitle' }
  },
  goods: {
    goodsCode: { value: '상품 코드', key: 'goods.goodsCode' },
    origin: { value: '제조국', key: 'goods.origin' },
    download: { value: '다운로드', key: 'goods.download' },
    manual: { value: '매뉴얼', key: 'goods.manual' },
    select: { value: '선택', key: 'goods.select' },
    goodsContent: { value: '상품 상세', key: 'goods.goodsContent' },
    goodsInfo: { value: '상품 정보', key: 'goods.goodsInfo' },
    noGoodsInfo: { value: '등록된 상품 정보가 없습니다.', key: 'goods.noGoodsInfo' },
    goodsCompany: { value: '기관 정보', key: 'goods.goodsCompany' },
    subCategory: { value: '하위 카테고리', key: 'goods.subCategory' },
    goods: { value: '상품', key: 'goods.goods' },
    noPrice: { value: '가격 협의', key: 'goods.noPrice' },
    navigator: { value: '내비게이션', key: 'goods.navigator' },
    goodsList: { value: '상품 목록', key: 'goods.goodsList' },
    files: { value: '파일', key: 'goods.files' },
    ttAllowFavoriteGoods: { value: '관심 상품', key: 'goods.ttAllowFavoriteGoods' },
    ttCancelFavoriteGoods: { value: '관심 상품', key: 'goods.ttCancelFavoriteGoods' },
    allEduCode: { value: 'AllEdu Code', key: 'goods.allEduCode' },
    award: { value: '수상 경력', key: 'goods.award' },
    ttShareGoods: { value: '공유 하기', key: 'goods.ttShareGoods' },
    ttJoinGoodsChat: { value: '채팅 문의', key: 'goods.ttJoinGoodsChat' },
    catalogs: { value: '카탈로그', key: 'goods.catalogs' },
    certification: { value: '인증현황', key: 'goods.certification' },
    youtubeUrl: { value: '상품 소개 영상', key: 'goods.youtubeUrl' },
    purchaseUrl: { value: '상품 구매', key: 'goods.purchaseUrl' },
    tryGoodsUrl: { value: '상품 체험', key: 'goods.tryGoodsUrl' },
    showGoods: { value: '진열', key: 'goods.showGoods' },
    registeredGoods: { value: '등록', key: 'goods.registeredGoods' },
    goodsUnit: { value: '개', key: 'goods.goodsUnit' }
  },
  indexV1: { category: { value: '카테고리', key: 'indexV1.category' } },
  layout: {
    nation: { value: '국가', key: 'layout.nation' },
    category: { value: '카테고리', key: 'layout.category' },
    finder: { value: '이곳에 검색어를 입력하여 주십시오.', key: 'layout.finder' },
    login: { value: '로그인', key: 'layout.login' },
    btnFind: { value: '검색', key: 'layout.btnFind' },
    join: { value: '회원가입', key: 'layout.join' },
    menu: { value: '메뉴', key: 'layout.menu' },
    company: { value: '기관', key: 'layout.company' },
    cs: { value: '고객센터', key: 'layout.cs' },
    selectLang: { value: '언어 설정', key: 'layout.selectLang' },
    logout: { value: '로그아웃', key: 'layout.logout' },
    companyNm: {
      value: '사단법인 한국에듀테크산업협회<br />주식회사 타임교육C&P 공동운영',
      key: 'layout.companyNm'
    },
    mypage: { value: '마이페이지', key: 'layout.mypage' },
    infinityLast: { value: '마지막 데이터입니다.', key: 'layout.infinityLast' },
    addr: {
      value: '(06179) 서울시 강남구 삼성로86길 23, 구일빌딩 3층<br />(06153) 서울시 강남구 봉은사로 442',
      key: 'layout.addr'
    },
    chat: { value: '채팅', key: 'layout.chat' },
    warning: {
      value: '이 사이트는 가입되어 있는 회원들이 제공한 정보로 구성되어 있으며, 이에 대한 책임은 각 회원들에게 있습니다.',
      key: 'layout.warning'
    },
    unregistered: { value: '(기관 정보 미등록)', key: 'layout.unregistered' },
    swalFindWord: { value: '2글자 이상 입력하여 주십시오.', key: 'layout.swalFindWord' },
    siteMap: { value: '사이트맵', key: 'layout.siteMap' },
    terms: { value: '회원약관', key: 'layout.terms' },
    privacy: { value: '개인정보취급방침', key: 'layout.privacy' },
    board: { value: '1:1 문의', key: 'layout.board' },
    companyNm1: { value: '사단법인 한국에듀테크산업협회', key: 'layout.companyNm1' },
    companyAddr1: {
      value: '(06179) 서울시 강남구 삼성로86길 23, 구일빌딩 3층',
      key: 'layout.companyAddr1'
    },
    companyNm2: { value: '주식회사 타임교육C&P', key: 'layout.companyNm2' },
    companyAddr2: { value: '(06153) 서울시 강남구 봉은사로 442', key: 'layout.companyAddr2' },
    joint: { value: '공동운영', key: 'layout.joint' }
  },
  mypage: {
    mypage: { value: '마이페이지', key: 'mypage.mypage' },
    join: { value: '기관 회원 등록', key: 'mypage.join' },
    userInfo: { value: '회원정보', key: 'mypage.userInfo' },
    companyStatus: { value: '기관 정보', key: 'mypage.companyStatus' },
    changePw: { value: '비밀번호 변경', key: 'mypage.changePw' },
    companyStatusNotRegister: { value: '일반 회원', key: 'mypage.companyStatusNotRegister' },
    companyStatusOk: { value: '기관 회원', key: 'mypage.companyStatusOk' },
    companyStatusBan: { value: '차단', key: 'mypage.companyStatusBan' },
    companyStatusInProgressUpdate: { value: '갱신 검토 중', key: 'mypage.companyStatusInProgressUpdate' },
    updateCompany: { value: '기관 정보 수정', key: 'mypage.updateCompany' },
    companyStatusInProgressJoin: { value: '가입 검토 중', key: 'mypage.companyStatusInProgressJoin' },
    email: { value: '아이디(이메일)', key: 'mypage.email' },
    joinType: { value: '가입방법', key: 'mypage.joinType' },
    btnChangePw: { value: '변경', key: 'mypage.btnChangePw' },
    likeGoods: { value: '관심 상품', key: 'mypage.likeGoods' },
    joinAt: { value: '가입일', key: 'mypage.joinAt' },
    likeCompany: { value: '관심 기관', key: 'mypage.likeCompany' },
    auction: { value: '입찰', key: 'mypage.auction' },
    auctionCompany: { value: '기관 입찰', key: 'mypage.auctionCompany' },
    auctionGoods: { value: '상품 입찰', key: 'mypage.auctionGoods' },
    registryGoods: { value: '상품 등록', key: 'mypage.registryGoods' },
    displayGoods: { value: '상품 진열 관리', key: 'mypage.displayGoods' },
    mngGoods: { value: '상품 관리', key: 'mypage.mngGoods' },
    companyCreateRequest: { value: '기관 등록 심사 현황', key: 'mypage.companyCreateRequest' },
    companyUpdateRequest: { value: '기관 갱신 심사', key: 'mypage.companyUpdateRequest' },
    goodsRequest: { value: '상품 심사', key: 'mypage.goodsRequest' },
    swalSendResetPwEmail: {
      value: '비밀번호 변경을 위한 이메일을<br />전송 하였습니다.',
      key: 'mypage.swalSendResetPwEmail'
    },
    consultant: { value: '담당자 관리', key: 'mypage.consultant' },
    goodsList: { value: '상품 관리', key: 'mypage.goodsList' },
    titleUserInfoDetail: { value: '회원 상세 정보', key: 'mypage.titleUserInfoDetail' },
    swalValidateUpdateUserInfoError: {
      value: '영문 데이터는 모두 입력하여 주십시오.',
      key: 'mypage.swalValidateUpdateUserInfoError'
    },
    tel: { value: '전화번호', key: 'mypage.tel' },
    swalUpdateuserInfoQuestion: {
      value: '회원 정보를 업데이트 하시겠습니까?',
      key: 'mypage.swalUpdateuserInfoQuestion'
    },
    nm: { value: '이름', key: 'mypage.nm' },
    agency: { value: '기관명', key: 'mypage.agency' },
    position: { value: '직책', key: 'mypage.position' },
    btnSave: { value: '저장', key: 'mypage.btnSave' },
    required: { value: '(* 영어 필수 입력)', key: 'mypage.required' },
    swalHaveNotUserInfo: {
      value: '회원 상세 정보를 입력 하셔야<br />이용이 가능합니다.',
      key: 'mypage.swalHaveNotUserInfo'
    },
    myInfoProgress: { value: '진행상태', key: 'mypage.myInfoProgress' },
    defaultInfo: { value: '기본정보', key: 'mypage.defaultInfo' },
    progJoined: { value: '회원가입', key: 'mypage.progJoined' },
    progUserInfo: { value: '회원상세정보', key: 'mypage.progUserInfo' },
    progCompany: { value: '기관등록', key: 'mypage.progCompany' },
    progConsultant: { value: '담당자 등록', key: 'mypage.progConsultant' },
    progGoods: { value: '상품등록', key: 'mypage.progGoods' },
    createStatusRequest: { value: '심사요청', key: 'mypage.createStatusRequest' },
    createStatusRegister: { value: '기관등록', key: 'mypage.createStatusRegister' },
    createStatusAccept: { value: '승인완료', key: 'mypage.createStatusAccept' },
    createStatusUserConfirm: { value: '등록확인', key: 'mypage.createStatusUserConfirm' },
    createStatusDone: { value: '등록완료', key: 'mypage.createStatusDone' },
    createStatusDeny: { value: '반려', key: 'mypage.createStatusDeny' },
    createStatusCancel: { value: '취소', key: 'mypage.createStatusCancel' },
    createStatusReregister: { value: '재등록', key: 'mypage.createStatusReregister' },
    createStatusTitle: { value: '기관등록 현황', key: 'mypage.createStatusTitle' },
    createStatusRegisterTooltip: {
      value: '기관 담당자 : 기관 회원 등록 정보 입력',
      key: 'mypage.createStatusRegisterTooltip'
    },
    createStatusRequestTooltip: { value: '관리자 : 심사 대기중', key: 'mypage.createStatusRequestTooltip' },
    createStatusAcceptTooltip: {
      value: '기관 담당자 :  마이페이지 > 기관 등록 심사 현황 > 등록확인 (클릭)',
      key: 'mypage.createStatusAcceptTooltip'
    },
    updateStatusRequest: { value: '심사요청', key: 'mypage.updateStatusRequest' },
    updateStatusRegister: { value: '갱신등록', key: 'mypage.updateStatusRegister' },
    updateStatusAccept: { value: '심사완료', key: 'mypage.updateStatusAccept' },
    updateStatusAcceptTooltip: {
      value: '기관 관리자 : 마이페이지 > 기관 갱신 심사 > 등록 완료 (클릭)',
      key: 'mypage.updateStatusAcceptTooltip'
    },
    updateStatusUserConfirm: { value: '등록확인', key: 'mypage.updateStatusUserConfirm' },
    updateStatusDone: { value: '등록완료', key: 'mypage.updateStatusDone' },
    updateStatusDeny: { value: '반려', key: 'mypage.updateStatusDeny' },
    updateStatusDenyTooltip: {
      value: '기관 관리자 : 마이페이지 > 기관 정보 수정 (재등록)',
      key: 'mypage.updateStatusDenyTooltip'
    },
    updateStatusTitle: { value: '기관정보 갱신 현황', key: 'mypage.updateStatusTitle' },
    updateStatusRegisterTooltip: {
      value: '관리자 : 심사 대기중',
      key: 'mypage.updateStatusRegisterTooltip'
    },
    updateStatusReregister: { value: '재등록', key: 'mypage.updateStatusReregister' }
  },
  mypageAuction: {
    balance: { value: '잔고', key: 'mypageAuction.balance' },
    shortageBalance: { value: '잔고가 부족합니다.', key: 'mypageAuction.shortageBalance' },
    btnChargeBalance: { value: '충전하기', key: 'mypageAuction.btnChargeBalance' },
    cancelAuction: { value: '취소 신청', key: 'mypageAuction.cancelAuction' },
    auctionStatus: { value: '입찰 상태', key: 'mypageAuction.auctionStatus' },
    auctionDueAt: { value: '종료일', key: 'mypageAuction.auctionDueAt' },
    auctionAppliedAt: { value: '적용일', key: 'mypageAuction.auctionAppliedAt' },
    availableAuction: { value: '입찰 가능', key: 'mypageAuction.availableAuction' },
    auctionMinBid: { value: '최소 입찰 포인트', key: 'mypageAuction.auctionMinBid' },
    auctionNo: { value: '입찰 번호', key: 'mypageAuction.auctionNo' },
    auctionParticipate: { value: '입찰 참여', key: 'mypageAuction.auctionParticipate' },
    notAvailableAuction: { value: '입찰 공고가 없습니다.', key: 'mypageAuction.notAvailableAuction' },
    alreadyParticipated: { value: '신청 완료', key: 'mypageAuction.alreadyParticipated' },
    auctionBid: { value: '입찰 포인트', key: 'mypageAuction.auctionBid' },
    requestAuction: { value: '입찰 신청', key: 'mypageAuction.requestAuction' },
    auctionParticipatedAt: { value: '입찰 신청일', key: 'mypageAuction.auctionParticipatedAt' },
    auctionPeriod: { value: '기간', key: 'mypageAuction.auctionPeriod' },
    auctionInfo: { value: '입찰정보', key: 'mypageAuction.auctionInfo' },
    status: { value: '상태', key: 'mypageAuction.status' },
    auctionParticipation: { value: '입찰 참여 기록', key: 'mypageAuction.auctionParticipation' },
    noHasAuctionCompany: {
      value: '입찰 참여 기록이 없습니다.',
      key: 'mypageAuction.noHasAuctionCompany'
    },
    auctionCompany: { value: '현재 가능한 입찰 공고', key: 'mypageAuction.auctionCompany' },
    doneCancel: { value: '입찰을 취소하였습니다.', key: 'mypageAuction.doneCancel' },
    cancel: { value: '취소', key: 'mypageAuction.cancel' },
    askMinBidCheck: {
      value: '최소 입찰 포인트를 확인해 주십시오.',
      key: 'mypageAuction.askMinBidCheck'
    },
    doneAuctionCompany: {
      value: '기관 입찰에 참여하였습니다.',
      key: 'mypageAuction.doneAuctionCompany'
    },
    auctionDone: { value: '입찰 완료', key: 'mypageAuction.auctionDone' },
    askCancel: { value: '입찰을 취소하시겠습니까?', key: 'mypageAuction.askCancel' },
    errNotFoundAuctionCompanyResult: {
      value: '입찰 취소가<br />불가능한 상태입니다.',
      key: 'mypageAuction.errNotFoundAuctionCompanyResult'
    },
    alreadyParticipate: {
      value: '이미 입찰에 참여하셨습니다.',
      key: 'mypageAuction.alreadyParticipate'
    },
    notFoundAuction: {
      value: '입찰 정보를 찾을 수 없습니다.',
      key: 'mypageAuction.notFoundAuction'
    }
  },
  mypageCompany: {
    createRequestNumber: { value: '가입 번호', key: 'mypageCompany.createRequestNumber' },
    swalCreateOkQuestionOk: {
      value: '등록 완료되었습니다.',
      key: 'mypageCompany.swalCreateOkQuestionOk'
    },
    createRequestStatus: { value: '상태', key: 'mypageCompany.createRequestStatus' },
    swalCreateCancelQuestion: {
      value: '취소하시겠습니까?',
      key: 'mypageCompany.swalCreateCancelQuestion'
    },
    swalCreateCancelQuestionOk: {
      value: '취소되었습니다.',
      key: 'mypageCompany.swalCreateCancelQuestionOk'
    },
    swalUpdateCompanyDataQuestion: {
      value: '업데이트 요청 하시겠습니까?',
      key: 'mypageCompany.swalUpdateCompanyDataQuestion'
    },
    swalAllowUpdateQuestion: {
      value: '정보를 업데이트 하시겠습니까?',
      key: 'mypageCompany.swalAllowUpdateQuestion'
    },
    swalAllowUpdateOk: {
      value: '정보가 업데이트 되었습니다.',
      key: 'mypageCompany.swalAllowUpdateOk'
    },
    swalUpdateCompanyDataOk: {
      value: '요청 완료 되었습니다.',
      key: 'mypageCompany.swalUpdateCompanyDataOk'
    },
    titleUpdateRequest: { value: '기관정보 갱신 심사 목록', key: 'mypageCompany.titleUpdateRequest' },
    requestIdx: { value: '요청번호', key: 'mypageCompany.requestIdx' },
    swalCancelUpdateOk: { value: '취소되었습니다.', key: 'mypageCompany.swalCancelUpdateOk' },
    swalCancelUpdateQuestion: {
      value: '정보 업데이트를 취소 하시겠습니까?',
      key: 'mypageCompany.swalCancelUpdateQuestion'
    },
    requestDate: { value: '요청일', key: 'mypageCompany.requestDate' },
    prevInfo: { value: '기존정보', key: 'mypageCompany.prevInfo' },
    nextInfo: { value: '심사정보', key: 'mypageCompany.nextInfo' },
    ttCancelUpdate: { value: '등록 취소', key: 'mypageCompany.ttCancelUpdate' },
    ttAllowUpdate: { value: '등록 완료', key: 'mypageCompany.ttAllowUpdate' },
    createRequestFunction: { value: '기능', key: 'mypageCompany.createRequestFunction' },
    createRequestInfo: { value: '기관명', key: 'mypageCompany.createRequestInfo' },
    function: { value: '기능', key: 'mypageCompany.function' },
    ttCreateAllow: { value: '등록 확인', key: 'mypageCompany.ttCreateAllow' },
    ttCreateCancel: { value: '등록 취소', key: 'mypageCompany.ttCreateCancel' },
    swalCreateOkQuestion: {
      value: '등록 완료하시겠습니까?',
      key: 'mypageCompany.swalCreateOkQuestion'
    }
  },
  mypageConsultant: {
    swalCreateOkQuestion: {
      value: '등록하시겠습니까?',
      key: 'mypageConsultant.swalCreateOkQuestion'
    },
    swalBindQuestion: { value: '수정하시겠습니까?', key: 'mypageConsultant.swalBindQuestion' },
    swalBindOk: { value: '등록되었습니다.', key: 'mypageConsultant.swalBindOk' },
    swalUpdateOkQuestionOk: {
      value: '수정되었습니다.',
      key: 'mypageConsultant.swalUpdateOkQuestionOk'
    },
    swalCreateOkQuestionOk: {
      value: '등록되었습니다.',
      key: 'mypageConsultant.swalCreateOkQuestionOk'
    },
    swalUpdateOkQuestion: {
      value: '수정하시겠습니까?',
      key: 'mypageConsultant.swalUpdateOkQuestion'
    },
    swalChangePeriodError: {
      value: '30일 이상 조회할 수 없습니다.',
      key: 'mypageConsultant.swalChangePeriodError'
    },
    swalUnbindQuestion: { value: '해제 하시겠습니까?', key: 'mypageConsultant.swalUnbindQuestion' },
    swalUnBindOk: { value: '해제 되었습니다.', key: 'mypageConsultant.swalUnBindOk' },
    modalUpdateTitle: { value: '담당자 수정', key: 'mypageConsultant.modalUpdateTitle' },
    swalToggleActivateQuestion: {
      value: '활성화하시겠습니까?',
      key: 'mypageConsultant.swalToggleActivateQuestion'
    },
    swalUnbindAllQuestion: {
      value: '모두 해제 하시겠습니까?',
      key: 'mypageConsultant.swalUnbindAllQuestion'
    },
    modalCreateTitle: { value: '담당자 등록', key: 'mypageConsultant.modalCreateTitle' },
    inputConsultantTitle: {
      value: '기관 담당자 등록',
      key: 'mypageConsultant.inputConsultantTitle'
    },
    swalToggleOk: { value: '수정되었습니다.', key: 'mypageConsultant.swalToggleOk' },
    swalToggleInactivateQuestion: {
      value: '비활성화하시겠습니까?',
      key: 'mypageConsultant.swalToggleInactivateQuestion'
    },
    validateNm: { value: '영문 이름을 입력하여 주십시오.', key: 'mypageConsultant.validateNm' },
    ttInputConsultant: {
      value: '밝은색은 숨김 항목, 검은색은 공개 항목입니다',
      key: 'mypageConsultant.ttInputConsultant'
    },
    validatePosition: {
      value: '영문 직책을 입력하여 주십시오.',
      key: 'mypageConsultant.validatePosition'
    },
    validateTel: { value: '전화번호를 입력하여 주십시오.', key: 'mypageConsultant.validateTel' },
    consultantListTitle: { value: '담당자 목록', key: 'mypageConsultant.consultantListTitle' },
    validateEmail: {
      value: '영문 이메일을 입력하여 주십시오.',
      key: 'mypageConsultant.validateEmail'
    },
    btnCreateInput: { value: '추가', key: 'mypageConsultant.btnCreateInput' },
    activate: { value: '활성화', key: 'mypageConsultant.activate' },
    isActivate: { value: '활성화', key: 'mypageConsultant.isActivate' },
    isNotActivate: { value: '비활성화', key: 'mypageConsultant.isNotActivate' },
    item: { value: '항목', key: 'mypageConsultant.item' },
    input: { value: '정보', key: 'mypageConsultant.input' },
    nm: { value: '이름', key: 'mypageConsultant.nm' },
    position: { value: '직책', key: 'mypageConsultant.position' },
    email: { value: '이메일', key: 'mypageConsultant.email' },
    tel: { value: '전화번호', key: 'mypageConsultant.tel' },
    function: { value: '기능', key: 'mypageConsultant.function' },
    createDate: { value: '등록일', key: 'mypageConsultant.createDate' },
    swalAddConsultantOk: {
      value: '모든 담당자가 추가되었습니다.',
      key: 'mypageConsultant.swalAddConsultantOk'
    },
    btnSave: { value: '저장', key: 'mypageConsultant.btnSave' },
    state: { value: '상태', key: 'mypageConsultant.state' },
    add: { value: '추가', key: 'mypageConsultant.add' },
    ttUpdate: { value: '수정하기', key: 'mypageConsultant.ttUpdate' },
    language: { value: '언어', key: 'mypageConsultant.language' },
    swalBoundLanguageError: {
      value: '같은 언어에 관리자를<br />한 명 이상 등록할 수 없습니다.',
      key: 'mypageConsultant.swalBoundLanguageError'
    },
    delete: { value: '삭제', key: 'mypageConsultant.delete' },
    goodsNo: { value: '상품번호', key: 'mypageConsultant.goodsNo' },
    openInfoTitle: { value: '정보공개', key: 'mypageConsultant.openInfoTitle' },
    goods: { value: '상품', key: 'mypageConsultant.goods' },
    consultantDetailTitle: {
      value: '담당자 상세 정보',
      key: 'mypageConsultant.consultantDetailTitle'
    },
    linkedLanguage: { value: '연결된 언어', key: 'mypageConsultant.linkedLanguage' },
    viewCount: { value: '조회 수', key: 'mypageConsultant.viewCount' },
    ttConsultantDetail: { value: '담당자 상세 정보', key: 'mypageConsultant.ttConsultantDetail' },
    ttUnbindAll: { value: '전체 해제', key: 'mypageConsultant.ttUnbindAll' },
    bindGoodsListTitle: { value: '등록된 상품 목록', key: 'mypageConsultant.bindGoodsListTitle' }
  },
  mypageGoods: {
    registryAt: { value: '등록일', key: 'mypageGoods.registryAt' },
    askCreateGoods: { value: '상품을 등록하시겠습니까?', key: 'mypageGoods.askCreateGoods' },
    evaluateGoods: { value: '상품 심사', key: 'mypageGoods.evaluateGoods' },
    consultantData: { value: '담당자 정보', key: 'mypageGoods.consultantData' },
    goodsData: { value: '상품 사양', key: 'mypageGoods.goodsData' },
    btnUpload: { value: '상품 등록', key: 'mypageGoods.btnUpload' },
    createGoods: { value: '신규 등록', key: 'mypageGoods.createGoods' },
    award: { value: '수상 경력', key: 'mypageGoods.award' },
    updateGoods: { value: '정보 변경', key: 'mypageGoods.updateGoods' },
    goodsNm: { value: '상품명', key: 'mypageGoods.goodsNm' },
    origin: { value: '제조국', key: 'mypageGoods.origin' },
    catchphrase: { value: '상품 개요', key: 'mypageGoods.catchphrase' },
    certification: { value: '인증현황', key: 'mypageGoods.certification' },
    price: { value: '가격', key: 'mypageGoods.price' },
    catalogs: { value: '카탈로그', key: 'mypageGoods.catalogs' },
    goodsInfo: { value: '상품 사양', key: 'mypageGoods.goodsInfo' },
    goodsCode: { value: '상품 코드', key: 'mypageGoods.goodsCode' },
    category: { value: '카테고리', key: 'mypageGoods.category' },
    manual: { value: '매뉴얼', key: 'mypageGoods.manual' },
    goodsImage: { value: '상품 이미지', key: 'mypageGoods.goodsImage' },
    askDelete: { value: '삭제하시겠습니까?', key: 'mypageGoods.askDelete' },
    contents: { value: '상품 소개', key: 'mypageGoods.contents' },
    cannotUpload: { value: '더 이상 업로드할 수 없습니다.', key: 'mypageGoods.cannotUpload' },
    failUpload: { value: '업로드에 실패하였습니다.', key: 'mypageGoods.failUpload' },
    btnAdd: { value: '추가하기', key: 'mypageGoods.btnAdd' },
    askSquareImage: {
      value: '* 정사각형 이미지를 입력하여 주십시오.',
      key: 'mypageGoods.askSquareImage'
    },
    noHasPermission: { value: '상품 등록 권한이 없습니다.', key: 'mypageGoods.noHasPermission' },
    youtubeUrl: {
      value: '상품 소개 영상(Youtube 등 링크 경로)',
      key: 'mypageGoods.youtubeUrl'
    },
    createGoodsResult: {
      value: '업로드되었습니다.<br />관리자 심사 후 포인트 결제됩니다.',
      key: 'mypageGoods.createGoodsResult'
    },
    titleCreateGoods: { value: '상품 등록', key: 'mypageGoods.titleCreateGoods' },
    purchaseUrl: { value: '상품 구매(링크 경로)', key: 'mypageGoods.purchaseUrl' },
    tryGoodsUrl: { value: '상품 체험(링크 경로)', key: 'mypageGoods.tryGoodsUrl' },
    notEnoughPoint: { value: '잔고가 부족합니다.', key: 'mypageGoods.notEnoughPoint' },
    status: { value: '상태', key: 'mypageGoods.status' },
    downloadFile: { value: '다운로드', key: 'mypageGoods.downloadFile' },
    askCancelRegistryGoods: {
      value: '상품 등록을 취소하시겠습니까?',
      key: 'mypageGoods.askCancelRegistryGoods'
    },
    badgeAdminCancel: { value: '등록 반려', key: 'mypageGoods.badgeAdminCancel' },
    badgeRequested: { value: '심사 중', key: 'mypageGoods.badgeRequested' },
    badgeUserCancel: { value: '등록 취소', key: 'mypageGoods.badgeUserCancel' },
    badgeDone: { value: '등록 완료', key: 'mypageGoods.badgeDone' },
    badgeAdminConfirm: { value: '승인 완료', key: 'mypageGoods.badgeAdminConfirm' },
    notFound: { value: '영문 데이터 에러', key: 'mypageGoods.notFound' },
    errImage: { value: '이미지를 1개 이상 입력하여 주십시오.', key: 'mypageGoods.errImage' },
    errNm: { value: '영문 이름을 입력하여 주십시오.', key: 'mypageGoods.errNm' },
    errCategoryId: { value: '카테고리를 지정하여 주십시오.', key: 'mypageGoods.errCategoryId' },
    errContents: { value: '상품 소개를 입력하여 주십시오.', key: 'mypageGoods.errContents' },
    errPrice: { value: '상품 가격을 입력하여 주십시오.', key: 'mypageGoods.errPrice' },
    chargedPoint: { value: '등록비', key: 'mypageGoods.chargedPoint' },
    function: { value: '기능', key: 'mypageGoods.function' },
    search: { value: '검색', key: 'mypageGoods.search' },
    language: { value: '언어', key: 'mypageGoods.language' },
    searchResult: { value: '검색 결과', key: 'mypageGoods.searchResult' },
    btnReset: { value: '초기화', key: 'mypageGoods.btnReset' },
    goods: { value: '상품', key: 'mypageGoods.goods' },
    productIdx: { value: '등록번호', key: 'mypageGoods.productIdx' },
    displayGoods: { value: '진열', key: 'mypageGoods.displayGoods' },
    english: { value: '영어 *필수', key: 'mypageGoods.english' },
    vietnamese: { value: '베트남어', key: 'mypageGoods.vietnamese' },
    korean: { value: '한국어', key: 'mypageGoods.korean' },
    negotiable: { value: '협의 가능', key: 'mypageGoods.negotiable' },
    chinese: { value: '중국어', key: 'mypageGoods.chinese' },
    japanese: { value: '일본어', key: 'mypageGoods.japanese' },
    swalToggleGoodsDisplayAllowQuestion: {
      value: '진열하시겠습니까?',
      key: 'mypageGoods.swalToggleGoodsDisplayAllowQuestion'
    },
    swalToggleGoodsDisplayOk: { value: '변경되었습니다.', key: 'mypageGoods.swalToggleGoodsDisplayOk' },
    swalToggleGoodsDisplayDisallowQuestion: {
      value: '진열 해제하시겠습니까?',
      key: 'mypageGoods.swalToggleGoodsDisplayDisallowQuestion'
    },
    badgeIsActivate: { value: '진열 중', key: 'mypageGoods.badgeIsActivate' },
    badgeIsNotActivate: { value: '미진열', key: 'mypageGoods.badgeIsNotActivate' },
    badgePossibleUpdate: { value: '수정 가능', key: 'mypageGoods.badgePossibleUpdate' },
    badgeUnderReview: { value: '심사 중', key: 'mypageGoods.badgeUnderReview' },
    ttUpdate: { value: '상품 수정', key: 'mypageGoods.ttUpdate' },
    ttInvisible: { value: '숨기기', key: 'mypageGoods.ttInvisible' },
    ttVisible: { value: '보이기', key: 'mypageGoods.ttVisible' },
    swalUpdateQuestion: { value: '저장하시겠습니까?', key: 'mypageGoods.swalUpdateQuestion' },
    swalUpdateOk: { value: '수정되었습니다.', key: 'mypageGoods.swalUpdateOk' },
    goodsDetail: { value: '상품 상세 정보', key: 'mypageGoods.goodsDetail' },
    ttConsultant: { value: '담당자 관리', key: 'mypageGoods.ttConsultant' },
    swalCancelGoodsUpdateRequestOk: {
      value: '취소되었습니다.',
      key: 'mypageGoods.swalCancelGoodsUpdateRequestOk'
    },
    swalCancelGoodsUpdateRequestQuestion: {
      value: '상품 업데이트를<br />취소하시겠습니까?',
      key: 'mypageGoods.swalCancelGoodsUpdateRequestQuestion'
    },
    swalAllowGoodsUpdateRequestQuestion: {
      value: '변경된 정보로<br />업데이트 확정하시겠습니까?',
      key: 'mypageGoods.swalAllowGoodsUpdateRequestQuestion'
    },
    ttConfirmCreateGoods: { value: '등록 확정', key: 'mypageGoods.ttConfirmCreateGoods' },
    ttCancelUpdateGoods: { value: '업데이트 취소', key: 'mypageGoods.ttCancelUpdateGoods' },
    swalAllowGoodsUpdateRequestOk: {
      value: '등록되었습니다.',
      key: 'mypageGoods.swalAllowGoodsUpdateRequestOk'
    },
    ttCancelCreateGoods: { value: '등록 취소', key: 'mypageGoods.ttCancelCreateGoods' },
    swalUpdateGoodsQuestion: {
      value: '상품정보 수정 요청하시겠습니까?',
      key: 'mypageGoods.swalUpdateGoodsQuestion'
    },
    ttConfirmUpdateGoods: { value: '업데이트 확정', key: 'mypageGoods.ttConfirmUpdateGoods' },
    swalUpdateGoodsOk: { value: '수정 요청하였습니다.', key: 'mypageGoods.swalUpdateGoodsOk' },
    updateTitle: { value: '상품 수정', key: 'mypageGoods.updateTitle' },
    btnUpdate: { value: '수정 요청', key: 'mypageGoods.btnUpdate' },
    swalAppendFile: {
      value: '파일은 최대 3개까지 등록하실 수 있습니다.',
      key: 'mypageGoods.swalAppendFile'
    },
    additionalInfoDesc: {
      value: '* 상품에 대한 제원을 임의로 추가하실 수 있습니다. (예. 상품 규격, 매 수, 세트 당 개수 등)',
      key: 'mypageGoods.additionalInfoDesc'
    },
    required: { value: '필수', key: 'mypageGoods.required' },
    AppendFileWarningText: {
      value: '* 파일은 최대 3개, 최대 용량 10MB',
      key: 'mypageGoods.AppendFileWarningText'
    },
    titleConsultant: { value: '담당자', key: 'mypageGoods.titleConsultant' },
    less5mb: {
      value: '10MB 이상의 파일은<br />업로드할 수 없습니다.',
      key: 'mypageGoods.less5mb'
    },
    swalUploadFileError: {
      value: '파일 업로드에 실패하였습니다.',
      key: 'mypageGoods.swalUploadFileError'
    },
    email: { value: '이메일', key: 'mypageGoods.email' },
    position: { value: '직책', key: 'mypageGoods.position' },
    nm: { value: '이름', key: 'mypageGoods.nm' },
    tel: { value: '전화번호', key: 'mypageGoods.tel' },
    titleChangedLog: { value: '변경이력', key: 'mypageGoods.titleChangedLog' },
    btnAddConsultant: { value: '담당자 등록', key: 'mypageGoods.btnAddConsultant' },
    alleduCode: { value: 'AllEdu Code', key: 'mypageGoods.alleduCode' },
    swalNoConsultantError: {
      value: '등록된 담당자가 없습니다.<br />담당자를 등록하여 주십시오.',
      key: 'mypageGoods.swalNoConsultantError'
    },
    person: { value: '명', key: 'mypageGoods.person' },
    swalNavigateToGoodsError: {
      value: '미진열된 상품은 볼 수 없습니다.',
      key: 'mypageGoods.swalNavigateToGoodsError'
    },
    value: { value: '개', key: 'mypageGoods.value' },
    titleQuill: { value: '상품 상세페이지 입력', key: 'mypageGoods.titleQuill' },
    progRequestedTooltip: {
      value: '[기관 담당자] : 상품등록',
      key: 'mypageGoods.progRequestedTooltip'
    },
    progRequested: { value: '상품등록', key: 'mypageGoods.progRequested' },
    progConfirm: { value: '승인완료', key: 'mypageGoods.progConfirm' },
    progConfirmTooltip: {
      value: '[올에듀샵 운영자] 등록 상품 심사 진행중',
      key: 'mypageGoods.progConfirmTooltip'
    },
    progUserConfirm: { value: '등록확정', key: 'mypageGoods.progUserConfirm' },
    progUserConfirmTooltip: {
      value: '[기관 담당자]  우측 기능:  <Check/> 등록확정 (클릭)',
      key: 'mypageGoods.progUserConfirmTooltip'
    },
    progShow: { value: '상품진열', key: 'mypageGoods.progShow' },
    progShowTooltip: {
      value: '[기관 담당자] 우측 기능 :  <Menu/> 열기 (클릭) → 우측 하단 : <Eye/> 보이기 (클릭)',
      key: 'mypageGoods.progShowTooltip'
    },
    progDone: { value: '등록완료', key: 'mypageGoods.progDone' },
    progAdminCancel: { value: '반려', key: 'mypageGoods.progAdminCancel' },
    progAdminCancelTooltip: {
      value: '[기관 담당자] 우측 기능 : <SpellCheck/> 반려 사유 (확인) →<SpellCheck/> 상품 수정 재등록 (클릭)',
      key: 'mypageGoods.progAdminCancelTooltip'
    },
    progUpdate: { value: '재등록', key: 'mypageGoods.progUpdate' }
  },
  mypageJoin: {
    logo: { value: '기관 로고', key: 'mypageJoin.logo' },
    addr: { value: '주소', key: 'mypageJoin.addr' },
    catchphrase: { value: '기관 소개', key: 'mypageJoin.catchphrase' },
    hp: { value: '홈페이지', key: 'mypageJoin.hp' },
    member: { value: '직원 수', key: 'mypageJoin.member' },
    foundation: { value: '설립일', key: 'mypageJoin.foundation' },
    nation: { value: '국가', key: 'mypageJoin.nation' },
    logoDesc: { value: '* 500 X 500 이미지 권장', key: 'mypageJoin.logoDesc' },
    nm: { value: '기관명', key: 'mypageJoin.nm' },
    phLogo: { value: '이미지 선택', key: 'mypageJoin.phLogo' },
    btnUpload: { value: '업로드', key: 'mypageJoin.btnUpload' },
    companyType: { value: '기관 형태', key: 'mypageJoin.companyType' },
    ceo: { value: '대표자명', key: 'mypageJoin.ceo' },
    nationSales: { value: '국가별 매출', key: 'mypageJoin.nationSales' },
    nationUsage: { value: '국가별 사용 인원', key: 'mypageJoin.nationUsage' },
    category: { value: '업종', key: 'mypageJoin.category' },
    totalSales: { value: '총매출', key: 'mypageJoin.totalSales' },
    usage: { value: '사용 인원수', key: 'mypageJoin.usage' },
    sales: { value: '매출액', key: 'mypageJoin.sales' },
    profit: { value: '순이익', key: 'mypageJoin.profit' },
    year: { value: '년도', key: 'mypageJoin.year' },
    totalSalesDesc: {
      value: '* $1 US 달러 단위로 입력하여 주십시오.',
      key: 'mypageJoin.totalSalesDesc'
    },
    nationSalesDesc: {
      value: '* $1 US 달러 단위로 입력하여 주십시오.',
      key: 'mypageJoin.nationSalesDesc'
    },
    nationUsageDesc: { value: '* 1명 단위로 입력하여 주십시오.', key: 'mypageJoin.nationUsageDesc' },
    btnJoin: { value: '회원가입', key: 'mypageJoin.btnJoin' },
    errCompanyTypeId: { value: '기관 형태를 입력하여 주십시오.', key: 'mypageJoin.errCompanyTypeId' },
    errLogo: { value: '기관 로고를 입력하여 주십시오.', key: 'mypageJoin.errLogo' },
    errNationCode: { value: '국가를 선택하여 주십시오.', key: 'mypageJoin.errNationCode' },
    errFoundation: { value: '설립일을 선택하여 주십시오.', key: 'mypageJoin.errFoundation' },
    errNm: { value: '기관명을 모두 입력하여 주십시오.', key: 'mypageJoin.errNm' },
    errHp: { value: '홈페이지 주소를 입력하여 주십시오.', key: 'mypageJoin.errHp' },
    errCeo: { value: '대표자명을 모두 입력하여 주십시오.', key: 'mypageJoin.errCeo' },
    errCatchphrase: { value: '기관 소개를 모두 입력하여 주십시오.', key: 'mypageJoin.errCatchphrase' },
    errCategory: { value: '업종을 모두 입력하여 주십시오.', key: 'mypageJoin.errCategory' },
    errAddr: { value: '주소를 모두 입력하여 주십시오.', key: 'mypageJoin.errAddr' },
    errPriceUnit: { value: '화폐단위를 입력하여 주십시오.', key: 'mypageJoin.errPriceUnit' },
    errMember: { value: '직원 수를 입력하여 주십시오.', key: 'mypageJoin.errMember' },
    dupNationUsage: {
      value: '국가별 사용 인원에<br />중복된 국가가 있습니다.',
      key: 'mypageJoin.dupNationUsage'
    },
    dupNationSales: {
      value: '국가별 판매량에<br />중복된 국가가 있습니다.',
      key: 'mypageJoin.dupNationSales'
    },
    joinSuccess: {
      value: '신청 완료<br/>관리자 심사 후<br />기관 등록이 완료됩니다.',
      key: 'mypageJoin.joinSuccess'
    },
    askJoin: { value: '회원 가입하시겠습니까?', key: 'mypageJoin.askJoin' },
    askUpdate: { value: '업데이트하시겠습니까?', key: 'mypageJoin.askUpdate' },
    dupTotalSales: { value: '총매출액에 중복된 연도가 있습니다.', key: 'mypageJoin.dupTotalSales' },
    updateSuccess: {
      value: '신청 완료<br/>관리자 심사 후<br />기관 정보 수정이 완료됩니다.',
      key: 'mypageJoin.updateSuccess'
    },
    errHasCompany: { value: '이미 신청되어 있습니다.', key: 'mypageJoin.errHasCompany' },
    introduceYoutubeUrl: {
      value: '소개 영상(Youtube 등 링크 경로)',
      key: 'mypageJoin.introduceYoutubeUrl'
    },
    btnEdit: { value: '수정하기', key: 'mypageJoin.btnEdit' },
    required: { value: '(*필수)', key: 'mypageJoin.required' },
    companyDetail: { value: '기관 상세 정보', key: 'mypageJoin.companyDetail' },
    attachment: { value: '기관 첨부파일', key: 'mypageJoin.attachment' },
    update: { value: '업데이트', key: 'mypageJoin.update' },
    uploadMulti: {
      value: '여러 개의 파일을<br />업로드하실 수 있습니다.',
      key: 'mypageJoin.uploadMulti'
    },
    vietnamese: { value: '베트남어', key: 'mypageJoin.vietnamese' },
    chinese: { value: '중국어', key: 'mypageJoin.chinese' },
    japanese: { value: '일본어', key: 'mypageJoin.japanese' },
    korean: { value: '한국어', key: 'mypageJoin.korean' },
    english: { value: '영어 *필수', key: 'mypageJoin.english' },
    companyTypeOne: { value: '개인사업자', key: 'mypageJoin.companyTypeOne' },
    companyTypeTwo: { value: '중소/중견기업', key: 'mypageJoin.companyTypeTwo' },
    companyTypeFour: { value: '비영리법인', key: 'mypageJoin.companyTypeFour' },
    companyTypeSix: { value: '유아/유치원', key: 'mypageJoin.companyTypeSix' },
    companyTypeThree: { value: '대기업', key: 'mypageJoin.companyTypeThree' },
    companyTypeFive: { value: '공공기관', key: 'mypageJoin.companyTypeFive' },
    companyTypeSeven: { value: '초등학교', key: 'mypageJoin.companyTypeSeven' },
    companyTypeEight: { value: '중학교', key: 'mypageJoin.companyTypeEight' },
    companyTypeTen: { value: '대학교', key: 'mypageJoin.companyTypeTen' },
    companyTypeEleven: { value: '직업학교', key: 'mypageJoin.companyTypeEleven' },
    companyTypeNine: { value: '고등학교', key: 'mypageJoin.companyTypeNine' },
    companyTypeThirteen: { value: '기타', key: 'mypageJoin.companyTypeThirteen' },
    companyTypeTwelve: { value: '평생교육기관', key: 'mypageJoin.companyTypeTwelve' },
    companyNameNoStartWithEnglish: {
      value: '기관명은 영문으로 시작하여야 합니다.',
      key: 'mypageJoin.companyNameNoStartWithEnglish'
    },
    btnDelete: { value: '삭제하기', key: 'mypageJoin.btnDelete' },
    btnAdd: { value: '추가하기', key: 'mypageJoin.btnAdd' },
    errCompanyDetail: {
      value: '기관 상세 설명을 입력하여 주십시오.',
      key: 'mypageJoin.errCompanyDetail'
    },
    filename: { value: '파일명', key: 'mypageJoin.filename' }
  },
  swal: {
    toHome: { value: '홈으로', key: 'swal.toHome' },
    notFound: { value: '페이지를 찾을 수 없습니다.', key: 'swal.notFound' },
    alreadyJoin: { value: '이미 가입된 이메일입니다.', key: 'swal.alreadyJoin' },
    needLogin: { value: '로그인이 필요한 서비스입니다.', key: 'swal.needLogin' },
    chkIds: { value: '이메일과 비밀번호를<br />확인하여 주십시오.', key: 'swal.chkIds' },
    logout: { value: '로그아웃하시겠습니까?', key: 'swal.logout' },
    preparing: { value: '기능 준비중 입니다.', key: 'swal.preparing' },
    needLoginToActivateLogin: {
      value: '로그인을 하셔야 만 담당자 정보를 조회 하실 수 있습니다.',
      key: 'swal.needLoginToActivateLogin'
    },
    btnLogin: { value: '로그인', key: 'swal.btnLogin' },
    btnClose: { value: '닫기', key: 'swal.btnClose' }
  },
  mypageMngGoods: {
    tabsMngGoods: { value: '상품 관리', key: 'mypageMngGoods.tabsMngGoods' },
    tabsDashboard: { value: '대시보드', key: 'mypageMngGoods.tabsDashboard' },
    requestDone: { value: '요청 완료하였습니다.', key: 'mypageMngGoods.requestDone' },
    tabsGoodsDisplay: { value: '진열 관리', key: 'mypageMngGoods.tabsGoodsDisplay' },
    tabsGoodsCreate: { value: '상품 등록', key: 'mypageMngGoods.tabsGoodsCreate' },
    updateGoods: { value: '상품 수정', key: 'mypageMngGoods.updateGoods' },
    askUpdateRequest: { value: '업데이트 요청하겠습니까?', key: 'mypageMngGoods.askUpdateRequest' },
    askCancelRegister: { value: '등록 취소하시겠습니까?', key: 'mypageMngGoods.askCancelRegister' },
    tabsGoodsUpdate: { value: '상품 수정', key: 'mypageMngGoods.tabsGoodsUpdate' },
    infoChargeFee: { value: '등록비용이 청구됩니다.', key: 'mypageMngGoods.infoChargeFee' },
    doneRegister: { value: '등록되었습니다.', key: 'mypageMngGoods.doneRegister' },
    btnSave: { value: '저장', key: 'mypageMngGoods.btnSave' },
    askRegister: { value: '등록하시겠습니까?', key: 'mypageMngGoods.askRegister' },
    notAvailable: { value: '등록된 상품이 없습니다.', key: 'mypageMngGoods.notAvailable' },
    doneCancel: { value: '취소되었습니다.', key: 'mypageMngGoods.doneCancel' },
    userBalance: {
      value: '고객님의 잔고는 {{balance}}입니다.',
      key: 'mypageMngGoods.userBalance'
    },
    tbRegisterNumb: { value: '등록번호', key: 'mypageMngGoods.tbRegisterNumb' },
    tbMemo: { value: '알림 메시지', key: 'mypageMngGoods.tbMemo' },
    tbGoodsStatus: { value: '상태', key: 'mypageMngGoods.tbGoodsStatus' },
    createGoodsList: { value: '신규 상품 승인 현황', key: 'mypageMngGoods.createGoodsList' },
    tbGoodsNm: { value: '상품명', key: 'mypageMngGoods.tbGoodsNm' },
    noRequest: { value: '승인현황이 없습니다.', key: 'mypageMngGoods.noRequest' },
    tbFee: { value: '등록 포인트', key: 'mypageMngGoods.tbFee' },
    btnCancel: { value: '취소', key: 'mypageMngGoods.btnCancel' },
    btnRegister: { value: '등록', key: 'mypageMngGoods.btnRegister' },
    btnUpdate: { value: '수정', key: 'mypageMngGoods.btnUpdate' },
    tbOk: { value: '확인', key: 'mypageMngGoods.tbOk' },
    lineAdmin: { value: '관리자', key: 'mypageMngGoods.lineAdmin' },
    lineRegister: { value: '등록', key: 'mypageMngGoods.lineRegister' },
    lineCompany: { value: '기관', key: 'mypageMngGoods.lineCompany' },
    requestUpdateRequest: {
      value: '상품 정보 업데이트 현황',
      key: 'mypageMngGoods.requestUpdateRequest'
    },
    lineDone: { value: '등록 완료', key: 'mypageMngGoods.lineDone' },
    askDeleteGoods: { value: '상품을 삭제하시겠습니까?', key: 'mypageMngGoods.askDeleteGoods' },
    noHasUpdateRequest: {
      value: '업데이트 요청이 없습니다.',
      key: 'mypageMngGoods.noHasUpdateRequest'
    },
    askShowGoods: { value: '숨김을 해지하시겠습니까?', key: 'mypageMngGoods.askShowGoods' },
    userGoods: {
      value: '고객님의 상품은 {{goods}}개입니다.',
      key: 'mypageMngGoods.userGoods'
    },
    findOptions: { value: '검색 조건', key: 'mypageMngGoods.findOptions' },
    askDeleteGoodsInfo: { value: '되돌릴 수 없습니다!', key: 'mypageMngGoods.askDeleteGoodsInfo' },
    askHideGoods: { value: '상품을 숨기시겠습니까?', key: 'mypageMngGoods.askHideGoods' },
    goods: { value: '등록상품', key: 'mypageMngGoods.goods' },
    btnInit: { value: '초기화', key: 'mypageMngGoods.btnInit' },
    btnCreateGoods: { value: '상품 등록', key: 'mypageMngGoods.btnCreateGoods' },
    btnFind: { value: '찾기', key: 'mypageMngGoods.btnFind' },
    tbImage: { value: '이미지', key: 'mypageMngGoods.tbImage' },
    tbGoodsNumber: { value: '상품번호', key: 'mypageMngGoods.tbGoodsNumber' },
    tbNmCateg: { value: '이름/카테고리', key: 'mypageMngGoods.tbNmCateg' },
    tbPrice: { value: '가격', key: 'mypageMngGoods.tbPrice' },
    tbFunc: { value: '기능', key: 'mypageMngGoods.tbFunc' },
    btnShow: { value: '보이기(진열)', key: 'mypageMngGoods.btnShow' },
    btnHide: { value: '숨기기(미진열)', key: 'mypageMngGoods.btnHide' },
    btnDelete: { value: '삭제', key: 'mypageMngGoods.btnDelete' },
    badgeOk: { value: '정상', key: 'mypageMngGoods.badgeOk' },
    badgeBan: { value: '관리자 정지', key: 'mypageMngGoods.badgeBan' },
    badgeHide: { value: '숨김', key: 'mypageMngGoods.badgeHide' },
    badgeRequest: { value: '심사 대기', key: 'mypageMngGoods.badgeRequest' },
    goodsList: { value: '상품 목록', key: 'mypageMngGoods.goodsList' }
  },
  nation: { wholeList: { value: '전체 보기', key: 'nation.wholeList' } },
  system: {
    shutdownDesc: {
      value: '서비스에 불편을 드려 죄송힙니다.<br/>점검 시간 동안<br />서비스 이용이 제한되는 점<br />양해 부탁드립니다.<br/>감사합니다.',
      key: 'system.shutdownDesc'
    },
    shutdown: { value: '서비스 점검 중입니다.', key: 'system.shutdown' }
  },
  board: {
    asklist: { value: '문의 목록', key: 'board.asklist' },
    answerlist: { value: '답변 목록', key: 'board.answerlist' },
    swalAskStatusDone: { value: '답변을 완료 하시겠습니까?', key: 'board.swalAskStatusDone' },
    swalAskStatusReask: { value: '재진행 하시겠습니까?', key: 'board.swalAskStatusReask' },
    swalChangeStatusDone: { value: '변경 되었습니다.', key: 'board.swalChangeStatusDone' },
    titleAnswerList: { value: '답변 목록', key: 'board.titleAnswerList' },
    statusProceeding: { value: '진행 중', key: 'board.statusProceeding' },
    statusComplete: { value: '완료', key: 'board.statusComplete' },
    number: { value: '번호', key: 'board.number' },
    createDate: { value: '등록일', key: 'board.createDate' },
    status: { value: '상태', key: 'board.status' },
    subject: { value: '제목', key: 'board.subject' },
    productName: { value: '상품', key: 'board.productName' },
    userName: { value: '문의기관', key: 'board.userName' },
    statusReask: { value: '재진행', key: 'board.statusReask' },
    badgeAnswer: { value: '답변', key: 'board.badgeAnswer' },
    badgeQuestion: { value: '문의', key: 'board.badgeQuestion' },
    inquirer: { value: '문의기관', key: 'board.inquirer' },
    ttProductLink: { value: '상품 바로가기', key: 'board.ttProductLink' },
    contact: { value: '연락처 (필요시)', key: 'board.contact' },
    attachment: { value: '파일 첨부', key: 'board.attachment' },
    titleReply: { value: '댓글', key: 'board.titleReply' },
    userAdmin: { value: '답변', key: 'board.userAdmin' },
    btnAdd: { value: '추가', key: 'board.btnAdd' },
    btnFile: { value: '파일', key: 'board.btnFile' },
    ttDelete: { value: '삭제', key: 'board.ttDelete' },
    swalBlankContent: { value: '내용을 입력하여 주십시오.', key: 'board.swalBlankContent' },
    swalCreateReply: { value: '추가 하시겠습니까?', key: 'board.swalCreateReply' },
    swalCreateReplyOk: { value: '추가 되었습니다.', key: 'board.swalCreateReplyOk' },
    swalDeleteReply: { value: '삭제 하시겠습니까?', key: 'board.swalDeleteReply' },
    swalDeleteReplyOk: { value: '삭제 되었습니다.', key: 'board.swalDeleteReplyOk' },
    companyName: { value: '기관명', key: 'board.companyName' },
    badgeWaiting: { value: '대기', key: 'board.badgeWaiting' },
    swalBlankReplyContent: { value: '댓글 내용을 입력하여 주십시오.', key: 'board.swalBlankReplyContent' },
    swalUpdateDone: { value: '문의를 완료 하시겠습니까?', key: 'board.swalUpdateDone' },
    swalUpdateDoneOk: { value: '완료 되었습니다.', key: 'board.swalUpdateDoneOk' },
    swalNavigateGoods: {
      value: '상품의 정보가 갱신되었습니다. <br />최신 정보로 조회 하시겠습니까?',
      key: 'board.swalNavigateGoods'
    },
    swalChangeContact: { value: '수정할 연락처를 입력하여 주십시오.', key: 'board.swalChangeContact' },
    titleQuestion: { value: '문의', key: 'board.titleQuestion' },
    companyEmail: { value: '이메일', key: 'board.companyEmail' },
    ttModify: { value: '수정', key: 'board.ttModify' },
    userUser: { value: '문의', key: 'board.userUser' },
    btnDelete: { value: '삭제', key: 'board.btnDelete' },
    swalBlankTitle: { value: '제목을 입력하여 주십시오.', key: 'board.swalBlankTitle' },
    swalApplyQuestion: { value: '문의 하시겠습니까?', key: 'board.swalApplyQuestion' },
    swalApplyQuestionOK: { value: '문의가 등록되었습니다.', key: 'board.swalApplyQuestionOK' },
    questionDescription: {
      value: '* 문의 사항은 [1:1문의 > 문의 목록] 에서 확인 가능합니다.',
      key: 'board.questionDescription'
    },
    titleContent: { value: '문의 내용', key: 'board.titleContent' },
    swalBlankContact: { value: '연락처를 입력하여 주십시오.', key: 'board.swalBlankContact' },
    noAttachment: { value: '등록된 첨부파일이 없습니다.', key: 'board.noAttachment' },
    btnAddFile: { value: '파일선택', key: 'board.btnAddFile' },
    btnApplyQuestion: { value: '문의등록', key: 'board.btnApplyQuestion' },
    cancelAskBoardModal: { value: '문의취소', key: 'board.cancelAskBoardModal' },
    askBoardModalContactInfo: {
      value: '* 긴급 연락처 (이메일, 전화)를 남겨 문의 하실 수 있습니다.',
      key: 'board.askBoardModalContactInfo'
    },
    askBoardModalFileSizeInfo: {
      value: '* 10MB 이하의 파일을 최대 3개까지 첨부 가능하며 3개 초과인 경우 압축해서 올려 주시기 바랍니다.',
      key: 'board.askBoardModalFileSizeInfo'
    },
    askBoardFileExtensionInfo: {
      value: '* 파일 첨부 가능 확장자 : jpg, png, svg, pdf, zip',
      key: 'board.askBoardFileExtensionInfo'
    },
    askBoardAskDelete: { value: '삭제 하시겠습니까?', key: 'board.askBoardAskDelete' },
    askBoardMaxFileLength: {
      value: '파일은 최대 3개까지 첨부 가능합니다.',
      key: 'board.askBoardMaxFileLength'
    },
    replyWriterCompany: { value: '답변', key: 'board.replyWriterCompany' },
    replyWriterUser: { value: '문의', key: 'board.replyWriterUser' },
    btnHistoryBack: { value: '돌아가기', key: 'board.btnHistoryBack' },
    askBoardStatusInProcessing: { value: '문의중', key: 'board.askBoardStatusInProcessing' },
    askBoardStatusDone: { value: '완료', key: 'board.askBoardStatusDone' },
    attachFileListTitle: { value: '첨부파일', key: 'board.attachFileListTitle' }
  },
  chat: {
    tabAsk: { value: '문의', key: 'chat.tabAsk' },
    tabReply: { value: '답변', key: 'chat.tabReply' },
    placeholderSearchInput: { value: '기관명 또는 상품명', key: 'chat.placeholderSearchInput' },
    swalInputSearchError: { value: '검색어를 입력하여 주십시오.', key: 'chat.swalInputSearchError' },
    searchBarBtnInit: { value: '초기화', key: 'chat.searchBarBtnInit' },
    searchBarBtnFind: { value: '검색', key: 'chat.searchBarBtnFind' },
    chatTitle: { value: '채팅', key: 'chat.chatTitle' },
    clickChatRoom: { value: '채팅방을 선택하여 주십시오.', key: 'chat.clickChatRoom' },
    loadPrevChat: { value: '이전 기록', key: 'chat.loadPrevChat' },
    btnSend: { value: '전송', key: 'chat.btnSend' },
    btnFile: { value: '파일', key: 'chat.btnFile' },
    askUser: { value: '문의자', key: 'chat.askUser' },
    replyCompany: { value: '기관', key: 'chat.replyCompany' },
    goods: { value: '상품', key: 'chat.goods' },
    chatRoleUser: { value: '문의자', key: 'chat.chatRoleUser' },
    chatRoleCompany: { value: '기관', key: 'chat.chatRoleCompany' },
    chatRoleConsultant: { value: '담당자', key: 'chat.chatRoleConsultant' },
    chatUserTitle: { value: '채팅 참여자', key: 'chat.chatUserTitle' },
    askEnterChatRoom: {
      value: '이전에 채팅을 진행한 기록이 있습니다. 원래 있던 방으로 입장하시겠습니까?',
      key: 'chat.askEnterChatRoom'
    },
    createChatRoomBtn: { value: '새 방 열기', key: 'chat.createChatRoomBtn' },
    enterChatRoomBtn: { value: '입장', key: 'chat.enterChatRoomBtn' },
    noData: { value: '채팅방이 없습니다.', key: 'chat.noData' }
  },
  companyConsultant: {
    checkDuplicateEmail: {
      value: '이메일 중복확인을 하여 주십시오.',
      key: 'companyConsultant.checkDuplicateEmail'
    },
    checkPassword: {
      value: '비밀번호는 8글자 이상 입력하여 주십시오.',
      key: 'companyConsultant.checkPassword'
    },
    notSamePassword: {
      value: '비밀번호가 일치하지 않습니다.',
      key: 'companyConsultant.notSamePassword'
    },
    questionCreateAccount: {
      value: '등록 하시겠습니까?',
      key: 'companyConsultant.questionCreateAccount'
    },
    checkEmail: {
      value: '이메일 형식을 확인하여 주십시오.',
      key: 'companyConsultant.checkEmail'
    },
    emailAlreadyRegistered: {
      value: '이미 등록된 이메일 입니다.',
      key: 'companyConsultant.emailAlreadyRegistered'
    },
    availableEmail: {
      value: '등록 가능한 이메일 입니다.',
      key: 'companyConsultant.availableEmail'
    },
    btnAddConsultant: { value: '채팅 담당자로 등록', key: 'companyConsultant.btnAddConsultant' },
    btnRegisterConsultant: {
      value: '채팅 관리자 등록',
      key: 'companyConsultant.btnRegisterConsultant'
    },
    registerDone: { value: '등록 되었습니다.', key: 'companyConsultant.registerDone' },
    placeholderEmail: {
      value: '이메일을 입력하여 주십시오.',
      key: 'companyConsultant.placeholderEmail'
    },
    btnCheckDuplication: { value: '중복 확인', key: 'companyConsultant.btnCheckDuplication' },
    placeholderPassword: {
      value: '비밀 번호를 입력하여 주십시오.',
      key: 'companyConsultant.placeholderPassword'
    },
    placeholderConfirm: {
      value: '비밀 번호를 다시 입력하여 주십시오.',
      key: 'companyConsultant.placeholderConfirm'
    },
    infoCheckEmail: {
      value: '* 이메일 중복 확인을 하여 주십시오.',
      key: 'companyConsultant.infoCheckEmail'
    },
    email: { value: '이메일', key: 'companyConsultant.email' },
    btnRegister: { value: '등록', key: 'companyConsultant.btnRegister' },
    labelPassword: { value: '비밀번호', key: 'companyConsultant.labelPassword' },
    labelConfirm: { value: '비밀번호 (확인)', key: 'companyConsultant.labelConfirm' }
  },
  terms: {
    terms: { value: '이용약관', key: 'terms.terms' },
    privacy: { value: '개인정보취급방침', key: 'terms.privacy' }
  }
}