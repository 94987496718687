import React, { useState } from 'react';
import { gql, useMutation, useQuery, useLazyQuery } from "@apollo/client";
import {
    GoodsFindOptions,
    Mutation,
    MutationAllowCreateGoodsRequestArgs,
    MutationAllowUpdateGoodsRequestArgs,
    MutationCancelCreateGoodsRequestArgs, 
    MutationCancelUpdateGoodsRequestArgs,
    MutationToggleGoodsDisplayArgs,
    MutationRequestGoodsCertificateArgs,
    Query,
} from "../../../../graphql/types";
import { MyPageLayout } from "../../layout/MyPageLayout";
import { FadeIn } from "../../../shares/fadeIn/FadeIn";
import { useSelector } from "react-redux";
import { I18nState } from "../../../../redux/I18n";
import { Pager } from "../../../shares/pager/Pager";
import { BizSmTitle, BizTitle } from "../../../shares/bizTitle/BizTitle";
import { MyPageGoodsListFindOptions } from "./findOptions/MyPageGoodsListFindOptions";
import { navigate } from "hookrouter";
import { ApolloCatch } from "../../../../utils/apollo/apolloCatch";
import { MyPageGoodsListResult } from "./result/MyPageGoodsListResult";
import { useTranslation } from "react-i18next";
import { SwalUtil } from "../../../../utils/swal/swalUtil";
import { BizGoodsModal, BizGoodsModalData } from "../../../shares/bizGoodsModal/BizGoodsModal";
import { BizErrors } from "../../../../graphql/errors";
import { UserState } from "../../../../redux/User";
import { MyPageGoodsRequestCreateList } from "../request/create/MyPageGoodsRequestCreateList";
import { MyPageGoodsRequestUpdate } from "../request/update/MyPageGoodsRequestUpdate";
import { I18nKeys } from "../../../../i18n/i18nKeys";


export const MyPageGoodsList = (props: {
    goodsPage: number,
    createPage: number,
    updatePage: number,
}) => {
    const [t] = useTranslation('common');
    const i18n = useSelector<any, I18nState>(state => state.i18n);
    const user = useSelector<any, UserState>(state => state.user);
    const [findOptions, setFindOptions] = useState<GoodsFindOptions>({
        langCode: i18n.langCode,
    });

    const { data, refetch } = useQuery<Query>(gqlData, {
        variables: {
            goodsPage: props.goodsPage,
            size: 20,
            options: {
                langCode: i18n.langCode,
            },
            createPage: props.createPage,
            updatePage: props.updatePage,
        }
    });

    const [mutToggleGoodsDisplay] = useMutation<Mutation, MutationToggleGoodsDisplayArgs>(gqlToggleGoodsDisplay);
    const [mutAllowCreate] = useMutation<Mutation, MutationAllowCreateGoodsRequestArgs>(gqlAllowCreateGoodsRequest);
    const [mutCancelCreate] = useMutation<Mutation, MutationCancelCreateGoodsRequestArgs>(gqlCancelCreateGoodsRequest);
    const [mutAllowUpdate] = useMutation<Mutation, MutationAllowUpdateGoodsRequestArgs>(gqlAllowUpdateGoodsRequest);
    const [mutCancelUpdate] = useMutation<Mutation, MutationCancelUpdateGoodsRequestArgs>(gqlCancelUpdateGoodsRequest);
    const [goodsData, setGoodsData] = useState<BizGoodsModalData | undefined>();

    const [certificateFilesCheck] = useLazyQuery(gqlCheckCertificateFiles, {
        onCompleted: (data) => {
            let checkResult = data.checkCertificateFiles;
            let isExists = checkResult.result;
            if(isExists) {
                SwalUtil.yn({
                    msg: t("mypageGoods.swalCertificateRequestConfirm"),
                    icon: 'question',
                    ok: () => {
                        mutRequestGoodsCertificate({
                            variables: checkResult
                        }).then(result => {
                           let isSucceeded = result.data?.requestGoodsCertificate;
                           if(isSucceeded) {
                                SwalUtil.ok({
                                    msg: t("mypageGoods.swalCertificateRequestSuccess"),
                                    icon: 'success',
                                });
                           }
                        }).catch(ApolloCatch({}));
                    }
                });
            } else {
                SwalUtil.yn({
                    msg: t("mypageGoods.swalCertificateFileRegisterRequest"),
                    icon: 'question',
                    ok: () => {
                        navigate(`/mypage/info/list`);
                    }
                });
            }
        }
    });
    const [mutRequestGoodsCertificate] = useMutation<Mutation, MutationRequestGoodsCertificateArgs>(gqlRequestGoodsCertificate);

    const handler = {
        onChangeGoodsPage: (page: number) => {
            navigate(`/mypage/goods/list/${page}/${props.createPage}/${props.updatePage}`);
        },
        onFind: () => {
            refetch({
                page: 0,
                size: 20,
                options: findOptions
            }).catch(ApolloCatch({}));
        },
        onInit: () => {
            refetch({
                page: 0,
                size: 20,
                options: {
                    langCode: findOptions.langCode
                }
            }).catch(ApolloCatch({}));

            setFindOptions({
                langCode: findOptions.langCode
            })
        },
        onToggleGoodsDisplay: (args: MutationToggleGoodsDisplayArgs) => {
            SwalUtil.yn({
                msg: args.swt ? t("mypageGoods.swalToggleGoodsDisplayAllowQuestion") : t("mypageGoods.swalToggleGoodsDisplayDisallowQuestion"),
                icon: 'question',
                ok: () => {
                    mutToggleGoodsDisplay({
                        variables: args
                    }).then(() => {
                        return refetch()
                    }).then(() => {
                        SwalUtil.ok({
                            msg: t("mypageGoods.swalToggleGoodsDisplayOk"),
                            icon: 'success'
                        })
                    }).catch(ApolloCatch({
                        [BizErrors.noHasConsultant]: SwalUtil.apolloCatch({
                            msg: t("mypageGoods.swalNoConsultantError"),
                            icon: 'error'
                        })
                    }));
                }
            })
        },
        onShowGoodsData: (args: BizGoodsModalData) => {
            setGoodsData(args);
        },
        onCloseModal: () => {
            setGoodsData(undefined);
        },
        onChangeCreatePage: (page: number) => {
            navigate(`/mypage/goods/list/${props.goodsPage}/${page}/${props.updatePage}`);
        },
        onChangeUpdatePage: (page: number) => {
            navigate(`/mypage/goods/list/${props.goodsPage}/${props.createPage}/${page}`);
        },
        onAllowGoodsCreateRequest: (args: MutationAllowCreateGoodsRequestArgs) => {
            SwalUtil.yn({
                msg: t('mypageGoods.askCreateGoods'),
                icon: 'question',
                ok: () => {
                    mutAllowCreate({
                        variables: args
                    }).then(() => {
                        return refetch();
                    }).catch(ApolloCatch({
                        [BizErrors.notEnoughPoint]: SwalUtil.apolloCatch({
                            msg: t('mypageGoods.notEnoughPoint'),
                            icon: 'error'
                        })
                    }))
                }
            })

        },
        onCancelGoodsCreateRequest: (args: MutationCancelCreateGoodsRequestArgs) => {
            SwalUtil.yn({
                msg: t('mypageGoods.askCancelRegistryGoods'),
                icon: 'question',
                ok: () => {
                    mutCancelCreate({
                        variables: args
                    }).then(() => {
                        return refetch({ ...props, userId: user.user.id });
                    }).catch(ApolloCatch({}));
                }
            })
        },
        onCancelGoodsUpdateRequest: (args: MutationCancelUpdateGoodsRequestArgs) => {
            SwalUtil.yn({
                msg: t("mypageGoods.swalCancelGoodsUpdateRequestQuestion"),
                icon: 'question',
                ok: () => {
                    mutCancelUpdate({
                        variables: args
                    }).then(() => {
                        return refetch()
                    }).then(() => {
                        SwalUtil.ok({
                            msg: t("mypageGoods.swalCancelGoodsUpdateRequestOk"),
                            icon: 'success'
                        });
                    }).catch(ApolloCatch({}));
                }
            })

        },
        onAllowGoodsUpdateRequest: (args: MutationAllowUpdateGoodsRequestArgs) => {
            SwalUtil.yn({
                msg: t("mypageGoods.swalAllowGoodsUpdateRequestQuestion"),
                icon: 'question',
                ok: () => {
                    mutAllowUpdate({
                        variables: args
                    }).then(() => {
                        return refetch()
                    }).then(() => {
                        SwalUtil.ok({
                            msg: t("mypageGoods.swalAllowGoodsUpdateRequestOk"),
                            icon: 'success'
                        });
                    }).catch(ApolloCatch({}));
                }
            })
        },
        onRequestGoodsCertificate: (args: MutationRequestGoodsCertificateArgs) => {
            certificateFilesCheck({
                variables: { goodsId: args.goodsId }
            });
        }
    };

    return (
        <>
            <MyPageLayout activatedSuffixRouter={'/goods/list'}>
                {data &&
                    <FadeIn>
                        <BizGoodsModal value={goodsData} onClose={() => setGoodsData(undefined)} />

                        <BizTitle>{t('mypageGoods.createGoods')}</BizTitle>
                        <MyPageGoodsRequestCreateList
                            {...data}
                            {...handler} />
                        <Pager
                            page={data.goodsCreateRequestList.page}
                            size={data.goodsCreateRequestList.size}
                            total={data.goodsCreateRequestList.total}
                            onChange={handler.onChangeCreatePage} />


                        <div style={{ height: 20 }} />
                        <BizTitle>{t('mypageGoods.updateGoods')}</BizTitle>
                        <MyPageGoodsRequestUpdate
                            {...handler}
                            {...data} />
                        <Pager
                            page={data.goodsUpdateRequestList.page}
                            size={data.goodsUpdateRequestList.size}
                            total={data.goodsUpdateRequestList.total}
                            onChange={handler.onChangeUpdatePage} />


                        <BizTitle>{t(I18nKeys.mypageMngGoods.goodsList.key)}</BizTitle>
                        <MyPageGoodsListFindOptions
                            findOptions={findOptions}
                            onChangeFindOptions={setFindOptions}
                            {...handler} />


                        <BizSmTitle>{t("mypageGoods.searchResult")}</BizSmTitle>
                        <MyPageGoodsListResult
                            {...handler}
                            {...data} />


                        <Pager
                            page={data.myGoodsList.page}
                            size={data.myGoodsList.size}
                            total={data.myGoodsList.total}
                            onChange={handler.onChangeGoodsPage} />
                    </FadeIn>
                }
            </MyPageLayout>
        </>
    )
}

const gqlData = gql`
    query DATA ($goodsPage: Int!, $size: Int!, $options: GoodsFindOptions!, $createPage: Int!, $updatePage: Int!) {
        myGoodsList(page: $goodsPage, size: $size, options: $options) {
            page
            size
            total
            list {
                id
                allEduCode
                isActivate
                goodsPermission
                categoryId
                companyUserId
                goodsData
                goodsInfoId
                companyData
                history {
                    goodsInfoId
                    goodsData
                    categoryId
                    createdAt
                }
                consultant {
                    langCode
                    consultant {
                        id
                        isActivate
                        nm
                        tel
                        position
                        email
                        permission
                        createdAt
                    }
                }
                productWarranty
                isCertificated
                certificateBeginDate
                certificateEndDate
            }
        }
        myCompany
        goodsCreateRequestList(page: $createPage, size: 10) {
            page
            size
            total
            list {
                id
                status
                categoryId
                data
                createdAt
                chargedPoint
                goodsId
                memo
            }
        }
        goodsUpdateRequestList(page: $updatePage, size: 10) {
            page
            size
            total
            list {
                id
                status
                categoryId
                data
                chargedPoint
                goodsId
                createdAt
                memo
            }
        }
    }
`;

const gqlToggleGoodsDisplay = gql`
    mutation Toggle ($goodsId: Int!, $swt: Boolean!) {
        toggleGoodsDisplay(goodsId: $goodsId, swt: $swt)
    }
`;

const gqlCancelCreateGoodsRequest = gql`
    mutation Cancel($createGoodsRequestId: Int!) {
        cancelCreateGoodsRequest(createGoodsRequestId: $createGoodsRequestId)
    }
`;

const gqlAllowCreateGoodsRequest = gql`
    mutation Allow($createGoodsRequestId: Int!) {
        allowCreateGoodsRequest(createGoodsRequestId: $createGoodsRequestId)
    }
`;

const gqlCancelUpdateGoodsRequest = gql`
    mutation Cancel($updateGoodsRequestId: Int!) {
        cancelUpdateGoodsRequest(updateGoodsRequestId: $updateGoodsRequestId)
    }
`;

const gqlAllowUpdateGoodsRequest = gql`
    mutation Allow($updateGoodsRequestId: Int!) {
        allowUpdateGoodsRequest(updateGoodsRequestId: $updateGoodsRequestId)
    }
`;

const gqlCheckCertificateFiles = gql`
    query DATA($goodsId: Int!) {
        checkCertificateFiles(goodsId: $goodsId) {
            result
            goodsId
        }
    }
`;

const gqlRequestGoodsCertificate = gql`
    mutation Update($goodsId: Int!) {
        requestGoodsCertificate(goodsId: $goodsId)
    }
`;