import React from 'react';
import {gql, useMutation, useQuery} from "@apollo/client";
import {Mutation, MutationBindConsultantWithGoodsArgs, Query} from "../../../../graphql/types";
import {MyPageLayout} from "../../layout/MyPageLayout";
import {BizTitle} from "../../../shares/bizTitle/BizTitle";
import {BizGoodsDataContents, BizGoodsDataShortInfo} from "../../../shares/bizGoodsDataView/BizGoodsDataView";
import {useSelector} from "react-redux";
import {I18nState} from "../../../../redux/I18n";
import {GoodsDataMapUtil} from "../../../../utils/goodsDataMapUtil/goodsDataMapUtil";
import {InputConsultant} from "../../share/inputs/inputConsultant/InputConsultant";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import {ApolloCatch} from "../../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../../graphql/errors";
import {BizCol12, FadeInContainer} from "../../../shares/fadeInContainer/FadeInContainer";
import {useTranslation} from "react-i18next";

export const MyPageGoodsBindConsultant = (props: {
    goodsId: number,
}) => {
    const [t] = useTranslation('common');

    const {data, refetch} = useQuery<Query, typeof props>(gqlData, {
        variables: props
    });
    const [mutBind] = useMutation<Mutation, MutationBindConsultantWithGoodsArgs>(gqlBind);
    const langCode = useSelector<any, I18nState>(state => state.i18n).langCode;

    const handler = {
        onUpdate: (args: MutationBindConsultantWithGoodsArgs) => {
            SwalUtil.ynPromise({
                msg: t("mypageGoods.swalUpdateQuestion")
            }).then(() => {
                return mutBind({variables: args})
            }).then(() => {
                return refetch();
            }).then(() => {
                SwalUtil.ok({
                    msg: t("mypageGoods.swalUpdateOk"),
                    icon: 'success',
                })
            }).catch(ApolloCatch({
                [BizErrors.swalCancel]: () => {
                },
            }))

        }
    };

    return (
        <MyPageLayout activatedSuffixRouter={'/goods/list'}>
            {data &&
            <FadeInContainer>
                {/*<BizCol12 marginBottom={false}>*/}
                {/*    <BizTitle>{t("mypageGoods.goodsData")}</BizTitle>*/}
                {/*</BizCol12>*/}
                <BizGoodsDataShortInfo
                    disableInteraction
                    goodsData={GoodsDataMapUtil.getGoodsData(langCode, data.myGoods.data)}
                    companyDataMap={data.myGoods.company}
                    categoryId={data.myGoods.categoryId}/>


                <BizCol12>
                    <BizTitle>{t("mypageGoods.consultantData")}</BizTitle>
                    <InputConsultant
                        allConsultant={data.myCompanyConsultant}
                        boundConsultant={data.boundConsultantWithGoods}
                        onSave={v => handler.onUpdate({goodsId: props.goodsId, input: v})}/>

                </BizCol12>


                <BizCol12 marginBottom={false}>
                    <BizTitle>{t("mypageGoods.goodsDetail")}</BizTitle>
                </BizCol12>

                <BizGoodsDataContents
                    goodsData={GoodsDataMapUtil.getGoodsData(langCode, data.myGoods.data)}
                    companyDataMap={data.myGoods.company}
                    categoryId={data.myGoods.categoryId}/>
            </FadeInContainer>
            }
        </MyPageLayout>
    )
}

const gqlData = gql`
    query DATA($goodsId: Int!) {
        myGoods(goodsId: $goodsId) {
            id
            data
            company
            categoryId
        }
        myCompanyConsultant {
            id
            isActivate
            permission
            email
            tel
            position
            nm
            createdAt
        }
        boundConsultantWithGoods(goodsId: $goodsId) {
            langCode
            consultant {
                id
                isActivate
                permission
                email
                tel
                position
                nm
                createdAt
            }
        }
    }
`;

const gqlBind = gql`
    mutation Bind($goodsId: Int!, $input: [BindConsultant!]!) {
        bindConsultantWithGoods(goodsId: $goodsId, input: $input)
    }
`;
