import React from 'react';
import {OnChangeValue} from "../../../../Intefaces";
import {useTranslation} from "react-i18next";
import {GoogleLogin, GoogleLoginResponse} from 'react-google-login';
import {GetEnv} from "../../../../../env/env";
import {CryptoUtil, CryptoUtilPrefix} from "../../../../../utils/crypto/CryptoUtil";
import {JoinType, MutationLoginWithSocialArgs} from "../../../../../graphql/types";

export const AuthLoginGoogle = (props: {
    onLoginWithGoogle: OnChangeValue<MutationLoginWithSocialArgs>
}) => {
    const [t] = useTranslation('common');

    return (
        <>
            <GoogleLogin
                // hostedDomain={`${window.location.protocol}/${window.location.host}`}
                clientId={GetEnv().googleOauthToken}
                onSuccess={user => {
                    const next = user as GoogleLoginResponse;
                    const email = next.getBasicProfile().getEmail();
                    const pw = CryptoUtil.encryptPw(CryptoUtilPrefix.Google, next.googleId);
                    props.onLoginWithGoogle({email, pw, joinType: JoinType.Google})
                }}
                onFailure={error => {
                    console.log(error);
                }}
                render={({onClick}) => (
                    <button
                        type='button'
                        style={{width: '100%', marginBottom: 10}}
                        className='btn btn-outline-secondary'
                        onClick={onClick}>
                        {t('auth.btnGoogle')}
                    </button>
                )}
            />
        </>
    )
}
