import React, {useState} from 'react';
import classNamesBind from "classnames/bind";
import styles from './BizCompanyDataViewGraph.module.scss';
import {ViewSelector} from '../../viewSelector/ViewSelector';
import {CompanyData} from "../../../../graphql/customScalars";
import {useTranslation} from "react-i18next";
import Chart from "react-apexcharts";
import {BizModal} from "../../bizModal/BizModal";
import {Pipe} from "../../../../utils/pipe/pipe";
import NumberFormat from "react-number-format";
import {ApexOptions} from "apexcharts";

const cx = classNamesBind.bind(styles);

interface Props {
    companyData: CompanyData
}

const PieGraphDataLabelOptions = {
    style: {
        colors: ['#000000'],
    },
    dropShadow: {
        enabled: false,
    },
    background: {
        enabled: true,
            opacity: 0.3,
            foreColor: '#ffffff',
            borderWidth: 0,
            dropShadow: {
            enabled: true,
        }
    },
    formatter: (val: number, opt: any): string => {
        const idx = opt.seriesIndex;
        const value = opt.w.globals.series[idx];
        return Pipe.toSeparatorNumber(value);
    }
};

export const BizCompanyDataViewGraph = (props: Props) => {
    return (
        <>
            <ViewSelector
                web={<Web {...props}/>}
                mobile={<Mobile {...props}/>}/>
        </>
    )
}

const Web = (props: Props) => {
    return (
        <div className={cx('cont')}>
            <div className={cx('box')}>
                <CompanyDetailGraphSales {...props}/>
            </div>
            <div className={cx('box')}>
                <CompanyDetailGraphNationSales {...props}/>
            </div>
            <div className={cx('box')}>
                <CompanyDetailGraphNationUsage {...props}/>
            </div>
        </div>
    )
}


const Mobile = (props: Props) => {
    return (
        <><Web {...props}/></>
    )
}


const CompanyDetailGraphNationSales = (props: {
    companyData: CompanyData
}) => {
    const data = props.companyData.nationSales;
    const [showModal, setShowModal] = useState(false);
    const [t] = useTranslation('common');


    const options: ApexOptions = {
        chart: {
            toolbar: {
                show: false,
            },
            animations: {
                enabled: false
            }
        },
        legend: {
            show: true,
            position: 'bottom',
            formatter(legendName: string, opts?: any): string {
                return legendName;
            }
        },
        labels: data.map(value => {
            return `[${value.nationCode.toUpperCase()}] ${Pipe.nationNm(value.nationCode)}`;
        }),
        dataLabels: PieGraphDataLabelOptions,
        tooltip: {
            y: {
                formatter: (val: number): string => {
                    return Pipe.toSeparatorNumber(val);
                }
            }
        }
    }

    const series = data.map(value => {
        return value.sales
    })

    return (
        <>
            <div className={cx('box-btn')}>
                <h5>{t('company.nationSales')}</h5>
                <button
                    className='btn btn-sm btn-outline-secondary'
                    onClick={() => {
                        setShowModal(true);
                    }}>{t('company.table')}
                </button>
            </div>

            {props.companyData.nationSales.length === 0 &&
            <div className={cx('box-nodata')}>{t('company.noChartData')}</div>
            }

            {props.companyData.nationSales.length !== 0 &&
            <Chart
                type='donut'
                options={options}
                series={series}
                width={'100%'}
                height={'330px'}/>
            }

            <BizModal
                title={t('company.nationSales')}
                isShow={showModal}
                onCancel={() => {
                    setShowModal(false);
                }}>
                <table className='table border-bottom'>
                    <colgroup>
                        <col style={{width: '60%'}}/>
                        <col style={{width: '40%'}}/>
                    </colgroup>
                    <thead>
                    <tr className='text-center'>
                        <td>{t('company.nation')}</td>
                        <td>{t('company.sales')}</td>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map((value, index) => {
                        return (
                            <tr key={index}>
                                <td>
                                    <span
                                        style={{marginRight: 10}}
                                        className={`flag-icon flag-icon-${value.nationCode.toLowerCase()}`}/>
                                    {Pipe.nationNm(value.nationCode)}
                                </td>
                                <td className='text-right'>
                                    <NumberFormat
                                        thousandSeparator
                                        suffix={` ${props.companyData.priceUnit}`}
                                        value={value.sales}
                                        displayType="text"/>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </BizModal>
        </>
    )
}


const CompanyDetailGraphNationUsage = (props: {
    companyData: CompanyData
}) => {
    const [showModal, setShowModal] = useState(false);
    const [t] = useTranslation('common');
    const data = props.companyData.nationUsage;

    const options: ApexOptions = {
        chart: {
            type: 'donut',
            toolbar: {
                show: false,
            },
            animations: {
                enabled: false
            },
        },
        legend: {
            show: true,
            position: 'bottom',
            formatter(legendName: string, opts?: any): string {
                return legendName;
            }
        },
        labels: data.map(value => {
            return `[${value.nationCode.toUpperCase()}] ${Pipe.nationNm(value.nationCode)}`;
        }),
        dataLabels: PieGraphDataLabelOptions,
        tooltip: {
            y: {
                formatter: (val: number): string => {
                    return Pipe.toSeparatorNumber(val);
                }
            }
        }
    }

    const series = data.map(value => {
        return value.usage
    })


    return (
        <>
            <div className={cx('box-btn')}>
                <h5>{t('company.nationUsage')}</h5>
                <button
                    className='btn btn-sm btn-outline-secondary'
                    onClick={() => {
                        setShowModal(true);
                    }}>{t('company.table')}
                </button>
            </div>

            {props.companyData.nationUsage.length === 0 &&
            <div className={cx('box-nodata')}>{t('company.noChartData')}</div>
            }

            {props.companyData.nationUsage.length !== 0 &&
            <Chart
                type='donut'
                options={options}
                series={series}
                width={'100%'}
                height={'330px'}/>
            }


            <BizModal
                title={t('company.nationUsage')}
                isShow={showModal}
                onCancel={() => {
                    setShowModal(false);
                }}>
                <table className='table border-bottom'>
                    <colgroup>
                        <col style={{width: '60%'}}/>
                        <col style={{width: '40%'}}/>
                    </colgroup>
                    <thead>
                    <tr className='text-center'>
                        <td>{t('company.nation')}</td>
                        <td>{t('company.usage')}</td>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map((value, index) => {
                        return (
                            <tr key={index}>
                                <td>
                                    <span
                                        style={{marginRight: 10}}
                                        className={`flag-icon flag-icon-${value.nationCode.toLowerCase()}`}/>
                                    {Pipe.nationNm(value.nationCode)}
                                </td>
                                <td className='text-right'>
                                    <NumberFormat
                                        thousandSeparator
                                        value={value.usage}
                                        displayType="text"/>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </BizModal>
        </>
    )
}

const CompanyDetailGraphSales = (props: {
    companyData: CompanyData
}) => {

    const [showModal, setShowModal] = useState(false);
    const [t] = useTranslation('common');

    const data = props.companyData.totalSales;


    const options: ApexOptions = {
        chart: {
            type: 'bar',
            toolbar: {
                show: false,
            },
            animations: {
                enabled: false
            },
        },
        xaxis: {
            categories: data.map(value => {
                return [value.year]
            })
        },
        yaxis: {
            labels: {
                formatter: (val: number): string => {
                    return Pipe.toSeparatorNumber(val)
                }
            }
        },
        dataLabels: {
            enabled: true,
            formatter: (val: number, opt: any): string => {
                return Pipe.toSeparatorNumber(val)
            }
        },

    }

    const series = [
        {
            name: 'sales',
            type: 'column',
            data: data.map(value => {
                return value.sales
            }),
        }
    ];

    return (
        <>
            <div className={cx('box-btn')}>
                <h5>{t('company.totalSales')}</h5>
                <button
                    className='btn btn-sm btn-outline-secondary'
                    onClick={() => setShowModal(true)}>{t('company.table')}
                </button>
            </div>

            {props.companyData.totalSales.length === 0 &&
            <div className={cx('box-nodata')}>{t('company.noChartData')}</div>
            }

            {props.companyData.totalSales.length !== 0 &&
            <Chart
                type='bar'
                options={options}
                series={series}
                width={'100%'}
                height={'300px'}/>
            }

            <BizModal
                title={t('company.totalSales')}
                isShow={showModal}
                onCancel={() => {
                    setShowModal(false);
                }}>
                <table className='table border-bottom'>
                    <colgroup>
                        <col style={{width: '50%'}}/>
                        <col style={{width: '50%'}}/>
                    </colgroup>
                    <thead>
                    <tr className='text-center'>
                        <td>{t('company.year')}</td>
                        <td>{t('company.totalSales')}</td>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map((value, index) => {
                        return (
                            <tr key={index}>
                                <td className='text-center'>
                                    {value.year}
                                </td>
                                <td className='text-right'>
                                    <NumberFormat
                                        suffix={` ${props.companyData.priceUnit}`}
                                        thousandSeparator
                                        value={value.sales}
                                        displayType="text"/>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </BizModal>
        </>
    )
}

