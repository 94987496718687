import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './CsFaqView.module.scss';
import {CsLayout} from "../../layout/CsLayout";
import {gql, useQuery} from "@apollo/client";
import {Query, QueryFaqWithIdArgs} from "../../../../graphql/types";
import {FadeIn} from "../../../shares/fadeIn/FadeIn";
import {BizTitle} from "../../../shares/bizTitle/BizTitle";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {BoardFileView} from "../../../shares/boardFileView/BoardFileView";

const cx = classNamesBind.bind(styles);

export const CsFaqView = (props: {
    faqId: number
}) => {
    const {data} = useQuery<Query, QueryFaqWithIdArgs>(gqlData, {
        variables: props
    });
    const [t] = useTranslation('common');

    return (
        <CsLayout activatedSuffixRouter={'/faq/list'}>
            {data &&
            <FadeIn>
                <BizTitle>{data.faqWithId.title}</BizTitle>
                <div className={cx('table-fluid')}>
                    <div className={cx('col', 'col-label')}>{t("cs.createDate")}</div>
                    <div
                        className={cx('col', 'col-value')}>
                        {moment(data.faqWithId.createdAt).format('YY.MM.DD - hh:mm a')}
                    </div>
                    <div className={cx('col', 'col-label')}>{t("cs.updateDate")}</div>
                    <div className={cx('col', 'col-value')}>
                        {moment(data.faqWithId.updatedAt).format('YY.MM.DD - hh:mm a')}
                    </div>
                </div>

                <BoardFileView files={data.faqWithId.fileList}/>

                <div className={cx('contents')}
                     ref={ref => {
                         if (ref) {
                             ref.innerHTML = data.faqWithId.contents
                         }
                     }}/>

            </FadeIn>
            }


        </CsLayout>
    )
}

const gqlData = gql`
    query DATA($faqId: Int!) {
        faqWithId(faqId: $faqId) {
            id
            title
            contents
            createdAt
            updatedAt
            fileList {
                fileNm
                fileSrc
            }
        }
    }
`;
