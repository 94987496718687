import React, {ReactNode} from 'react';
import classNamesBind from "classnames/bind";
import styles from './BizTitle.module.scss';
import {HelpOutline} from "@material-ui/icons";
import {Tooltip} from "@material-ui/core";

const cx = classNamesBind.bind(styles);

export const BizTitle = (props: {
    children?: ReactNode,
    marginBottom?: boolean,
    marginLeft?: boolean,
    desc?: ReactNode,
    tooltip?: ReactNode
}) => {
    return (
        <div className={cx('cont', 'bar')}
             style={{marginBottom: props.marginBottom ? 15 : 0, paddingLeft: props.marginLeft ? 10 : 0}}>
            <div className={cx('title')}>
                {props.children}
                {props.tooltip &&
                <Tooltip title={props.tooltip}>
                    <HelpOutline/>
                </Tooltip>
                }
            </div>

            <div className={cx('space')}/>
            <div className={cx('desc')}>{props.desc}</div>

        </div>
    )
}

export const BizSmTitle = (props: {
    children?: ReactNode,
    tooltip?: ReactNode,
    bar?: boolean,
    actions?: ReactNode,
}) => {
    return (
        <div className={cx('cont', props.bar ? 'bar' : '')}>
            <div className={cx('title-sm')}>
                {props.children}
                {props.tooltip &&
                <Tooltip title={props.tooltip}>
                    <HelpOutline/>
                </Tooltip>
                }
            </div>
            <div className={cx('space')}/>
            <div className={cx('desc')}>{props.actions}</div>
        </div>

    )
}
