import React, {useEffect} from 'react';
import $scriptjs from 'scriptjs';
import {GetEnv} from "../../../env/env";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {navigate} from "hookrouter";
import {gql, useMutation} from "@apollo/client";
import {JoinType, Mutation, MutationLoginWithSocialArgs} from "../../../graphql/types";
import {CryptoUtil, CryptoUtilPrefix} from "../../../utils/crypto/CryptoUtil";
import {LocalStorage} from "../../../utils/localstorage/LocalStorage";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../graphql/errors";
import {useTranslation} from "react-i18next";

const NAVER_SDK_URL = 'https://static.nid.naver.com/js/naverLogin_implicit-1.0.3.js';
const JQUERY_URL = 'https://code.jquery.com/jquery-1.11.3.min.js';

export const AuthNaverCallback = () => {
    const [t] = useTranslation('common');

    const [mutLoginWithSocial] = useMutation<Mutation, MutationLoginWithSocialArgs>(gqlLoginWithSocial);

    useEffect(() => {
        $scriptjs(JQUERY_URL, () => {
            $scriptjs(NAVER_SDK_URL, () => {
                // @ts-ignore
                let sdk = new naver_id_login(GetEnv().naverToken, GetEnv().naverCallbackUrl);
                const accessToken = sdk.oauthParams.access_token;


                $.ajax({
                    url: "https://openapi.naver.com/v1/nid/getUserProfile.json?response_type=json",
                    type: "GET",
                    data: {"access_token": accessToken},
                    dataType: "jsonp",
                    jsonp: "oauth_callback",
                    success: function (result) {
                        const {email, id} = result.response;
                        handler.onLoginWithSocal(email, id)
                    },
                    error: function (xhr, ajaxOptions, thrownError) {
                        SwalUtil.ok({
                            msg: t("auth.failNaverLogin"),
                            icon: 'error',
                        })
                        navigate('/')
                    }
                });


            })
        })
    /*
    // 다음과 같은 경고 메시지를 처리하기 위해서 useDeepCompareEffect를 이용하여
    // 소스를  수정하였으나 운영서버에서 제대로 동작하기 않고, 로컬 개발환경에서
    // 테스트하기 위해서는 테스트 환경을 구성해야 하므로, 일단 소스를 원상복구시키고
    // eslint-disable-next-line으로 처리하기로 한다.
    // ---------------------------------------------------------------------------------
    // Line 53:8:  React Hook useEffect has missing dependencies: 'handler' and 't'. 
    // Either include them or remove the dependency array  react-hooks/exhaustive-deps
    // ---------------------------------------------------------------------------------
    */
    // eslint-disable-next-line
    }, []);

    const handler = {
        onLoginWithSocal: (email: string, id: string) => {
            const pw = CryptoUtil.encryptPw(CryptoUtilPrefix.Naver, id);
            mutLoginWithSocial({
                variables: {
                    email, pw, joinType: JoinType.Naver
                }
            }).then(res => {
                LocalStorage.setSession(res.data!.loginWithSocial);
                LocalStorage.setIsNaverLogined(true);
                window.close();
            }).catch(ApolloCatch({
                [BizErrors.dontActBizErrors]: () => {
                },
                [BizErrors.notFoundUser]: SwalUtil.apolloCatch({
                    msg: t("auth.notFoundUser"),
                    icon: 'error'
                })
            }))
        }
    }

    return (
        <>
            <div id="naverIdLogin"></div>
        </>
    )
}


const gqlLoginWithSocial = gql`
    mutation LoginWithSocial($email: String!, $pw: String!, $joinType: JoinType!) {
        loginWithSocial(email: $email, pw: $pw, joinType: $joinType)
    }
`;

