import React, {Fragment, ReactNode, useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './BizMenuLayout.module.scss'
import {ViewSelector} from "../viewSelector/ViewSelector";
import {Spring} from "react-spring/renderprops-universal";
import {navigate, usePath} from "hookrouter";
import {
    Divider,
    Fab,
    List,
    ListItem, ListItemText,
    SwipeableDrawer,
    Tooltip
} from "@material-ui/core";
import {Add} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import { OnChangeValue } from "../../Intefaces";

const cx = classNamesBind.bind(styles);

const useStyles = makeStyles((theme) => ({
    fab: {
        margin: theme.spacing(2),
    },
    absolute: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(3),
    },
    btmNav: {
        height: 50,
        lineHeight: 50,
        padding: 10,
        color: 'black'
    }
}));

export const BizMenuLayout = (props: Attr) => {
    return (
        <>
            <ViewSelector
                web={
                    <Web {...props}>
                    </Web>
                }
                mobile={
                    <Mobile {...props}>
                    </Mobile>
                }/>
        </>
    )
}

const Web = (props: Attr) => {
    const [idx, setIdx] = useState(0);
    const activeFontColor = 'rgb(0,0,0)';
    const inactiveFontColor = 'rgb(222,222,222)';
    const path = usePath();

    useEffect(() => {
        const nextIdx = props.data.findIndex(value => {
            return props.activatedRouterSuffix === value.routerSuffix;
        });

        if (nextIdx === -1) {
            setIdx(0);
        } else {
            setIdx(nextIdx);
        }
    }, [props.data, path, props.activatedRouterSuffix]);

    return (
        <div className={cx('cont')}>
            <div className={cx('nav')}>
                {props.title && <div className={cx('title')}>
                    {props.title}
                </div>}
                <div className={cx('box-btn')}>
                    {props.data.map((value, index) => {
                        const activated = index === idx;
                        return (
                            <Spring
                                key={index}
                                from={{color: inactiveFontColor}}
                                to={{color: activated ? activeFontColor : inactiveFontColor}}>
                                {params =>
                                    <>
                                        <div
                                            style={params}
                                            className={cx('btn-nav')}
                                            onClick={() => {
                                                setIdx(index);
                                                window.scrollTo(0, 0);
                                                
                                                if(!props.onChangeMenu) {
                                                    navigate(`${props.routerPrefix}${value.routerSuffix}`);
                                                } else {
                                                    // 최상위 카테고리 클릭시 State값의 카테고리 id를 변경하면서
                                                    // 해당 카테고리의 상품을 출력한다.
                                                    props.onChangeMenu(value.routerSuffix);
                                                }
                                            }}>
                                            {value.title}
                                        </div>
                                    </>
                                }
                            </Spring>

                        )
                    })}
                </div>
            </div>

            <div className={cx('box-contents')}>
                {props.children}
            </div>
        </div>
    )
}

const Mobile = (props: Attr) => {
    const classes = useStyles();
    const [drawer, setDrawer] = useState(false);

    return (
        <>
            {props.children}

            <Tooltip title="Add" aria-label="add">
                <Fab
                    className={classes.absolute}
                    onClick={() => {
                        setDrawer(true);
                    }}>
                    <Add/>
                </Fab>
            </Tooltip>

            <SwipeableDrawer
                anchor="bottom"
                onClose={() => {
                    setDrawer(false);
                }}
                onOpen={() => {
                    setDrawer(false);
                }}
                open={drawer}>

                {props.data.map((value, index) => {
                    return (
                        <Fragment key={index}>
                            <List onClick={() => {
                                if(!props.onChangeMenu) {
                                    navigate(`${props.routerPrefix}${value.routerSuffix}`);
                                } else {
                                    // 최상위 카테고리 클릭시 State값의 카테고리 id를 변경하면서
                                    // 해당 카테고리의 상품을 출력한다.
                                    props.onChangeMenu(value.routerSuffix);
                                }
                                setDrawer(false);
                                window.scrollTo(0, 0);
                            }}>
                                <ListItem>
                                    <ListItemText primary={value.title}/>
                                </ListItem>
                            </List>
                            <Divider/>
                        </Fragment>
                    )
                })}
            </SwipeableDrawer>
        </>
    )
}

interface Attr {
    title: ReactNode
    data: BizMenuData[],
    routerPrefix: string,
    activatedRouterSuffix: string,
    children: ReactNode,
    onChangeMenu?: OnChangeValue<string>,
}

export interface BizMenuData {
    title: string;
    routerSuffix: string;
}
