import React, {Fragment, ReactNode} from 'react';
import classNamesBind from "classnames/bind";
import styles from './InputFiles.module.scss';
import {useTranslation} from "react-i18next";
import {DataUtil} from "../../../../../utils/data/DataUtil";
import {CopyUtil} from "../../../../../utils/copyUtil/copyUtil";
import {ApolloCatch} from "../../../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../../../graphql/errors";
import {SwalUtil} from "../../../../../utils/swal/swalUtil";
import {AddCircleOutline, Close} from "@material-ui/icons";
import {OnChangeValue} from "../../../../Intefaces";
import {GoodsFile} from "../../../../../graphql/customScalars";
import {Tooltip} from "@material-ui/core";

const cx = classNamesBind.bind(styles);

export const InputFiles = (props: {
    title: ReactNode,
    value: GoodsFile[]
    onChange: OnChangeValue<GoodsFile[]>
}) => {
    const [t] = useTranslation('common');

    const onAppendFile = () => {
        if (2 < props.value.length) {
            SwalUtil.ok({
                msg: t("common.swalAppendFile"),
                icon: 'warning'
            })
            return;
        }


        DataUtil.uploadFile()
            .then(res => {
                let copy = CopyUtil.copy(props.value);
                for (let file of res) {
                    copy.push({
                        nm: file.nm,
                        src: file.src,
                        createdAt: file.createdAt,
                    })
                }
                props.onChange(copy);
            })
            .catch(ApolloCatch({
                [BizErrors.default]: () => {
                    SwalUtil.error();
                },
                [BizErrors.overMaxSize]: () => {
                    SwalUtil.ok({
                        msg: t('common.less5mb'),
                        icon: "error"
                    })
                }
            }))
    }

    const onDeleteFile = (idx: number) => {
        let copy = CopyUtil.copy(props.value);

        copy = [
            ...copy.slice(0, idx),
            ...copy.slice(idx + 1, copy.length)
        ];

        props.onChange(copy);
    }

    return (
        <Fragment>
            <div className={cx('title')}>{props.title}</div>
            <p className={cx('desc')}>{t("common.AppendFileWarningText")}</p>

            <div className={cx('table')}>
                <div className={cx('header', 'col-delete')}/>
                <div className={cx('header', 'col-nm')}>{t('common.filename')}</div>
            </div>

            {props.value.map((value, index) => {
                return (
                    <div className={cx('table', 'hover')} key={index}>
                        <div className={cx('col', 'col-delete')}>
                            <Tooltip title={t('common.btnDelete') || ''} onClick={() => {
                                onDeleteFile(index);
                            }}>
                                <Close className={cx('btn-add')} style={{marginTop: -8}}/>
                            </Tooltip>
                        </div>
                        <div className={cx('col', 'col-nm')}>{value.nm}</div>
                    </div>
                )
            })}

            <div className={cx('table')}>
                <div className={cx('col', 'col-delete')}/>
                <div className={cx('col', 'col-nm')}>
                    <Tooltip title={t('common.btnAdd') || ''} onClick={onAppendFile}>
                        <AddCircleOutline className={cx('btn-add')}/>
                    </Tooltip>
                </div>
            </div>

        </Fragment>
    )
}
