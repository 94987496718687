import React, {Fragment, useEffect, useState} from 'react';
import classNamesBind from "classnames/bind";
import styles from './BizCompanyDataConsultant.module.scss';
import {ViewSelector} from '../../viewSelector/ViewSelector';
import {CompanyConsultant, Mutation, MutationInquiryCompanyConsultantArgs} from "../../../../graphql/types";
import {BizTitle} from "../../bizTitle/BizTitle";
import {useSelector} from "react-redux";
import {I18nState} from "../../../../redux/I18n";
import {I18nUtil} from '../../../../utils/i18n/i18n';
import {Accordion, AccordionDetails, AccordionSummary, Button} from "@material-ui/core";
import {Create, ExpandMore, SearchOutlined} from "@material-ui/icons";
import {OnChangeValue, OnVoidFunction} from "../../../Intefaces";
import {CopyUtil} from "../../../../utils/copyUtil/copyUtil";
import {gql} from "@apollo/client/core";
import {useMutation} from "@apollo/client";
import {ApolloCatch} from "../../../../utils/apollo/apolloCatch";
import {useTranslation} from "react-i18next";
import {BizNoDataTable} from "../../bizNoDataTable/BizNoDataTable";
import {UserState} from "../../../../redux/User";
import {I18nKeys} from "../../../../i18n/i18nKeys";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import {navigate} from "hookrouter";

const cx = classNamesBind.bind(styles);

interface Props {
    consultant: CompanyConsultant[],
    onShowAskBoardModal: OnVoidFunction,
    onCreateChatRoomWithConsultantId?: OnChangeValue<number>,
    onCreateChatRoomWithGoodsInfoIdAndConsultantId?: OnChangeValue<number>,
}

type CompProps = Props & {
    list: List,
    onActivate: OnChangeValue<number>,
}

type List = (CompanyConsultant & { isActivate: boolean })[];
const disableBlock = "***";

export const BizCompanyDataConsultant = (props: Props) => {
    const [list, setList] = useState<List>([]);
    const [mutInquiry] = useMutation<Mutation, MutationInquiryCompanyConsultantArgs>(gqlInquiry);
    const [t] = useTranslation('common');
    const user = useSelector<any, UserState>(state => state.user);

    useEffect(() => {
        const next: List = props.consultant.map(value => {
            return {...value, isActivate: false}
        })
        setList(next);
    }, [props.consultant])


    const handler = {
        consultant: props.consultant,
        list,
        onCreateChatRoomWithConsultantId: props.onCreateChatRoomWithConsultantId,
        onCreateChatRoomWithGoodsInfoIdAndConsultantId: props.onCreateChatRoomWithGoodsInfoIdAndConsultantId,
        onActivate: (idx: number) => {
            // 유저 로그인과 상관없이 카운팅 한다.
            handler.onInquiry(list[idx].id)

            if (!user.isLogin) {
                SwalUtil.ynPromise({
                    msg: t(I18nKeys.swal.needLoginToActivateLogin.key),
                    okBtn: t(I18nKeys.swal.btnLogin.key),
                    cancelBtn: t(I18nKeys.swal.btnClose.key),
                }).then(() => {
                    navigate('/auth/login')
                }).catch(ApolloCatch({}))
                return;
            }

            const copy = CopyUtil.copyAll(list);

            if (copy[idx].isActivate) {
                return;
            }

            if (!user.isLogin) {
                return;
            }

            copy[idx].isActivate = true;
            setList(copy);
        },
        onShowAskBoardModal: () => {
            if (!user.isLogin) {
                SwalUtil.ynPromise({
                    msg: t(I18nKeys.swal.needLogin.key),
                    okBtn: t(I18nKeys.swal.btnLogin.key),
                    cancelBtn: t(I18nKeys.swal.btnClose.key),
                }).then(() => {
                    navigate('/auth/login')
                }).catch(ApolloCatch({}));
                return;
            }
            props.onShowAskBoardModal();
        },
        onInquiry: (consultantId: number) => {
            // 여기에 서버로정보 보내기
            mutInquiry({
                variables: {
                    companyConsultantId: consultantId
                }
            }).catch(ApolloCatch({}));
        },
    }

    return (
        <>
            <div className={cx('cont')}>
                <BizTitle tooltip={t("company.ttOpenConsultantInfo") || ""}>{t("company.consultantInfo")}</BizTitle>
                <ViewSelector
                    web={<Web {...handler}/>}
                    mobile={<Mobile {...handler}/>}/>
            </div>

        </>
    );
};

const Web = (props: CompProps) => {
    const langCode = useSelector<any, I18nState>(state => state.i18n).langCode;
    const user = useSelector<any, UserState>(state => state.user);
    const [t] = useTranslation('common');

    return (
        <>
            <div className={cx('table')}>
                <div className={cx('header', 'box-position')}>{t("company.consultantPosition")}</div>
                <div className={cx('header', 'box-nm')}>{t("company.consultantName")}</div>
                <div className={cx('header', 'box-email')}>{t("company.consultantEmail")}</div>
                <div className={cx('header', 'box-tel')}>{t("company.cosultantTel")}</div>
                <div className={cx('header', 'box-func')}>{t("company.consultantFunction")}</div>
            </div>

            {props.list.length === 0 && <BizNoDataTable/>}

            {props.list.map((value, index) => (
                <Fragment key={index}>
                    <div className={cx('table')}>
                        <div className={cx('col', 'box-position')}>
                            {I18nUtil.value(langCode, value.position)}
                        </div>
                        <div className={cx('col', 'box-nm')}>
                            {value.isActivate && (I18nUtil.value(langCode, value.nm) || disableBlock)}
                            {!value.isActivate && disableBlock}
                        </div>
                        <div className={cx('col', 'box-email')}>
                            {value.isActivate && (value.email || disableBlock)}
                            {!value.isActivate && disableBlock}
                        </div>
                        <div className={cx('col', 'box-tel')}>
                            {value.isActivate && (I18nUtil.value(langCode, value.tel) || disableBlock)}
                            {!value.isActivate && disableBlock}
                        </div>
                        <div className={cx('col', 'box-func')}>
                            {!value.isActivate &&
                            <Button
                                size='small'
                                style={{marginBottom: 5}}
                                classes={{
                                    root: cx('btn-root'),
                                    label: cx('btn-color')
                                }}
                                variant="outlined"
                                startIcon={<SearchOutlined/>}
                                onClick={() => props.onActivate(index)}>
                                {t("company.ttViewing")}
                            </Button>
                            }

                            <Button
                                size='small'
                                variant="outlined"
                                style={{marginBottom: 5}}
                                classes={{
                                    root: cx('btn-root'),
                                    label: cx('btn-color')
                                }}
                                startIcon={<Create/>}
                                onClick={props.onShowAskBoardModal}>
                                {t(I18nKeys.company.chatWithConsultant.key)}
                            </Button>

                            {
                                props.onCreateChatRoomWithConsultantId
                                && value.isRegisteredAccount
                                &&
                                <Button
                                    size='small'
                                    variant="outlined"
                                    classes={{
                                        root: cx('btn-root'),
                                        label: cx('btn-color')
                                    }}
                                    startIcon={<ChatIcon/>}
                                    onClick={() => props.onCreateChatRoomWithConsultantId!(value.id)}>
                                    {t(I18nKeys.company.chat.key)}
                                </Button>
                            }

                            {
                                props.onCreateChatRoomWithGoodsInfoIdAndConsultantId
                                && value.isRegisteredAccount
                                &&
                                <Button
                                    size='small'
                                    variant="outlined"
                                    classes={{
                                        root: cx('btn-root'),
                                        label: cx('btn-color')
                                    }}
                                    startIcon={<ChatIcon/>}
                                    onClick={() => props.onCreateChatRoomWithGoodsInfoIdAndConsultantId!(value.id)}>
                                    {t(I18nKeys.company.chat.key)}
                                </Button>
                            }
                        </div>
                    </div>

                    {!user.isLogin &&
                    <p className={cx('box-info')}>{t(I18nKeys.company.notShowConsultant.key)}</p>
                    }
                    {user.isLogin &&
                    <p className={cx('box-info')}>{t(I18nKeys.company.showConsultant.key)}</p>
                    }


                </Fragment>


            ))}
        </>
    )
}

const Mobile = (props: CompProps) => {
    const langCode = useSelector<any, I18nState>(state => state.i18n).langCode;

    return (
        <>
            {props.list.map((value, index) => (
                <Accordion
                    key={index}
                    onClick={() => props.onActivate(index)}
                    expanded={value.isActivate}>
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}>
                        {I18nUtil.value(langCode, value.position)}
                    </AccordionSummary>
                    <AccordionDetails style={{flexWrap: 'wrap'}}>
                        <div style={{width: '100%'}}>{I18nUtil.value(langCode, value.nm)}</div>
                        <div style={{width: '100%'}}>{I18nUtil.value(langCode, value.tel)}</div>
                        <div style={{width: '100%'}}>{value.email}</div>
                    </AccordionDetails>
                </Accordion>
            ))}
        </>
    )
}

const ChatIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
             fill="currentColor"
             className="bi bi-chat-dots" viewBox="0 0 16 16">
            <path
                d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
            <path
                d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z"/>
        </svg>
    )
}

const gqlInquiry = gql`
    mutation Inquiry($companyConsultantId: Int!) {
        inquiryCompanyConsultant(companyConsultantId: $companyConsultantId)
    }
`;
