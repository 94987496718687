import React, {ReactNode, useEffect, useState} from 'react';
import Quill from "quill";
import {DataUtil} from "../../../../../utils/data/DataUtil";
import {ApolloCatch} from "../../../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../../../graphql/errors";
import {SwalUtil} from "../../../../../utils/swal/swalUtil";
import {useTranslation} from "react-i18next";
import 'quill/dist/quill.snow.css';
import {GlobalVars} from "../../../../../globalVars/globalVars";
import {OnChangeValue} from "../../../../Intefaces";
import classNamesBind from "classnames/bind";
import style from './InputQuill.module.scss';
import {Button} from "@material-ui/core";
import {Code, LineStyle} from "@material-ui/icons";

const cx = classNamesBind.bind(style);

enum Editor {
    Quill,
    Textarea
}

export const InputQuill = (props: {
    title?: ReactNode,
    langCode: string,
    value: string,
    from: string,
    onChangeValue: OnChangeValue<string>
}) => {
    const [t] = useTranslation('common');
    const [quill, setQuill] = useState<Quill | undefined>();
    const [editor, setEditor] = useState(Editor.Quill);

    const getPlaceHolder = () => {
        switch(props.from) {
            case "goods":
                return t('mypageGoods.quillPlaceholder');
            case "company":
                return t('mypageCompany.quillPlaceholder');
            default:
                return "";
        }
    } 
    const [quillPlaceholder, setQuillPlaceholder] = useState(getPlaceHolder());

    //*------------------------------------------------------------*/
    // 다음 경고를 무시하게 한다. 
    // 해당 경고를 수정했을 경우 InputQuill이 제대로 동작하지 않는다.
    // (※경고를 수정했으나 InputQuill이 수정되지 않는 문제가 발생하여
    //    소스를 원상복구한다.)
    //---------------------------------------------------------------
    // React Hook useEffect has missing dependencies: 'props.langCode', 'props.value', and 
    // 'quill'. Either include them or remove the dependency array
    //*------------------------------------------------------------*/
    useEffect(() => {
        if (editor !== Editor.Quill) {
            return;
        }

        if (!quill) {
            return;
        }

        quill.root.innerHTML = props.value;
        localStorage.setItem("GOODS_CREATE_LANG_CODE", props.langCode);
    // eslint-disable-next-line
    }, [editor]);

    //*------------------------------------------------------------*/
    // 다음 경고를 무시하게 한다. 
    // 해당 경고를 수정했을 경우 InputQuill이 제대로 동작하지 않는다.
    // (※경고를 수정했으나 InputQuill이 수정되지 않는 문제가 발생하여
    //    소스를 원상복구한다.)
    //---------------------------------------------------------------
    // React Hook useEffect has a missing dependency: 'quill'. 
    // Either include it or remove the dependency array
    //*------------------------------------------------------------*/
    useEffect(() => {
        return () => {
            if (!quill) {
                return;
            }
            quill.root.remove();
        }
    // eslint-disable-next-line
    }, []);

    //*------------------------------------------------------------*/  
    // 다음 경고를 무시하게 한다. 
    // 해당 경고를 수정했을 경우 InputQuill이 제대로 동작하지 않는다.
    // (※경고를 수정했으나 InputQuill이 수정되지 않는 문제가 발생하여
    //    소스를 원상복구한다.)
    //---------------------------------------------------------------
    // React Hook useEffect has missing dependencies: 'props.langCode', 'props.value', 
    // 'quill', 'quillPlaceholder', and 't'. Either include them or remove the dependency array
    // react-hooks/exhaustive-deps
    //*------------------------------------------------------------*/
    useEffect(() => {
        if (quill) {
            return;
        }

        const editor = new Quill("#quill-editor", {
            modules: {
                toolbar: {
                    container: [
                        [{'header': [1, 2, 3, 4, 5, 6, false]}],
                        [{'align': []}],
                        ['bold', 'italic', 'underline', 'strike'],
                        ["image"],
                    ],
                    handlers: {
                        image: () => {
                            DataUtil.uploadFile({image: true, multiple: true})
                                .then(res => {
                                    for (let item of res) {
                                        const img = document.createElement('img');
                                        img.setAttribute('style', 'width:100%');
                                        img.setAttribute('src', item.src);
                                        editor.root.appendChild(img)
                                    }
                                })
                                .catch(ApolloCatch({
                                    [BizErrors.default]: () => {
                                        SwalUtil.ok({
                                            msg: t('mypageGoods.failUpload'),
                                            icon: "error"
                                        })
                                    },
                                    [BizErrors.overMaxSize]: () => {
                                        SwalUtil.ok({
                                            msg: t('mypageGoods.less5mb'),
                                            icon: "error"
                                        })
                                    }
                                }))
                        }
                    }
                },
            },
            placeholder: quillPlaceholder,
            theme: 'snow',
        });

        editor.on("text-change", () => {
            const langCode = localStorage.getItem("GOODS_CREATE_LANG_CODE") || '';
            GlobalVars.quill.createGoods({langCode: langCode, content: editor.root.innerHTML});
        });

        localStorage.setItem("GOODS_CREATE_LANG_CODE", props.langCode);
        editor.root.innerHTML = props.value;
        setQuill(editor);
    // eslint-disable-next-line
    }, [t]);

    //*------------------------------------------------------------*/  
    // 다음 경고를 무시하게 한다. 
    // 해당 경고를 수정했을 경우 InputQuill이 제대로 동작하지 않는다.
    // (※경고를 수정했으나 InputQuill이 수정되지 않는 문제가 발생하여
    //    소스를 원상복구한다.)
    //---------------------------------------------------------------
    // React Hook useEffect has missing dependencies: 'props.value', 'quill', 
    // and 'quillPlaceholder'. Either include them or remove the dependency array.
    // You can also do a functional update 'setQuillPlaceholder(q => ...)' 
    // if you only need 'quillPlaceholder' in the 'setQuillPlaceholder' call  react-hooks/exhaustive-deps
    //*------------------------------------------------------------*/
    useEffect(() => {
        if (!quill) {
            return;
        }

        quill.root.innerHTML = props.value;
        setQuillPlaceholder(quillPlaceholder);
        localStorage.setItem("GOODS_CREATE_LANG_CODE", props.langCode);
    // eslint-disable-next-line
    }, [props.langCode]);

    return (
        <>
            <div className={cx('table')}>
                <div className={cx('title', 'p-0')}>{props.title} <span className={cx("blue")}>(Designed)</span></div>
                <div className={cx('col-flex-grow-1', 'text-right')}>
                    <Button
                        startIcon={<Code/>}
                        onClick={() => setEditor(Editor.Textarea)}>
                        {t("common.btnToggleEditHtmlCode")}
                    </Button>
                    <Button
                        startIcon={<LineStyle/>}
                        onClick={() => setEditor(Editor.Quill)}>
                        {t("common.btnToggleEditor")}
                    </Button>
                </div>

            </div>

            <div style={{display: editor === Editor.Quill ? '' : 'none'}}>
                <div
                    id='quill-editor'
                    style={{height: 500}}/>
            </div>

            <div style={{display: editor === Editor.Textarea ? '' : 'none'}}>
                <textarea
                    value={props.value}
                    onChange={ev => props.onChangeValue(ev.target.value)}
                    className={cx('form-control', 'textarea')}/>
            </div>

        </>
    )
}
