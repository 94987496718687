import React from 'react';
import {gql, useMutation, useQuery} from "@apollo/client";
import {AskBoardStatus, Mutation, MutationUpdateCompanyAskBoardStatusArgs, Query} from "../../../../graphql/types";
import {BoardLayout} from "../../BoardLayout";
import {BizSmTitle, BizTitle} from "../../../shares/bizTitle/BizTitle";
import {BoardListView} from './view/BoardListView';
import {Pager} from "../../../shares/pager/Pager";
import {navigate} from "hookrouter";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import {ApolloCatch} from "../../../../utils/apollo/apolloCatch";
import {BizNoDataTable} from "../../../shares/bizNoDataTable/BizNoDataTable";
import {useTranslation} from "react-i18next";
import {I18nKeys} from "../../../../i18n/i18nKeys";

export const BoardList = (props: {
    page: number,
}) => {
    const {data, error, refetch} = useQuery<Query, { page: number }>(gqlData, {
        variables: props
    });

    const [mutUpdateStatus] = useMutation<Mutation, MutationUpdateCompanyAskBoardStatusArgs>(gqlUpdateStatus);

    const [t] = useTranslation('common');

    const handler = {
        onChangePage: (p: number) => {
            navigate(`/ask/company/board/list/${p}`);
        },
        onUpdateAskBoardStatus: (args: MutationUpdateCompanyAskBoardStatusArgs) => {
            SwalUtil.ynPromise({
                msg: args.askBoardStatus === AskBoardStatus.Done ? t(I18nKeys.board.swalAskStatusDone.key): t(I18nKeys.board.swalAskStatusReask.key)
            }).then(() => {
                return mutUpdateStatus({
                    variables: args
                })
            }).then(() => {
                return refetch();
            }).then(() => {
                SwalUtil.ok({
                    msg: t(I18nKeys.board.swalChangeStatusDone.key),
                    icon: 'success',
                })
            }).catch(ApolloCatch({}));
        }
    };

    return (
        <BoardLayout
            activatedRouterSuffix='/company/board/list'>
            {data &&
            <>
                <BizTitle>{t(I18nKeys.board.titleAnswerList.key)}</BizTitle>
                <div style={{height: 10}}/>
                <BizSmTitle>{t(I18nKeys.board.statusProceeding.key)}</BizSmTitle>
                <BoardListView
                    {...handler}
                    askBoardList={data.companyAskBoardInProcessing}/>


                <div style={{height: 50}}/>
                <BizSmTitle>{t(I18nKeys.board.statusComplete.key)}</BizSmTitle>
                <BoardListView
                    isDone
                    {...handler}
                    askBoardList={data.companyAskBoardInDone.list}/>
                <Pager
                    page={data.companyAskBoardInDone.page}
                    size={data.companyAskBoardInDone.size}
                    total={data.companyAskBoardInDone.total}
                    onChange={handler.onChangePage}/>

            </>
            }

            {error && <BizNoDataTable/>}
        </BoardLayout>
    )
}

const gqlData = gql`
    query DATA($page: Int!) {
        companyAskBoardInProcessing {
            id
            allEduCode
            companyNm
            content
            createdAt
            goodsData
            goodsInfoId
            lastReplyWriter
            status
            title
            userCompanyNm
            userEmail
        }

        companyAskBoardInDone(page: $page, size: 20) {
            page
            size
            total
            list {
                id
                allEduCode
                companyNm
                content
                createdAt
                goodsData
                goodsInfoId
                lastReplyWriter
                status
                title
                userCompanyNm
                userEmail
            }
        }
    }
`;

const gqlUpdateStatus = gql`
    mutation UpdateAskBoard($askBoardId: Int!, $askBoardStatus: AskBoardStatus!) {
        updateCompanyAskBoardStatus(askBoardId: $askBoardId, askBoardStatus: $askBoardStatus)
    }
`;
