import {ReduxStore} from "../../index";
import {I18nUtil} from "../i18n/i18n";
import moment from "moment";

export class Pipe {
    static companyType(id: number, langCode: string): string {
        const list = ReduxStore.getState().data.companyType;

        const elem = list.find(value => {
            return value.id === id
        })

        if (elem) {
            return I18nUtil.value(langCode, elem.nm);
        } else {
            return "Unknown"
        }
    }

    static nationNm(code: string): string {
        const list = ReduxStore.getState().data.nationCode;

        const elem = list.find(value => {
            return value.code === code;
        })

        if (elem) {
            return elem.shortNm;
        } else {
            return "Unknown"
        }
    }

    static langNm(code: string): string {
        const list = ReduxStore.getState().data.langCode;

        const elem = list.find(value => {
            return value.code === code;
        })

        if (elem) {
            return elem.nm;
        } else {
            return "Unknown"
        }
    }

    static getRegionId(region: string): number {
        const list = ReduxStore.getState().data.region;

        const elem = list.find(value => {
            return value.region === region;
        })

        if (elem) {
            return elem.id;
        } else {
            return 0;
        }
    }

    static toSeparatorNumber(numb: number): string {
        return numb.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }


    static toTimeMonth(date: Date): number {
        return Number(moment(date).format("YYYYMM"));
    }

    static toTimeDay(date: Date): number {
        return Number(moment(date).format('YYYYMMDD'));
    }
}
