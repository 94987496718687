import React, {useState} from 'react';
import classNamesBind from "classnames/bind";
import styles from './MyPageConsultantUpdateModal.module.scss';
import {
    CompanyConsultant,
    InputCompanyConsultant,
    MutationUpdateCompanyConsultantArgs
} from "../../../../../graphql/types";
import {OnChangeValue} from "../../../../Intefaces";
import {MyPageConsultantInput} from "../input/MyPageConsultantInput";
import {useTranslation} from "react-i18next";

const cx = classNamesBind.bind(styles);

interface Props {
    consultant: CompanyConsultant,
    onUpdate: OnChangeValue<MutationUpdateCompanyConsultantArgs>
}

export const MyPageConsultantUpdateModal = (props: Props) => {
    const [input, setInput] = useState<InputCompanyConsultant>({
        email: props.consultant.email,
        nm: props.consultant.nm,
        permission: props.consultant.permission,
        position: props.consultant.position,
        tel: props.consultant.tel
    });

    const [t] = useTranslation('common');

    const handler = {
        input,
        onChangeInput: setInput
    }

    return (
        <>
            <MyPageConsultantInput
                {...handler}/>

            <div className={cx('table')}>
                <div className={cx('col', 'col-full', 'text-right')}>
                    <button
                        className='btn btn-sm btn-success'
                        onClick={() => {
                            props.onUpdate({
                                companyConsultantId: props.consultant.id,
                                input: input
                            })
                        }}>{t("mypageConsultant.btnSave")}
                    </button>
                </div>
            </div>
        </>
    )
}
