import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './IndexMainCarousel.module.scss';
import {IndexCarousel} from "../../../../graphql/types";
import {Carousel} from "react-bootstrap";

const cx = classNamesBind.bind(styles);

export const IndexMainCarousel = (props: {
    indexCarousel: IndexCarousel[],
}) => {
    const onClickLink = (link?: string) => {
        if (!link) {
            return;
        }

        const linkTokens = link.split("://");
        const urlPart = linkTokens[1];
        if(urlPart === "") {
            return;
        }

        window.open(link, "_blank");
    }

    return (
        <>
            <div className={cx('cont')}>
                {props.indexCarousel.length !== 0 &&
                <>
                    <div className={cx('box-carousel')}>
                        <Carousel
                            controls={false}
                            indicators={true}
                        >
                            {props.indexCarousel.map((value, index) => (
                                <Carousel.Item
                                    key={index}>
                                    <img
                                        onClick={() => onClickLink(value.link || '')}
                                        className="d-block w-100"
                                        alt='banner'
                                        src={value.src}/>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                </>
                }
            </div>
        </>
    )
}
