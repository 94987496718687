import React, {Fragment} from "react";
import {CompanyList} from "../../../../graphql/types";
import {Pager} from "../../../shares/pager/Pager";
import {OnChangeValue} from "../../../Intefaces";
import {BizCompanyDataIdx} from "../../../shares/bizCompanyDataIdx/BizCompanyDataIdx";

export const MyInfoCompany = (props: {
    companyList: CompanyList,
    onChangePage: OnChangeValue<number>
}) => {

    return (
        <Fragment>
            <BizCompanyDataIdx
                companyList={props.companyList.list}/>

            <Pager
                scrollTag={'mypage'}
                offset={-70}
                page={props.companyList.page}
                size={props.companyList.size}
                total={props.companyList.total}
                onChange={props.onChangePage}/>

        </Fragment>
    )
}
