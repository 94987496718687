import * as firebase from 'firebase';
import {BizErrors} from "../../graphql/errors";
import {GraphQLError} from "graphql";
import {FbKey} from "./fbKey";
import {JoinType, MutationLoginWithSocialArgs} from "../../graphql/types";
import {CryptoUtil, CryptoUtilPrefix} from "../crypto/CryptoUtil";
import {v4} from 'uuid';

export class FB {
    static message: firebase.messaging.Messaging;

    static initFirebase() {
        const key = process.env.REACT_APP_FB || '';
        firebase.initializeApp(FbKey[key]);
    }

    static signInGoogle(): Promise<MutationLoginWithSocialArgs> {
        let provider = new firebase.auth.GoogleAuthProvider();
        return firebase.auth()
            .signInWithPopup(provider)
            .then(res => {
                if (!res.user) {
                    throw new GraphQLError(BizErrors.failGoogleLogin);
                }

                const uid = res.user.uid;
                const email = res.user.email || `Google-${v4().substring(0, 8)}`;
                const cryptedPw = CryptoUtil.encryptPw(CryptoUtilPrefix.Google, uid);
                return {email: email, pw: cryptedPw, joinType: JoinType.Google};
            })
    }

    static signInEmailAndPassword(params: {
        email: string,
        pw: string,
    }): Promise<string> {
        return firebase.auth()
            .signInWithEmailAndPassword(params.email, params.pw)
            .then(cred => {
                if (!cred.user) {
                    throw new GraphQLError(BizErrors.failLoginFb)
                }
                return cred.user.uid
            })

    }

    static async sendEmailVerified(email: string, pw: string): Promise<void> {
        const cred = await firebase.auth().signInWithEmailAndPassword(email, pw)
        if (!cred.user) {
            throw new GraphQLError(BizErrors.failLoginFb)
        }
        return cred.user.sendEmailVerification()
    }

    static async hasVerifiedEmail(email: string, pw: string): Promise<{ emailVerified: boolean }> {
        const cred = await firebase.auth().signInWithEmailAndPassword(email, pw)
        if (!cred.user) {
            throw new GraphQLError(BizErrors.failLoginFb)
        }

        return {emailVerified: cred.user.emailVerified}
    }

    static async sendResetPwEmail(email: string): Promise<void> {
        return firebase.auth().sendPasswordResetEmail(email)
    }

    // 소셜 로그인 관련 기능
    static clearAllCookie() {
        let cookies = document.cookie.split(";");
        document.cookie = '';

        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i];
            let eqPos = cookie.indexOf("=");
            let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    }
}
