import React, {Fragment} from "react";
import {GoodsList} from "../../../../graphql/types";
import {OnChangeValue} from "../../../Intefaces";
import {Pager} from "../../../shares/pager/Pager";
import {BizGoodsIdxList} from "../../../shares/bizGoodsIdxList/BizGoodsIdxList";

export const MyInfoGoods = (props: {
    goodsList: GoodsList,
    onChangePage: OnChangeValue<number>,
}) => {
    return (
        <Fragment>
            <BizGoodsIdxList
                goods={props.goodsList.list}/>

            <Pager
                scrollTag={'mypage'}
                offset={-70}
                page={props.goodsList.page}
                size={props.goodsList.size}
                total={props.goodsList.total}
                onChange={props.onChangePage}/>
        </Fragment>
    )
}

