export const FbKey: any = {
    dev: {
        apiKey: "AIzaSyBYo9r4Uaj6PHdHtYrMhz-OrxmxXnHF-NU",
        authDomain: "te-user-dev.firebaseapp.com",
        projectId: "te-user-dev",
        storageBucket: "te-user-dev.appspot.com",
        messagingSenderId: "189852851609",
        appId: "1:189852851609:web:1101f37ebe18ee7472c708",
        measurementId: "G-JFG1BGZY0X"
    },
    prod: {
        apiKey: "AIzaSyBWLQJQJwF1xm9cooqafvn_6-aqXV_ogvY",
        authDomain: "te-user-prod.firebaseapp.com",
        projectId: "te-user-prod",
        storageBucket: "te-user-prod.appspot.com",
        messagingSenderId: "78348619692",
        appId: "1:78348619692:web:ae79857e882a93d5993409",
        measurementId: "G-Z26R342SGC"
    }
}
