import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './BizNoDataTable.module.scss';
import {useTranslation} from "react-i18next";

const cx = classNamesBind.bind(styles);

export const BizNoDataTable = (props: {
    borderNone?: boolean
}) => {
    const [t] = useTranslation('common');

    return (
        <div className={cx('cont')}>
            <div className={cx('box-full', 'text-center')}>
                <div className={cx('table')} style={{border: props.borderNone ? 'none' : ''}}>
                    <div className={cx('col', 'col-full')}>
                        {t("common.noDataTable")}
                    </div>
                </div>
            </div>
        </div>
    )
}
