import React, {Fragment} from 'react';
import {AskBoardStatus} from "../../../graphql/types";
import {useTranslation} from "react-i18next";
import {I18nKeys} from "../../../i18n/i18nKeys";

export const BadgeAskBoardStatus = (props: {
    status: AskBoardStatus
}) => {
    const [t] = useTranslation('common');

    return (
        <Fragment>
            {props.status === AskBoardStatus.Done && <span>{t(I18nKeys.board.askBoardStatusDone.key)}</span>}
            {props.status === AskBoardStatus.InProcessing && <span>{t(I18nKeys.board.askBoardStatusInProcessing.key)}</span>}
        </Fragment>
    )
}
