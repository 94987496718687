import React, { useState } from "react";
import Popup from 'reactjs-popup';
import { Area, ImageMap } from '@qiuz/react-image-map';
import classNamesBind from "classnames/bind";
import styles from './IndexMain.module.scss';
import {BizScrollTop} from "../../shares/bizScrollTop/BizScrollTop";
import {gql} from "@apollo/client/core";
import {useQuery} from "@apollo/client";
import {IndexDataMode, Query} from "../../../graphql/types";
import {IndexMainCarousel} from "./carousel/IndexMainCarousel";
import {FadeIn} from "../../shares/fadeIn/FadeIn";
import {useSelector} from "react-redux";
import {I18nState} from "../../../redux/I18n";
import {SystemState} from "../../../redux/System";
import {IndexTitle} from "./title/IndexTitle";
import {I18nUtil} from "../../../utils/i18n/i18n";
import {IndexMainGoods} from "./goods/IndexMainGoods";
import {IndexMainImage} from "./image/IndexMainImage";

export const IndexMain = () => {
    const cx = classNamesBind.bind(styles);

    const {data} = useQuery<Query>(gqlData);
    const langCode = useSelector<any, I18nState>(state => state.i18n).langCode;
    const viewMode = useSelector<any, SystemState>(state => state.system).viewMode;

    const [open, setOpen] = useState(true);
    const closeModal = () => {
        setOpen(false);
    }


    //
    // 우수 인증상품 랜덤 출력
    //////////////////////////////////////////////////////////////////////////////
    
    // 우수 인증상품 메인화면 출력 인덱스 ID
    const RANDOM_DISPLAY_INDEX = 1;

    // 메인화면 출력상품 리스트의 카테고리 아이디 번호
    // - 테스트 카테고리 생성 후 데이터를 삭제해도 DB에서 지워지지 않는 문제가 있고,
    //   쿼리문을 수정하여 넣기에는 무리가 있어 보여 이곳에 하드코딩한다.
    const CATEGORY_IDS: any = {
        '1': 0,              // 인증상품
        '2': 3,              // 플랫폼
        '3': 41,             // 솔루션(도구)
        '4': 2,              // 콘텐츠
        '5': 4,              // 기자재
        '6': 46              // CDN
    };

    const randomGoods = {
        // 랜덤하게 생성된 인덱스를 저장한다.
        randomIndexes: [], 

        // 랜덤 인덱스를 생성한다.
        makeRandomIndex: (length : number) => {
            const index = Math.floor((Math.random() * length));
            return index;
        },

        // 중복 인덱스가 존재하는지 확인한다.
        existsDuplicatedIndex: (index : number) => {
            for(let i = 0; i < randomGoods.randomIndexes.length; i++) {
                if(randomGoods.randomIndexes[i] === index) {
                    return true;
                }
            }

            return false;
        },

        // 상품 개수만큼 랜덤인덱스를 생성한다.
        choiceRandomIndexes:(length : number) => {
            if(length > 0 && randomGoods.randomIndexes.length === length) {
                return;
            }

            const index = randomGoods.makeRandomIndex(length);
            const isDuplicated = randomGoods.existsDuplicatedIndex(index);

            if(isDuplicated) {
                randomGoods.choiceRandomIndexes(length);
            } else {
                randomGoods.randomIndexes[randomGoods.randomIndexes.length] = index;
                if(randomGoods.randomIndexes.length < length) {
                    randomGoods.choiceRandomIndexes(length);
                }
            }
        },

        // 랜덤인덱스순으로 재구성된 상품배열을 얻는다.
        getRandomIndexes: (goods : any) => {
            randomGoods.choiceRandomIndexes(goods.length);
            
            const reorderedGoods = [];
            for(let i = 0; i < randomGoods.randomIndexes.length; i++) {
                reorderedGoods[i] = goods[randomGoods.randomIndexes[i]];
            }

            return reorderedGoods;        
        },

        // 랜덤인덱스로 재구성된 상품을 리스트를 출력한다.
        display: (data : Query) => {
            data.indexGoods.data[RANDOM_DISPLAY_INDEX] = randomGoods.getRandomIndexes(
                data.indexGoods.data[RANDOM_DISPLAY_INDEX]
            );

            let items = [];
            items.push(
                data.indexIdx
                    .sort((a, b) => a.order - b.order)
                    .map((value, index) => (
                            <div key={index} style={{marginBottom: 30}}>
                                <IndexTitle categoryId={CATEGORY_IDS[''+value.id]}>{I18nUtil.value(langCode, value.nm)}</IndexTitle>
                                {/*--------------------------------------------------------------*/}
                                {/* 랜덤출력상품은 리스트를 'order'컬럼순으로 정렬하지 않는다.       */}
                                {/*--------------------------------------------------------------*/}
                                {
                                    value.dataMode === IndexDataMode.Goods && 
                                    <IndexMainGoods 
                                        indexIdx={value}
                                        indexGoodsList={data.indexGoods.data[value.id]} 
                                    />
                                }
                                {
                                    value.dataMode === IndexDataMode.Image && 
                                    <IndexMainImage 
                                        indexIdx={value}
                                        indexImageList={data.indexImage.data[value.id]}  
                                    />
                                }
                            </div>

                    ))
            )
            
            return items;
        }
        //////////////////////////////////////////////////////////////////////////////
    }

    const setCookie = (cname: string, cvalue: string, exdays: number) => {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";

        closeModal();
    }

    /*
    const deleteCookie = (cname: string) => {
        const d = new Date();
        d.setTime(d.getTime() + (24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=;" + expires + ";path=/";
    }
    */

    const getCookie = (cname:string) => {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    //---------------------------------------------------------------------------------------
    // 팝업창은 수시로 변경되기 때문에 소스 전체를 삭제하지 않고
    // 출력부분만 주석처리한다(나중에 별도의 파일로 옮길 예정).
    // 다음과 같은 warning이 발생하기 때문에 eslint-disable-next-line로 처리한다.
    // Line 177:11:  'popup' is assigned a value but never used   @typescript-eslint/no-unused-vars
    //---------------------------------------------------------------------------------------
    // eslint-disable-next-line
    const popup = () => {
        if(getCookie("aespp") === "Y") {
            return <></>;
        }

        const mapArea: Area[] = [
            {
              left: '0px',
              top: '0px',
              height: '480px',
              width: '480px',
              style: { cursor: 'pointer' }
            }
        ]

        const openPopupLink = () => {
            closeModal();

            const win = window.open("https://www.alledu.shop/cs/notice/view/1556");
            win?.focus();

            return false;
        }
        
        return (
            <Popup open={open} position="right center" modal closeOnDocumentClick={false}>
                <div /*style={{
                    marginRight: "24px",
                    marginLeft: "0px"
                }}*/>
                    <div className={cx("modal-header")}>
                        <div className={cx("modal-title")}></div>
                        <div>
                            <button className={cx("modal-close")} onClick={closeModal}>&times;</button>
                        </div>
                    </div>
                    <div>
                        <ImageMap
                            width="480px"
                            height="480px"
                            className="usage-map"
                            src="/te-statics/popup/2024edutechkoreafair-popup.png"
                            map={mapArea}
                            onMapClick={openPopupLink}
                        />
                    </div>
                    <div className={cx("modal-footer")}>
                        <input
                            id="pp-aes"
                            type="checkbox"
                            onClick={() => {
                                setCookie("aespp", "Y", 1) 
                            }}/>
                            <label htmlFor="pp-aes">&nbsp;하루 동안 이 창을 열지 않음</label>
                    </div>
                </div>
            </Popup>
        )
    }
    

    //
    // 팝업창을 띄울 때 배경화면 blur처리
    //------------------------------------------------------
    /*
    useEffect(() =>{
        let blurValue = 0;
        const setRootBlur = (value: number) => {
            document.getElementById("root")!.style.filter = "blur(" + value + "px)";
        }

        if(getCookie("aespp") === "Y") {
            setRootBlur(blurValue);
            return;
        }

        if(open) {
            blurValue = 5;
        } else {
            blurValue = 0;
        }

        setRootBlur(blurValue);
    }, [open]);
    */
   //------------------------------------------------------


    return (
        <BizScrollTop>
            {data &&
            <FadeIn>
                <div style={{height: 30}}/>
                <IndexMainCarousel
                    indexCarousel={
                        data.indexCarousel
                            .filter(value => {
                                return ((value.viewMode === viewMode) && (value.langCode === langCode))
                            })
                            .sort((a, b) => a.order - b.order)
                    }/>
                {randomGoods.display(data)}
            </FadeIn>
            }
            {/*popup()*/}
        </BizScrollTop>
    )
};

const gqlData = gql`
    query Data {
        indexCarousel {
            langCode
            link
            order
            src
            viewMode
        }
        indexIdx {
            id
            nm
            dataMode
            order
            goodsViewMode
            imageViewMode
        }
        indexGoods
        indexImage
    }
`