import React, {Fragment} from 'react';
import {IndexIdx, IndexImage, IndexImageViewMode} from "../../../../graphql/types";
import {IndexMainImageHalf} from "./half/IndexMainImageHalf";


export const IndexMainImage = (props: {
    indexIdx: IndexIdx,
    indexImageList: Array<IndexImage>,
}) => {
    return (
        <Fragment>
            {props.indexIdx.imageViewMode === IndexImageViewMode.Half &&
            <IndexMainImageHalf divide={2} list={props.indexImageList}/>}

            {props.indexIdx.imageViewMode === IndexImageViewMode.Third &&
            <IndexMainImageHalf divide={3} list={props.indexImageList}/>}

            {props.indexIdx.imageViewMode === IndexImageViewMode.Quarter &&
            <IndexMainImageHalf divide={4} list={props.indexImageList}/>}
        </Fragment>
    )
}