import React from 'react';
import {FadeIn} from "../../shares/fadeIn/FadeIn";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {useTranslation} from "react-i18next";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../graphql/errors";
import {gql, useMutation} from "@apollo/client";
import {Mutation, MutationJoinArgs,} from "../../../graphql/types";
import {AuthJoinForm} from "./form/AuthJoinForm";
import {navigate} from "hookrouter";
import {FB} from "../../../utils/fb/FB";

export const AuthJoin = () => {
    const [t] = useTranslation('common');
    const [mutJoinWithEmailAndPassword] = useMutation<Mutation, MutationJoinArgs>(gqlJoinWithEmailAndPassword);

    const handler = {
        onJoinWithEmailAndPw: (args: MutationJoinArgs) => {
            mutJoinWithEmailAndPassword({
                variables: args
            }).then(() => {
                SwalUtil.ok({
                    msg: t('auth.checkEmail'),
                    icon: 'success'
                });
                navigate('/auth/login');
                return FB.sendEmailVerified(args.email, args.pw)
            }).catch(ApolloCatch({
                [BizErrors.alreadyJoinedEmail]: SwalUtil.apolloCatch({
                    msg: t("auth.alreadyJoinedEmail"),
                    icon: 'error'
                }),
                [BizErrors.failLoginFb]: SwalUtil.apolloCatch({
                    msg: t("auth.failLoginFb"),
                    icon: 'error',
                })
            }));

        },
    }


    return (
        <FadeIn>
            <AuthJoinForm
                {...handler}/>
        </FadeIn>

    )
};

const gqlJoinWithEmailAndPassword = gql`
    mutation Join($email: String!, $pw: String!, $joinType: JoinType!) {
        join(email: $email, pw: $pw, joinType: $joinType)
    }
`;
