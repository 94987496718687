import React, {ReactNode} from "react";
import classNamesBind from "classnames/bind";
import styles from './IndexTitle.module.scss'
import { useTranslation } from "react-i18next";
import { navigate } from "hookrouter";

const cx = classNamesBind.bind(styles);

export const IndexTitle = (props: {
    children: ReactNode,
    categoryId: number
}) => {
    const [t] = useTranslation('common');
    return (
        <>
            <div className={cx('cont')}>
                <div className={cx('title-container')}>
                    <h4 className={cx('title')}>{props.children}</h4>
                    <div className={cx('more')}>
                        <a 
                            href="#!"
                            onClick={() => {
                                navigate(`/goods/category/${props.categoryId}`)
                                return false;
                            }}
                        >
                        {t('common.btnMore')}
                        </a>
                    </div>
                </div>
                <div className={cx('line')}></div>
            </div>
        </>
    )
}
