import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './MyPageCompanyCreateInput.module.scss';
import {CompanyData} from "../../../../../graphql/customScalars";
import {OnChangeValue} from "../../../../Intefaces";
import {useTranslation} from "react-i18next";
import {InputLogo} from "../../../share/inputs/inputLogo/InputLogo";
import {InputText} from "../../../share/inputs/inputText/InputText";
import {CopyUtil} from "../../../../../utils/copyUtil/copyUtil";
import {InputNumber} from "../../../share/inputs/inputNumber/InputNumber";
import {InputDatepicker} from '../../../share/inputs/inputDatepicker/InputDatepicker';
import {InputNationPicker} from "../../../share/inputs/inputNationPicker/InputNationPicker";
import {InputCompanyType} from "../../../share/inputs/inputCompanyType/InputCompanyType";
import {InputNationSales} from "../../../share/inputs/inputNationSales/InputNationSales";
import {InputNationUsage} from "../../../share/inputs/inputNationUsage/InputNationUsage";
import {InputTotalSales} from "../../../share/inputs/inputTotalSales/InputTotalSales";
import {InputFiles} from "../../../share/inputs/inputFiles/InputFiles";
import {InputTextarea} from "../../../share/inputs/inputTextarea/InputTextarea";

const cx = classNamesBind.bind(styles);

interface Props {
    input: CompanyData,
    onChangeInput: OnChangeValue<CompanyData>
}

export const MyPageCompanyCreateInput = (props: Props) => {
    return (
        <>
            <div className='web-view'><Web {...props}/></div>
            <div className='mobile-view'><Mobile {...props}/></div>
        </>
    )
}

const Web = (props: Props) => {
    const [t] = useTranslation('common');

    return (
        <>
            <div className={cx('cont')}>
                <div className={cx('box', 'sm')}>
                    <InputLogo {...props}/>
                </div>

                <div className={cx('box', 'sm')}>
                    <InputText
                        title={t('mypageJoin.ceo')}
                        value={props.input.ceo}
                        onChange={v => {
                            const copy = CopyUtil.copyAll(props.input);
                            copy.ceo = v;
                            props.onChangeInput(copy);
                        }}/>
                </div>

                <div className={cx('box', 'sm')}>
                    <InputText
                        pattern={/^[A-Za-z0-9]([A-Za-z0-9_\-.,()&+'\s])*$/gi}
                        title={t('mypageJoin.nm')}
                        value={props.input.companyNm}
                        onChange={v => {
                            const copy = CopyUtil.copyAll(props.input);
                            copy.companyNm = v;
                            props.onChangeInput(copy);
                        }}/>
                </div>

                <div className={cx('box', 'sm')}>
                    <InputText
                        title={t('mypageJoin.category')}
                        value={props.input.category}
                        onChange={v => {
                            const copy = CopyUtil.copyAll(props.input);
                            copy.category = v;
                            props.onChangeInput(copy);
                        }}/>
                </div>

                <div className={cx('box', 'sm')}>
                    <InputText
                        title={t('mypageJoin.addr')}
                        value={props.input.addr}
                        onChange={v => {
                            const copy = CopyUtil.copyAll(props.input);
                            copy.addr = v;
                            props.onChangeInput(copy);
                        }}/>
                </div>

                <div className={cx('box', 'sm')}>
                    <InputNumber
                        title={t('mypageJoin.member')}
                        value={props.input.member}
                        onChange={v => {
                            const copy = CopyUtil.copyAll(props.input);
                            copy.member = v;
                            props.onChangeInput(copy);
                        }}/>
                </div>


                <div className={cx('box', 'sm')}>
                    <InputDatepicker
                        title={t('mypageJoin.foundation')}
                        value={props.input.foundation}
                        onChange={v => {
                            const copy = CopyUtil.copyAll(props.input);
                            copy.foundation = v;
                            props.onChangeInput(copy);
                        }}/>
                </div>

                <div className={cx('box', 'sm')}>
                    <InputText
                        title={t('mypageJoin.hp')}
                        value={props.input.hp}
                        onChange={v => {
                            const copy = CopyUtil.copyAll(props.input);
                            copy.hp = v;
                            props.onChangeInput(copy);
                        }}/>
                </div>

                <div className={cx('box', 'sm')}>
                    <InputNationPicker
                        title={t('mypageJoin.nation')}
                        value={props.input.nationCode}
                        onChange={v => {
                            const copy = CopyUtil.copyAll(props.input);
                            copy.nationCode = v;
                            props.onChangeInput(copy);
                        }}/>
                </div>

                <div className={cx('box', 'sm')}>
                    <InputCompanyType
                        title={t('mypageJoin.companyType')}
                        value={props.input.companyTypeId}
                        onChange={v => {
                            const copy = CopyUtil.copyAll(props.input);
                            copy.companyTypeId = v;
                            props.onChangeInput(copy);
                        }}/>
                </div>

                {/*<div className={cx('box', 'sm')}>*/}
                {/*    <InputPriceUnit*/}
                {/*        title={t('기준 화폐')}*/}
                {/*        value={props.input.priceUnit}*/}
                {/*        onChange={v => {*/}
                {/*            const copy = CopyUtil.copyAll(props.input);*/}
                {/*            copy.priceUnit = v;*/}
                {/*            props.onChangeInput(copy);*/}
                {/*        }}/>*/}
                {/*    ※ 기준화폐는 아래의 매출액에 대한 화폐 단위입니다.*/}
                {/*</div>*/}

                <div className={cx('box', 'sm')}>
                    <InputText
                        title={t('mypageJoin.introduceYoutubeUrl')}
                        value={props.input.introduceYoutubeUrl}
                        onChange={v => {
                            const copy = CopyUtil.copyAll(props.input);
                            copy.introduceYoutubeUrl = v;
                            props.onChangeInput(copy);
                        }}/>
                </div>

            </div>

            {/* 여러개 등록하는 인풋값 */}
            <div className={cx('cont')}>
                <div className={cx('box', 'sm')}>
                    <InputFiles
                        title={t('mypageJoin.attachment')}
                        value={props.input.companyFiles}
                        onChange={v => {
                            const copy = CopyUtil.copyAll(props.input);
                            copy.companyFiles = v;
                            props.onChangeInput(copy);
                        }}
                    />
                </div>

                <div className={cx('box', 'sm')}>
                    <InputNationSales
                        title={t('mypageJoin.nationSales')}
                        value={props.input.nationSales}
                        onChangeValue={v => {
                            const copy = CopyUtil.copyAll(props.input);
                            copy.nationSales = v;
                            props.onChangeInput(copy);
                        }}/>
                </div>

                <div className={cx('box', 'sm')}>
                    <InputNationUsage
                        title={t('mypageJoin.nationUsage')}
                        value={props.input.nationUsage}
                        onChangeValue={v => {
                            const copy = CopyUtil.copyAll(props.input);
                            copy.nationUsage = v;
                            props.onChangeInput(copy);
                        }}/>
                </div>

                <div className={cx('box', 'sm')}>
                    <InputTotalSales
                        title={t('mypageJoin.totalSales')}
                        value={props.input.totalSales}
                        onChangeValue={v => {
                            const copy = CopyUtil.copyAll(props.input);
                            copy.totalSales = v;
                            props.onChangeInput(copy);
                        }}/>
                </div>


                <div className={cx('box')}>
                    <InputTextarea
                        title={t('mypageJoin.catchphrase')}
                        value={props.input.catchphrase}
                        onChange={v => {
                            const copy = CopyUtil.copyAll(props.input);
                            copy.catchphrase = v;
                            props.onChangeInput(copy);
                        }}/>
                </div>
            </div>
        </>
    )
}

const Mobile = (props: Props) => {
    return (
        <><Web {...props}/></>
    )
}
