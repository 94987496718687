import React, {Fragment, ReactNode, useState} from 'react';
import classNamesBind from "classnames/bind";
import styles from './InputCategory.module.scss';
import {OnChangeValue} from "../../../../Intefaces";
import {useSelector} from "react-redux";
import {CategUtil} from "../../../../layout/web/categ/CategUtil";
import {Breadcrumbs} from "@material-ui/core";
import {NavigateNext} from "@material-ui/icons";
import {I18nState} from "../../../../../redux/I18n";
import {I18nUtil} from "../../../../../utils/i18n/i18n";
import {Category} from "../../../../../graphql/types";
import {DataState} from "../../../../../redux/Data";

const cx = classNamesBind.bind(styles);

export const InputCategory = (props: {
    title?: ReactNode,
    value: number,
    onChange: OnChangeValue<number>
}) => {
    const i18n = useSelector<any, I18nState>(state => state.i18n);
    const category = useSelector<any, DataState>(state => state.data).category;
    const superCateg = CategUtil.getSuperCategory({parentId: props.value, category});
    const [first] = useState<Category[]>(CategUtil.getSubWithParentId({parentId: 1, category}));
    const [subs, setSubs] = useState<Array<Category[]>>([]);


    const onClick = (selectedCategory: Category) => {
        props.onChange(selectedCategory.id);

        let copy = CategUtil.copyChildNode(subs);
        const childLength = selectedCategory.depth - 1;
        while (childLength < copy.length) {
            copy.pop();
        }

        const appendCateg = CategUtil.getSubWithParentId({parentId: selectedCategory.id, category});

        if (appendCateg) {
            copy.push(appendCateg);
        }
        setSubs(copy);
    }

    return (
        <Fragment>
            {props.title && <div className={cx('title')}>{props.title}</div>}
            <div className='form-control' style={{marginBottom: 15}}>
                <Breadcrumbs separator={<NavigateNext fontSize="small"/>}>
                    {superCateg.map((value, index) => {
                        return (
                            <div key={index}>{I18nUtil.value(i18n.langCode, value.nm)}</div>
                        )
                    })}
                </Breadcrumbs>
            </div>

            <div className={cx('cont-scroll')}>
                <div className={cx('cont')}>
                    <div className={cx('box')}>
                        {first.map((value, index) => {
                            return (
                                <div className={cx('item')} key={index}
                                     onClick={event => {
                                         onClick(value);
                                     }}>
                                    {I18nUtil.value(i18n.langCode, value.nm)}
                                </div>
                            )
                        })}
                    </div>

                    {subs.map((parent, pIdx) => {
                        return (
                            <div key={pIdx} className={cx('box')}>
                                {parent.map((item, cIdx) => {
                                    return (
                                        <div className={cx('item')} key={cIdx + pIdx}
                                             onClick={() => {
                                                 onClick(item);
                                             }}>
                                            {I18nUtil.value(i18n.langCode, item.nm)}
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>

            </div>


        </Fragment>
    )
}
