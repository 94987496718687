import React from 'react';
import styles from './ChatV1SearchBar.module.scss';
import {OnChangeValue, OnVoidFunction} from "../../../../Intefaces";
import {SwalUtil} from "../../../../../utils/swal/swalUtil";
import {ChatV1PageQueryParams} from "../../ChatV1";
import {useTranslation} from "react-i18next";
import {I18nKeys} from "../../../../../i18n/i18nKeys";

export const ChatV1SearchBar = (props: {
    params: ChatV1PageQueryParams,
    onChangeFindWord: OnChangeValue<string>,
    onClear: OnVoidFunction,
}) => {
    const [t] = useTranslation('common');
    return (
        <form
            className={styles.cont}
            onSubmit={ev => {
                ev.preventDefault();
                if (!props.params.findWord) {
                    SwalUtil.ok({
                        msg: t(I18nKeys.chat.swalInputSearchError.key),
                        icon: 'error',
                    });
                    return;
                }
            }}>
            <input
                value={props.params.findWord || ''}
                onChange={ev => props.onChangeFindWord(ev.target.value)}
                placeholder={t(I18nKeys.chat.placeholderSearchInput.key)}
                className='form-control form-control-sm'/>
            <div className={styles.contBtn}>
                <button
                    onClick={props.onClear}
                    type='button'
                    className='btn btn-sm btn-outline-success'>{t(I18nKeys.chat.searchBarBtnInit.key)}
                </button>
                <button
                    type='submit'
                    className='btn btn-sm btn-success'>{t(I18nKeys.chat.searchBarBtnFind.key)}
                </button>
            </div>
        </form>
    )
}
