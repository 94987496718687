import React, {useState} from 'react';
import {gql, useMutation, useQuery} from "@apollo/client";
import {
    Mutation,
    MutationToggleCompanyConsultantArgs,
    MutationUnbindGoodsAllWithConsultantArgs,
    MutationUnbindGoodsWithConsultantArgs,
    Query
} from "../../../../graphql/types";
import {MyPageLayout} from "../../layout/MyPageLayout";
import {BizTitle} from "../../../shares/bizTitle/BizTitle";
import {BizPeriodPicker, Period} from "../../../shares/bizPeriodPicker/BizPeriodPicker";
import moment from "moment";
import {Pipe} from "../../../../utils/pipe/pipe";
import {FormControlLabel, IconButton, Switch, Tooltip} from "@material-ui/core";
import {MyPageConsultantDetailView} from "./view/MyPageConsultantDetailView";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import {MyPageConsultantDetailGraphViews} from "./graphViews/MyPageConsultantDetailGraphViews";
import {Pager} from "../../../shares/pager/Pager";
import {navigate} from "hookrouter";
import {DeleteOutlined} from "@material-ui/icons";
import {NotFound} from "../../../system/notFound/NotFound";
import {MyPageConsultantDetailGoodsList} from "./goodsList/MyPageConsultantDetailGoodsList";
import {ApolloCatch} from "../../../../utils/apollo/apolloCatch";
import {useTranslation} from "react-i18next";

export const MyPageConsultantDetail = (props: {
    companyConsultantId: number,
    page: number,
}) => {
    const [t] = useTranslation('common');
    const [period, setPeriod] = useState<Period>({
        stDate: moment().days(-7).toDate(),
        edDate: moment().toDate(),
    });
    const {data, refetch, error} = useQuery<Query, any>(gqlData, {
        variables: {
            companyConsultantId: props.companyConsultantId,
            stDate: Pipe.toTimeDay(period.stDate),
            edDate: Pipe.toTimeDay(period.edDate),
            page: props.page,
        }
    });
    const [mutToggle] = useMutation<Mutation, MutationToggleCompanyConsultantArgs>(gqlToggle);
    const [mutUnbind] = useMutation<Mutation, MutationUnbindGoodsWithConsultantArgs>(gqlUnbind);
    const [mutUnbindAll] = useMutation<Mutation, MutationUnbindGoodsAllWithConsultantArgs>(gqlUnbindAll);

    const handler = {
        onChangePeriod: (args: Period) => {
            if (30 < moment(args.edDate).diff(args.stDate, 'd')) {
                SwalUtil.ok({
                    msg: t("mypageConsultant.swalChangePeriodError"),
                    icon: 'error'
                });
                return;
            }
            setPeriod(args);
        },
        onChangePage: (p: number) => {
            navigate(`/mypage/consultant/detail/${props.companyConsultantId}/${p}`)
        },
        onUnbind: (goodsId: number) => {
            SwalUtil.ynPromise({
                msg: t("mypageConsultant.swalUnbindQuestion"),
            }).then(() => {
                return mutUnbind({
                    variables: {
                        consultantId: props.companyConsultantId,
                        goodsId
                    }
                })
            }).then(() => {
                return refetch()
            }).then(() => {
                SwalUtil.ok({
                    msg: t("mypageConsultant.swalUnBindOk"),
                    icon: "success"
                })
            }).catch(ApolloCatch({}));
        },
        onUnbindAll: () => {
            SwalUtil.ynPromise({
                msg: t("mypageConsultant.swalUnbindAllQuestion"),
            }).then(() => {
                return mutUnbindAll({
                    variables: {
                        consultantId: props.companyConsultantId,
                    }
                })
            }).then(() => {
                return refetch()
            }).then(() => {
                SwalUtil.ok({
                    msg: t("mypageConsultant.swalUnBindOk"),
                    icon: "success"
                })
            }).catch(ApolloCatch({}));
        },
        onToggle: (args: MutationToggleCompanyConsultantArgs) => {
            SwalUtil.ynPromise({
                msg: args.swt ? t("mypageConsultant.swalToggleActivateQuestion") : t("mypageConsultant.swalToggleInactivateQuestion")
            }).then(() => {
                return mutToggle({variables: args})
            }).then(() => {
                return refetch();
            }).then(() => {
                SwalUtil.ok({
                    msg: t("mypageConsultant.swalToggleOk"),
                    icon: 'success'
                })
            }).catch(ApolloCatch({}));
        },
    };

    return (
        <MyPageLayout activatedSuffixRouter={'/consultant/list'}>
            {error && <NotFound/>}

            {data &&
            <>
                <BizTitle desc={
                    <FormControlLabel
                        control={
                            <Switch
                                color='primary'
                                checked={data.myCompanyConsultantWithId.isActivate}
                                onChange={ev => handler.onToggle({
                                    companyConsultantId: props.companyConsultantId,
                                    swt: ev.target.checked
                                })}/>
                        }
                        label={data.myCompanyConsultantWithId.isActivate ? t("mypageConsultant.isActivate") : t("mypageConsultant.isNotActivate")}/>

                }>{t("mypageConsultant.consultantDetailTitle")}</BizTitle>
                <MyPageConsultantDetailView
                    consultant={data.myCompanyConsultantWithId}/>


                <div style={{height: 50}}/>
                <BizTitle
                    desc={
                        <>
                            <BizPeriodPicker
                                period={period}
                                onChange={handler.onChangePeriod}/>
                        </>
                    }>{t("mypageConsultant.viewCount")} : {Pipe.toSeparatorNumber(data.myCompanyConsultantWithId.totalView)}</BizTitle>
                <MyPageConsultantDetailGraphViews
                    views={data.myCompanyConsultantStatics.views}/>

                <div style={{height: 30}}/>
                <BizTitle desc={
                    <Tooltip title={t("mypageConsultant.ttUnbindAll") || ""}>
                        <IconButton onClick={() => handler.onUnbindAll()}>
                            <DeleteOutlined/>
                        </IconButton>
                    </Tooltip>
                }>{t("mypageConsultant.bindGoodsListTitle")}</BizTitle>

                <MyPageConsultantDetailGoodsList
                    {...handler}
                    value={data.myGoodsWithConsultant.list}/>

                <Pager
                    page={data.myGoodsWithConsultant.page}
                    size={data.myGoodsWithConsultant.size}
                    total={data.myGoodsWithConsultant.total}
                    onChange={handler.onChangePage}/>
                <div style={{height: 80}}/>

            </>
            }

        </MyPageLayout>
    )
}

const gqlData = gql`
    query DATA($companyConsultantId: Int!, $stDate: Int!, $edDate: Int!, $page: Int!){
        myCompanyConsultantWithId(companyConsultantId:$companyConsultantId){
            id
            isActivate
            permission
            email
            tel
            position
            nm
            totalView
            createdAt
        }
        myGoodsWithConsultant(companyConsultantId:$companyConsultantId, page:$page, size:20){
            page
            size
            total
            list {
                langCode
                goods {
                    id
                    allEduCode
                    categoryId
                    isActivate
                    isPaid
                    data
                    company
                    companyUserId
                }
            }
        }
        myCompanyConsultantStatics(companyConsultantId:$companyConsultantId, stDate:$stDate, edDate:$edDate){
            views{
                date
                view
            }
        }
        isRegisteredCompanyConsultantWithId(companyConsultantId: $companyConsultantId)
    }
`;


const gqlToggle = gql`
    mutation Toggle($companyConsultantId: Int!, $swt: Boolean!) {
        toggleCompanyConsultant(companyConsultantId: $companyConsultantId, swt: $swt)
    }
`;

const gqlUnbind = gql`
    mutation Unbind($goodsId: Int!, $consultantId: Int!) {
        unbindGoodsWithConsultant(goodsId: $goodsId, consultantId: $consultantId)
    }
`


const gqlUnbindAll = gql`
    mutation UnbindAll( $consultantId: Int!) {
        unbindGoodsAllWithConsultant(consultantId: $consultantId)
    }
`
