import React, {useEffect, useState} from 'react';
import {useMutation, useQuery, useReactiveVar} from "@apollo/client";
import {Mutation, MutationCreateGoodsRetryArgs, Query} from "../../../../../graphql/types";
import {CopyUtil} from "../../../../../utils/copyUtil/copyUtil";
import {MyPageGoodsTabs, MyPageGoodsTabsData} from "../../share/tabs/MyPageGoodsTabs";
import {useTranslation} from "react-i18next";
import {GlobalVars} from "../../../../../globalVars/globalVars";
import {InputCreateGoods} from "../new/input/InputCreateGoods";
import {Pipe} from "../../../../../utils/pipe/pipe";
import {GoodsDataMapUtil} from "../../../../../utils/goodsDataMapUtil/goodsDataMapUtil";
import {SwalUtil} from "../../../../../utils/swal/swalUtil";
import {DataUtil} from "../../../../../utils/data/DataUtil";
import {navigate} from "hookrouter";
import {ApolloCatch} from "../../../../../utils/apollo/apolloCatch";
import {MyPageLayout} from "../../../layout/MyPageLayout";
import {BizTitle} from "../../../../shares/bizTitle/BizTitle";
import {InputCategory} from "../../../share/inputs/inputCategory/InputCategory";
import {InputQuill} from "../../../share/inputs/inputQuill/InputQuill";
import {gql} from "@apollo/client/core";

export const MyPageGoodsRegistryCreate = (props: {
    goodsCreateRequestId: number,
}) => {
    useQuery<Query>(gqlData, {
        variables: {
            id: props.goodsCreateRequestId
        },
        onCompleted: res => {
            const nextUpdateGoodsArgs: MutationCreateGoodsRetryArgs = {
                input: {
                    goodsCreateRequestId: props.goodsCreateRequestId,
                    categoryId: res.goodsCreateRequest.categoryId,
                    data: CopyUtil.copyAll(res.goodsCreateRequest.data),
                }
            };
            setUpdateGoods(nextUpdateGoodsArgs);
        }
    });
    const [mutUpdate] = useMutation<Mutation, MutationCreateGoodsRetryArgs>(gqlUpdate);
    const [updateGoods, setUpdateGoods] = useState<MutationCreateGoodsRetryArgs | undefined>();
    const [tabData, setTabData] = useState<MyPageGoodsTabsData | undefined>();
    const [langCode, setLangCode] = useState('en');
    const [t] = useTranslation('common');
    const quillData = useReactiveVar(GlobalVars.quill.createGoods);

    //*------------------------------------------------------------*/
    // 다음 경고를 무시하게 한다. 
    // 해당 경고를 수정했을 경우 InputQuill이 제대로 동작하지 않는다.
    // (※경고를 수정했으나 InputQuill이 수정되지 않는 문제가 발생하여
    //    소스를 원상복구한다.)
    //---------------------------------------------------------------
    // React Hook useEffect has a missing dependency: 'updateGoods'.
    // Either include it or remove the dependency array  react-hooks/exhaustive-deps
    //*------------------------------------------------------------*/
    useEffect(() => {
        if (!updateGoods) {
            return;
        }

        const copy = CopyUtil.copyAll(updateGoods);
        if (!copy.input.data.hasOwnProperty(quillData.langCode)) {
            return;
        }

        copy.input.data[quillData.langCode].contents = quillData.content;
        setUpdateGoods(copy);
    // eslint-disable-next-line
    }, [quillData]);

    // 에디터 초기화
    useEffect(() => {
        return () => {
            GlobalVars.quill.createGoods({langCode: '', content: ''})
        }
    }, [])


    useEffect(() => {
        // 업데이트값은 있고 텝 데이터가 없을떄만 실행한다.
        if ((!updateGoods)) {
            return;
        }

        const goodsTabsData: MyPageGoodsTabsData = {};
        goodsTabsData['en'] = {
            title: t("mypageGoods.english"),
            children: <InputCreateGoods
                value={updateGoods.input.data['en']}
                onChangeValue={v => {
                    const copy = CopyUtil.copyAll(updateGoods);
                    copy.input.data['en'] = v;
                    setUpdateGoods(copy);
                }}/>
        }


        for (let langCode of Object.keys(updateGoods.input.data).filter(value => {
            return value !== 'en';
        })) {
            goodsTabsData[langCode] = {
                title: Pipe.langNm(langCode),
                children: <InputCreateGoods
                    value={updateGoods.input.data[langCode]}
                    onChangeValue={v => {
                        const copy = CopyUtil.copyAll(updateGoods);
                        copy.input.data[langCode] = v;
                        setUpdateGoods(copy);
                    }}/>
            }
        }

        setTabData(goodsTabsData);
    }, [updateGoods, t]);


    const handler = {
        onUpdate: () => {
            if (!updateGoods) {
                return;
            }

            const res = GoodsDataMapUtil.validate(updateGoods.input.data);
            if (!res.isOk) {
                SwalUtil.ok({
                    msg: t(res.tValue),
                    icon: 'error'
                });
                return;
            }

            SwalUtil.yn({
                msg: t("mypageGoods.swalUpdateGoodsQuestion"),
                icon: 'question',
                ok: () => {
                    mutUpdate({
                        variables: updateGoods
                    }).then(() => {
                        return DataUtil.getDefaultData()
                    }).then(() => {
                        SwalUtil.ok({
                            msg: t("mypageGoods.swalUpdateGoodsOk"),
                            icon: "success"
                        });
                        navigate('/mypage/goods/list')
                    }).catch(ApolloCatch({}));
                }
            });
        },

    };

    return (
        <MyPageLayout activatedSuffixRouter={'/goods/list'}>
            {updateGoods && tabData &&
            <>
                <BizTitle>{t("mypageGoods.updateTitle")}</BizTitle>

                <div style={{height: 20}}/>
                <InputCategory
                    title={t('mypageGoods.category')}
                    value={updateGoods.input.categoryId}
                    onChange={v => {
                        const copy = CopyUtil.copyAll(updateGoods);
                        copy.input.categoryId = v;
                        setUpdateGoods(copy);
                    }}/>

                <div style={{height: 20}}/>
                <MyPageGoodsTabs
                    langCode={langCode}
                    data={tabData}
                    onChangeLangCode={setLangCode}/>

                <InputQuill
                    title={t("mypageGoods.titleQuill")}
                    langCode={langCode}
                    value={updateGoods.input.data[langCode].contents}
                    from="goods"
                    onChangeValue={v => {
                        const copy = CopyUtil.copyAll(updateGoods);
                        copy.input.data[langCode].contents = v;
                        setUpdateGoods(copy);
                    }}/>


                <hr/>
                <div className='text-right' style={{marginBottom: 100}}>
                    <button
                        style={{marginRight: 10}}
                        className='btn btn-sm btn-success'
                        onClick={handler.onUpdate}>
                        {t("mypageGoods.btnUpdate")}
                    </button>
                </div>
            </>
            }
        </MyPageLayout>
    )
}


const gqlUpdate = gql`
    mutation Update($input: CreateGoodsRetry!) {
        createGoodsRetry(input: $input)
    }
`;


const gqlData = gql`
    query Data($id: Int!) {
        goodsCreateRequest(id: $id) {
            id
            categoryId
            data
            createdAt
        }
    }
`;
