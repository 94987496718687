import React, {useState} from 'react';
import classNamesBind from "classnames/bind";
import styles from './AuthJoinForm.module.scss';
import {A} from "hookrouter";
import {useTranslation} from "react-i18next";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import {OnChangeValue} from "../../../Intefaces";
import {JoinType} from "../../../../graphql/types";
import {AuthSocialLogin} from "../../share/socialLogin/AuthSocialLogin";
import imgLogo from '../../../../assets/img/svg/AllEdu.Shop_Logo_v3.svg';

const cx = classNamesBind.bind(styles);

export const AuthJoinForm = (props: {
    onJoinWithEmailAndPw: OnChangeValue<{ email: string, pw: string, joinType: JoinType }>,
}) => {
    const [t] = useTranslation('common');
    const [email, setEmail] = useState('');
    const [pw, setPw] = useState('');
    const [confirm, setConfirm] = useState('');
    const [terms, setTerms] = useState(false)

    const handler = {
        onJoin: () => {
            if ((!email) || (!pw) || (!confirm)) {
                SwalUtil.ok({
                    msg: t('auth.enterAllField'),
                    icon: 'success'
                })
                return;
            }

            if (!terms) {
                SwalUtil.ok({
                    msg: t('auth.checkTerms'),
                    icon: 'error'
                })
                return;
            }

            if (pw !== confirm) {
                SwalUtil.ok({
                    msg: t('auth.notSamePw'),
                    icon: 'error'
                })
                return;
            }

            props.onJoinWithEmailAndPw({email, pw, joinType: JoinType.Email});
        }
    }

    return (
        <>
            <div style={{height: 80}}/>
            <img className={cx('img-logo')} src={imgLogo} alt={'alledu.shop'}/>
            <form className={cx('box-join')} onSubmit={ev => {
                ev.preventDefault();
                handler.onJoin();
            }}>
                <p>{t("auth.email")}</p>
                <input
                    required
                    onChange={event => {
                        setEmail(event.target.value)
                    }}
                    value={email} className="form-control"
                    type="email"
                    placeholder={t('auth.phEmail')}/>

                <p>{t("auth.password")}</p>
                <input
                    required
                    maxLength={20}
                    onChange={event => {
                        setPw(event.target.value)
                    }}
                    value={pw}
                    className="form-control"
                    type="password"
                    placeholder={t('auth.phPw')}/>

                <p>{t("auth.confirm")}</p>
                <input
                    required
                    maxLength={20}
                    onChange={event => {
                        setConfirm(event.target.value)
                    }}
                    value={confirm}
                    className="form-control" type="password" placeholder={t('auth.phConfirm')}/>

                <hr/>
                <div className={cx('box-terms')}>
                    <input
                        onChange={event => {
                            setTerms(event.target.checked)
                        }}
                        checked={terms}
                        id="terms" className={cx("check-box") + " form-control"} type="checkbox"/>
                    <label htmlFor="terms">{t('auth.phTerms')}</label>
                </div>

                <div style={{height: 40}}/>
                <button
                    type="submit"
                    className="btn btn-primary btn-block">
                    {t('auth.btnJoin')}
                </button>


                <div style={{height: 10}}/>
                <p style={{marginRight: 10}}>{t('auth.goLogin')}
                    <A href={'/auth/login'} style={{paddingLeft: 10}}>{t('auth.btnLogin')}</A>
                </p>
                <p style={{marginTop: 0}}>{t('auth.goReset')}
                    <A href={'/auth/reset'} style={{paddingLeft: 10}}>{t('auth.btnReset')}</A>
                </p>

                <hr/>
                <AuthSocialLogin title={t("auth.snsJoin")}/>
            </form>

            <p className={cx('copyright')}>{t('common.copyright')}</p>

        </>
    )
}
