import React, {Fragment, ReactNode} from 'react';
import classNamesBind from "classnames/bind";
import styles from './InputDatepicker.module.scss';
import DatePicker, {registerLocale} from "react-datepicker";
import ko from "date-fns/locale/ko";
import en from "date-fns/locale/en-US";
import {OnChangeValue} from "../../../../Intefaces";
import {useSelector} from "react-redux";
import {I18nState} from "../../../../../redux/I18n";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const cx = classNamesBind.bind(styles);
registerLocale('ko', ko);
registerLocale('en', en);

export const InputDatepicker = (props: {
    title: ReactNode
    value: Date,
    onChange: OnChangeValue<Date>
}) => {
    const i18n = useSelector<any, I18nState>(state => state.i18n);

    return (
        <>
            <div className={cx('title')}>{props.title}</div>
            <div className={cx('cont')}>
                <DatePicker
                    className='form-control form-control-sm text-center'
                    wrapperClassName={cx('date-picker')}
                    locale={i18n.langCode === 'ko' ? 'ko' : 'en'}
                    dateFormat={"yyyy/MM/dd"}
                    selected={moment(props.value).toDate()}
                    showMonthDropdown={true}
                    showYearDropdown={true}
                    onChange={date => {
                        if (date instanceof Date) {
                            props.onChange(date);
                        }
                    }}/>
            </div>
        </>
    )
}
