import React, {useEffect, useState} from 'react';
import {gql, useMutation} from "@apollo/client";
import {AuthBackdoorInput} from "./input/AuthBackdoorInput";
import {Mutation, MutationBackdoorLoginArgs} from "../../../graphql/types";
import {CryptoUtil} from "../../../utils/crypto/CryptoUtil";
import {LocalStorage} from "../../../utils/localstorage/LocalStorage";
import {navigate} from "hookrouter";
import {DataUtil} from "../../../utils/data/DataUtil";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {useDispatch} from "react-redux";
import {UserAction} from "../../../redux/User";
import {useTranslation} from "react-i18next";

export interface AuthBackdoorInputData {
    email: string;
    pw: string;
}

export const AuthBackdoor = (props: {
    userId: number,
}) => {
    const [input, setInput] = useState<AuthBackdoorInputData>({
        email: '',
        pw: '',
    })

    const [mutLogin] = useMutation<Mutation, MutationBackdoorLoginArgs>(gqlLogin);
    const dispatch = useDispatch();
    const [t] = useTranslation('common');

    useEffect(() => {
        LocalStorage.clearSession();
        dispatch(UserAction.logout());
    }, [dispatch]);

    const handler = {
        onLogin: () => {
            mutLogin({
                variables: {
                    email: input.email,
                    pw: CryptoUtil.adminPw(input.pw),
                    userId: props.userId
                }
            }).then(res => {
                LocalStorage.setSession(res.data!.backdoorLogin);
                navigate('/');
                return DataUtil.refreshSession()
            }).catch(() => {
                SwalUtil.ok({
                    msg: t("auth.swalLoginOk"),
                    icon: 'success'
                })
            }).catch(ApolloCatch({}));

        }
    };

    return (
        <>
            <AuthBackdoorInput
                {...handler}
                userId={props.userId}
                value={input}
                onChange={setInput}/>
        </>
    )
}


const gqlLogin = gql`
    mutation BackdoorLogin($userId: Int!, $email: String!, $pw: String!) {
        backdoorLogin(userId: $userId, email: $email, pw: $pw)
    }
`;
