import React, {useEffect, useState} from 'react';
import classNamesBind from "classnames/bind";
import styles from './BizCompanyDataViewHeaderInteraction.module.scss';
import {AssignmentIndOutlined, ChatBubbleOutline, Favorite, FavoriteBorder, ShareOutlined} from "@material-ui/icons";
import {Button} from "@material-ui/core";
import {useSelector} from "react-redux";
import {UserState} from "../../../../../redux/User";
import {gql, useLazyQuery, useMutation} from '@apollo/client';
import {
    CompanyPermission,
    Mutation,
    MutationDislikeCompanyArgs,
    MutationLikeCompanyArgs,
    Query,
    QueryIsLikeCompanyArgs
} from "../../../../../graphql/types";
import {CopyUtil} from "../../../../../utils/copyUtil/copyUtil";
import {ApolloCatch} from "../../../../../utils/apollo/apolloCatch";
import {CompanyData} from "../../../../../graphql/customScalars";
import {BizSocialShareModal} from "../../../bizSocialShareModal/BizSocialShareModal";
import {navigate, usePath} from "hookrouter";
import {useTranslation} from "react-i18next";
import {SwalUtil} from "../../../../../utils/swal/swalUtil";
import {I18nKeys} from "../../../../../i18n/i18nKeys";
import {BizAskBoardModal} from "../../../bizAskBoardModal/BizAskBoardModal";
import {OnChangeValue} from "../../../../Intefaces";
import {useDeepCompareEffect} from "use-deep-compare";

const cx = classNamesBind.bind(styles);

interface Props {
    companyUserId?: number,
    companyData: CompanyData,
    onCreateChatRoomWithCompanyId?: OnChangeValue<number>
}


interface InteractionState {
    isLogin: boolean;
    userLikeCompany: boolean;
}


export const BizCompanyDataViewHeaderInteraction = (props: Props) => {
    const user = useSelector<any, UserState>(state => state.user);
    const [state, setState] = useState<InteractionState>({
        isLogin: user.isLogin,
        userLikeCompany: false,
    });
    const [askBoardModal, setAskBoardModal] = useState(false);
    const [shareModal, setShareModal] = useState(false);
    const [getUserLikeCompany, resUserLikeCompany] = useLazyQuery<Query, QueryIsLikeCompanyArgs>(gqlIsLike);
    const [mutLike] = useMutation<Mutation, MutationLikeCompanyArgs>(gqlLike);
    const [mutDislike] = useMutation<Mutation, MutationDislikeCompanyArgs>(gqlDislike);


    const path = usePath();
    const [t] = useTranslation('common');

    useEffect(() => {
        if (!(props.companyUserId && user.isLogin)) {
            return;
        }

        getUserLikeCompany({
            variables: {
                companyUserId: props.companyUserId!
            }
        });
    }, [props.companyUserId, getUserLikeCompany, user.isLogin]);

    useDeepCompareEffect(() => {
        if (!resUserLikeCompany.data) {
            return;
        }

        const copy = CopyUtil.copyAll(state);
        copy.userLikeCompany = resUserLikeCompany.data.isLikeCompany;
        setState(copy);

    }, [resUserLikeCompany, state]);

    const handler = {
        onClickLike: () => {
            if (!props.companyUserId) {
                return;
            }

            if (!resUserLikeCompany.refetch) {
                return;
            }

            mutLike({
                variables: {
                    id: props.companyUserId
                }
            }).then(() => {
                return resUserLikeCompany.refetch()
            }).catch(ApolloCatch({}))

        },
        onClickDislike: () => {

            if (!props.companyUserId) {
                return;
            }

            if (!resUserLikeCompany.refetch) {
                return;
            }

            mutDislike({
                variables: {
                    id: props.companyUserId
                }
            }).then(() => {
                return resUserLikeCompany.refetch()
            }).catch(ApolloCatch({}))
        },
        onClickShare: () => {
            setShareModal(!shareModal);
        },
        onClickChat: () => {
            SwalUtil.ok({
                msg: t(I18nKeys.swal.preparing.key),
                icon: 'success'
            })
        },
        onClickAskBoard: () => {
            if (!user.isLogin) {
                SwalUtil.ynPromise({
                    msg: t(I18nKeys.company.needLoginWithChat.key),
                    okBtn: t(I18nKeys.swal.btnLogin.key),
                    cancelBtn: t(I18nKeys.swal.btnClose.key),
                }).then(() => {
                    navigate('/auth/login')
                }).catch(ApolloCatch({}));
                return;
            }


            setAskBoardModal(true);
        },
        onNeedLogin: () => {
            SwalUtil.ynPromise({
                msg: t(I18nKeys.swal.needLogin.key),
                okBtn: t(I18nKeys.swal.btnLogin.key),
                cancelBtn: t(I18nKeys.swal.btnClose.key),
            }).then(() => {
                navigate('/auth/login')
            }).catch(ApolloCatch({}));
        },
    }

    return (
        <>
            <BizSocialShareModal
                shareData={{
                    nm: props.companyData.companyNm,
                    url: `${window.location.protocol}//${window.location.host}${path}`,
                    desc: props.companyData.catchphrase,
                    imgPath: props.companyData.logoUrl,
                }}
                open={shareModal}
                onClose={() => setShareModal(false)}/>

            {props.companyUserId &&
            <BizAskBoardModal
                isShow={askBoardModal}
                onCancel={() => setAskBoardModal(false)}
                companyId={props.companyUserId}/>
            }


            <div>
                {/* 로그인 안되었을때 목업 버튼*/}
                {!user.isLogin &&
                <Button
                    style={{width: '100%', marginBottom: 5}}
                    variant='outlined'
                    classes={{
                        label: cx('btn-color')
                    }}
                    startIcon={<FavoriteBorder/>}
                    onClick={handler.onNeedLogin}>
                    {t("company.ttAllowFavoriteCompany")}
                </Button>
                }


                {/* 로그인 되었을때 */}
                {(user.isLogin && Boolean(props.companyUserId)) &&
                <>
                    {state.userLikeCompany &&
                    <Button
                        style={{width: '100%', marginBottom: 5}}
                        classes={{
                            label: cx('btn-color')
                        }}
                        variant='outlined'
                        startIcon={<Favorite color='secondary'/>}
                        onClick={handler.onClickDislike}>
                        {t("company.ttCancelFavoriteCompany")}
                    </Button>
                    }

                    {!state.userLikeCompany &&
                    <Button
                        style={{width: '100%', marginBottom: 5}}
                        variant='outlined'
                        classes={{
                            label: cx('btn-color')
                        }}
                        startIcon={<FavoriteBorder/>}
                        onClick={handler.onClickLike}>
                        {t("company.ttAllowFavoriteCompany")}
                    </Button>
                    }

                    {
                        props.companyUserId
                        && props.onCreateChatRoomWithCompanyId
                        && user.user.companyPermission.includes(CompanyPermission.UserChat)
                        && (user.user.id !== props.companyUserId)
                        &&
                        <Button
                            style={{width: '100%', marginBottom: 5}}
                            variant='outlined'
                            classes={{
                                label: cx('btn-color')
                            }}
                            startIcon={<ChatBubbleOutline/>}
                            onClick={() => props.onCreateChatRoomWithCompanyId!(props.companyUserId!)}>
                            {/*{t("company.ttAllowFavoriteCompany")}*/}
                            {t(I18nKeys.company.chat.key)}
                        </Button>
                    }

                </>
                }

                <Button
                    style={{width: '100%', marginBottom: 5}}
                    variant='outlined'
                    classes={{
                        label: cx('btn-color')
                    }}
                    startIcon={<AssignmentIndOutlined/>}
                    onClick={handler.onClickAskBoard}>
                    {t(I18nKeys.layout.board.key)}
                </Button>

                <Button
                    style={{width: '100%', marginBottom: 5}}
                    classes={{
                        label: cx('btn-color')
                    }}
                    variant='outlined'
                    startIcon={<ShareOutlined/>}
                    onClick={handler.onClickShare}>
                    {t("company.ttShareCompany")}
                </Button>
            </div>
        </>
    )
}

const gqlIsLike = gql`
    query Like($companyUserId: Int!) {
        isLikeCompany(companyUserId: $companyUserId)
    }
`;

const gqlLike = gql`
    mutation Like($id: Int!) {
        likeCompany(id: $id)
    }
`;


const gqlDislike = gql`
    mutation Like($id: Int!) {
        dislikeCompany(id: $id)
    }
`;


