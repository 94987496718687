import React, {useEffect} from 'react';
import {gql, useMutation} from "@apollo/client";
import {ApolloCatch, ApolloWithFbCatch} from "../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../graphql/errors";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {LocalStorage} from "../../../utils/localstorage/LocalStorage";
import {DataUtil} from "../../../utils/data/DataUtil";
import {
    JoinType,
    Mutation,
    MutationGetUserJoinTypeArgs,
    MutationLoginArgs,
    MutationLoginWithUidArgs
} from "../../../graphql/types";
import {AuthLoginForm} from "./form/AuthLoginForm";
import {useTranslation} from "react-i18next";
import {FB} from "../../../utils/fb/FB";
import {GraphQLError} from "graphql";
import {GlobalVars} from "../../../globalVars/globalVars";
import {FadeIn} from "../../shares/fadeIn/FadeIn";
import {I18nKeys} from "../../../i18n/i18nKeys";

export const AuthLogin = () => {
    const [t] = useTranslation('common');
    const [mutGetUserJoinType] = useMutation<Mutation, MutationGetUserJoinTypeArgs>(gqlGetUserJoinType);
    const [mutLoginWithUid] = useMutation<Mutation, MutationLoginWithUidArgs>(gqlLoginWithUid);

    // 네이버 로그인 타이머.
    const timer = setInterval(() => {
        if (LocalStorage.getIsNaverLogined()) {
            LocalStorage.setIsNaverLogined(false);
            window.history.back();
        }
    }, 1000);

    useEffect(() => {
        return () => {
            clearInterval(timer);
        }
    /*
    // 다음과 같은 경고 메시지를 처리하기 위해서 useEffect파라미터로 timer을 넘겨주었으나
    // 운영서버에서 제대로 동작하기 않고, 로컬 개발환경에서 테스트하기 위해서는 테스트 환경을 
    // 구성해야 하므로, 일단 소스를 원상복구시키고 eslint-disable-next-line으로 처리하기로 한다.
    // ---------------------------------------------------------------------------------
    // Line 40:8:  React Hook useEffect has a missing dependency: 'timer'. 
    // Either include it or remove the dependency array  react-hooks/exhaustive-deps
    // ---------------------------------------------------------------------------------
    */
    // eslint-disable-next-line
    }, [])


    const handler = {
        // 이메일 회원
        onLogin: (args: MutationLoginArgs) => {
            GlobalVars.system.loadingView(true);
            mutGetUserJoinType({variables: {email: args.email}})
                .then(res => {
                    if (!res.data) {
                        throw new GraphQLError(BizErrors.notFoundData);
                    }

                    if (res.data.getUserJoinType !== JoinType.Email) {
                        throw new GraphQLError(BizErrors.noJoinTypeEmail);
                    }

                    return FB.hasVerifiedEmail(args.email, args.pw)
                })
                .then(res => {
                    if (!res.emailVerified) {
                        throw new GraphQLError(BizErrors.noHasEmailVerified)
                    }

                    return FB.signInEmailAndPassword(args)
                })
                .then(uid => {
                    return mutLoginWithUid({variables: {uid}})
                })
                .then(res => {
                    LocalStorage.setSession(res.data!.loginWithUid);
                    return DataUtil.refreshSession()
                })
                .then(() => {
                    window.history.back();
                })
                .catch(ApolloWithFbCatch({
                    [BizErrors.dontActBizErrors]: () => {
                    },
                    [BizErrors.notFoundUser]: SwalUtil.apolloCatch({
                        msg: t('auth.notFoundUser'),
                        icon: 'error'
                    }),
                    [BizErrors.failLoginFb]: SwalUtil.apolloCatch({
                        msg: t(I18nKeys.auth.wrongPassword.key),
                        icon: 'error'
                    }),
                    [BizErrors.noHasEmailVerified]: SwalUtil.apolloCatch({
                        msg: t('auth.noHasEmailVerified'),
                        icon: 'error',
                    }),
                    [BizErrors.notFoundData]: SwalUtil.apolloCatch({
                        msg: t('auth.notFoundData'),
                        icon: 'error'
                    }),
                    [BizErrors.noJoinTypeEmail]: SwalUtil.apolloCatch({
                        msg: t('auth.noJoinTypeEmail'),
                        icon: 'error'
                    })
                }))
        },
        onResendEmailVerified: (args: { email: string, pw: string }) => {
            GlobalVars.system.loadingView(true);
            mutGetUserJoinType({
                variables: {email: args.email}
            }).then(res => {
                if (!res.data) {
                    throw new GraphQLError(BizErrors.notFoundData);
                }

                if (res.data.getUserJoinType !== JoinType.Email) {
                    throw new GraphQLError(BizErrors.noJoinTypeEmail);
                }

                return FB.sendEmailVerified(args.email, args.pw)
            }).then(() => {
                SwalUtil.ok({
                    msg: t('auth.resentEmailVerified'),
                    icon: 'success'
                })
            }).catch(ApolloCatch({
                [BizErrors.notFoundData]: SwalUtil.apolloCatch({
                    msg: t('auth.notFoundData'),
                    icon: 'error'
                }),
                [BizErrors.noJoinTypeEmail]: SwalUtil.apolloCatch({
                    msg: t('auth.noJoinTypeEmail'),
                    icon: 'error'
                })
            }))

        },
        onResetPw: (args: { email: string }) => {
            GlobalVars.system.loadingView(true);
            mutGetUserJoinType({
                variables: {email: args.email}
            }).then(res => {
                if (!res.data) {
                    throw new GraphQLError(BizErrors.notFoundData);
                }

                if (res.data.getUserJoinType !== JoinType.Email) {
                    throw new GraphQLError(BizErrors.noJoinTypeEmail);
                }

                return FB.sendResetPwEmail(args.email)
            }).then(() => {
                SwalUtil.ok({
                    msg: t('auth.sendPwReset'),
                    icon: 'success'
                })
            }).catch(ApolloCatch({
                [BizErrors.notFoundData]: SwalUtil.apolloCatch({
                    msg: t('auth.notFoundData'),
                    icon: 'error'
                }),
                [BizErrors.noJoinTypeEmail]: SwalUtil.apolloCatch({
                    msg: t('auth.noJoinTypeEmail'),
                    icon: 'error'
                })
            }))
        },
    }

    return (
        <>
            <FadeIn>
                <AuthLoginForm
                    {...handler}/>
            </FadeIn>
        </>
    )
}


const gqlLoginWithUid = gql`
    mutation LoginWithUid($uid: String!) {
        loginWithUid(uid: $uid)
    }
`;

const gqlGetUserJoinType = gql`
    mutation GetUserJoinType($email: String!) {
        getUserJoinType(email: $email)
    }
`;
