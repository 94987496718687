import React, {ReactNode} from 'react';
import classNamesBind from "classnames/bind";
import styles from './InputMultiLang.module.scss';
import {MultiLang, OnChangeValue} from "../../../../Intefaces";
import {useSelector} from "react-redux";
import {DataState} from "../../../../../redux/Data";

const cx = classNamesBind.bind(styles);

export const InputMultiLang = (props: {
    title?: ReactNode,
    value: MultiLang,
    onChange: OnChangeValue<MultiLang>
    disableLabel?: boolean
}) => {
    const data = useSelector<any, DataState>(state => state.data);
    const copy = Object.assign({}, props.value);
    const extractData = (langCode: string): string => {
        if (props.value.hasOwnProperty(langCode)) {
            return props.value[langCode];
        } else {
            return ""
        }
    }

    return (
        <>
            {props.title && <div className={cx('title')}>{props.title}</div>}

            {data.langCode.map((value, index) => {
                return (
                    <div key={index} className={cx('cont')}>
                        {!props.disableLabel && <div className={cx('label')}>{value.nm} {value.nm === "English" && <span className={cx("red")}>*</span>}</div>}
                        <div className={cx('input')}>
                            <input
                                className='form-control form-control-sm'
                                type='text'
                                value={extractData(value.code)}
                                onChange={event => {
                                    copy[value.code] = event.target.value;
                                    props.onChange(copy);
                                }}/>
                        </div>
                    </div>
                )
            })}
        </>
    )
}
