import React, {useEffect, useState} from 'react';
import classNamesBind from "classnames/bind";
import styles from './InputConsultantAddModal.module.scss';
import {CompanyConsultant} from "../../../../../../graphql/types";
import {OnChangeValue} from "../../../../../Intefaces";
import {Checkbox} from "@material-ui/core";
import {useSelector} from "react-redux";
import {I18nState} from "../../../../../../redux/I18n";
import {I18nUtil} from "../../../../../../utils/i18n/i18n";
import {CopyUtil} from "../../../../../../utils/copyUtil/copyUtil";
import {useTranslation} from "react-i18next";

const cx = classNamesBind.bind(styles);

interface Props {
    langCode: string,
    consultant: CompanyConsultant[],
    onConfirm: OnChangeValue<ConfirmConsultant[]>
}

export type ConfirmConsultant = CompanyConsultant & { langCode: string, isActivate: boolean }

export const InputConsultantAddModal = (props: Props) => {
    const [consultantList, setConsultantList] = useState<ConfirmConsultant[]>([]);
    const [consultantCheckedList] = useState<Array<boolean>>([]);
    const [t] = useTranslation('common');
    const langCode = useSelector<any, I18nState>(state => state.i18n).langCode;

    useEffect(() => {
        const next: ConfirmConsultant[] = [];

        for(let i = 0; i < props.consultant.length; i++) {
            const companyConsultant = props.consultant[i];
            next.push({...companyConsultant, langCode: props.langCode});
            consultantCheckedList[i] = false;
        }

        setConsultantList(next);
    }, [props.consultant, consultantCheckedList, props.langCode]);

    const handler = {
        consultantList,
        setConsultantList,
        onConfirm: () => {
            const confirm: ConfirmConsultant[] = [];

            for(let i = 0; i < consultantList.length; i++) {
                const elem = consultantList[i];
                elem.isActivate = consultantCheckedList[i];

                if (!elem.isActivate) {
                    continue;
                }
                
                confirm.push(CopyUtil.copyAll(elem))
            }
            props.onConfirm(confirm);
        }
    }

    const isActivated = (isActivated: boolean) => {
        return isActivated ? {} : {textDecoration: 'line-through'}
    }


    return (
        <>
            <div className={cx('table')}>
                <div className={cx('header', 'col-add')}>{t("mypageConsultant.add")}</div>
                <div className={cx('header', 'col-position')}>{t("mypageConsultant.position")}</div>
                <div className={cx('header', 'col-nm')}>{t("mypageConsultant.nm")}</div>
            </div>

            {consultantList.map((value, index) => (
                <div key={index} className={cx('table', 'hover')}>
                    <div className={cx('col', 'col-add')}>
                        <Checkbox
                            color='primary'
                            classes={{
                                root: 'p-0'
                            }}
                            onChange={ev => {
                                const copy = CopyUtil.copyAll(consultantList);
                                consultantCheckedList[index] = ev.target.checked;
                                setConsultantList(copy);
                            }}/>

                    </div>
                    <div className={cx('col', 'col-position', 'text-center')} style={isActivated(value.isActivate)}>
                        {I18nUtil.value(langCode, value.position)}
                    </div>
                    <div className={cx('col', 'col-nm', 'text-center')} style={isActivated(value.isActivate)}>
                        {I18nUtil.value(langCode, value.nm)}
                    </div>
                </div>
            ))}

            <div className={cx('table')}>
                <div className={cx('col', 'col-full', 'text-right')}>
                    <button
                        className='btn btn-sm btn-success'
                        onClick={handler.onConfirm}>{t("mypageConsultant.btnSave")}</button>
                </div>
            </div>
        </>
    )
}
