import {createActionCreators, createReducerFunction, ImmerReducer} from "immer-reducer";


export interface ChatState {
    refetchChatList: boolean;
    refetchChatContent: boolean;
    refetchTotalNotice: boolean;
    totalNotice: number,
}

const initState: ChatState = {
    refetchChatContent: false,
    refetchChatList: false,
    refetchTotalNotice: false,
    totalNotice: 0,
};

class Chat extends ImmerReducer<typeof initState> {
    chatContent(swt: boolean) {
        this.draftState.refetchChatContent = swt;
    }

    chatList(swt: boolean) {
        this.draftState.refetchChatList = swt;
    }

    refetchTotalNotice(swt: boolean) {
        this.draftState.refetchTotalNotice = swt;
    }

    totalNotice(notice: number) {
        this.draftState.totalNotice = notice;
    }
}


export const ChatAction = createActionCreators(Chat);
export const ChatReducer = createReducerFunction(Chat, initState);
