import React, {ReactNode} from "react";
import classNamesBind from "classnames/bind";
import styles from './BizGoodsIdxList.module.scss'
import {CategoryGoodsCount, Goods} from "../../../graphql/types";
import {GoodsData} from "../../../graphql/customScalars";
import {GoodsDataMapUtil} from "../../../utils/goodsDataMapUtil/goodsDataMapUtil";
import {useSelector} from "react-redux";
import {I18nState} from "../../../redux/I18n";
import {Pipe} from "../../../utils/pipe/pipe";
import {BizTitle} from "../bizTitle/BizTitle";
import {OnChangeValue} from "../../Intefaces";
import {Pager} from "../pager/Pager";
import {BizNoDataTable} from "../bizNoDataTable/BizNoDataTable";
import {navigate} from "hookrouter";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {SystemState, ViewMode} from "../../../redux/System";
import {useTranslation} from "react-i18next";
import {I18nKeys} from "../../../i18n/i18nKeys";
import { GoodsCertificateImage } from "../goodsCertificate/GoodsCertificateImage";

const cx = classNamesBind.bind(styles);

export const BizGoodsIdxList = (props: {
    title?: ReactNode,
    goods: Goods[],
    categoryGoodsCount?: CategoryGoodsCount,
    pager?: { page: number, size: number, total: number, onChangePage: OnChangeValue<number> },
    from?: string
}) => {
    const langCode = useSelector<any, I18nState>(state => state.i18n).langCode;
    const [t] = useTranslation('common');

    return (
        <div className={cx('cont')}>
            {props.title &&
            <div className={cx('box-full')}>
                <BizTitle desc={
                    <>
                        {props.categoryGoodsCount &&
                        <>
                            (
                            {t(I18nKeys.goods.registeredGoods.key)} {Pipe.toSeparatorNumber(props.categoryGoodsCount.registered)} {t(I18nKeys.goods.goodsUnit.key)}, {t(I18nKeys.goods.showGoods.key)} {Pipe.toSeparatorNumber(props.categoryGoodsCount.show)} {t(I18nKeys.goods.goodsUnit.key)}
                            )
                        </>
                        }
                    </>

                }>{props.title}</BizTitle>

            </div>
            }

            {props.goods.length === 0 &&
            <div className={cx('box-full')}>
                <BizNoDataTable/>
            </div>
            }


            {props.goods.map((value, index) => {
                const goodsData = GoodsDataMapUtil.getGoodsData(langCode, value.data);
                return (
                    <GoodsBtn
                        key={index}
                        goodsId={value.id}
                        goodsData={goodsData}
                        isPaid={value.isPaid}
                        goodsAllEduCode={value.allEduCode}
                        isCertificated={value.isCertificated}
                        from={props.from}/>
                )
            })}

            {props.pager &&
            <div className={cx('box-full')}>
                <Pager
                    offset={-40}
                    page={props.pager.page}
                    size={props.pager.size}
                    total={props.pager.total}
                    onChange={props.pager.onChangePage}/>
            </div>
            }
        </div>
    )
}

export const BizGoodsIdxWithCarousel = (props: {
    title?: ReactNode,
    goods: Goods[]
}) => {
    const langCode = useSelector<any, I18nState>(state => state.i18n).langCode;
    const viewMode = useSelector<any, SystemState>(state => state.system).viewMode;
    return (

        <>
            <div className={cx('cont')}>
                {props.title &&
                <div className={cx('box-full')}>
                    <BizTitle>{props.title}</BizTitle>

                </div>
                }

                {props.goods.length < 5 &&
                <div className={cx('box-full')}>
                    <BizNoDataTable/>
                </div>
                }
            </div>

            {4 < props.goods.length &&
            <div className={cx('cont-slide')}>
                <Slider {...{
                    dots: false,
                    infinite: true,
                    autoplay: true,
                    autoplaySpeed: 3000,
                    speed: 1000,
                    slidesToShow: viewMode === ViewMode.Web ? 5 : 2,
                    slidesToScroll: 1
                }}>
                    {props.goods.map((value, index) => {
                        const goodsData = GoodsDataMapUtil.getGoodsData(langCode, value.data);
                        return (
                            <GoodsBtn
                                isSlide
                                key={index}
                                goodsId={value.id}
                                goodsData={goodsData}
                                goodsAllEduCode={value.allEduCode}
                                isPaid={value.isPaid}
                                isCertificated={value.isCertificated}/>
                        )
                    })}
                </Slider>
            </div>
            }

        </>
    )
}


const GoodsBtn = (props: {
    isSlide?: boolean,
    isPaid: boolean,
    goodsId: number,
    goodsAllEduCode: string,
    goodsData: GoodsData,
    isCertificated: string,
    from?: string
}) => {
    const [t] = useTranslation('common');

    const getMainImage = (src : string) => {
        const lastIndex = src.lastIndexOf("/");
        const imgUrl = src.substring(0, lastIndex);
        const imgFileName = src.substring(lastIndex + 1);

        const fileNameSeperatorIndex = imgFileName.lastIndexOf(".")
        const fileNameBody = imgFileName.substring(0, fileNameSeperatorIndex);
        const fileExtension = imgFileName.substring(fileNameSeperatorIndex + 1)

        const PNG_EXTENSION = "png";
        var mainImgSrc = imgUrl + "/main/" + fileNameBody;
        if(fileExtension.toLowerCase() !== PNG_EXTENSION) {
            mainImgSrc += "." + PNG_EXTENSION
        } else {
            mainImgSrc += "." + fileExtension
        }

        return mainImgSrc;
    }

    return (
        <div className={cx('cont-goods', props.isSlide ? 'slide' : '')}>
            <div className={cx('box-img', props.isPaid ? 'red' : '')}
                 onClick={() => navigate(`/goods/detail/${props.goodsAllEduCode}`)}>
                <img src={getMainImage(props.goodsData.goodsImage[0].src)} alt={props.goodsData.nm}/>
                <GoodsCertificateImage 
                    isCertificated={props.isCertificated}
                    width={props.from === "GoodsCategory" ? "57px" : "70px"}
                />
            </div>
            <div className={cx('box-info')}>
                <div className={cx('nm')}>{props.goodsData.nm}</div>
                <div className={cx('space')}/>

                <div className={cx('catchphrase')}>{props.goodsData.catchphrase}</div>
                {props.goodsData.goodsPrice.noPrice &&
                <div className={cx('price')}>{t("goods.noPrice")}</div>
                }

                {!props.goodsData.goodsPrice.noPrice &&
                <div className={cx('price')}>
                    {Pipe.toSeparatorNumber(props.goodsData.goodsPrice.price)} {props.goodsData.goodsPrice.priceUnit}
                </div>
                }
            </div>

        </div>
    )
}
