import React, {Fragment} from 'react';
import classNamesBind from "classnames/bind";
import styles from './Debug.module.scss';
import {BizTitle} from "../../shares/bizTitle/BizTitle";

const cx = classNamesBind.bind(styles);

export const Debug = () => {
    return (
        <Fragment>
            <div className={cx('container')}>
                <div className='row'>
                    <div className='col-12'>
                        <BizTitle>개발모드</BizTitle>
                        <div className={cx('table')}>
                            <div>다국어 코드 (CSV)</div>
                            <div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
