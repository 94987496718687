import React from 'react';
import {gql, useMutation, useQuery} from "@apollo/client";
import {
    Mutation,
    MutationAllowUpdateCompanyArgs,
    MutationCancelUpdateCompanyArgs,
    Query
} from "../../../../graphql/types";
import {MyPageLayout} from "../../layout/MyPageLayout";
import {BizTitle} from "../../../shares/bizTitle/BizTitle";
import {MyPageCompanyUpdateRequestList} from "./list/MyPageCompanyUpdateRequestList";
import {Pager} from "../../../shares/pager/Pager";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import {navigate} from "hookrouter";
import {ApolloCatch} from "../../../../utils/apollo/apolloCatch";
import {useTranslation} from "react-i18next";


export const MyPageCompanyUpdateRequest = (props: {
    page: number
}) => {
    const [t] = useTranslation('common');
    const {data} = useQuery<Query, { page: number }>(gqlData, {
        variables: props
    });

    const [mutAllow] = useMutation<Mutation, MutationAllowUpdateCompanyArgs>(gqlAllow);
    const [mutCancel] = useMutation<Mutation, MutationCancelUpdateCompanyArgs>(gqlCancel);

    const handler = {
        onAllow: (args: MutationAllowUpdateCompanyArgs) => {
            SwalUtil.yn({
                msg: t("mypageCompany.swalAllowUpdateQuestion"),
                icon: 'success',
                ok: () => {
                    mutAllow({
                        variables: args
                    }).then(() => {
                        SwalUtil.ok({
                            msg: t("mypageCompany.swalAllowUpdateOk"),
                            icon: 'success',
                        });
                        navigate('/mypage/info')
                    }).catch(ApolloCatch({}))
                }
            })

        },
        onCancel: (args: MutationCancelUpdateCompanyArgs) => {
            SwalUtil.yn({
                msg: t("mypageCompany.swalCancelUpdateQuestion"),
                icon: 'success',
                ok: () => {
                    mutCancel({
                        variables: args
                    }).then(() => {
                        SwalUtil.ok({
                            msg: t("mypageCompany.swalCancelUpdateOk"),
                            icon: 'success',
                        });
                        navigate('/mypage/info')
                    }).catch(ApolloCatch({}))
                }
            })

        },
        onChangePage: (page: number) => {
            navigate(`/mypage/company/update/request/${page}`);
        }
    };

    return (
        <MyPageLayout activatedSuffixRouter={'/company/update/request'}>
            {data &&
            <>
                <BizTitle>{t("mypageCompany.titleUpdateRequest")}</BizTitle>
                <MyPageCompanyUpdateRequestList
                    {...handler}
                    {...data}/>

                <Pager
                    page={data.companyUpdateRequestList.page}
                    size={data.companyUpdateRequestList.size}
                    total={data.companyUpdateRequestList.total}
                    onChange={handler.onChangePage}/>
            </>
            }
        </MyPageLayout>
    )
}

const gqlAllow = gql`
    mutation Allow($companyUpdateRequestId: Int!){
        allowUpdateCompany(companyUpdateRequestId:$companyUpdateRequestId)
    }
`;

const gqlCancel = gql`
    mutation Cancel($companyUpdateRequestId: Int!){
        cancelUpdateCompany(companyUpdateRequestId:$companyUpdateRequestId)
    }
`;

const gqlData = gql`
    query DATA ($page: Int!){companyUpdateRequestList(page:$page, size:20){
        page
        size
        total
        list
        {
            id
            status
            nextCompany
            prevCompany
            createdAt
            memo
        }
    }
    }
`;
