import React, {Fragment} from 'react';
import styles from './BoardFileView.module.scss';
import {BoardFile} from "../../../graphql/types";
import {DataUtil} from "../../../utils/data/DataUtil";
import {BizSmTitle} from "../bizTitle/BizTitle";
import {CloudDownload} from "@material-ui/icons";
import {useTranslation} from "react-i18next";
import {I18nKeys} from "../../../i18n/i18nKeys";


export const BoardFileView = (props: {
    files: BoardFile[],
}) => {

    const [t] = useTranslation('common');

    return (
        <Fragment>
            {props.files.length !== 0 &&
            <>
                <BizSmTitle>{t(I18nKeys.board.attachFileListTitle.key)}</BizSmTitle>
                {props.files.map((value, index) =>
                    <div key={index}>
                    <span className={styles.fileNm} onClick={() => DataUtil.downloadFile(value.fileSrc)}>
                        <CloudDownload/> {value.fileNm}
                    </span>
                    </div>
                )}
                <div style={{height: 30}}/>
            </>
            }
        </Fragment>
    )
}
