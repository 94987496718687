import React, {Fragment, ReactNode} from 'react';
import classNamesBind from "classnames/bind";
import styles from './OtherInputTab.module.scss';
import {OnChangeValue} from "../../../Intefaces";
import {Spring} from "react-spring/renderprops-universal";

const cx = classNamesBind.bind(styles);

interface Props {
    langCode: string;
    onChangeLangCode: OnChangeValue<string>,
    data: OtherInputTabsData;
}

export const OtherInputTab = (props: Props) => {
    return (
        <Fragment>
            <div className='web-view'><Web {...props}/></div>
            <div className='mobile-view'><Mobile {...props}/></div>
        </Fragment>
    )
}

const Web = (props: Props) => {
    const langCodeList = Object.keys(props.data);
    const activeFontColor = 'rgb(255,255,255)';
    const inactiveFontColor = 'rgb(62,62,62)';
    const activeBarColor = 'rgba(13,179,230,1)';
    const inactiveBarColor = 'rgba(13,179,230,0)';

    return (
        <>
            <div className={cx('cont-tab')}>
                {langCodeList.map((value, index) => {
                    const isActivate = value === props.langCode;
                    return (
                        <Spring
                            key={index}
                            from={{
                                color: inactiveFontColor,
                                bar: inactiveBarColor
                            }}
                            to={{
                                color: isActivate ? activeFontColor : inactiveFontColor,
                                bar: isActivate ? activeBarColor : inactiveBarColor,
                            }}>
                            {params => (
                                <Fragment>
                                    <div
                                        style={{background: params.bar, color: params.color}}
                                        className={cx('btn-tab')}
                                        onClick={() => {
                                            props.onChangeLangCode(value);
                                        }}>
                                        {props.data[value].title}
                                    </div>
                                </Fragment>

                            )}
                        </Spring>
                    )
                })}
            </div>

            {props.data[props.langCode] && props.data[props.langCode].children}
        </>
    )
}

const Mobile = (props: Props) => {
    return (
        <><Web {...props}/></>
    )
}

export type OtherInputTabsData = {
    [langCode: string]: {title: ReactNode, children: ReactNode}
}
