import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './BizCompanyDataViewHeader.module.scss';
import {CompanyData} from "../../../../graphql/customScalars";
import {BizTitle} from "../../bizTitle/BizTitle";
import {useTranslation} from "react-i18next";
import {Pipe} from "../../../../utils/pipe/pipe";
import {useSelector} from "react-redux";
import {I18nState} from "../../../../redux/I18n";
import moment from "moment";
import {Kotra} from "../../../../graphql/types";
import {I18nUtil} from "../../../../utils/i18n/i18n";
import {BizCompanyDataViewHeaderInteraction} from "./interaction/BizCompanyDataViewHeaderInteraction";
import isUrl from 'is-url';
import {I18nKeys} from "../../../../i18n/i18nKeys";
import {OnChangeValue} from "../../../Intefaces";

const cx = classNamesBind.bind(styles);

interface Props {
    companyData: CompanyData,
    kotra?: Kotra
    companyUserId?: number,
    onCreateChatRoomWithCompanyId?: OnChangeValue<number>
}

export const BizCompanyDataViewHeader = (props: Props) => {
    return (
        <>
            <div className={cx('box-img', 'p-r-30', 'm-b-30')} style={{marginBottom: 30}}>
                <div className={cx('img-logo')} >
                    <img src={props.companyData.logoUrl} alt=""/>
                </div>
                <BizCompanyDataViewHeaderInteraction
                    onCreateChatRoomWithCompanyId={props.onCreateChatRoomWithCompanyId}
                    companyUserId={props.companyUserId}
                    companyData={props.companyData}/>
            </div>
            <div className={cx('box-info', 'm-b-30')} style={{marginBottom: 30}}>
                <BizTitle>

                    {props.companyData.companyNm}
                </BizTitle>

                <BizCompanyDataHeaderTable {...props}/>

            </div>

        </>
    )
}

export const BizCompanyDataHeaderTable = (props: {
    companyData: CompanyData,
    kotra?: Kotra
}) => {
    const [t] = useTranslation('common');
    const i18n = useSelector<any, I18nState>(state => state.i18n);

    const handler = {
        openCompanyHp: () => {
            const hp = props.companyData.hp;
            if (hp.startsWith('http')) {
                window.open(hp, '_blank');
            } else {
                window.open('http://' + hp, '_blank')
            }
        },
        getLastTotalSales: (): number => {
            if (props.companyData.totalSales.length !== 0) {
                const length = props.companyData.totalSales.length;
                const totalSales = props.companyData.totalSales.slice(length - 1, length);
                return totalSales[0].sales;
            }

            return 0
        },
        onDownload: (src: string) => {
            const tag = document.createElement("a");
            tag.href = src;
            tag.target = "_blank";
            tag.setAttribute('hidden', 'true');
            tag.click();
        }

    }

    return (
        <>
            <div className={cx('table-fluid')}>
                <div className={cx('col', 'col-header')}>
                    {t('company.companyType')}
                </div>
                <div className={cx('col', 'col-value')}>
                    {Pipe.companyType(props.companyData.companyTypeId, i18n.langCode)}
                </div>

                <div className={cx('col', 'col-header')}>
                    {t('company.member')}
                </div>
                <div className={cx('col', 'col-value')}>
                    {Pipe.toSeparatorNumber(props.companyData.member)}
                </div>


                <div className={cx('col', 'col-header')}>
                    {t('company.category')}
                </div>
                <div className={cx('col', 'col-value')}>
                    {props.companyData.category}
                </div>

                <div className={cx('col', 'col-header')}>
                    {t('company.foundation')}
                </div>
                <div className={cx('col', 'col-value')}>
                    {moment(props.companyData.foundation).format("YY.MM.DD")}
                </div>


                <div className={cx('col', 'col-header')}>
                    {t('company.sales')}
                </div>
                <div className={cx('col', 'col-value')}>
                    {Pipe.toSeparatorNumber(handler.getLastTotalSales())} {props.companyData.priceUnit}
                </div>

                <div className={cx('col', 'col-header')}>
                    {t('company.ceo')}
                </div>
                <div className={cx('col', 'col-value')}>
                    {props.companyData.ceo}
                </div>


                <div className={cx('col', 'col-header')}>
                    {t('company.hp')}
                </div>
                <div className={cx('col', 'col-value', 'pointer')}
                     onClick={handler.openCompanyHp}>
                    {props.companyData.hp}

                </div>

                <div className={cx('col', 'col-header')}>
                    {t('company.nation')}
                </div>
                <div className={cx('col', 'col-value')}>
                    {Pipe.nationNm(props.companyData.nationCode)}
                </div>

                <div className={cx('col', 'col-header')}>
                    {t('company.addr')}
                </div>
                <div className={cx('col', 'col-full-value')}>
                    {props.companyData.addr}
                </div>

                {(props.companyData.introduceYoutubeUrl && isUrl(props.companyData.introduceYoutubeUrl)) &&
                <>
                    <div className={cx('col', 'col-header')}>
                        {t(I18nKeys.company.introduceYoutube.key)}
                    </div>
                    <div className={cx('col', 'col-full-value')}>
                        <a href={props.companyData.introduceYoutubeUrl} target='_blank' rel="noreferrer">
                            {props.companyData.introduceYoutubeUrl}
                        </a>
                    </div>
                </>
                }

                {props.companyData.companyFiles.length !== 0 &&
                <>
                    <div className={cx('col', 'col-header')}>
                        {t(I18nKeys.company.attachment.key)}
                    </div>
                    <div className={cx('col', 'col-full-value')}>
                        {props.companyData.companyFiles.map((value, index) => (
                            <div className={cx('btn-file')} key={index}
                                 onClick={() => handler.onDownload(value.src)}>
                                {value.nm}
                            </div>
                        ))}
                    </div>
                </>
                }

                {props.kotra &&
                <>
                    <div className={cx('col', 'col-header')}>
                        {t('company.kotra')}
                    </div>
                    <div className={cx('col', 'col-full-value')}
                         ref={ref => {
                             if (ref) {
                                 ref.innerText = I18nUtil.value(i18n.langCode, props.kotra?.data)
                             }
                         }}>
                    </div>

                </>
                }

            </div>
        </>
    )
}
