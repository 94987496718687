import React, {useEffect, useState} from 'react';
import {ViewSelector} from '../../../../shares/viewSelector/ViewSelector';
import {CompanyDataMap} from "../../../../../graphql/customScalars";
import {OtherInputTab, OtherInputTabsData} from "../../../share/otherInputTab/OtherInputTab";
import {MyPageCompanyCreateInput} from "../../create/input/MyPageCompanyCreateInput";
import {CopyUtil} from "../../../../../utils/copyUtil/copyUtil";
import {Pipe} from "../../../../../utils/pipe/pipe";
import {MyPageCompanyCreateOtherInput} from "../../create/otherInput/MyPageCompanyCreateOtherInput";
import {useTranslation} from "react-i18next";
import {OnChangeValue} from "../../../../Intefaces";
import {InputQuill} from "../../../share/inputs/inputQuill/InputQuill";
import {GlobalVars} from "../../../../../globalVars/globalVars";
import {useReactiveVar} from "@apollo/client";

interface Props {
    value: CompanyDataMap,
    onChangeValue: OnChangeValue<CompanyDataMap>
}

export const MyPageCompanyUpdateInput = (props: Props) => {
    return (
        <>
            <ViewSelector
                web={<Web {...props}/>}
                mobile={<Mobile {...props}/>}/>
        </>
    )
}

const Web = (props: Props) => {
    const [t] = useTranslation('common');
    const [tabLangCode, setTabLangCode] = useState('en');

    const quillData = useReactiveVar(GlobalVars.quill.createGoods);

    //*------------------------------------------------------------*/
    // 다음 경고를 무시하게 한다. 
    // 해당 경고를 수정했을 경우 InputQuill이 제대로 동작하지 않는다.
    // (※경고를 수정했으나 InputQuill이 수정되지 않는 문제가 발생하여
    //    소스를 원상복구한다.)
    //---------------------------------------------------------------
    // React Hook useEffect has a missing dependency: 'props'. 
    // Either include it or remove the dependency array. However, 
    // 'props' will change when *any* prop changes, so the preferred fix is
    // to destructure the 'props' object outside of the useEffect call and 
    // refer to those specific props inside useEffect  react-hooks/exhaustive-deps
    //*------------------------------------------------------------*/
    useEffect(() => {

        const copy = CopyUtil.copyAll(props.value);
        if (!copy.hasOwnProperty(quillData.langCode)) {
            return;
        }

        copy[quillData.langCode].companyDetail = quillData.content;
        props.onChangeValue(copy);
    // eslint-disable-next-line
    }, [quillData]);

    // 에디터 초기화
    useEffect(() => {
        return () => {
            GlobalVars.quill.createGoods({langCode: '', content: ''})
        }
    }, [])


    const otherInputData: OtherInputTabsData = {};
    otherInputData['en'] = {
        title: <>English <span style={{color: 'red'}}>{t('mypageJoin.required')}</span></>,
        children: <MyPageCompanyCreateInput
            input={props.value['en']}
            onChangeInput={v => {
                let copy = CopyUtil.copyAll(props.value);
                copy['en'] = v;
                props.onChangeValue(copy);
            }}/>
    }

    const otherInputLangCode = Object.keys(props.value).filter(v => {
        return v !== 'en';
    })

    for (let langCode of otherInputLangCode) {
        otherInputData[langCode] =
            {
                title: Pipe.langNm(langCode),
                children: <MyPageCompanyCreateOtherInput
                    input={props.value[langCode]}
                    onChangeInput={v => {
                        const copy = CopyUtil.copyAll(props.value);
                        copy[langCode] = v;
                        props.onChangeValue(copy);
                    }}/>
            }
    }

    return (
        <>

            <OtherInputTab
                langCode={tabLangCode}
                onChangeLangCode={setTabLangCode}
                data={otherInputData}/>

            <InputQuill
                title={t("mypageJoin.companyDetail")}
                langCode={tabLangCode}
                value={props.value[tabLangCode].companyDetail}
                from="company"
                onChangeValue={v => {
                    const copy = CopyUtil.copyAll(props.value);
                    copy[tabLangCode].companyDetail = v;
                    props.onChangeValue(copy);
                }}/>
        </>
    )
}

const Mobile = (props: Props) => {
    return (
        <><Web {...props}/></>
    )
}
