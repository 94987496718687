import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './MyInfoCertificateApplication.module.scss';
import {UpdateUserInfo, UserInfo} from "../../../../graphql/types";
import {OnChangeValue} from "../../../Intefaces";
import {useTranslation} from "react-i18next";
import { Maybe } from 'graphql/jsutils/Maybe';

const cx = classNamesBind.bind(styles);

export const MyInfoCertificateApplication = (props: {
    value: UserInfo,
    updateUserInfo: UpdateUserInfo,
    onChangeValue: OnChangeValue<UserInfo>,
    onUpdateMyInfo: OnChangeValue<UpdateUserInfo>,
}) => {
    const [t] = useTranslation('common');

    function getButton(element: Maybe<String>, label: string) {
        return (<>
            <br/>
            <button
                className="btn btn-sm btn-primary"
                onClick={ 
                () => { return viewUploadedFile(element) }
            }>{label}
            </button>
        </>);
    }
    
    function displayFileButton(element: Maybe<String>, label: string) {
        if(!(element === null || element === "")) {
            return getButton(element, label);
        }

        return "";
    }

    function viewUploadedFile(url: Maybe<String>) {
        if(url == null) return false;

        let _url = url as string;
        var win = window.open(_url, "_blank");
        win?.focus();

        return false;
    }

    return (
        <>
            <div className={cx('cont')}>
                <div className={cx('label')} style={{width: "20%"}}>{t('mypage.businessLicense')}</div>
                <div className={cx('input')}>
                    <input
                        className='form-control form-control-sm'
                        accept='application/pad, image/png, image/jpeg, image/gif'
                        type='file'
                        onChange={event => {
                            const {files} = event.target;

                            if ((files) && (files.length !== 0)) {
                                if(props.updateUserInfo.certificateInfo?.businessLicense) {
                                    props.updateUserInfo.certificateInfo.businessLicense = files[0];
                                }
                            }
                    }}/>
                            
                    { 
                        displayFileButton(
                            props.value.certificateInfo?.businessLicense, t('mypage.businessLicense')
                        ) 
                    }
                </div>
            </div>

            <div className={cx('cont')}>
                <div className={cx('label')} style={{width: "20%"}}>{t('mypage.financialStatements')}</div>
                <div className={cx('input')}>
                    <input
                        className='form-control form-control-sm'
                        accept='application/pad, image/png, image/jpeg, image/gif'
                        type='file'
                        onChange={event => {
                            const {files} = event.target;

                            if ((files) && (files.length !== 0)) {
                                if(props.updateUserInfo.certificateInfo) {
                                    props.updateUserInfo.certificateInfo.financialStatements = files[0];
                                }
                            }
                        }}
                    />

                    { 
                        displayFileButton(
                            props.value.certificateInfo?.financialStatements, t('mypage.financialStatements')
                        ) 
                    }
                </div>
            </div>

            <hr/>

            <div className={cx('table')}>
                <div className={cx('col-full', 'text-right')}>
                    <button
                        className='btn btn-sm btn-success'
                        onClick={() => {
                            props.onUpdateMyInfo(props.updateUserInfo);
                        }}>{t("mypage.btnSave")}
                    </button>
                </div>
            </div>
        </>
    )
};
