import React, {useState} from 'react';
import classNamesBind from "classnames/bind";
import styles from './MyPageCompanyUpdateRequestList.module.scss';
import {ViewSelector} from '../../../../shares/viewSelector/ViewSelector';
import {
    CompanyUpdateRequestList,
    CompanyUpdateStatus, MutationAllowUpdateCompanyArgs,
    MutationCancelUpdateCompanyArgs
} from "../../../../../graphql/types";
import moment from "moment";
import {BizCompanyDataInTable} from "../../../../shares/bizCompanyDataInTable/BizCompanyDataInTable";
import {IconButton, Tooltip} from "@material-ui/core";
import {Check, Delete, SpellcheckOutlined} from "@material-ui/icons";
import {CompanyDataMap} from "../../../../../graphql/customScalars";
import {BizModal} from "../../../../shares/bizModal/BizModal";
import {BizCompanyDataView} from "../../../../shares/bizCompanyDataView/BizCompanyDataView";
import {OnChangeValue} from "../../../../Intefaces";
import {BadgeCompanyUpdateStatus} from "../../../../shares/badgeCompanyUpdateStatus/BadgeCompanyUpdateStatus";
import {useTranslation} from "react-i18next";
import {I18nKeys} from "../../../../../i18n/i18nKeys";
import {SwalUtil} from "../../../../../utils/swal/swalUtil";

const cx = classNamesBind.bind(styles);

interface Props {
    companyUpdateRequestList: CompanyUpdateRequestList,
    onAllow: OnChangeValue<MutationAllowUpdateCompanyArgs>,
    onCancel: OnChangeValue<MutationCancelUpdateCompanyArgs>
}

export const MyPageCompanyUpdateRequestList = (props: Props) => {
    return (
        <>
            <ViewSelector
                web={<Web {...props}/>}
                mobile={<Mobile {...props}/>}/>
        </>
    )
}

const Web = (props: Props) => {
    const [viewCompany, setViewCompany] = useState<CompanyDataMap | undefined>();
    const [t] = useTranslation('common');

    const handler = {
        onShowMemo: (memo: string) => {
            SwalUtil.ok({
                msg: memo,
                icon: 'info'
            })
        }
    }

    return (
        <>

            <BizModal
                size="xl"
                isShow={Boolean(viewCompany)}
                onCancel={() => setViewCompany(undefined)}>
                {viewCompany &&
                <BizCompanyDataView
                    companyDataMap={viewCompany}
                    companyLikes={{likes: 0, goodsLikes: 0, views: 0}}/>
                }
            </BizModal>
            <div className={cx('table')}>
                <div className={cx('header', 'box-id')}>{t("mypageCompany.requestIdx")}</div>
                <div className={cx('header', 'box-created-at')}>{t("mypageCompany.requestDate")}</div>
                <div className={cx('header', 'box-prev')}>{t("mypageCompany.prevInfo")}</div>
                <div className={cx('header', 'box-next')}>{t("mypageCompany.nextInfo")}</div>
                <div className={cx('header', 'box-func')}>{t("mypageCompany.function")}</div>
            </div>


            {props.companyUpdateRequestList.list.map((value, index) => (
                <div key={index} className={cx('table')}>
                    <div className={cx('col', 'box-id')}>{value.id}</div>
                    <div className={cx('box-created-at', 'text-center')}>
                        {moment(value.createdAt).format('YY.MM.DD')}<br/>
                        <BadgeCompanyUpdateStatus status={value.status}/>
                    </div>
                    <div className={cx('box-prev')} onClick={() => {
                        setViewCompany(value.prevCompany)
                    }}>
                        <BizCompanyDataInTable
                            companyDataMap={value.prevCompany}/>
                    </div>
                    <div className={cx('box-next')} onClick={() => {
                        setViewCompany(value.nextCompany)
                    }}>
                        <BizCompanyDataInTable
                            companyDataMap={value.nextCompany}/>
                    </div>
                    <div className={cx('col', 'box-func')}>
                        {value.status === CompanyUpdateStatus.Requested &&
                        <>
                            <Tooltip title={t("mypageCompany.ttCancelUpdate") || ""}>
                                <IconButton onClick={() => props.onCancel({companyUpdateRequestId: value.id})}>
                                    <Delete/>
                                </IconButton>
                            </Tooltip>

                        </>
                        }

                        {value.status === CompanyUpdateStatus.Accepted &&
                        <>
                            <Tooltip title={t("mypageCompany.ttAllowUpdate") || ""}>
                                <IconButton onClick={() => props.onAllow({companyUpdateRequestId: value.id})}>
                                    <Check/>
                                </IconButton>
                            </Tooltip>

                            <Tooltip title={t("mypageCompany.ttCancelUpdate") || ""}>
                                <IconButton onClick={() => props.onCancel({companyUpdateRequestId: value.id})}>
                                    <Delete/>
                                </IconButton>
                            </Tooltip>
                        </>
                        }

                        {value.status === CompanyUpdateStatus.Denied &&
                        <Tooltip title={t(I18nKeys.company.deniedReason.key) || ''}>
                            <IconButton onClick={() => handler.onShowMemo(value.memo)}>
                                <SpellcheckOutlined/>
                            </IconButton>
                        </Tooltip>
                        }
                    </div>
                </div>
            ))}
        </>
    )
}

const Mobile = (props: Props) => {
    return (
        <><Web {...props}/></>
    )
}
