import React, {Fragment, useCallback, useEffect, useState} from 'react';
import classNamesBind from "classnames/bind";
import styles from './InputReactQuill.module.scss';
import {OnChangeValue} from "../../../../Intefaces";
import 'quill/dist/quill.snow.css';
import {DataUtil} from "../../../../../utils/data/DataUtil";
import {SwalUtil} from "../../../../../utils/swal/swalUtil";
import {ApolloCatch} from "../../../../../utils/apollo/apolloCatch";
import {Code, Edit} from "@material-ui/icons";
import {BizErrors} from "../../../../../graphql/errors";
import {useQuill} from "react-quilljs";
import {Button} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import { useDeepCompareEffect } from 'use-deep-compare';

const cx = classNamesBind.bind(styles);


enum EditorMode {
    Quill,
    Textarea,
}

export const InputReactQuill = (props: {
    disableHTML?: boolean,
    value: string,
    onChangeValue: OnChangeValue<string>
}) => {
    const [contents, setContents] = useState(props.value);
    const [editorMode, setEditorMode] = useState(EditorMode.Quill);
    const [t] = useTranslation('common');

    const {quill, quillRef} = useQuill({
        modules: {
            toolbar: {
                container: [
                    [{'header': [1, 2, 3, 4, 5, 6, false]}],
                    [{'align': []}],
                    ['bold', 'italic', 'underline', 'strike'],
                    ["image"],
                    [{'color': []}, {'background': []}],
                ]
            },
        }
    });

    const handler = {
        onUploadImage: useCallback(() => {
            DataUtil.uploadFile({image: true, multiple: true})
                .then(res => {

                    for (let file of res) {
                        if (!quill) {
                            continue;
                        }

                        const img = document.createElement('img');
                        img.setAttribute('style', 'width:100%');
                        img.setAttribute('src', file.src);
                        quill.root.appendChild(img);
                    }
                })
                .catch(ApolloCatch({
                    [BizErrors.default]: () => {
                        SwalUtil.ok({
                            msg: t("cs.swalUploadError"),
                            icon: "error"
                        })
                    },
                    [BizErrors.overMaxSize]: () => {
                        SwalUtil.ok({
                            msg: t("cs.swalUploadOverMaxSize"),
                            icon: "error"
                        })
                    }
                }));
        }, [quill, t])
    }

    useDeepCompareEffect(() => {
        if ((editorMode === EditorMode.Quill) && quill) {
            quill.clipboard.dangerouslyPasteHTML(props.value);
        }
    }, [])

    useDeepCompareEffect(() => {
        if (quill) {
            quill.getModule('toolbar').addHandler('image', handler.onUploadImage);
            quill.on('text-change', () => {
                if (quill) {
                    setContents(quill.root.innerHTML);
                }
            })

            if (props.value) {
                quill.clipboard.dangerouslyPasteHTML(props.value);
            }
        }
    }, [quill]);

    useEffect(() => {
        if (quillRef) {
            window.scrollTo(0, 0);
        }
    }, [quillRef]);

    useDeepCompareEffect(() => {
        props.onChangeValue(contents);
    }, [contents]);


    return (
        <Fragment>
            {!props.disableHTML &&
            <div className='text-right'>
                <Button startIcon={<Code/>} onClick={() => setEditorMode(EditorMode.Textarea)}>
                    {t("common.btnToggleEditHtmlCode")}
                </Button>
                <Button startIcon={<Edit/>} onClick={() => setEditorMode(EditorMode.Quill)}>
                    {t("common.btnToggleEditor")}
                </Button>
            </div>
            }
            {editorMode === EditorMode.Textarea &&
            <textarea
                className={cx('textarea', 'form-control', 'form-control-sm')}
                onChange={ev => {
                    props.onChangeValue(ev.target.value);
                }}
                value={props.value}/>
            }

            <div style={{display: editorMode === EditorMode.Quill ? '' : 'none'}}>
                <div ref={quillRef} style={{height: 500}}/>
            </div>
        </Fragment>
    )
}
