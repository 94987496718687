import React from 'react';
import {gql, useQuery} from "@apollo/client";
import {Query} from "../../../graphql/types";
import {IndexFindDefault} from "./default/IndexFindDefault";
import {FadeIn} from "../../shares/fadeIn/FadeIn";

export const IndexFind = (props: {
    companyPage: number,
    goodsPage: number,
    word: string,
}) => {
    const {data} = useQuery<Query, typeof props>(gqlData, {
        variables: props
    });

    return (
        <>
            {data &&
            <FadeIn>
                <IndexFindDefault 
                    {...data}
                    word={props.word}/>
            </FadeIn>
            }
        </>
    )
}

const gqlData = gql`
    query DATA ($companyPage: Int!, $goodsPage: Int!, $word: String!) {
        companyWithNm(page: $companyPage, size: 20, word: $word) {
            page
            size
            total
            list {
                id
                isPaid
                company
            }
        }
        goodsWithNm(page: $goodsPage, size: 20, word: $word)  {
            page
            size
            total
            list {
                id
                isPaid
                company
                allEduCode
                data
            }
        }
    }
`;
