import React, {useState} from 'react';
import classNamesBind from "classnames/bind";
import styles from './CsQnaCreate.module.scss';
import {CsLayout} from "../../layout/CsLayout";
import {BizTitle} from "../../../shares/bizTitle/BizTitle";
import {InputReactQuill} from "../../../mypage/share/inputs/inputReactQuill/InputReactQuill";
import {Mutation, MutationCreateQnaArgs} from "../../../../graphql/types";
import {CopyUtil} from "../../../../utils/copyUtil/copyUtil";
import {gql} from "@apollo/client/core";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import {useMutation} from "@apollo/client";
import {navigate} from "hookrouter";
import {ApolloCatch} from "../../../../utils/apollo/apolloCatch";
import {useTranslation} from "react-i18next";

const cx = classNamesBind.bind(styles);

export const CsQnaCreate = () => {
    const [data, setData] = useState<MutationCreateQnaArgs>({
        title: '',
        contents: ''
    });
    const [mutCreate] = useMutation<Mutation, MutationCreateQnaArgs>(gqlCreate);
    const [t] = useTranslation('common');

    const handler = {
        onCreate: () => {
            if (!data.title) {
                SwalUtil.ok({
                    msg: t("cs.swalCreateQnaNotTitle"),
                    icon: 'warning'
                });
                return;
            }

            if (!data.contents) {
                SwalUtil.ok({
                    msg: t("cs.swalCreateQnaNotContents"),
                    icon: 'warning'
                });
                return;
            }

            SwalUtil.ynPromise({
                msg: t("cs.swalCreateQnaQuestion")
            }).then(() => {
                return mutCreate({
                    variables: data
                })
            }).then(() => {
                SwalUtil.ok({
                    msg: t("cs.swalCreateQnaOk"),
                    icon: 'success'
                });
                navigate('/cs/qna/list/0')
            }).catch(ApolloCatch({}));
        }
    }

    return (
        <CsLayout activatedSuffixRouter={'/qna/list'}>
            <BizTitle>{t("cs.qnaTitle")}</BizTitle>

            <div className={cx('table')}>
                <div className={cx('col', 'col-label')}>{t("cs.title")}</div>
                <div className={cx('col-value')}>
                    <input
                        className='form-control form-control-sm'
                        value={data.title}
                        onChange={ev => {
                            const copy = CopyUtil.copyAll(data);
                            copy.title = ev.target.value;
                            setData(copy);
                        }}
                    />
                </div>
            </div>


            <div className={cx('table')}>
                <div className={cx('col', 'col-label')}>{t("cs.contents")}</div>
                <div className={cx('col-value')}>
                    <InputReactQuill
                        disableHTML
                        value={data.contents}
                        onChangeValue={v => {
                            const copy = CopyUtil.copyAll(data);
                            copy.contents = v;
                            setData(copy);
                        }}/>
                </div>
            </div>

            <div className={cx('table')}>
                <div className={cx('col-full', 'text-right')}>
                    <button
                        className='btn btn-sm btn-success'
                        onClick={handler.onCreate}>
                        {t("cs.btnAdd")}
                    </button>
                </div>
            </div>


        </CsLayout>
    )
}

const gqlCreate = gql`
    mutation Create($title: String!, $contents: String!) {
        createQna(title: $title, contents: $contents)
    }
`;
