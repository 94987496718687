import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './BizSocialShareBtn.module.scss';
import {OnVoidFunction} from "../../../Intefaces";

const cx = classNamesBind.bind(styles);

export const BizSocialShareBtn = (props: {
    label: string,
    onClick: OnVoidFunction,
    img: any
}) => {
    return (
        <div className={cx('cont')} onClick={props.onClick}>
            <div className={cx('img')}>
                <img src={props.img} alt={props.label}/>
            </div>
            <div className={cx('label')}>
                {props.label}
            </div>
        </div>
    )
}
