import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './MyPageConsultantDetailGoodsList.module.scss';
import {MyGoodsWithConsultant} from "../../../../../graphql/types";
import {BizGoodsIconInTable} from "../../../../shares/bizGoodsIconInTable/BizGoodsIconInTable";
import {Pipe} from "../../../../../utils/pipe/pipe";
import {navigate} from "hookrouter";
import {IconButton, Tooltip} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import {BizNoDataTable} from "../../../../shares/bizNoDataTable/BizNoDataTable";
import {OnChangeValue} from "../../../../Intefaces";
import {useTranslation} from "react-i18next";

const cx = classNamesBind.bind(styles);

export const MyPageConsultantDetailGoodsList = (props: {
    value: MyGoodsWithConsultant[],
    onUnbind: OnChangeValue<number>
}) => {
    const [t] = useTranslation('common');

    return (
        <>
            <div className={cx('table')}>
                <div className={cx('header', 'col-all-edu-id')}>{t("mypageConsultant.goodsNo")}</div>
                <div className={cx('header', 'col-goods')}>{t("mypageConsultant.goods")}</div>
                <div className={cx('header', 'col-lang')}>{t("mypageConsultant.linkedLanguage")}</div>
                <div className={cx('header', 'col-func')}>{t("mypageConsultant.function")}</div>
            </div>

            {props.value.length === 0 && <BizNoDataTable/>}
            {props.value.map((value, index) => (
                <div key={index} className={cx('table')}>
                    <div className={cx('col', 'col-all-edu-id')}>{value.goods.allEduCode}</div>
                    <div className={cx('col', 'col-goods', 'pointer')} onClick={() => navigate(`/mypage/goods/bind/${value.goods.id}`)}>
                        <BizGoodsIconInTable
                            goodsDataMap={value.goods.data}
                            categoryId={value.goods.categoryId}/>
                    </div>
                    <div className={cx('col', 'col-lang')}>{Pipe.langNm(value.langCode)}</div>
                    <div className={cx('col', 'col-func')}>
                        <Tooltip title={'해제'}>
                            <IconButton onClick={() => props.onUnbind(value.goods.id)}>
                                <Close/>
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            ))}
        </>
    )
}
