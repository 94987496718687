import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './BizGoodsDataViewTabs.module.scss';
import {useTranslation} from "react-i18next";
import {Link} from "react-scroll";

const cx = classNamesBind.bind(styles);

export const BizGoodsDataViewTabs = (props: {
    idx: number,
    disableInteraction?: boolean,
}) => {
    const [t] = useTranslation('common');
    const offset = -170;

    return (
        <div id={'content'} className={cx('box-tab')}>
            <Link
                className={cx('tab', props.idx === 0 ? 'open' : 'hide')}
                disabled={props.disableInteraction} to='content' smooth offset={offset}
                style={{cursor: 'pointer'}}>
                {t('goods.goodsContent')}
            </Link>
            <Link
                className={cx('tab', props.idx === 1 ? 'open' : 'hide')}
                disabled={props.disableInteraction} to='info' smooth offset={offset}
                style={{cursor: 'pointer'}}>
                {t('goods.goodsInfo')}
            </Link>
            <Link
                className={cx('tab', props.idx === 2 ? 'open' : 'hide')}
                disabled={props.disableInteraction} to='company' smooth offset={offset}>
                {t('goods.goodsCompany')}
            </Link>
        </div>
    )
}
