import {createActionCreators, createReducerFunction, ImmerReducer} from "immer-reducer";


export enum AppStatus {
    Loading = 'loading',
    ServerShutdown = 'shutdown',
    Ok = 'ok',
}

export enum ViewMode {
    Web = 'Web',
    Mobile = 'Mobile'
}

export interface SystemState {
    critWidth: number;
    viewMode: ViewMode;
    loadingView: boolean;
    appStatus: AppStatus;
}

const initState: SystemState = {
    critWidth: 998,
    viewMode: ViewMode.Web,
    loadingView: false,
    appStatus: AppStatus.Loading,
};

class System extends ImmerReducer<typeof initState> {
    setWindowWidth(width: number) {
        if ((width < this.state.critWidth) && (this.state.viewMode === ViewMode.Web)) {
            this.draftState.viewMode = ViewMode.Mobile;
        } else if ((this.state.critWidth < width) && (this.state.viewMode === ViewMode.Mobile)) {
            this.draftState.viewMode = ViewMode.Web;
        }
    }

    setLoadingView(swt: boolean) {
        this.draftState.loadingView = swt;
    }

    setAppStatus(swt: AppStatus) {
        this.draftState.appStatus = swt;
    }
}

export const SystemAction = createActionCreators(System);
export const SystemReducer = createReducerFunction(System, initState);

