import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './EdutechKoreaFair2024CompanyData.module.scss';
import {useSelector} from "react-redux";
import {navigate} from "hookrouter";
import { I18nState } from '../../../../../redux/I18n';
import { BizNoDataTable } from '../../../../shares/bizNoDataTable/BizNoDataTable';
import { EdutechKoreaFair2024Company } from '../../../../../graphql/types';
import { CompanyDataUtil } from '../../../../../utils/companyDataUtil/CompanyDataUtil';
import { Pipe } from '../../../../../utils/pipe/pipe';

const cx = classNamesBind.bind(styles);

interface Props {
    companyList: EdutechKoreaFair2024Company[]
}

export const EdutechKoreaFair2024CompanyData = (props: Props) => {
    const langCode = useSelector<any, I18nState>(state => state.i18n).langCode;


    return (
        <>
            {props.companyList.length === 0 && <BizNoDataTable/>}

            <div className={cx('cont')}>

                {props.companyList.map((value, index) => {
                    const companyData = CompanyDataUtil.getValue(langCode, value.companyData);
                    return (
                        <div key={index}
                             className={cx(value.isPaid ? 'box-full' : 'box-half', 'p-5')}
                             onClick={() => navigate(`/edutechkoreafair/2024/detail/${value.id}`)}>
                            
                            <div className={cx('btn-company')}>
                                <img src={companyData.logoUrl} alt='logo'/>

                                <div className={cx('info')}>
                                    <div className={cx('company-nm')}>{companyData.companyNm}</div>
                                    <div className={cx('space')}/>
                                    <div className={cx('nation')}>
                                        <span className={cx('flag', 'flag-icon', `flag-icon-${companyData.nationCode.toLowerCase()}`)}/>
                                        <span className={cx('nm')}>{Pipe.nationNm(companyData.nationCode)}</span>
                                    </div>
                                    <div className={cx('catchphrase')}>
                                        {companyData.catchphrase}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </>

    )
}
