import React, {Fragment} from 'react';
import styles from './TermsView.module.scss';
import {BizTitle} from "../../shares/bizTitle/BizTitle";

export const TermsView = (props: {
    title: string,
    body: string,
}) => {
    return (
        <Fragment>
            <BizTitle>{props.title}</BizTitle>
            <div
                className={styles.cont}
                ref={ref => {
                if (ref) {
                    ref.innerHTML = props.body
                }
            }}/>
            <div style={{height: 100}}/>
        </Fragment>
    )
}
