import React from "react";
import {gql, useMutation, useQuery} from "@apollo/client";
import {
    Mutation,
    MutationCreateChatRoomWithGoodsInfoIdAndConsultantIdArgs,
    MutationCreateChatRoomWithGoodsInfoIdArgs,
    MutationHasChatRoomWithGoodsInfoIdArgs,
    Query
} from "../../../graphql/types";
import {BizScrollTop} from "../../shares/bizScrollTop/BizScrollTop";
import {BizGoodsDataView} from "../../shares/bizGoodsDataView/BizGoodsDataView";
import {useSelector} from "react-redux";
import {I18nState} from "../../../redux/I18n";
import {AllEduCode} from "../../../graphql/customScalars";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {ChatV1PageQueryParams} from "../../chat/v1/ChatV1";
import {navigate} from "hookrouter";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../graphql/errors";
import {I18nKeys} from "../../../i18n/i18nKeys";
import {useTranslation} from "react-i18next";

export const GoodsDetail = (props: {
    allEduCode: AllEduCode
}) => {
    const [t] = useTranslation('common');
    const langCode = useSelector<any, I18nState>(state => state.i18n).langCode;
    const {data} = useQuery<Query, typeof props>(glaData, {
        variables: props,
        context: {
            header: {
                langCode
            }
        }
    });
     
    const [mutCreateChatRoomWithGoodsInfoIdAndConsultant]
        = useMutation<Mutation, MutationCreateChatRoomWithGoodsInfoIdAndConsultantIdArgs>(gqlCreateChatRoomWithConsultant);
    const [mutCreateChatRoomWithGoodsInfoId]
        = useMutation<Mutation, MutationCreateChatRoomWithGoodsInfoIdArgs>(gqlCreateChatRoomWithGoodsInfoId);
    const [mutHasChatRoomWithGoodsInfoId]
        = useMutation<Mutation, MutationHasChatRoomWithGoodsInfoIdArgs>(gqlHasChatRoomWithGoodsInfoId);

    const handler = {
        onCreateChatRoomWithGoodsInfoId: (goodsInfoId: number) => {
            mutHasChatRoomWithGoodsInfoId({
                variables: {
                    goodsInfoId
                }
            }).then(res => {
                const chatRoomId = res.data?.hasChatRoomWithGoodsInfoId || -1;
                if (chatRoomId === -1) {
                    chatHandler.createChatRoomWithGoodsInfoId(goodsInfoId);
                } else {
                    chatHandler.askCreateChatRoomWithGoodsInfoId(goodsInfoId, chatRoomId);
                }
            })
        },
        onCreateChatRoomWithGoodsInfoIdAndConsultantId: (consultantId: number) => {
            if (!data) {
                return;
            }

            const goodsInfoId = data.goodsWithAllEduCode.goodsInfoId;
            mutHasChatRoomWithGoodsInfoId({
                variables: {
                    goodsInfoId
                }
            }).then(res => {
                const chatRoomId = res.data?.hasChatRoomWithGoodsInfoId || -1;
                if (chatRoomId === -1) {
                    chatHandler.createChatRoomWithGoodsInfoId(goodsInfoId);
                } else {
                    chatHandler.askCreateChatRoomWithConsultantId(goodsInfoId, consultantId, chatRoomId);
                }
            })
        }
    }

    const chatHandler = {
        askCreateChatRoomWithGoodsInfoId: (goodsInfoId: number, chatRoomId: number) => {
            SwalUtil.ynPromise({
                msg: t(I18nKeys.chat.askEnterChatRoom.key),
                okBtn: t(I18nKeys.chat.enterChatRoomBtn.key),
                cancelBtn: t(I18nKeys.chat.createChatRoomBtn.key),
            }).then(() => {
                chatHandler.navigateChatRoomWithChatRoomId(chatRoomId);
            }).catch(ApolloCatch({
                [BizErrors.swalCancel]: () => {
                    chatHandler.createChatRoomWithGoodsInfoId(chatRoomId);
                }
            }));
        },
        askCreateChatRoomWithConsultantId: (goodsInfoId: number, consultantId: number, chatRoomId: number) => {
            SwalUtil.ynPromise({
                msg: t(I18nKeys.chat.askEnterChatRoom.key),
                okBtn: t(I18nKeys.chat.enterChatRoomBtn.key),
                cancelBtn: t(I18nKeys.chat.createChatRoomBtn.key),
            }).then(() => {
                chatHandler.navigateChatRoomWithChatRoomId(chatRoomId);
            }).catch(ApolloCatch({
                [BizErrors.swalCancel]: () => {
                    chatHandler.createChatRoomWithGoodsInfoIdAndConsultantId(goodsInfoId, consultantId);
                }
            }));
        },
        createChatRoomWithGoodsInfoId: (goodsInfoId: number) => {
            mutCreateChatRoomWithGoodsInfoId({
                variables: {
                    goodsInfoId: goodsInfoId
                }
            }).then(res => {
                chatHandler.navigateChatRoomWithChatRoomId(res.data!.createChatRoomWithGoodsInfoId)
            }).catch(ApolloCatch({}));
        },
        createChatRoomWithGoodsInfoIdAndConsultantId: (goodsInfoId: number, consultantId: number) => {
            mutCreateChatRoomWithGoodsInfoIdAndConsultant({
                variables: {
                    goodsInfoId,
                    consultantId,
                }
            }).then(res => {
                chatHandler.navigateChatRoomWithChatRoomId(res.data!.createChatRoomWithGoodsInfoIdAndConsultantId)
            }).catch(ApolloCatch({}))
        },
        navigateChatRoomWithGoodsInfoId: (goodsInfoId: number) => {
            const params: ChatV1PageQueryParams = {
                tab: 'user',
                userChatListPage: 0,
                consultChatListPage: 0,
                goodsInfoId,
            };
            navigate('/chat', true, params);
        },
        navigateChatRoomWithChatRoomId: (chatRoomId: number) => {
            const params: ChatV1PageQueryParams = {
                tab: 'user',
                userChatListPage: 0,
                consultChatListPage: 0,
                chatRoomId,
            };
            navigate('/chat', true, params);
        }
    }

    return (
        <BizScrollTop>
            {data &&
            <>
                <BizGoodsDataView
                    onCreateChatRoomWithGoodsInfoIdAndConsultantId={handler.onCreateChatRoomWithGoodsInfoIdAndConsultantId}
                    onCreateChatRoomWithGoodsInfoId={handler.onCreateChatRoomWithGoodsInfoId}
                    companyUserId={data.goodsWithAllEduCode.companyUserId}
                    goodsId={data.goodsWithAllEduCode.id}
                    allEduCode={data.goodsWithAllEduCode.allEduCode}
                    goodsInfoId={data.goodsWithAllEduCode.goodsInfoId}
                    consultant={data.goodsConsultantWithAllEduCode}
                    companyDataMap={data.goodsWithAllEduCode.company}
                    categoryId={data.goodsWithAllEduCode.categoryId}
                    goodsDataMap={data.goodsWithAllEduCode.data}
                    isCertificated={data.goodsWithAllEduCode.isCertificated}/>
            </>
            }
        </BizScrollTop>
    )
}

const glaData = gql`
    query Goods($allEduCode: AllEduCode!) {
        goodsWithAllEduCode(allEduCode: $allEduCode) {
            id
            allEduCode
            goodsInfoId
            categoryId
            company
            data
            companyUserId
            isCertificated
        }
        goodsConsultantWithAllEduCode(allEduCode: $allEduCode) {
            id
            email
            nm
            position
            tel
            isRegisteredAccount
        }
    }
`;

const gqlCreateChatRoomWithConsultant = gql`
    mutation CreateChatRoom($goodsInfoId: Int!, $consultantId: Int!) {
        createChatRoomWithGoodsInfoIdAndConsultantId(goodsInfoId: $goodsInfoId, consultantId: $consultantId)
    }
`;

const gqlCreateChatRoomWithGoodsInfoId = gql`
    mutation CreateChatRoom($goodsInfoId: Int!) {
        createChatRoomWithGoodsInfoId(goodsInfoId: $goodsInfoId)
    }
`;

const gqlHasChatRoomWithGoodsInfoId = gql`
    mutation HasChatRoomWithGoodsInfoId($goodsInfoId: Int!) {
        hasChatRoomWithGoodsInfoId(goodsInfoId: $goodsInfoId)
    }
`;
