import React, {useState} from 'react';
import classNamesBind from "classnames/bind";
import styles from './MyPageConsultantCreateModal.module.scss';
import {OnChangeValue} from "../../../../Intefaces";
import {CompanyConsultantPermission, InputCompanyConsultant,} from "../../../../../graphql/types";
import {CopyUtil} from "../../../../../utils/copyUtil/copyUtil";
import {MultiLangUtil} from "../../../../../graphql/customScalars";
import {MyPageConsultantInput} from "../input/MyPageConsultantInput";
import {useTranslation} from "react-i18next";
import { ViewSelector } from '../../../../shares/viewSelector/ViewSelector';

const cx = classNamesBind.bind(styles);

interface Props {
    onCreate: OnChangeValue<InputCompanyConsultant>
}

type CompProps = Props & {
    input: InputCompanyConsultant,
    setInput: OnChangeValue<InputCompanyConsultant>,
}

export const MyPageConsultantCreateModal = (props: Props) => {
    const [input, setInput] = useState<InputCompanyConsultant>({
        email: '',
        nm: MultiLangUtil.newMultiLangValue(),
        permission: [CompanyConsultantPermission.Position],
        position: MultiLangUtil.newMultiLangValue(),
        tel: MultiLangUtil.newMultiLangValue(),
    });

    const handler = {
        input,
        setInput
    }

    return (
        <>
            <ViewSelector
                web={<Web {...props} {...handler}/>}
                mobile={<Mobile {...props} {...handler}/>}/>
        </>
    )
}

const Web = (props: CompProps) => {
    const [t] = useTranslation('common');

    return (
        <>
            <MyPageConsultantInput
                input={props.input}
                onChangeInput={props.setInput}/>


            <div className={cx('table')}>
                <div className={cx('col', 'col-full', 'text-right')}>
                    <button
                        className='btn btn-sm btn-success'
                        onClick={() => props.onCreate(CopyUtil.copyAll(props.input))}>
                        {t("mypageConsultant.btnCreateInput")}
                    </button>
                </div>
            </div>
        </>
    )
}

const Mobile = (props: CompProps) => {
    return (
        <><Web {...props}/></>
    )
}
