import React, {Fragment, ReactNode, useEffect, useState} from 'react';
import styles from './BizProgressBar.module.scss';
import {useTranslation} from "react-i18next";
import classNames from "classnames";


export const BizProgressBar = (props: {
    valueList: BizProgressBarData[],
    status: string,
    icon?: ReactNode,
}) => {
    const [t] = useTranslation("common");
    const [width, setWidth] = useState(0);
    const [barSize, setBarSize] = useState<{
        width: string,
        left: string,
    }>({
        width: '0%',
        left: '0%'
    });

    const [idx, setIdx] = useState(0);

    useEffect(() => {
        const width = 100 / props.valueList.length;
        setWidth(width);

        setBarSize({
            width: `${width * (props.valueList.length - 1)}%`,
            left: `${width / 2}%`
        });

        const idx = props.valueList.findIndex(value => {
            return value.status === props.status;
        });

        setIdx(idx);
    }, [props.valueList, props.status]);

    const replace = (str: string): string => {
        let res = str.replace('<Menu/>', menuIconHtml);
        res = res.replace('<Check/>', checkIconHtml);
        res = res.replace('<Eye/>', eyeIconHtml);
        res = res.replace('<SpellCheck/>', spellCheckIconHtml);
        return res;
    }

    return (
        <Fragment>
            <div className={styles.cont}>
                {/*<Bar value={20}/>*/}
                <div className={styles.bar} style={{
                    width: barSize.width,
                    left: barSize.left
                }}/>

                {props.valueList.map((value, index) => (
                    <Fragment key={index}>
                        <Circle
                            index={index}
                            nowIndex={idx}
                            tLabel={value.tLabel}
                            isCurrent={idx === index}
                            isActivate={index <= idx}
                            width={`${width}%`}
                            icon={props.icon}/>
                    </Fragment>
                ))}
            </div>

            <div className={styles.contTooltip}>
                {((idx < props.valueList.length) && (idx !== -1) && (props.valueList[idx].tTooltip)) &&
                <div className={styles.tooltip} ref={ref => {
                    if (ref) {
                        ref.innerHTML = replace(t(props.valueList[idx].tTooltip || ''))
                    }
                }}>
                </div>
                }
            </div>
        </Fragment>
    )
}

const Circle = (props: {
    index: number,
    nowIndex: number,
    width: string,
    isActivate: boolean,
    isCurrent: boolean,
    tLabel: string,
    icon?: ReactNode,
}) => {
    const [t] = useTranslation('common');
    const isActivate = props.index === (props.nowIndex + 1);
    return (
        <div className={styles.contCircle} style={{width: props.width}}>
            <div className={styles.icon}>
                {isActivate && props.icon}
            </div>
            <div className={classNames(styles.circle, props.isActivate ? styles.activate : '')}>
                {isActivate && <div className={styles.innerCircle}/>}
            </div>
            <div className={classNames(styles.label, isActivate ? styles.activate : '')}>
                {t(props.tLabel)}
            </div>
        </div>
    )
}

const checkIconHtml = `
<div style="height: 18px; width: 18px; display: inline-block; color: gray;">
    <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
        <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path>
    </svg>
</div>
`;

const eyeIconHtml = `
<div style="height: 18px; width: 18px; display: inline-block; color: gray;">
    <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
        <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"></path>
    </svg>
</div>
`;

const menuIconHtml = `
<div style="height: 18px; width: 18px; display: inline-block; color: gray;">
    <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
        <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
    </svg>
</div>
`;

const spellCheckIconHtml = `
<div style="height: 18px; width: 18px; display: inline-block; color: gray;">
    <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
        <path d="M12.45 16h2.09L9.43 3H7.57L2.46 16h2.09l1.12-3h5.64l1.14 3zm-6.02-5L8.5 5.48 10.57 11H6.43zm15.16.59l-8.09 8.09L9.83 16l-1.41 1.41 5.09 5.09L23 13l-1.41-1.41z"></path>
    </svg>
</div>
`;

export interface BizProgressBarData {
    status: string,
    tLabel: string,
    tTooltip?: string,
}
