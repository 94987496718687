import React, {ReactNode} from 'react';
import classNamesBind from "classnames/bind";
import styles from './BtnBox.module.scss';

const cx = classNamesBind.bind(styles);

export const BtnBox = (props: {
    children: ReactNode
}) => {
    return (
        <div className={cx('cont')}>
            {props.children}
        </div>
    )
}
