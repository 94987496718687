import React, {useEffect, useState} from 'react';
import {gql, useQuery} from "@apollo/client";
import {Element} from "react-scroll";
import {BizTitle} from "../../../shares/bizTitle/BizTitle";
import {Pager} from "../../../shares/pager/Pager";
import {CsLayout} from "../../layout/CsLayout";
import style from './CsNewsletterList.module.scss';
import classNamesBind from "classnames/bind";
import {Query} from '../../../../graphql/types';
import {useSelector} from "react-redux";
import {I18nState} from "../../../../redux/I18n";
import {ApolloCatch} from "../../../../utils/apollo/apolloCatch";
import {Pipe} from '../../../../utils/pipe/pipe';
import moment from "moment";
import {navigate} from "hookrouter";
import {useTranslation} from "react-i18next";
import {I18nKeys} from "../../../../i18n/i18nKeys";

const cx = classNamesBind.bind(style);

export const CsNewsletterList = (props: {
    page: number
}) => {
    const langCode = useSelector<any, I18nState>(state => state.i18n).langCode;

    let [listStartNumber, setListStartNumber] = useState<number>(0);
    const {data, refetch} = useQuery<Query, any>(gqlData, {
        variables: {
            page: props.page
        }
    });
    const [t] = useTranslation('common');

    useEffect(() => {
        if (!refetch) {
            return;
        }

        refetch({
            page: 0
        }).catch(ApolloCatch({}));

        if(data?.newsletterList) {
            const getListStartNumber = () => {
                let total = data.newsletterList.total;
                let page = data.newsletterList.page;
                let size = data.newsletterList.size;
                return total - (page * size);
            }

            setListStartNumber(getListStartNumber());
        }
    }, [langCode, data, refetch, setListStartNumber]);


    const handler = {
        onClickNewsletter: (id: number) => {
            navigate(`/cs/newsletter/view/${id}`);
        },
        onChangePage: (p: number) => {
            navigate(`/cs/newsletter/list/${p}`);
        }
    };


    return (
        <CsLayout activatedSuffixRouter={'/newsletter/list'}>
            {data &&
            <>
                <Element name='pager'/>
                <BizTitle>{t("cs.newsletterTitle")}</BizTitle>

                <div className={cx('table')}>
                    <div className={cx('col-header', 'col-id')}>{t(I18nKeys.cs.idx.key)}</div>
                    <div className={cx('col-header', 'col-title')}>{t("cs.title")}</div>
                    <div className={cx('col-header', 'col-view')}>{t("cs.viewCount")}</div>
                    <div className={cx('col-header', 'col-created-at')}>{t("cs.createDate")}</div>
                    <div className={cx('col-header', 'col-updated-at')}>{t("cs.updateDate")}</div>
                </div>

                {data.newsletterList.list.map((value, index) => (
                    <div key={index} className={cx('table', 'hover', 'pointer')}
                         onClick={() => handler.onClickNewsletter(value.id)}>
                        <div className={cx('col', 'col-id')}>{listStartNumber--}</div>
                        <div className={cx('col', 'col-title', 'ellipsis')}>{value.title}</div>
                        <div className={cx('col', 'col-view')}>{Pipe.toSeparatorNumber(value.views)}</div>
                        <div className={cx('col', 'col-created-at')}>{moment(value.createdAt).format('YY.MM.DD')}</div>
                        <div className={cx('col', 'col-updated-at')}>{moment(value.updatedAt).format('YY.MM.DD')}</div>
                    </div>

                ))}

                <Pager
                    offset={-100}
                    page={data.newsletterList.page}
                    size={data.newsletterList.size}
                    total={data.newsletterList.total}
                    onChange={handler.onChangePage}/>
            </>
            }
        </CsLayout>
    )
}

const gqlData = gql`
    query DATA($page: Int!) {
        newsletterList(page: $page, size: 20) {
            page
            size
            total
            list {
                id
                title
                views
                createdAt
                updatedAt
            }
        }
    }
`;
