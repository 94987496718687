import React, {Fragment} from 'react';
import {CompanyCreateStatus, CompanyUpdateStatus} from "../../../graphql/types";
import {useTranslation} from "react-i18next";
import { I18nKeys } from '../../../i18n/i18nKeys';

export const BadgeCompanyRequestStatus = (props: {
    status: CompanyUpdateStatus | CompanyCreateStatus
}) => {
    const {status} = props;
    const [t] = useTranslation('common');
    return (
        <Fragment>
            {status === CompanyCreateStatus.Accepted &&
            <span className='badge badge-success'>{t(I18nKeys.company.badgeCompanyRequestStatusAccepted.key)}</span>}
            {status === CompanyCreateStatus.Requested &&
            <span className='badge badge-warning'>{t(I18nKeys.company.badgeCompanyRequestStatusRequested.key)}</span>}
            {status === CompanyCreateStatus.Cancel &&
            <span className='badge badge-danger'>{t(I18nKeys.company.badgeCompanyRequestStatusCancel.key)}</span>}
            {status === CompanyCreateStatus.Denied &&
            <span className='badge badge-success'>{t(I18nKeys.company.badgeCompanyRequestStatusDnied.key)}</span>}
        </Fragment>
    )
}
