import React, {Fragment} from 'react';
import classNamesBind from "classnames/bind";
import styles from './FooterWeb.module.scss';
import {useTranslation} from "react-i18next";
import logo from '../../../../assets/img/logo/png/AES_Emblem_v.1.3_Global.png';
import {navigate} from 'hookrouter'
import {useSelector} from "react-redux";
import {UserState} from "../../../../redux/User";
import {UserType} from "../../../../graphql/types";

const cx = classNamesBind.bind(styles);

export const FooterWeb = () => {
    const [t] = useTranslation('common');
    const user = useSelector<any, UserState>(state => state.user);

    const handler = {
        onClickLink: (link: string ) => {
            navigate(link);
        }
    }

    return (
        <Fragment>
            <div className={cx('cont')}>
                <div className={cx("logoBox")}>
                    <a href="https://aesglobal.org" target="_blank" rel="noreferrer">
                        <img src={logo} alt='logo' className={cx("logo")} height="240"/>
                    </a>
                </div>
                <div className={cx('box')}>
                    <div className={cx('box-sitemap')}>
                        <div className={cx('btn-sitemap')} onClick={() => handler.onClickLink("/cs/notice/list")}>{t("layout.cs")}</div>
                        {user.isLogin &&
                            <>
                                {user.user.userType !== UserType.Consultant &&
                                <>
                                    <div className={cx('btn-sitemap')} onClick={() => {
                                        navigate('/ask/board/list')
                                    }}>
                                        {t('layout.board')}
                                    </div>
                                </>
                                }
                            </>
                        }
                        <div className={cx('btn-sitemap')} onClick={() => handler.onClickLink("/terms")}>{t("layout.terms")}</div>
                        <div className={cx('btn-sitemap')} onClick={() => handler.onClickLink("/privacy")}>{t("layout.privacy")}</div>
                    </div>

                    <div style={{height: 30}}/>

                    <div>
                        <p dangerouslySetInnerHTML={{__html: t("layout.siteDescription")}}></p>
                    </div>

                    <div style={{height: 20}}/>
                    <div className={cx("title")}>{t("layout.joint")}</div>
                    <p>
                        {t("layout.companyNm1")}<br />
                        {t("layout.companyAddr1")}
                    </p>

                    <p>
                        {t("layout.companyNm2")}<br />
                        {t("layout.companyAddr2")}
                    </p>

                    <div style={{height: 10}}/>
                    <p>{t('common.copyright')}</p>
                </div>
            </div>
        </Fragment>
    )
}
