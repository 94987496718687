import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './BizGoodsModal.module.scss';
import {BizGoodsDataView} from "../bizGoodsDataView/BizGoodsDataView";
import {CompanyDataMap, GoodsDataMap} from "../../../graphql/customScalars";
import PerfectScrollbar from "react-perfect-scrollbar";
import {IconButton, Tooltip} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import {OnVoidFunction} from "../../Intefaces";
import {useTranslation} from "react-i18next";

const cx = classNamesBind.bind(styles);

export const BizGoodsModal = (props: {
    value?: BizGoodsModalData,
    onClose: OnVoidFunction,
}) => {
    const [t] = useTranslation('common');

    return (
        <>
            {props.value &&
            <div className={cx('cont')}>
                <div className={cx('box')}>
                    <div className={cx('header')}>
                        <div className={cx('space')}/>
                        <Tooltip title={t("common.ttClose") || ""}>
                            <IconButton onClick={() => props.onClose()}>
                                <Close/>
                            </IconButton>
                        </Tooltip>
                    </div>
                    <PerfectScrollbar>
                        <BizGoodsDataView {...props.value}/>
                    </PerfectScrollbar>
                </div>

            </div>
            }
        </>
    )
}

export interface BizGoodsModalData {
    companyDataMap: CompanyDataMap,
    categoryId: number,
    goodsDataMap: GoodsDataMap
}
