import React, {useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './MyPageUpdateCompany.module.scss'
import {MyPageLayout} from "../../layout/MyPageLayout";
import {useTranslation} from "react-i18next";
import {gql, useMutation, useQuery} from "@apollo/client";
import {Mutation, MutationAllowUpdateCompanyArgs, MutationRequestUpdateCompanyArgs, Query} from "../../../../graphql/types";
import {CompanyDataMap} from "../../../../graphql/customScalars";
import {MyPageCompanyUpdateInput} from "./input/MyPageCompanyUpdateInput";
import {CopyUtil} from "../../../../utils/copyUtil/copyUtil";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import {CompanyDataUtil} from "../../../../utils/companyDataUtil/CompanyDataUtil";
import {ApolloCatch} from "../../../../utils/apollo/apolloCatch";
import {navigate} from "hookrouter";

const cx = classNamesBind.bind(styles);

export const MyPageUpdateCompany = () => {
    const [t] = useTranslation('common');
    const [companyDataMap, setCompanyDataMap] = useState<CompanyDataMap | undefined>();
    const [mutAllow] = useMutation<Mutation, MutationAllowUpdateCompanyArgs>(gqlAllow);

    useQuery<Query>(gqlData, {
        onCompleted: res => {
            setCompanyDataMap(CompanyDataUtil.addNoLangs(res.myCompany));
        }
    });

    const [mutUpdate] = useMutation<Mutation, MutationRequestUpdateCompanyArgs>(gqlUpdate);

    const handler = {
        onUpdate: () => {
            if (!companyDataMap) {
                return;
            }

            const copy = CopyUtil.copyAll(CompanyDataUtil.sync(companyDataMap));
            const result = CompanyDataUtil.validate(copy)

            if (!result.isOk) {
                SwalUtil.ok({
                    msg: t(result.tValue),
                    icon: 'error'
                });
                return;
            }

            SwalUtil.yn({
                msg: t("mypageCompany.swalUpdateCompanyDataQuestion"),
                icon: "question",
                ok: () => {
                    mutUpdate({
                        variables: {
                            input: copy
                        }
                    }).then((res) => {
                        if(res.data!.requestUpdateCompany === 0) {
                            SwalUtil.ok({
                                msg: t("mypageCompany.swalUpdateCompanyRequestIdError"),
                                icon: 'error',
                            })
                            return;
                        }

                        const companyUpdateRequestId = res.data!.requestUpdateCompany;
                        mutAllow({
                            variables: {
                                companyUpdateRequestId: companyUpdateRequestId
                            }
                        }).then(() => {
                            SwalUtil.ok({
                                msg: t("mypageCompany.swalUpdateCompanyOk"),
                                icon: 'success',
                            });
    
                            navigate('/mypage/info');
                        })                       
                    }).catch(ApolloCatch({}))
                }
            })
        }
    }

    return (
        <MyPageLayout activatedSuffixRouter={'/company/update'}>
            {companyDataMap &&
            <>
                <MyPageCompanyUpdateInput
                    value={companyDataMap}
                    onChangeValue={setCompanyDataMap}/>

                <div className={cx('table')}>
                    <div style={{width: '100%'}} className='text-right'>
                        <button
                            className='btn btn-sm btn-success'
                            onClick={handler.onUpdate}>수정
                        </button>
                    </div>
                </div>

            </>

            }


        </MyPageLayout>
    )
}


const gqlData = gql`
    query Company {
        myCompany
    }
`;

const gqlUpdate = gql`
    mutation Update($input: CompanyDataMap!) {
        requestUpdateCompany(input: $input)
    }
`;

const gqlAllow = gql`
    mutation Allow($companyUpdateRequestId: Int!){
        allowUpdateCompany(companyUpdateRequestId:$companyUpdateRequestId)
    }
`;