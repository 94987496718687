import React, {Fragment} from 'react';
import classNamesBind from "classnames/bind";
import styles from './BoardViewContent.module.scss';
import {BizTitle} from "../../../../shares/bizTitle/BizTitle";
import {AskBoard, AskBoardStatus, MutationUpdateCompanyAskBoardStatusArgs} from "../../../../../graphql/types";
import moment from "moment";
import {useSelector} from "react-redux";
import {I18nState} from "../../../../../redux/I18n";
import {I18nUtil} from "../../../../../utils/i18n/i18n";
import {GoodsDataMapUtil} from "../../../../../utils/goodsDataMapUtil/goodsDataMapUtil";
import {Button, Tooltip} from "@material-ui/core";
import {Add, Launch, SpellcheckOutlined} from "@material-ui/icons";
import {DataUtil} from "../../../../../utils/data/DataUtil";
import {OnChangeValue, OnVoidFunction} from "../../../../Intefaces";
import {useTranslation} from "react-i18next";
import {I18nKeys} from "../../../../../i18n/i18nKeys";
import {BadgeAskBoardStatus} from "../../../../shares/badgeAskBoardStatus/BadgeAskBoardStatus";

const cx = classNamesBind.bind(styles);

export const BoardViewContent = (props: {
    askBoard: AskBoard,
    onUpdateStatus: OnChangeValue<MutationUpdateCompanyAskBoardStatusArgs>,
    onShowGoods: OnVoidFunction,
    isDone?: boolean,
}) => {
    const [t] = useTranslation('common');
    const {askBoard} = props;
    const langCode = useSelector<any, I18nState>(state => state.i18n).langCode;

    return (
        <Fragment>
            <BizTitle>{askBoard.id}. {askBoard.title}</BizTitle>
            <div className={cx('table')}>
                <div className={cx('header', 'col-label')}>{t(I18nKeys.board.status.key)}</div>
                <div className={cx('col', 'col-value')}>
                    <BadgeAskBoardStatus status={askBoard.status}/>
                </div>
                <div className={cx('header', 'col-label')}>{t(I18nKeys.board.createDate.key)}</div>
                <div className={cx('col', 'col-value')}>{moment(askBoard.createdAt).format('YY.MM.DD')}</div>
            </div>
            <div className={cx('table')}>
                <div className={cx('header', 'col-label')}>{t(I18nKeys.board.inquirer.key)}</div>
                <div className={cx('col', 'col-value', 'col-user')}>
                    <div className={cx('email')}>{askBoard.userEmail}</div>
                    <div className={cx('company-nm')}>[{I18nUtil.value(langCode, askBoard.userCompanyNm)}]</div>
                </div>
                <div className={cx('header', 'col-label')}>{t(I18nKeys.board.productName.key)}</div>
                <div className={cx('col', 'col-value')}>
                    <div className={cx('alledu-code')}>{askBoard.allEduCode}-{askBoard.goodsInfoId}</div>
                    {askBoard.goodsData &&
                    <div className={cx('goods-nm')}>
                        ({GoodsDataMapUtil.getGoodsData(langCode, askBoard.goodsData).nm})
                        <span
                            onClick={() => {
                            }}>
                            <Tooltip title={t(I18nKeys.board.ttProductLink.key) || ""}>
                                <Launch onClick={props.onShowGoods} style={{color: '#0db3e6', fontSize: '1rem'}}/>
                            </Tooltip>
                        </span>
                    </div>
                    }
                </div>
            </div>

            <div className={cx('table')}>
                <div className={cx('header', 'col-label')}>{t(I18nKeys.board.contact.key)}</div>
                <div className={cx('col', 'col-value')}>{askBoard.contact || '-'}</div>
            </div>
            <div className={cx('table')}>
                <div className={cx('col-full')}
                    ref={ref =>{
                        if (ref) {
                            ref.innerHTML = askBoard.content;
                        }
                    }}/>
            </div>

            {askBoard.attachFiles.length !== 0 &&
            <>
                <div className={styles.boxAttachFile}>
                    <div className={styles.fileTitle}>{t(I18nKeys.board.attachment.key)}</div>
                    {askBoard.attachFiles.map((value, index) => (
                        <div className={styles.fileItem}
                             key={index}>
                            <span onClick={() => DataUtil.downloadFile(value.src)}>{index + 1}. {value.nm}</span>
                        </div>
                    ))}
                </div>
            </>
            }

            {askBoard.status === AskBoardStatus.InProcessing &&
            <div className={styles.boxFunc}>
                <Button
                    onClick={() => props.onUpdateStatus({
                        askBoardId: askBoard.id,
                        askBoardStatus: AskBoardStatus.Done
                    })}
                    startIcon={<SpellcheckOutlined/>}>{t(I18nKeys.board.statusComplete.key)}</Button>
            </div>}

            {askBoard.status === AskBoardStatus.Done &&
            <div className={styles.boxFunc}>
                <Button
                    onClick={() => props.onUpdateStatus({
                        askBoardId: askBoard.id,
                        askBoardStatus: AskBoardStatus.InProcessing
                    })}
                    startIcon={<Add/>}>{t(I18nKeys.board.statusReask.key)}</Button>
            </div>}
        </Fragment>
    )
}
