import {createActionCreators, createReducerFunction, ImmerReducer} from "immer-reducer";


export interface LayoutState {
    showCategory: boolean
}

const initState: LayoutState = {
    showCategory: false
};

class Layout extends ImmerReducer<typeof initState> {
    showCategory(swt: boolean) {
        this.draftState.showCategory = swt;
    }
}


export const LayoutAction = createActionCreators(Layout);
export const LayoutReducer = createReducerFunction(Layout, initState);

