import {createActionCreators, createReducerFunction, ImmerReducer} from "immer-reducer";


export interface LayoutWebState {
    showCategory: boolean;
}

const initState: LayoutWebState = {
    showCategory: false,
};

class LayoutWeb extends ImmerReducer<typeof initState> {
    setCategory(swt: boolean) {
        this.draftState.showCategory = swt;
    }
}


export const LayoutWebAction = createActionCreators(LayoutWeb);
export const LayoutWebReducer = createReducerFunction(LayoutWeb, initState);
