import React, {Fragment, ReactNode} from 'react';
import classNamesBind from "classnames/bind";
import styles from './InputSquareImages.module.scss';
import {OnChangeValue} from "../../../../Intefaces";
import {DataUtil} from "../../../../../utils/data/DataUtil";
import {CopyUtil} from "../../../../../utils/copyUtil/copyUtil";
import {SwalUtil} from "../../../../../utils/swal/swalUtil";
import {AddOutlined} from "@material-ui/icons";
import {ApolloCatch} from "../../../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../../../graphql/errors";
import {useTranslation} from "react-i18next";
import {GoodsImage} from "../../../../../graphql/customScalars";

const cx = classNamesBind.bind(styles);

export const InputSquareImages = (props: {
    title: ReactNode,
    value: GoodsImage[],
    onChange: OnChangeValue<GoodsImage[]>
}) => {
    const [t] = useTranslation("common");
    const maxSize = 5;

    const onAppend = () => {
        if (maxSize <= props.value.length) {
            SwalUtil.ok({
                msg: t('mypageGoods.cannotUpload'),
                icon: "error"
            });
            return;
        }

        DataUtil.uploadFile({image: true})
            .then(res => {
                const copy = CopyUtil.copy(props.value);
                for (let item of res) {
                    copy.push({
                        src: item.src,
                        createdAt: item.createdAt,
                    })
                }
                props.onChange(copy);
            })
            .catch(ApolloCatch({
                [BizErrors.default]: () => {
                    SwalUtil.ok({
                        msg: t('mypageGoods.failUpload'),
                        icon: "error"
                    })
                },
                [BizErrors.overMaxSize]: () => {
                    SwalUtil.ok({
                        msg: t('mypageGoods.less5mb'),
                        icon: "error"
                    })
                }
            }))
    }

    const onDelete = (idx: number) => {
        SwalUtil.yn({
            msg: t('mypageGoods.askDelete'),
            icon: "question",
            ok: () => {
                let copy = CopyUtil.copy(props.value);
                copy = [
                    ...copy.slice(0, idx),
                    ...copy.slice(idx + 1, copy.length)
                ];
                props.onChange(copy);
            }
        })
    }

    return (
        <Fragment>
            <div className={cx('title')}>{props.title}</div>
            <p className={cx("desc")}>{t('mypageGoods.askSquareImage')}</p>

            <div className={cx('cont-img')}>
                {props.value.map((value, index) => {
                    return (
                        <div
                            key={index}
                            onClick={event => {
                                onDelete(index)
                            }}>
                            <img className={cx('img')} src={value.src} alt='goodsImage'/>
                        </div>

                    )
                })}

                {props.value.length < maxSize &&
                <div className={cx('add')}
                     onClick={onAppend}>
                    <AddOutlined/>
                </div>
                }
            </div>
        </Fragment>
    )
}
