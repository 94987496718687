import React, {Fragment} from 'react';
import classNamesBind from "classnames/bind";
import styles from './BizCategoryBreadCrumbs.module.scss';
import {useSelector} from "react-redux";
import {CategUtil} from "../../layout/web/categ/CategUtil";
import {I18nState} from "../../../redux/I18n";
import {Breadcrumbs} from "@material-ui/core";
import {I18nUtil} from "../../../utils/i18n/i18n";

const cx = classNamesBind.bind(styles);

export const BizCategoryBreadCrumbs = (props: {
    categoryId: number
}) => {
    const superSubs = CategUtil.getSuperCategories(props.categoryId);
    const i18n = useSelector<any, I18nState>(state => state.i18n);

    return (
        <Fragment>
            <Breadcrumbs className={cx('cont')} separator=">">
                {superSubs.map((value, index) => {
                    return (
                        <div
                            className={cx('item')}
                            key={index}>
                            {I18nUtil.value(i18n.langCode, value.nm)}
                        </div>
                    )
                })}

            </Breadcrumbs>
        </Fragment>
    )
}
