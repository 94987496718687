import React, {useState} from 'react';
import classNamesBind from "classnames/bind";
import styles from './LayoutMobileSidebar.module.scss';
import logo from '../../../../assets/img/svg/AllEdu.Shop_Logo_v3.svg';
import {useDispatch, useSelector} from "react-redux";
import {I18nAction, I18nState} from "../../../../redux/I18n";
import {UserAction, UserState} from "../../../../redux/User";
import {DataAction, DataState} from "../../../../redux/Data";
import {Accordion, AccordionDetails, AccordionSummary, Button, InputBase} from "@material-ui/core";
import {ExpandMore} from "@material-ui/icons";
import {CategUtil} from "../../web/categ/CategUtil";
import {I18nUtil} from "../../../../utils/i18n/i18n";
import {useTranslation} from "react-i18next";
import {navigate} from "hookrouter";
import {LayoutMobileAction} from "../../../../redux/LayoutMobile";
import {Pipe} from "../../../../utils/pipe/pipe";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import {DataUtil} from "../../../../utils/data/DataUtil";
import {ApolloCatch} from "../../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../../graphql/errors";
import {LocalStorage} from "../../../../utils/localstorage/LocalStorage";
import { ReduxStore } from '../../../..';

const cx = classNamesBind.bind(styles);

export const LayoutMobileSidebar = () => {
    const i18n = useSelector<any, I18nState>(state => state.i18n);
    const user = useSelector<any, UserState>(state => state.user);
    const data = useSelector<any, DataState>(state => state.data);
    const [word, setWord] = useState("");
    const [t] = useTranslation('common');
    const dispatch = useDispatch();

    const firstNode = CategUtil.getSubWithParentId({parentId: 1, category: data.category});

    const onFind = (findWord: string) => {
        dispatch(LayoutMobileAction.setSidebar(false));

        if (findWord.length < 2) {
            SwalUtil.ok({
                msg: t("layout.swalFindWord"),
                icon: 'warning'
            })
            return;
        }
        navigate(`/index/find/0/0/${encodeURI(findWord)}`);

        setWord("");
    }


    const onLogout = () => {
        dispatch(LayoutMobileAction.setSidebar(false));
        SwalUtil.yn({
            msg: t('swal.logout'),
            icon: 'question',
            ok: () => {
                DataUtil.logout()
                    .then(() => {
                        navigate('/');
                    })
                    .catch(ApolloCatch({
                        [BizErrors.default]: () => {
                            LocalStorage.clearSession();
                            dispatch(UserAction.logout());
                            navigate('/');
                        }
                    }))
            }
        })
    }


    // 에듀테크코리아페어 2024 카테고리 아이디
    const EDUTECH_KOREA_FAIR_2024_CATEGORY_ID = 10000002024;

    // 에듀테크코리아페어 2024 서브 카테고리 ALL 아이디
    const EDUTECH_KOREA_FAIR_2024_SUB_CATEGORY_ALL = 1;

    const onClickCateg = (categoryId: number) => {
        ReduxStore.dispatch(
            DataAction.setCurrentSelectedGoodsCategory({
                id: categoryId, 
                listPage: {
                    pageStartId: 0,
                    currentPage: 0,
                    page: 0
                }
            })
        );

        if(categoryId === EDUTECH_KOREA_FAIR_2024_CATEGORY_ID) {
            navigate(`/edutechkoreafair/2024/list/${EDUTECH_KOREA_FAIR_2024_SUB_CATEGORY_ALL}`);
        } else {
            navigate(`/goods/category/${categoryId}`);
        }
        
        dispatch(LayoutMobileAction.setSidebar(false));
    }

    const onClickButton = (router: string) => {
        navigate(router);
        dispatch(LayoutMobileAction.setSidebar(false));
    }

    return (
        <div className={cx('cont')}>
            <div className={cx('box-logo')}>
                <img className={cx('logo')} src={logo} alt={'logo'}/>
            </div>

            {user.isLogin &&
            <div className={cx('box-user')}>
                <p>{user.user.email}</p>
            </div>
            }

            {!user.isLogin &&
            <div className={cx('box-auth')}>
                <Button
                    className={cx('btn')}
                    variant='contained'
                    color='primary'
                    onClick={() => {
                        onClickButton('/auth/login')
                    }}>{t('layout.login')}</Button>
                <Button
                    className={cx('btn')}
                    variant='outlined'
                    color='primary'
                    onClick={() => {
                        onClickButton('/auth/join')
                    }}>{t('layout.join')}</Button>
            </div>
            }


            <div className={cx('box-accordion')}>
                <Accordion>
                    <form className={cx('box-find')} onSubmit={ev => {
                        ev.preventDefault();
                        onFind(word);
                    }}>
                        <InputBase
                            value={word}
                            onChange={ev => {
                                setWord(ev.target.value)
                            }}
                            className={cx('input')}
                            type='text'
                            placeholder={t('layout.finder')}/>
                        <Button
                            type='submit'
                            variant="outlined"
                            color='primary'
                            className={cx('btn-find')}>
                            {t('layout.btnFind')}
                        </Button>
                    </form>
                </Accordion>
            </div>


            <div className={cx('box-accordion')}>
                <Accordion>
                    <div className={cx('title')}>
                        {t('layout.menu')}
                    </div>
                </Accordion>
                <Accordion expanded={false}>
                    <AccordionSummary
                        className={cx('categ-nm')}
                        onClick={()=> {
                            onClickButton('/nation');
                        }}>{t('layout.nation')}</AccordionSummary>
                </Accordion>
                <Accordion expanded={false}>
                    <AccordionSummary
                        className={cx('categ-nm')}
                        onClick={() => {
                            onClickButton('/company/list');
                        }}>{t('layout.company')}</AccordionSummary>
                </Accordion>
                {
                    user.isLogin &&
                    <Accordion expanded={false}>
                        <AccordionSummary
                            className={cx('categ-nm')}
                            onClick={() => {
                                onClickButton('/mypage/info');
                            }}>{t('layout.mypage')}</AccordionSummary>
                    </Accordion>
                }
            </div>

            <div className={cx('box-accordion')}>
                <Accordion>
                    <div className={cx('title')}>
                        {t('layout.cs')}
                    </div>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        className={cx('categ-nm')}
                        onClick={() => {
                            onClickButton('/cs/notice/list');
                        }}>{t('cs.noticeTitle')}
                    </AccordionSummary>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        className={cx('categ-nm')}
                        onClick={() => {
                            onClickButton('/cs/newsletter/list');
                        }}>{t('cs.newsletterTitle')}
                    </AccordionSummary>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        className={cx('categ-nm')}
                        onClick={() => {
                            onClickButton('/cs/faq/list');
                        }}>{t('cs.faqTitle')}
                    </AccordionSummary>
                </Accordion>
            </div>

            <div className={cx('box-accordion')}>
                <Accordion>
                    <div className={cx('title')}>
                        {t('layout.category')}
                    </div>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        className={cx('categ-nm')}>
                        <div className={cx('btn')}
                            onClick={() => {
                                onClickCateg(EDUTECH_KOREA_FAIR_2024_CATEGORY_ID);
                            }}
                            onMouseEnter={event => {
                                event.preventDefault();
                                event.stopPropagation();
                            }}
                        >
                            <img
                                src="/te-statics/category/edutech_korea_fair/2024/logo-r30.png"
                                alt='2024-edutechkoreafair-logo'
                            />
                            {"2024에듀테크코리아페어"}
                        </div>
                    </AccordionSummary>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        className={cx('categ-nm')}>
                        <div className={cx('btn')}
                            onClick={() => {
                                onClickCateg(0);
                            }}
                            onMouseEnter={event => {
                                event.preventDefault();
                                event.stopPropagation();
                            }}
                        >
                            <img
                                src="/te-statics/category/certified_product_category/certified_product_category.png"
                                alt='logo'
                            />
                            {t('goods.certifiedProduct')}
                        </div>
                    </AccordionSummary>
                </Accordion>
                {firstNode.map((value, index) => {
                    return (
                        <Accordion key={index}>
                            <AccordionSummary
                                className={cx('categ-nm')}
                                expandIcon={<ExpandMore/>}>
                                <img src={value.img} alt={value.img}/>
                                {I18nUtil.value(i18n.langCode, value.nm)}
                            </AccordionSummary>
                            <AccordionDetails style={{flexWrap: 'wrap'}}>
                                {CategUtil.getSubWithParentId({
                                    category: data.category,
                                    parentId: value.id
                                }).map((sub, idx) => {
                                    return (
                                        <div
                                            key={idx + index}
                                            className={cx('box-sub')}
                                            onClick={() => {
                                                onClickCateg(sub.id);
                                            }}>
                                            {I18nUtil.value(i18n.langCode, sub.nm)}
                                        </div>
                                    )
                                })}
                            </AccordionDetails>
                        </Accordion>
                    )
                })}
            </div>

            <div className={cx('box-accordion')}>
                <Accordion>
                    <div className={cx('title')}>
                        {t('layout.selectLang')}
                    </div>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        className={cx('categ-nm')}>
                        {Pipe.langNm(i18n.langCode)}
                    </AccordionSummary>
                    <AccordionDetails style={{flexWrap: 'wrap'}}>
                        {data.langCode.map((value, index) => {
                            return (
                                <div
                                    key={index}
                                    className={cx('box-sub')}
                                    onClick={() => {
                                        dispatch(I18nAction.setLangCode(value.code));
                                    }}>
                                    {value.nm}
                                </div>
                            )
                        })}
                    </AccordionDetails>
                </Accordion>
            </div>

            {user.isLogin &&
            <div className={cx('box-accordion')}>
                <Button
                    variant='outlined'
                    color='primary'
                    style={{width: '100%'}}
                    onClick={() => {
                        onLogout();
                    }}>
                    {t('layout.logout')}
                </Button>
            </div>
            }
        </div>
    )
}
