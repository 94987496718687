import React, {Fragment} from 'react';
import classNamesBind from "classnames/bind";
import styles from './MyPageCompanyCreateRequestList.module.scss';
import {
    CompanyCreateRequest,
    CompanyCreateStatus,
    MutationAllowCreateCompanyArgs,
    MutationCancelCreateCompanyArgs
} from "../../../../../graphql/types";
import {BizCompanyDataInTable} from "../../../../shares/bizCompanyDataInTable/BizCompanyDataInTable";
import {IconButton, Tooltip} from "@material-ui/core";
import {Check, Delete, SpellcheckOutlined} from "@material-ui/icons";
import {OnChangeValue} from "../../../../Intefaces";
import {useTranslation} from "react-i18next";
import {BadgeCompanyRequestStatus} from "../../../../shares/badgeCompanyRequestStatus/BadgeCompanyRequestStatus";
import {SwalUtil} from "../../../../../utils/swal/swalUtil";
import {I18nKeys} from "../../../../../i18n/i18nKeys";

const cx = classNamesBind.bind(styles);

interface Props {
    companyCreateRequestList: CompanyCreateRequest[],
    onAllow: OnChangeValue<MutationAllowCreateCompanyArgs>,
    onCancel: OnChangeValue<MutationCancelCreateCompanyArgs>,
    onClickData: OnChangeValue<CompanyCreateRequest>
}

export const MyPageCompanyCreateRequestList = (props: Props) => {
    return (
        <Fragment>
            <div className='web-view'><Web {...props}/></div>
            <div className='mobile-view'><Mobile {...props}/></div>
        </Fragment>
    )
}

const Web = (props: Props) => {
    const [t] = useTranslation('common');

    const handler = {
        onShowMemo: (memo: string) => {
            SwalUtil.ok({
                msg: memo,
                icon: 'info'
            })
        }
    }

    return (
        <>
            <div className={cx('table')}>
                <div className={cx('header', 'col-id')}>{t("mypageCompany.createRequestNumber")}</div>
                <div className={cx('header', 'col-status')}>{t("mypageCompany.createRequestStatus")}</div>
                <div className={cx('header', 'col-company')}>{t("mypageCompany.createRequestInfo")}</div>
                <div className={cx('header', 'col-func')}>{t("mypageCompany.createRequestFunction")}</div>
            </div>

            {props.companyCreateRequestList.map((value, index) => (
                <div key={index} className={cx('table')}>
                    <div className={cx('col', 'col-id')}>{value.id}</div>
                    <div className={cx('col', 'col-status')}>
                        <BadgeCompanyRequestStatus
                            status={value.status}/>
                    </div>
                    <div className={cx('col-company')} style={{width: '40%'}} onClick={() => {
                        props.onClickData(value);
                    }}>
                        <BizCompanyDataInTable
                            companyDataMap={value.companyDataMap}/>
                    </div>
                    <div className={cx('col', 'col-func')}>
                        {value.status === CompanyCreateStatus.Accepted &&
                        <>
                            <Tooltip title={t("mypageCompany.ttCreateAllow") || ""}>
                                <IconButton onClick={() => {
                                    props.onAllow({companyCreateRequestId: value.id});
                                }}>
                                    <Check/>
                                </IconButton>
                            </Tooltip>

                            <Tooltip title={t("mypageCompany.ttCreateCancel") || ""}>
                                <IconButton onClick={() => {
                                    props.onCancel({companyCreateRequestId: value.id});
                                }}>
                                    <Delete/>
                                </IconButton>
                            </Tooltip>
                        </>
                        }

                        {value.status === CompanyCreateStatus.Requested &&
                        <>
                            <Tooltip title={t("mypageCompany.ttCreateCancel") || ""}>
                                <IconButton onClick={() => {
                                    props.onCancel({companyCreateRequestId: value.id});
                                }}>
                                    <Delete/>
                                </IconButton>
                            </Tooltip>
                        </>
                        }

                        {value.status === CompanyCreateStatus.Denied &&
                        <Tooltip title={t(I18nKeys.company.deniedReason.key) || ''}>
                            <IconButton onClick={() => handler.onShowMemo(value.memo)}>
                                <SpellcheckOutlined/>
                            </IconButton>
                        </Tooltip>
                        }
                    </div>
                </div>
            ))}

        </>
    )
}

const Mobile = (props: Props) => {
    return (
        <><Web {...props}/></>
    )
}
