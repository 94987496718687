import React, {Fragment} from 'react';
import classNamesBind from "classnames/bind";
import styles from './NavBtm.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {UserAction, UserState} from "../../../../redux/User";
import {useTranslation} from "react-i18next";
import {KeyboardArrowUp, Menu} from "@material-ui/icons";
import {Spring} from "react-spring/renderprops-universal";
import {A, navigate} from "hookrouter";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import {LayoutWebAction, LayoutWebState} from "../../../../redux/LayoutWeb";
import {DataUtil} from "../../../../utils/data/DataUtil";
import {LocalStorage} from "../../../../utils/localstorage/LocalStorage";
import {ApolloCatch} from "../../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../../graphql/errors";
import {UserType} from "../../../../graphql/types";

const cx = classNamesBind.bind(styles);

export const NavBtm = () => {
    const user = useSelector<any, UserState>(state => (state as any).user);
    const layoutWeb = useSelector<any, LayoutWebState>(state => state.layoutWeb);
    const [t] = useTranslation('common');
    const dispatch = useDispatch();

    const onLogout = () => {

        SwalUtil.yn({
            msg: t('swal.logout'),
            icon: 'question',
            ok: () => {
                DataUtil.logout()
                    .then(res => {
                        navigate('/');
                    })
                    .catch(ApolloCatch({
                        [BizErrors.default]: () => {
                            LocalStorage.clearSession();
                            dispatch(UserAction.logout());
                            navigate('/');
                        }
                    }))
            }
        })
    }

    return (
        <div className={cx('btm-nav')}>
            <div className={cx('cont', 'flex', "alignItemsCenter")}>
                <div className={cx('box-category')} onClick={event => {
                    dispatch(LayoutWebAction.setCategory(!layoutWeb.showCategory));
                }}>
                    <Menu/>
                    <div className={cx('content')}>{t("layout.category")}</div>
                    <Spring
                        from={{transform: 'rotate(0deg)'}}
                        to={{transform: layoutWeb.showCategory ? 'rotate(180deg)' : 'rotate(0deg)'}}>
                        {params => (
                            <div style={params}>
                                <KeyboardArrowUp/>
                            </div>
                        )}
                    </Spring>
                </div>

                <div className={cx('box-item')} onClick={() => {
                    navigate('/nation/0');
                }}>{t("layout.nation")}</div>

                <div className={cx('box-item')} onClick={() => {
                    navigate('/company/list/0')
                }}>{t("layout.company")}</div>

                {user.isLogin && (
                    <Fragment>
                        {/* 채팅기능 주석처리 - 20230406 daekyu.ju */}
                        {/*
                        <div className={cx('box-item')} onClick={() => navigate('/chat', true, {}, true)}>
                            <Badge
                                variant={"dot"}
                                max={99}
                                badgeContent={chat.totalNotice}
                                color={"error"}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right"
                                }}>
                                {t("layout.chat")}
                            </Badge>
                        </div>
                        */}
                    </Fragment>
                )}

                <div className={cx('space')}/>


                {user.isLogin && (
                    <Fragment>
                        <div className={cx('box-item', 'sm')} onClick={() => {
                                navigate('/cs/notice/list')
                        }}>
                            {t("layout.cs")}
                        </div>
                        {user.user.userType !== UserType.Consultant &&
                            <div className={cx('box-item', 'sm')} onClick={() => {
                                navigate('/mypage/info')
                            }}>
                                {t("layout.mypage")}
                            </div>
                        }
                        <div onClick={event => {
                            event.preventDefault();
                            onLogout();
                        }} className={cx('box-item', 'sm')}>{t("layout.logout")}</div>
                    </Fragment>
                )}


                {!user.isLogin && (
                    <Fragment>
                        <A href={'/cs/notice/list'}>
                            <div className={cx('box-item', 'sm')}>{t("layout.cs")}</div>
                        </A>
                        <A href={'/auth/join'}>
                            <div className={cx('box-item', 'sm')}>{t("layout.join")}</div>
                        </A>
                        <A href={'/auth/login'}>
                            <div className={cx('box-item', 'sm')}>{t("layout.login")}</div>
                        </A>
                    </Fragment>
                )}
            </div>
        </div>
    )
}
