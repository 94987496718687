import React, {Fragment, ReactNode} from 'react';
import classNamesBind from "classnames/bind";
import styles from './LayoutMobile.module.scss';
import {AppBar, IconButton, SwipeableDrawer, Toolbar} from "@material-ui/core";
import {Menu} from "@material-ui/icons";
import logo from '../../../assets/img/svg/AllEdu.Shop_Logo_v3.svg';
import {useDispatch, useSelector} from "react-redux";
import {LayoutMobileAction, LayoutMobileState} from "../../../redux/LayoutMobile";
import {LayoutMobileSidebar} from "./sidebar/LayoutMobileSidebar";
import {useTranslation} from "react-i18next";
import {navigate} from "hookrouter";

const cx = classNamesBind.bind(styles);

export const LayoutMobile = (props: {
    children: ReactNode
}) => {
    const layout = useSelector<any, LayoutMobileState>(state => state.layoutMobile);
    const [t] = useTranslation('common');
    const dispatch = useDispatch();

    const onChangeSidebar = (swt: boolean) => {
        dispatch(LayoutMobileAction.setSidebar(swt));
    }

    return (
        <Fragment>
            <AppBar className={cx('nav')} color="default">
                <Toolbar>
                    <div
                        className={cx('box-logo')}
                        onClick={event => {
                            navigate('/')
                        }}>
                        <img src={logo} alt='logo'/>
                    </div>

                    <div className={cx('space')}/>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={event => {
                            onChangeSidebar(true);
                        }}>
                        <Menu/>
                    </IconButton>
                </Toolbar>
            </AppBar>

            <SwipeableDrawer
                anchor={"left"}
                onClose={() => {
                    onChangeSidebar(false);
                }}
                onOpen={() => {
                    onChangeSidebar(true);
                }}
                open={layout.showSidebar}>
                <LayoutMobileSidebar/>
            </SwipeableDrawer>

            <div className={cx('content')}>
                {props.children}
            </div>


            <div className={cx('footer')}>
                <p>{t('layout.companyNm').split("<br />").map(line => {
                    return (<span>{line}<br/></span>)
                })}</p>
                <p>{t('layout.addr').split("<br />").map(line => {
                    return (<span>{line}<br/></span>)
                })}</p>
                <p>{t('layout.warning')}</p>
                <p>{t('common.copyright')}</p>
            </div>

            {/*{!user.isLogin &&*/}
            {/*<BottomNavigation className={cx('btm-nav')} showLabels>*/}
            {/*    <BottomNavigationAction*/}
            {/*        label={t('layout.nation')}*/}
            {/*        icon={<Language/>}*/}
            {/*        onClick={event => {*/}
            {/*            navigate('/nation');*/}
            {/*        }}/>*/}
            {/*    <BottomNavigationAction*/}
            {/*        label={t('layout.company')}*/}
            {/*        icon={<Work/>}*/}
            {/*        onClick={event => {*/}
            {/*            navigate('/company');*/}
            {/*        }}/>*/}
            {/*</BottomNavigation>*/}
            {/*}*/}

            {/*{user.isLogin &&*/}
            {/*<BottomNavigation className={cx('btm-nav')} showLabels>*/}
            {/*    <BottomNavigationAction*/}
            {/*        label={t('layout.nation')}*/}
            {/*        icon={<Language/>}*/}
            {/*        onClick={event => {*/}
            {/*            navigate('/nation');*/}
            {/*        }}/>*/}
            {/*    <BottomNavigationAction*/}
            {/*        label={t('layout.company')}*/}
            {/*        icon={<Work/>}*/}
            {/*        onClick={event => {*/}
            {/*            navigate('/company');*/}
            {/*        }}/>*/}
            {/*    <BottomNavigationAction*/}
            {/*        label={t('layout.chat')}*/}
            {/*        icon={<Chat/>}*/}
            {/*        onClick={event => {*/}
            {/*            navigate('/chat');*/}
            {/*        }}/>*/}
            {/*    <BottomNavigationAction*/}
            {/*        label={t('layout.mypage-old')}*/}
            {/*        icon={<Person/>}*/}
            {/*        onClick={event => {*/}
            {/*            navigate('/mypage-old');*/}
            {/*        }}/>*/}
            {/*</BottomNavigation>*/}
            {/*}*/}


        </Fragment>
    )
}
