import React, { useState } from 'react';
import classNamesBind from "classnames/bind";
import styles from './NavTop.module.scss';
import { A, navigate } from "hookrouter";
import { Search } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../../../assets/img/svg/AllEdu.Shop_Logo_v3.svg";
import { I18nAction } from "../../../../redux/I18n";
import { DataState } from "../../../../redux/Data";
import { Dropdown } from "react-bootstrap";
import { SwalUtil } from "../../../../utils/swal/swalUtil";

const cx = classNamesBind.bind(styles);

export const NavTop = () => {
    const [t] = useTranslation('common');
    const data = useSelector<any, DataState>(state => state.data);
    const [word, setWord] = useState("");
    const dispatch = useDispatch();

    const onFind = (findWord: string) => {
        if (findWord.length < 2) {
            SwalUtil.ok({
                msg: '2글자 이상 입력하여 주십시오.',
                icon: 'warning'
            })
            return;
        }
        navigate(`/index/find/0/0/${encodeURI(findWord)}`);
        setWord("");
    }

    const getQuillPlaceHolder = (from: string) => {
        switch(from) {
            case "goods":
                return t('mypageGoods.quillPlaceholder');
            case "company":
                return t('mypageCompany.quillPlaceholder');
            default:
                return "";
        }
    } 

    return (
        <div className={cx('top-nav')}>
            <div className={cx('cont', 'flex')}>
                <A className={cx("logoA")} href='/'>
                    <img className={cx("logo")} src={logo} alt='logo' />
                </A>
                <div className={cx('box-finder')}>
                    <form className={cx("box-input")} onSubmit={ev => {
                        ev.preventDefault();
                        onFind(word);
                    }}>
                        <input
                            required
                            value={word}
                            onChange={ev => setWord(ev.target.value)}
                            type="text"
                            placeholder={t("layout.finder")} />
                        <Search className={cx("icon-search")} />
                    </form>
                </div>
                <div className={cx("btn-lang")}>
                    <Dropdown className={cx("dropdown")}>
                        <Dropdown.Toggle variant="success" id="dropdown-basic" className={cx("dropdown")}>
                            Language
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {data.langCode.map((value, index) => {
                                return (
                                    <Dropdown.Item key={index} id={"language" + index} onClick={() => {
                                        dispatch(I18nAction.setLangCode(value.code));

                                        data.langCode.map((value, index) => {
                                            const removeTarget = document.getElementById("language" + index);
                                            removeTarget?.classList.remove('active');
                                            return null;
                                        })

                                        const activeTarget = document.getElementById("language" + index);
                                        activeTarget?.classList.add('active')

                                        // [마이페이지 > 상품등록 > 상품상세] 에디터의 placeholder를 변경한다.
                                        const quillEditor = document.getElementsByClassName("ql-editor")[0];
                                        if(quillEditor) {
                                            const url = document.location.href;
                                            const urlTokens = url.split("/");
                                            const from = urlTokens[urlTokens.length - 2];
                                            quillEditor.setAttribute("data-placeholder", getQuillPlaceHolder(from));
                                        }

                                    }} > {value.nm}</Dropdown.Item>
                                )
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </div >
    )
}
