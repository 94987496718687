import React, {Fragment} from 'react';
import classNamesBind from "classnames/bind";
import styles from './BoardListView.module.scss';
import {navigate} from "hookrouter";
import {
    AskBoard,
    AskBoardStatus,
    ChatWriter,
    MutationUpdateCompanyAskBoardStatusArgs
} from "../../../../../graphql/types";
import {BizNoDataTable} from "../../../../shares/bizNoDataTable/BizNoDataTable";
import moment from "moment";
import {useSelector} from "react-redux";
import {I18nState} from "../../../../../redux/I18n";
import {GoodsDataMapUtil} from "../../../../../utils/goodsDataMapUtil/goodsDataMapUtil";
import {I18nUtil} from '../../../../../utils/i18n/i18n';
import {IconButton, Tooltip} from "@material-ui/core";
import {Add, Spellcheck} from '@material-ui/icons';
import {OnChangeValue} from "../../../../Intefaces";
import {I18nKeys} from "../../../../../i18n/i18nKeys";
import {useTranslation} from "react-i18next";

const cx = classNamesBind.bind(styles);

export const BoardListView = (props: {
    askBoardList: AskBoard[],
    isDone?: boolean,
    onUpdateAskBoardStatus: OnChangeValue<MutationUpdateCompanyAskBoardStatusArgs>
}) => {
    const [t] = useTranslation('common');
    const langCode = useSelector<any, I18nState>(state => state.i18n).langCode;

    const handler = {
        onClickBoard: (id: number) => {
            navigate(`/ask/company/board/view/${id}`);
        }
    }
    return (
        <Fragment>
            <div className={cx('table')}>
                <div className={cx('header', 'col-id')}>{t(I18nKeys.board.number.key)}</div>
                <div className={cx('header', 'col-created-at')}>{t(I18nKeys.board.createDate.key)}</div>
                <div className={cx('header', 'col-status')}>{t(I18nKeys.board.status.key)}</div>
                <div className={cx('header', 'col-title')}>{t(I18nKeys.board.subject.key)}</div>
                <div className={cx('header', 'col-goods')}>{t(I18nKeys.board.productName.key)}</div>
                <div className={cx('header', 'col-user')}>{t(I18nKeys.board.userName.key)}</div>
                <div className={cx('header', 'col-func')}/>
            </div>


            {props.askBoardList.length === 0 && <BizNoDataTable/>}

            {props.askBoardList.map((value, index) => (
                <div key={index} className={cx('table', 'hover', 'pointer')}>
                    <div onClick={() => handler.onClickBoard(value.id)} className={cx('col', 'col-id')}>{value.id}</div>
                    <div onClick={() => handler.onClickBoard(value.id)} className={cx('col', 'col-created-at')}>
                        {moment(value.createdAt).format('YY.MM.DD')}
                    </div>
                    <div onClick={() => handler.onClickBoard(value.id)} className={cx('col', 'col-status')}>
                        {props.isDone && <span className='badge badge-secondary'>{t(I18nKeys.board.statusComplete.key)}</span>}
                        {!props.isDone && <BadgeAskBoardStatus status={value.lastReplyWriter}/>}
                    </div>
                    <div onClick={() => handler.onClickBoard(value.id)} className={cx('col', 'col-title', 'ellipsis')}>
                        <span>
                            {value.title}
                        </span>
                    </div>
                    <div onClick={() => handler.onClickBoard(value.id)} className={cx('col-goods')}>
                        <div className={cx('alledu-code')}>{value.allEduCode}-{value.goodsInfoId}</div>
                        <div className={cx('goods-nm')}>
                            {value.goodsData &&
                            <>
                                ({GoodsDataMapUtil.getGoodsData(langCode, value.goodsData).nm})
                            </>
                            }
                        </div>
                    </div>
                    <div onClick={() => handler.onClickBoard(value.id)} className={cx('col-user')}>
                        <div className={cx('email')}>
                            {value.userEmail}
                        </div>
                        <div className={cx('company-nm')}>
                            ({I18nUtil.value(langCode, value.userCompanyNm)})
                        </div>
                    </div>

                    <div className={cx('header', 'col-func')}>
                        {!props.isDone &&
                        <Tooltip title={t(I18nKeys.board.statusComplete.key) || "완료"}>
                            <IconButton
                                size='small'
                                onClick={() => props.onUpdateAskBoardStatus({
                                    askBoardId: value.id,
                                    askBoardStatus: AskBoardStatus.Done
                                })}>
                                <Spellcheck/>
                            </IconButton>
                        </Tooltip>
                        }

                        {props.isDone &&
                        <Tooltip title={t(I18nKeys.board.statusReask.key) || "재진행"}>
                            <IconButton
                                size='small'
                                onClick={() => props.onUpdateAskBoardStatus({
                                    askBoardId: value.id,
                                    askBoardStatus: AskBoardStatus.InProcessing
                                })}>
                                <Add/>
                            </IconButton>
                        </Tooltip>
                        }

                    </div>

                </div>

            ))}


        </Fragment>
    )
}


const BadgeAskBoardStatus = (props: {
    status: ChatWriter
}) => {
    const [t] = useTranslation('common');

    return (
        <Fragment>
            {props.status === ChatWriter.Company && <span className='badge badge-primary'>{t(I18nKeys.board.badgeAnswer.key)}</span>}
            {props.status === ChatWriter.User && <span className='badge badge-warning'>{t(I18nKeys.board.badgeQuestion.key)}</span>}
            {props.status === ChatWriter.None && <span className='badge badge-warning'>{t(I18nKeys.board.badgeQuestion.key)}</span>}
        </Fragment>
    )
}
