import React, {Fragment} from 'react';
import {LocalShippingOutlined} from "@material-ui/icons";
import {MyPageProcess} from "../../../../graphql/types";
import {I18nKeys} from "../../../../i18n/i18nKeys";
import {BizProgressBar, BizProgressBarData} from "../../../shares/bizProgressBar/BizProgressBar";


enum ProgStatus {
    Join = 'Join',
    UserInfo = 'UserInfo',
    JoinCompany = 'JoinCompany',
    RegisterConsultant = 'RegisterConsultant',
    RegisterGoods = 'RegisterGoods',
}

export const MyPageMyInfoProcessBar = (props: {
    process: MyPageProcess,
}) => {
    let status = ProgStatus.Join;

    if (props.process.registerUserInfo) {
        status = ProgStatus.UserInfo;
    }

    if (props.process.registerCompany) {
        status = ProgStatus.JoinCompany;
    }

    if (props.process.registerConsultant) {
        status = ProgStatus.RegisterConsultant;
    }

    if (props.process.registerGoods) {
        status = ProgStatus.RegisterGoods;
    }
    return (
        <Fragment>
            <BizProgressBar
                icon={<LocalShippingOutlined/>}
                valueList={DefaultProgValueList}
                status={status.toString()}/>
        </Fragment>
    )
}

const DefaultProgValueList: BizProgressBarData[] = [
    {
        status: ProgStatus.Join.toString(),
        tLabel: I18nKeys.mypage.progJoined.key,
        tTooltip: '',
    },
    {
        status: ProgStatus.UserInfo.toString(),
        tLabel: I18nKeys.mypage.progUserInfo.key,
        tTooltip: '',
    },
    {
        status: ProgStatus.JoinCompany.toString(),
        tLabel: I18nKeys.mypage.progCompany.key,
        tTooltip: '',
    },
    {
        status: ProgStatus.RegisterConsultant.toString(),
        tLabel: I18nKeys.mypage.progConsultant.key,
        tTooltip: '',
    },
    {
        status: ProgStatus.RegisterGoods.toString(),
        tLabel: I18nKeys.mypage.progGoods.key,
        tTooltip: '',
    }
];
