import {Category} from "../../../../graphql/types";
import {ReduxStore} from "../../../../index";
import {CopyUtil} from "../../../../utils/copyUtil/copyUtil";

export class CategUtil {
    static getSubCateg(params: {
        depth: number,
        parentId: number,
        category: Category[]
    }): Category[] {
        if (params.depth === 1) {
            return [];
        }

        let res: Category[] = [];
        for (let category of params.category) {
            if ((category.depth === params.depth) && (category.parentId === params.parentId)) {
                res.push(category);
            }
        }

        res = res.sort((a, b) => {
            return a.order - b.order
        })

        return res;
    }

    static getSubWithParentId(params: {
        parentId: number,
        category: Category[],
    }): Category[] {

        let res: Category[] = [];
        for (let category of params.category) {
            if (category.parentId === params.parentId) {
                res.push(category);
            }
        }

        res = res.sort((a, b) => {
            return a.order - b.order
        })

        return res;
    }

    static copyChildNode(list: Array<Category[]>): Array<Category[]> {
        let res: Array<Category[]> = [];

        for (let categList of list) {
            const group: Category[] = [];
            for (let category of categList) {
                group.push(Object.assign({}, category))
            }
            res.push(group);
        }

        return res;
    }

    static getSuper(id: number): Category {
        const list = ReduxStore.getState().data.category;

        const categ = list.find(value => {
            return id === value.id
        });

        if (!categ) {
            return {depth: 0, id: 0, img: "", nm: {}, order: 0, parentId: 0, superId: []};
        }

        const superb = list.find(value => {
            return value.id === categ.parentId
        });

        if (superb) {
            return superb;
        } else {
            return {depth: 0, id: 0, img: "", nm: {}, order: 0, parentId: 0, superId: []};
        }
    }

    static getSub(id: number): Category[] {
        const list = ReduxStore.getState().data.category;

        const categ = list.find(value => {
            return id === value.id
        });

        if (!categ) {
            return [];
        }

        const res = []
        for (let category of list) {
            if (category.parentId === categ.id) {
                res.push(category);
            }
        }

        res.sort((a, b) => {
            return a.order - b.order;
        })

        return res;
    }

    static getRootCategories(id: number): Category[] {
        const list = ReduxStore.getState().data.category;

        const categ = list.find(value => {
            return id === value.id
        });

        if (!categ) {
            return [];
        }

        const res = []
        for (let category of list) {
            if (category.parentId === categ.parentId) {
                res.push(category);
            }
        }

        res.sort((a, b) => {
            return a.order - b.order;
        })

        return res;
    }

    static getSameDepth(params: {
        categoryId: number,
        category: Category[]
    }): Category[] {
        const res: Category[] = [];

        const root = this.getRoot(params.categoryId, params.category);

        for (let category of params.category) {
            if (category.parentId === root.parentId) {
                res.push(CopyUtil.copyAll(category));
            }
        }

        res.sort ((a, b) => a.order - b.order);

        return res;
    }

    static getSuperCategory(params: {
        parentId: number,
        category: Category[],
    }): Category[] {
        const res: Category[] = [];
        let findId = params.parentId;
        const getCategory = (id: number) => {
            return params.category.find(value => {
                return id === value.id;
            });
        };

        while (true) {
            const categ = getCategory(findId);

            if (categ) {
                res.push(categ);
                findId = categ.parentId;
            } else {
                break;
            }
        }

        res.sort((a, b) => {
            return a.id - b.id;
        })

        return res;
    }

    static getSuperCategories(categoryId: number): Category[] {
        const category = ReduxStore.getState().data.category;

        const root = category.find(value => {
            return value.id === categoryId
        });


        if (!root) {
            return []
        }


        let res: Category[] = [];
        for (let categ of category) {
            if (root.superId.includes(categ.id)) {
                res.push(categ);
            }
        }

        res = res.sort((a, b) => {
            return a.order - b.order
        });

        res.push(this.getRoot(categoryId, category));

        return res;
    }

    static getRoot(categoryId: number, category: Category[]): Category {

        const root = category.find(value => {
            return value.id === categoryId
        });

        if (root) {
            return root;
        }

        return {
            id: 0,
            img: "",
            nm: {en: "", kr: ""},
            order: 0,
            parentId: 0,
            superId: [],
            depth: 0
        };
    }
}
