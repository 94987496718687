import {createActionCreators, createReducerFunction, ImmerReducer} from "immer-reducer";
import {JoinType, User as GraphqlUser, UserType} from "../graphql/types";


export interface UserState {
    user: GraphqlUser;
    isLogin: boolean;
    balance: number,
}

const initState: UserState = {
    isLogin: false,
    user: {
        id: 0,
        email: "",
        companyPermission: [],
        joinType: JoinType.Email,
        joinAt: new Date(),
        userType: UserType.User,
        hasUserInfo: false,

    },
    balance: 0
};

class User extends ImmerReducer<typeof initState> {
    login(user: GraphqlUser) {
        this.draftState.isLogin = true;
        this.draftState.user = user;
    }


    logout() {
        this.draftState.isLogin = false;
        this.draftState.user = {
            id: 0,
            email: "",
            companyPermission: [],
            joinType: JoinType.Email,
            joinAt: new Date(),
            userType: UserType.User,
            hasUserInfo: false,
        }
        this.draftState.balance = 0;
    }

    setBalance(balance: number) {
        this.draftState.balance = balance;
    }
}

export const UserAction = createActionCreators(User);
export const UserReducer = createReducerFunction(User, initState);

