import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './GoodsConferenceCategory.module.scss';
import {gql, useQuery} from "@apollo/client";
import {Query} from "../../../graphql/types";
import {BizTitle} from "../../shares/bizTitle/BizTitle";
import {CategUtil} from "../../layout/web/categ/CategUtil";
import {useSelector} from "react-redux";
import {I18nState} from "../../../redux/I18n";
import {useTranslation} from "react-i18next";
import {BizMenuData, BizMenuLayout} from "../../shares/bizMenuLayout/BizMenuLayout";
import {Pager} from "../../shares/pager/Pager";
import {navigate} from "hookrouter";
import {BizGoodsIdxList} from "../../shares/bizGoodsIdxList/BizGoodsIdxList";
import {I18nUtil} from '../../../utils/i18n/i18n';
import {DataState} from "../../../redux/Data";
import {BizGoodsDataViewBreadcrumbs} from "../../shares/bizGoodsDataView/breadcrumbs/BizGoodsDataViewBreadcrumbs";
import {FadeIn} from "../../shares/fadeIn/FadeIn";
import {Element} from "react-scroll";

const cx = classNamesBind.bind(styles);

export const GoodsConferenceCategory = (props: {
    id: number,
    page: number,
}) => {
    const [t] = useTranslation('common');
    const langCode = useSelector<any, I18nState>(state => state.i18n).langCode;
    const category = useSelector<any, DataState>(state => state.data).category;
    const onChangePage = (page: number) => {
        navigate(`/goods/conference/${props.id}/${page}`)
    }

    //
    // DB등록된 카테고리에 [인증상품]카테고리를 '0'번째 카테고리로 삽입한다.
    ////////////////////////////////////////////////////////////////////
    let categoryId = props.id;
    const CERTIFIED_PRODUCT_CATEGORY_ID = 0;
    const PLATFORM_CATEGORY_ID = 3;
    let categories : BizMenuData[] = Array<BizMenuData>();

    const categoryHandler = {
        // '0'번 카테고리로 '인증상품'을 삽입한다.
        init: () => {
            categories[0] = {
                title: t('goods.certifiedProduct'),
                routerSuffix: '/0',
            }
        },

        // 카테고리가 '인증상품'인지를 확인한다.
        isCertifiedProduct: () => {
            return props.id === CERTIFIED_PRODUCT_CATEGORY_ID;
        },

        // '0'번 카테고리에 '인증상품'이 들어가 있는 배열과
        // 나머지 카테고리들이 들어가 있는 배열을 서로 Merge한다.
        merge: () => {
            // '인증상품'은 DB에서 관리되는 데이터가 아니라
            // 수동으로 UI쪽에 입력한 데이터이기 때문에 depth가 존재하지 않는다.
            // 따라서, 같은 depth의 카테고리를 출력하기 위해서 카테고리가 '인증상품'일 경우
            // '플랫폼'과 같은 depth에 있는 카테고리를 읽어들이도록 한다.
            if(categoryId === 0) {
                categoryId = PLATFORM_CATEGORY_ID;
            }

            const registeredCategories : BizMenuData[] =  CategUtil.getSameDepth(
                {categoryId: categoryId, category}
            ).map(value => {
                return {
                    title: I18nUtil.value(langCode, value.nm),
                    routerSuffix: `/${value.id}`,
                }
            });
            categories = [ categories[0], ...registeredCategories ];
        },

        // Merge한 카테고리를 생성한 다음에는 현재의 카테고리 아이디를
        // 원래의 '인증상품'카테고리로 복원한다.
        restoreToOriginId : () => {
            categoryId = CERTIFIED_PRODUCT_CATEGORY_ID;
        },

        // 카테고리가 '인증상품'일 경우 하위 카테고리 없기 때문에
        // 하위 카테고리 정보를 표시하지 않는다.
        displayCategoryInfo: () => {
            if(!categoryHandler.isCertifiedProduct()) {
                return (
                    <>
                        <div className={cx('table')}>
                            <div className={cx('label')}>{t('goods.navigator')}</div>
                            <div className={cx('value')}>
                                <BizGoodsDataViewBreadcrumbs categoryId={props.id} />
                            </div>
                         </div>
                         <div className={cx('table')}>
                            <div className={cx('label')}>{t("goods.subCategory")}</div>
                            <div className={cx('value')}>
                                {CategUtil.getSubWithParentId({ parentId: props.id, category }).map((value, index) => (
                                    <div
                                        key={index}
                                        onClick={() => navigate(`/goods/conference/${value.id}`)}>
                                        {I18nUtil.value(langCode, value.nm)}
                                    </div>
                                ))}
                            </div>
                         </div>

                         <div className={cx('table')}/>
                    </>
                )
            }

            return (
                <></>
            )
        },

        // 카테고리가 '인증상품'아니 경우에만 타이틀을 표시한다.
        getTitle: () => {
            if(!categoryHandler.isCertifiedProduct()) {
                return (
                    <BizTitle>{I18nUtil.value(langCode, CategUtil.getRoot(categoryId, category).nm)}</BizTitle>
                )
            }
       },

        // 카테고리 아이디가 '0'(인증상품)일 경우 다음 로직이 실행되도록 한다.
        handle: () => {
            categoryHandler.init();
            categoryHandler.merge();
            
            if(categoryHandler.isCertifiedProduct()) {
                categoryHandler.restoreToOriginId();
            }
        },

        // '인증상품'인지 아닌지에 따라 다른 데이터가 출력되도록 한다.
        view: (data : any) => {
            let view;

            if(categoryHandler.isCertifiedProduct()) {
                view = categoryHandler.viewGoods(data.certificationGoodsList, data.certificationGoodsCount);
            } else {
                view = categoryHandler.viewGoods(data.goodsWithCategoryId, data.categoryGoodsCount);
            }
            
            return view;
        },

        // 카테고리에 따라 상품리스트 화면을 출력한다.
        viewGoods: (goods : any, count: any) => {
            return (
                <>
                <BizGoodsIdxList
                        title={t("goods.goodsList")}
                        categoryGoodsCount={count}
                        goods={goods.list}/>

                    <Pager
                        offset={-100}
                        page={goods.page}
                        size={goods.size}
                        total={goods.total}
                        onChange={onChangePage}/>
                </>
            )
        }
    } 
    categoryHandler.handle();
    ////////////////////////////////////////////////////////////////////


    const { data } = useQuery<Query, typeof props>(gqlData, {
        variables: props
    });


    const displayConference = () => {
        setTimeout(function() {
            const entries = window.location.href.split("/");
            const isConferenceCertificatedGoods = (
                "/goods/conference/0" === ("/" + entries[3] + "/" + entries[4] + "/" + entries[5])
            );
            
            if(!isConferenceCertificatedGoods) return;

            const goodsList = data?.certificationGoodsList;
            const lastPage = Math.ceil(goodsList!.total / goodsList!.size);
            //const lastPage = 5;

            let currentPage = -1;
            if(entries.length === 4) {
                currentPage = 0;
            } else {
                currentPage = parseInt(entries[entries.length - 1], 10);
            }

            if(currentPage === lastPage - 1) {
                currentPage = 0;
            } else {
                currentPage = currentPage + 1;
            }
            
            window.location.href = "/goods/conference/0/" + currentPage;
        }, 30000);
    }

  
    return (
        <>
            <Element name='pager'/>
            <BizMenuLayout
                title={' '}
                routerPrefix='/goods/conference'
                activatedRouterSuffix={`/${categoryId}`}
                data={categories}>

                {categoryHandler.getTitle()}
                {data &&
                <FadeIn>
                    {categoryHandler.displayCategoryInfo()}
                    {categoryHandler.view(data)}
                </FadeIn>
                }
            </BizMenuLayout>
            {displayConference()}
        </>
    )
}

const gqlData = gql`
    query Goods($id: Int!, $page: Int!){
        goodsWithCategoryId(categoryId:$id, page:$page, size:20){
            page
            size
            total
            list {
                id
                data
                company
                isPaid
                allEduCode
            }
        }
        categoryGoodsCount(categoryId: $id) {
            registered
            show
        }
        certificationGoodsList(page:$page, size:20){
            page
            size
            total
            list {
                id
                data
                company
                isPaid
                allEduCode
            }
        }
        certificationGoodsCount(noneparams: true) {
            registered
            show
        }
    }
`