import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './InputCreateGoods.module.scss';
import {OnChangeValue} from "../../../../../Intefaces";
import {InputText} from "../../../../share/inputs/inputText/InputText";
import {CopyUtil} from "../../../../../../utils/copyUtil/copyUtil";
import {InputNationPicker} from "../../../../share/inputs/inputNationPicker/InputNationPicker";
import {InputFiles} from "../../../../share/inputs/inputFiles/InputFiles";
import {InputSquareImages} from "../../../../share/inputs/inputSquareImages/InputSquareImages";
import {useTranslation} from "react-i18next";
import {GoodsData} from "../../../../../../graphql/customScalars";
import {InputGoodsPrice} from "../../../../share/inputs/inputGoodsPrice/InputGoodsPrice";

const cx = classNamesBind.bind(styles);

export const InputCreateGoods = (props: {
    value: GoodsData,
    onChangeValue: OnChangeValue<GoodsData>
}) => {
    const [t] = useTranslation('common');

    return (
        <>
            <div id='create-goods' className={cx('cont')}>
                <div className={cx('box', 'sm')}>
                    <InputText
                        title={t('mypageGoods.goodsNm')}
                        value={props.value.nm}
                        onChange={value => {
                            const copy = CopyUtil.copyAll(props.value);
                            copy.nm = value;
                            props.onChangeValue(copy);
                        }}/>
                </div>

                <div className={cx('box', 'sm')}>
                    <InputNationPicker
                        title={t('mypageGoods.origin')}
                        value={props.value.originNationCode}
                        onChange={v => {
                            const copy = CopyUtil.copyAll(props.value);
                            copy.originNationCode = v;
                            props.onChangeValue(copy);
                        }}/>
                </div>

                <div className={cx('box')}>
                    <InputText
                        title={t('mypageGoods.catchphrase')+t('mypageGoods.catchphraseDescription')}
                        value={props.value.catchphrase}
                        onChange={value => {
                            const copy = CopyUtil.copyAll(props.value);
                            copy.catchphrase = value;
                            props.onChangeValue(copy);
                        }}/>
                </div>

                <div className={cx('box')}>
                    <InputText
                        title={t('mypageGoods.certification')}
                        value={props.value.certification}
                        onChange={value => {
                            const copy = CopyUtil.copyAll(props.value);
                            copy.certification = value;
                            props.onChangeValue(copy);
                        }}/>
                </div>

                <div className={cx('box', 'sm')}>
                    <InputGoodsPrice
                        title={t('mypageGoods.price')}
                        value={props.value.goodsPrice}
                        onChange={v => {
                            const copy = CopyUtil.copyAll(props.value);
                            copy.goodsPrice = v;
                            props.onChangeValue(copy);
                        }}/>
                </div>

                <div className={cx('box', 'sm')}>
                    <InputText
                        title={t('mypageGoods.youtubeUrl')}
                        value={props.value.youtubeUrl}
                        onChange={v => {
                            const copy = CopyUtil.copyAll(props.value);
                            copy.youtubeUrl = v;
                            props.onChangeValue(copy);
                        }}/>
                </div>

                <div className={cx('box', 'sm')}>
                    <InputText
                        title={t('mypageGoods.purchaseUrl')}
                        value={props.value.purchaseUrl}
                        onChange={v => {
                            const copy = CopyUtil.copyAll(props.value);
                            copy.purchaseUrl = v;
                            props.onChangeValue(copy);
                        }}/>
                </div>

                <div className={cx('box')}>
                    <InputFiles
                        title={t('mypageGoods.downloadFile')}
                        value={props.value.goodsFile}
                        onChange={v => {
                            const copy = CopyUtil.copyAll(props.value);
                            copy.goodsFile = v;
                            props.onChangeValue(copy);
                        }}
                    />
                </div>

                <div className={cx('box')}>
                    <InputSquareImages
                        title={t('mypageGoods.goodsImage')}
                        value={props.value.goodsImage}
                        onChange={v => {
                            const copy = CopyUtil.copyAll(props.value);
                            copy.goodsImage = v;
                            props.onChangeValue(copy);
                        }}/>
                </div>

                {/*<div className={cx('box')}>*/}
                {/*    <InputWysiwyg*/}
                {/*        title={t('mypageGoods.contents')}*/}
                {/*        langCode={props.langCode}*/}
                {/*        value={props.value.contents}*/}
                {/*        onChangeContents={t => {*/}
                {/*            console.log('parents', t);*/}
                {/*            const copy = CopyUtil.copyAll(props.value);*/}
                {/*            copy.contents = t;*/}
                {/*            props.onChangeValue(copy);*/}
                {/*        }}/>*/}
                {/*</div>*/}
            </div>
        </>
    )
}
