import React, {ReactNode, useEffect} from 'react';
import {FadeIn} from '../fadeIn/FadeIn';
import {useSelector} from "react-redux";
import {SystemState, ViewMode} from "../../../redux/System";

export const FadeInContainer = (props: {
    children?: ReactNode,
}) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [props.children])

    return (
        <FadeIn>
            <div className='container'>
                <div className='row'>
                    {props.children}
                </div>
            </div>
        </FadeIn>
    )
}

export const BizCol12 = (props: {
    children?: ReactNode,
    marginBottom?: boolean
}) => {
    const system = useSelector<any, SystemState>(state => state.system);
    const hasPadding = system.viewMode === ViewMode.Web;

    return (
        <div className={`col-12 ${!hasPadding ? 'p-0' : ''}`}
             style={{marginBottom: props.marginBottom ? 30 : 0}}>
            {props.children}
        </div>
    )
}
