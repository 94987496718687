import {makeVar} from "@apollo/client";

export const GlobalVars = {
    system: {
        refreshWindow: makeVar(false),
        loadingView: makeVar(false),
    },
    quill: {
        createGoods: makeVar<QuillCreateGoodsData>({langCode: '', content: ''})
    }
}

export type QuillCreateGoodsData = {langCode: string, content: string};
