import React from "react";
import {
    Mutation,
    MutationCreateChatRoomWithCompanyIdAndConsultantIdArgs,
    MutationCreateChatRoomWithCompanyIdArgs,
    MutationHasChatRoomWithCompanyIdArgs,
    Query
} from "../../../graphql/types";
import {gql, useMutation, useQuery} from "@apollo/client";
import {navigate} from "hookrouter";
import {BizCompanyDataView} from "../../shares/bizCompanyDataView/BizCompanyDataView";
import {BizScrollTop} from "../../shares/bizScrollTop/BizScrollTop";
import {BizGoodsIdxList} from "../../shares/bizGoodsIdxList/BizGoodsIdxList";
import {Element} from "react-scroll";
import {useTranslation} from "react-i18next";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {ChatV1PageQueryParams} from "../../chat/v1/ChatV1";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../graphql/errors";
import {I18nKeys} from "../../../i18n/i18nKeys";
import {NotFound} from "../../system/notFound/NotFound";


export const CompanyDetail = (props: {
    companyUserId: number,
    goodsPage: number
}) => {
    const [t] = useTranslation('common');

    const {data, error} = useQuery<Query, typeof props>(gqlData, {
        variables: props
    });

    const [mutCreateChatRoomWithConsultant] = useMutation<Mutation, MutationCreateChatRoomWithCompanyIdAndConsultantIdArgs>(gqlCreateChatRoomWithConsultant);
    const [mutCreateChatRoom] = useMutation<Mutation, MutationCreateChatRoomWithCompanyIdArgs>(gqlCreateChatRoom);
    const [mutHasChatRoomWithCompanyId] = useMutation<Mutation, MutationHasChatRoomWithCompanyIdArgs>(gqlHasChatRoomWithCompanyId);

    const handler = {
        onCreateChatRoomWithConsultantId: (consultantId: number) => {
            if (!props.companyUserId) {
                return;
            }

            mutHasChatRoomWithCompanyId({
                variables: {
                    companyId: props.companyUserId
                }
            }).then(res => {
                const chatRoomId = res.data!.hasChatRoomWithCompanyId || -1;
                if (chatRoomId === -1) {
                    chatHandler.createChatRoomWithConsultantId(props.companyUserId, consultantId)
                } else {
                    chatHandler.askCreateChatRoomWithConsultantId(props.companyUserId, consultantId, chatRoomId);
                }
            }).catch(ApolloCatch({}));
        },
        onCreateChatRoomWithCompanyId: (companyId: number) => {
            mutHasChatRoomWithCompanyId({
                variables: {
                    companyId
                }
            }).then(res => {
                const chatRoomId = res.data!.hasChatRoomWithCompanyId || -1;
                if (chatRoomId === -1) {
                    chatHandler.createChatRoomWithCompanyId(props.companyUserId)
                } else {
                    chatHandler.askCreateChatRoomWithCompanyId(props.companyUserId, chatRoomId);
                }
            }).catch(ApolloCatch({}));
        },
    }

    const chatHandler = {
        askCreateChatRoomWithCompanyId: (companyId: number, chatRoomId: number) => {
            SwalUtil.ynPromise({
                msg: t(I18nKeys.chat.askEnterChatRoom.key),
                okBtn: t(I18nKeys.chat.enterChatRoomBtn.key),
                cancelBtn: t(I18nKeys.chat.createChatRoomBtn.key),
            }).then(() => {
                chatHandler.navigateChatRoomWithChatRoomId(chatRoomId);
            }).catch(ApolloCatch({
                [BizErrors.swalCancel]: () => {
                    chatHandler.createChatRoomWithCompanyId(companyId);
                }
            }));
        },
        askCreateChatRoomWithConsultantId: (companyId: number, consultantId: number, chatRoomId: number) => {
            SwalUtil.ynPromise({
                msg: t(I18nKeys.chat.askEnterChatRoom.key),
                okBtn: t(I18nKeys.chat.enterChatRoomBtn.key),
                cancelBtn: t(I18nKeys.chat.createChatRoomBtn.key),
            }).then(() => {
                chatHandler.navigateChatRoomWithChatRoomId(chatRoomId);
            }).catch(ApolloCatch({
                [BizErrors.swalCancel]: () => {
                    chatHandler.createChatRoomWithConsultantId(companyId, consultantId);
                }
            }));
        },
        createChatRoomWithCompanyId: (companyId: number) => {
            mutCreateChatRoom({
                variables: {
                    companyId
                }
            }).then(res => {
                chatHandler.navigateChatRoomWithChatRoomId(res.data!.createChatRoomWithCompanyId)
            }).catch(ApolloCatch({}));
        },
        createChatRoomWithConsultantId: (companyId: number, consultantId: number) => {
            mutCreateChatRoomWithConsultant({
                variables: {
                    companyId,
                    consultantId,
                }
            }).then(res => {
                chatHandler.navigateChatRoomWithChatRoomId(res.data!.createChatRoomWithCompanyIdAndConsultantId)
            }).catch(ApolloCatch({}));
        },
        navigateChatRoomWithCompanyId: (companyId: number) => {
            const params: ChatV1PageQueryParams = {
                tab: 'user',
                userChatListPage: 0,
                consultChatListPage: 0,
                companyId,
            };
            navigate('/chat', true, params);
        },
        navigateChatRoomWithChatRoomId: (chatRoomId: number) => {
            const params: ChatV1PageQueryParams = {
                tab: 'user',
                userChatListPage: 0,
                consultChatListPage: 0,
                chatRoomId,
            };

            navigate('/chat', true, params);
        }
    }

    const onChangePage = (page: number) => {
        navigate(`/company/detail/${props.companyUserId}/${page}`);
    }

    return (
        <BizScrollTop>
            {data &&
            <div style={{paddingTop: 30}}>
                <BizCompanyDataView
                    {...handler}
                    onCreateChatRoomWithConsultantId={handler.onCreateChatRoomWithConsultantId}
                    companyUserId={props.companyUserId}
                    companyDataMap={data.companyWithId.company}
                    companyLikes={data.companyLikes}
                    consultant={data.companyConsultantWithId}/>
                <Element name={'pager'}/>
                <div style={{height: 60}}/>

                <BizGoodsIdxList
                    title={t("company.companyGoodsListTitle")}
                    goods={data.goodsWithCompanyUserId.list}
                    pager={{
                        page: data.goodsWithCompanyUserId.page,
                        size: data.goodsWithCompanyUserId.size,
                        total: data.goodsWithCompanyUserId.total,
                        onChangePage: onChangePage
                    }}/>
                <div style={{height: 60}}/>
            </div>
            }

            {error && <NotFound/>}
        </BizScrollTop>
    )
}

const gqlData = gql`
    query CompanyWithId($companyUserId: Int!, $goodsPage: Int!){
        companyWithId(companyUserId: $companyUserId) {
            id
            company
        }
        companyLikes(companyUserId: $companyUserId) {
            likes
            goodsLikes
            views
        }
        kotraWithId(companyUserId: $companyUserId) {
            data
        }
        companyConsultantWithId(companyUserId: $companyUserId) {
            id
            email
            tel
            nm
            position
            isRegisteredAccount
        }
        goodsWithCompanyUserId(companyUserId: $companyUserId, page: $goodsPage, size: 20) {
            page
            size
            total
            list {
                id
                allEduCode
                data
                company
                isPaid
            }
        }
    }
`;

const gqlCreateChatRoomWithConsultant = gql`
    mutation CreateChatRoom($companyId: Int!, $consultantId: Int!) {
        createChatRoomWithCompanyIdAndConsultantId(companyId: $companyId, consultantId: $consultantId)
    }
`;

const gqlCreateChatRoom = gql`
    mutation CreateChat($companyId: Int!) {
        createChatRoomWithCompanyId(companyId: $companyId)
    }
`;

const gqlHasChatRoomWithCompanyId = gql`
    mutation HasChatRoomWithCompanyId($companyId: Int!) {
        hasChatRoomWithCompanyId(companyId: $companyId)
    }
`;
