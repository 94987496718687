import React from 'react';
import NaverLogin from 'react-login-by-naver';
import {GetEnv} from "../../../../../env/env";
import {useTranslation} from "react-i18next";

export const AuthLoginNaver = () => {
    const [t] = useTranslation('common');

    return (
        <>
            <NaverLogin
                clientId={GetEnv().naverToken}
                callbackUrl={GetEnv().naverCallbackUrl}
                render={(props) =>
                    <button
                        type='button'
                        className='btn btn-success'
                        style={{width: '100%', marginBottom: 10}}
                        onClick={props.onClick}>{t('auth.btnNaver')}
                    </button>
                }
                onSuccess={(naverUser) => {
                }}
                onFailure={() => {
                }}
            />
        </>
    )
}
