export class CopyUtil {
    static copyAll<T>(value: T): T {
        return JSON.parse(JSON.stringify(value));
    }

    static copy<T>(list: T[]): T[] {
        const res: T[] = [];
        for (let elem of list) {
            res.push(Object.assign({}, elem))
        }
        return res;
    }

    static copyValue<T>(list: T[]): T[] {
        const res: T[] = [];
        for (let elem of list) {
            const copy = elem;
            res.push(copy)
        }
        return res;
    }

    //
    // static transCompanyToUpdate(value: Company): UpdateCompany {
    //
    //     const nationSales = value.nationSales.map(v => {
    //         return {nationCode: v.nationCode, sales: v.sales};
    //     })
    //
    //     const totalSales = value.totalSales.map(v => {
    //         return {year: v.year, sales: v.sales};
    //     })
    //
    //     const nationUsage = value.nationUsage.map(v => {
    //         return {nationCode: v.nationCode, usage: v.usage};
    //     })
    //
    //     return {
    //         addr: value.addr,
    //         catchphrase: value.catchphrase,
    //         category: value.category,
    //         ceo: value.ceo,
    //         companyType: value.companyType,
    //         foundation: new Date(value.foundation),
    //         hp: value.hp,
    //         id: value.id,
    //         logo: undefined,
    //         member: value.member,
    //         nationCode: value.nationCode,
    //         nationSales: nationSales,
    //         nationUsage: nationUsage,
    //         totalSales: totalSales,
    //         nm: value.nm
    //     }
    // }

    //
    // static copyCreateGoods(value: CreateGoods): CreateGoods {
    //     const copy = Object.assign({}, value);
    //     copy.catalogs = this.copy(value.catalogs);
    //     copy.images = this.copy(value.images);
    //     copy.info = this.copy(value.info);
    //     copy.manuals = this.copy(value.manuals);
    //     return copy;
    // }
    //
    // static copyGoodsToCreateGoods(value: Goods): CreateGoods {
    //     const langList = ReduxStore.getState().data.langCode;
    //     const createMultiLang = (): MultiLang => {
    //         const res: MultiLang = {};
    //         for (let elem of langList) {
    //             res[elem.code] = ""
    //         }
    //         return res;
    //     }
    //
    //     return {
    //         award: Object.assign({}, value.award || createMultiLang()),
    //         catalogs: value.catalogs.map(v => {
    //             return {id: v.id, nm: v.nm, src: v.src}
    //         }),
    //         catchphrase: Object.assign({}, value.catchphrase || createMultiLang()),
    //         categoryId: value.categoryId,
    //         certification: Object.assign({}, value.certification || createMultiLang()),
    //         contents: Object.assign({}, value.contents || createMultiLang()),
    //         goodsCode: value.goodsCode,
    //         images: value.images.map(v => {
    //             return {id: v.id, nm: v.nm, src: v.src}
    //         }),
    //         info: value.info.map(v => {
    //             return {key: Object.assign({}, v.key), value: Object.assign({}, v.value)}
    //         }),
    //         manuals: value.manuals.map(v => {
    //             return {id: v.id, nm: v.nm, src: v.src}
    //         }),
    //         nationCode: value.nationCode,
    //         nm: Object.assign({}, value.nm || createMultiLang()),
    //         price: value.price,
    //     }
    // }
}
