import React, {useState} from 'react';
import {gql, useLazyQuery} from "@apollo/client";
import {ChatRoomFindOptions, Query, UserType} from "../../../graphql/types";
import {useQueryParams} from "hookrouter";
import {ViewSelector} from "../../shares/viewSelector/ViewSelector";
import {ChatV1Web} from "./web/ChatV1Web";
import {FadeIn} from "../../shares/fadeIn/FadeIn";
import {useDispatch, useSelector} from "react-redux";
import {UserState} from "../../../redux/User";
import {ChatV1TabData} from "./share/tab/ChatV1Tab";
import {CopyUtil} from "../../../utils/copyUtil/copyUtil";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {ChatAction, ChatState} from "../../../redux/Chat";
import {I18nKeys} from "../../../i18n/i18nKeys";
import {ChatV1Mobile} from './mobile/ChatV1Mobile';
import { useDeepCompareEffect } from 'use-deep-compare';


export const ChatV1 = () => {
    const [state, setState] = useState<ChatV1PageQueryParams>({
        tab: 'user',
        userChatListPage: 0,
    });
    const [tabData, setTabData] = useState<ChatV1TabData[]>([]);
    const [getData, resData] = useLazyQuery<Query, GetDataQueryParams>(gqlData, {
        context: {
            loadingView: false,
        }
    });
    const user = useSelector<any, UserState>(state => state.user);
    const chatRefetch = useSelector<any, ChatState>(state => state.chat);
    const [queryParams] = useQueryParams();
    const dispatch = useDispatch();

    const handler = {
        onChangePage: (p: number) => {
            const copy = CopyUtil.copyAll(state);
            copy.userChatListPage = p;
            setState(copy)
        },
        onChangeTabValue: (v: string) => {
            const copy = CopyUtil.copyAll(state);
            copy.tab = v;
            setState(copy)
        },
        refetch: () => {
            if (resData.refetch) {
                resData
                    .refetch()
                    .catch(ApolloCatch({}));
            }
        },
        onChangeConsultPage: (p: number) => {
            const copy = CopyUtil.copyAll(state);
            copy.consultChatListPage = p;
            setState(copy)
        },
        onChangeFindWord: (w: string) => {
            const copy = CopyUtil.copyAll(state);
            copy.findWord = w;
            setState(copy);
        },
        onClear: () => {
            const copy = CopyUtil.copyAll(state);
            copy.companyId = undefined;
            copy.goodsId = undefined;
            copy.goodsInfoId = undefined;
            copy.findWord = undefined;
            setState(copy);
        },
        onOpenChatRoom: (chatRoomId: number) => {
            const copy = CopyUtil.copyAll(state);
            copy.chatRoomId = chatRoomId;
            setState(copy);
        },
        onBackList: () => {
            const copy = CopyUtil.copyAll(state);
            copy.chatRoomId = undefined;
            setState(copy);
        }
    };

    useDeepCompareEffect(() => {
        if (user.user.userType === UserType.User) {
            setTabData([
                {value: 'user', tLabel: I18nKeys.chat.tabAsk.key},
            ])
        } else {
            setTabData([
                {value: 'user', tLabel: I18nKeys.chat.tabAsk.key},
                {value: 'consult', tLabel: I18nKeys.chat.tabReply.key},
            ])
        }

        const params: ChatV1PageQueryParams = {
            chatRoomId: Number(queryParams.chatRoomId) || undefined,
            findWord: queryParams.findWord || undefined,
            consultChatListPage: Number(queryParams.consultChatListPage) || undefined,
            tab: queryParams.tab || 'user',
            userChatListPage: Number(queryParams.userChatListPage) || 0,
            companyId: Number(queryParams.companyId) || undefined,
            goodsId: Number(queryParams.goodsId) || undefined,
            goodsInfoId: Number(queryParams.goodsInfoId) || undefined,
        };

        setState(params);
    }, [queryParams, user.user.userType]);

    useDeepCompareEffect(() => {
        const variables: GetDataQueryParams = {
            chatRoomConsultPage: state.consultChatListPage || 0,
            chatRoomPage: state.userChatListPage,
            findOption: {
                findWord: state.findWord,
                companyId: state.companyId,
                goodsId: state.goodsId,
                goodsInfoId: state.goodsInfoId,
            },
        };

        if (resData.refetch) {
            resData
                .refetch(variables)
                .catch(ApolloCatch({}));
        } else {
            getData({variables});
        }
    }, [state, getData]);

    useDeepCompareEffect(() => {
        if (chatRefetch.refetchChatList) {
            dispatch(ChatAction.chatList(false));
            handler.refetch();
        }
    }, [chatRefetch, dispatch, handler]);


    return (
        <>
            {resData.data &&
            <FadeIn>
                <ViewSelector
                    web={
                        <ChatV1Web
                            {...handler}
                            params={state}
                            tabData={tabData}
                            chatRoomList={resData.data.chatRoomList}
                            chatRoomConsultList={resData.data.chatRoomConsultList}/>
                    }
                    mobile={
                        <ChatV1Mobile
                            {...handler}
                            params={state}
                            tabData={tabData}
                            chatRoomList={resData.data.chatRoomList}
                            chatRoomConsultList={resData.data.chatRoomConsultList}/>
                    }/>

            </FadeIn>
            }
        </>
    )
}

export type TabValue = 'user' | 'consult' | string;


export interface ChatV1PageQueryParams {
    tab: TabValue;
    userChatListPage: number;
    findWord?: string,
    consultChatListPage?: number;
    chatRoomId?: number;
    companyId?: number,
    goodsId?: number,
    goodsInfoId?: number,
}

interface GetDataQueryParams {
    chatRoomPage: number,
    chatRoomConsultPage: number,
    findOption?: ChatRoomFindOptions,
}


const gqlData = gql`
    query DATA($chatRoomPage: Int!, $chatRoomConsultPage: Int!, $findOption: ChatRoomFindOptions) {
        chatRoomList(page: $chatRoomPage, size: 10, findOption: $findOption) {
            page
            size
            total
            list {
                id
                nm
                imgSrc
                notice
                lastChatContent
                updatedAt
            }
        }
        chatRoomConsultList(page: $chatRoomConsultPage, size: 10, findOption: $findOption) {
            page
            size
            total
            list {
                id
                nm
                imgSrc
                notice
                lastChatContent
                updatedAt
            }
        }
    }
`;
