import React, {ReactNode} from 'react';
import {BizMenuData, BizMenuLayout} from "../../shares/bizMenuLayout/BizMenuLayout";
import {useTranslation} from "react-i18next";

export const CsLayout = (props: {
    children: ReactNode,
    activatedSuffixRouter: string
}) => {
    const [t] = useTranslation('common');

    let menu: BizMenuData[] = [
        {title: t("cs.noticeTitle"), routerSuffix: '/notice/list'},
        {title: t("cs.newsletterTitle"), routerSuffix: '/newsletter/list'},
        {title: t("cs.faqTitle"), routerSuffix: '/faq/list'},
    ]


    return (
        <>
            <BizMenuLayout
                title={t("layout.cs")}
                data={menu}
                routerPrefix={'/cs'}
                activatedRouterSuffix={props.activatedSuffixRouter}>
                {props.children}
            </BizMenuLayout>
        </>
    )
}
