import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './IndexMainImageHalf.module.scss';
import {IndexImage} from "../../../../../graphql/types";

const cx = classNamesBind.bind(styles);

export const IndexMainImageHalf = (props: {
    divide: number;
    list: IndexImage[]
}) => {

    const handler = {
        onOpenUrl: (url: string) => {
            window.open(url, '_blank');
        }
    }

    return (
        <div className={cx('cont')}>
            {props.list.map((value, index) => (
                <div className={cx('box-div')} style={{width: `calc(100% / ${props.divide})`}} key={index}>
                    <div className={cx('box-img')} onClick={() => handler.onOpenUrl(value.link)}>
                        <img src={value.src} alt=''/>
                    </div>
                </div>
            ))}
        </div>
    )
}
