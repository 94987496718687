import React, {useState} from 'react';
import classNamesBind from "classnames/bind";
import styles from './AuthLoginForm.module.scss';
import {useTranslation} from "react-i18next";
import {navigate} from "hookrouter";
import {JoinType, MutationLoginArgs} from "../../../../graphql/types";
import {OnChangeValue} from "../../../Intefaces";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import imgLogo from '../../../../assets/img/svg/AllEdu.Shop_Logo_v3.svg';
import {AuthSocialLogin} from "../../share/socialLogin/AuthSocialLogin";


const cx = classNamesBind.bind(styles);

export const AuthLoginForm = (props: {
    onLogin: OnChangeValue<MutationLoginArgs>,
    onResetPw: OnChangeValue<{ email: string }>,
    onResendEmailVerified: OnChangeValue<{ email: string, pw: string }>,
}) => {
    const [t] = useTranslation('common');
    const [email, setEmail] = useState("");
    const [pw, setPw] = useState("");

    const handler = {
        onLogin: () => {
            props.onLogin({email, pw: pw, joinType: JoinType.Email});
        },
        onResetPw: () => {
            if (!email) {
                SwalUtil.ok({
                    msg: t("auth.phEmail"),
                    icon: 'error'
                })
                return;
            }
            props.onResetPw({email})
        },
        onResendEmailVerified: () => {
            if ((!email) && (!pw)) {
                SwalUtil.ok({
                    msg: t("auth.swalCheckInputError"),
                    icon: 'error'
                })
                return;
            }
            props.onResendEmailVerified({
                email: email,
                pw: pw
            })

        }
    }

    return (
        <div className={cx('cont')}>
            <div className={cx('box-login')}>
                <img className={cx('img-logo')} src={imgLogo} alt={'alledu.shop'}/>
                <form className={cx("box-form")} onSubmit={ev => {
                    ev.preventDefault();
                    handler.onLogin();
                }}>
                    <p>{t("auth.email")}</p>
                    <input
                        required
                        className='form-control'
                        type='email'
                        placeholder={t('auth.phEmail')}
                        value={email}
                        onChange={event => {
                            setEmail(event.target.value)
                        }}/>
                    <p>{t("auth.password")}</p>
                    <input
                        required
                        className='form-control'
                        type='password'
                        placeholder={t('auth.phPw')}
                        value={pw}
                        onChange={event => {
                            setPw(event.target.value)
                        }}/>

                    <div style={{height: 40}}/>
                    <button
                        style={{marginBottom: 10}}
                        type="submit"
                        className="btn btn-primary btn-block">
                        {t('auth.btnLogin')}</button>

                    {/*회원가입*/}
                    <button
                        type='button'
                        onClick={event => {
                            navigate('/auth/join');
                        }}
                        className='btn btn-outline-secondary btn-block'>
                        {t('auth.btnJoin')}
                    </button>

                    {/*회원가입, 비밀번호 찾기 안내 */}
                    <div style={{height: 10}}/>
                    <p>{t("auth.goReset")}
                        <span style={{
                            paddingLeft: 10,
                            color: '#86a7cd',
                            cursor: "pointer"
                        }} onClick={handler.onResetPw}>{t("auth.btnReset")}</span>
                    </p>

                    <p style={{marginTop: 0}}>{t("auth.onResendEmailVerified")}
                        <span style={{
                            paddingLeft: 10,
                            color: '#86a7cd',
                            cursor: "pointer"
                        }} onClick={handler.onResendEmailVerified}>{t("auth.btnResend")}</span>
                    </p>

                    {/*소셜 로그인*/}
                    <hr/>
                    <AuthSocialLogin title={t("auth.snsLogin")}/>
                </form>
            </div>
        </div>
    )
}
