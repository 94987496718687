import React, {Fragment, ReactNode} from 'react';
import classNamesBind from "classnames/bind";
import styles from './InputNumber.module.scss';
import {OnChangeNumber} from "../../../../Intefaces";
import NumberFormat from "react-number-format";

const cx = classNamesBind.bind(styles);

export const InputNumber = (props: {
    title: ReactNode,
    value: number,
    onChange: OnChangeNumber
}) => {


    return (
        <Fragment>
            <div className={cx('title')}>{props.title}</div>
            <div className={cx('box')}>
                <div className={cx('label')}/>
                <NumberFormat
                    thousandSeparator
                    className='form-control form-control-sm text-center'
                    displayType='input'
                    value={props.value}
                    allowNegative={false}
                    decimalScale={0}
                    onValueChange={values => {
                        props.onChange(values.floatValue || 0);
                    }}/>
            </div>
        </Fragment>

    )
}
