import React, {useEffect, useState} from 'react';
import {Mutation, MutationAllowCreateCompanyArgs, MutationRequestCreateCompanyArgs} from "../../../../graphql/types";
import {MyPageLayout} from "../../layout/MyPageLayout";
import {useTranslation} from "react-i18next";
import {BtnBox} from "../../../shares/btnBox/BtnBox";
import {ReduxStore} from "../../../../index";
import {GetEnv} from "../../../../env/env";
import {MyPageCompanyCreateInput} from "./input/MyPageCompanyCreateInput";
import {CopyUtil} from "../../../../utils/copyUtil/copyUtil";
import {OtherInputTab, OtherInputTabsData} from "../../share/otherInputTab/OtherInputTab";
import {MyPageCompanyCreateOtherInput} from "./otherInput/MyPageCompanyCreateOtherInput";
import {Pipe} from "../../../../utils/pipe/pipe";
import {CompanyDataUtil} from "../../../../utils/companyDataUtil/CompanyDataUtil";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import {gql} from "@apollo/client/core";
import {useMutation, useReactiveVar} from "@apollo/client";
import {ApolloCatch} from "../../../../utils/apollo/apolloCatch";
import {navigate} from "hookrouter";
import {InputQuill} from "../../share/inputs/inputQuill/InputQuill";
import {GlobalVars} from "../../../../globalVars/globalVars";

export const MyPageCompanyCreate = (props: {}) => {
    const [t] = useTranslation('common');
    const [input, setInput] = useState<MutationRequestCreateCompanyArgs>
    (GetEnv().env === 'dev' ? CreateCompanyInput.dummy() : CreateCompanyInput.prod())

    const [tabLangCode, setTabLangCode] = useState('en');
    const [mutRequest] = useMutation<Mutation, MutationRequestCreateCompanyArgs>(gqlRequest);
    const [mutAllow] = useMutation<Mutation, MutationAllowCreateCompanyArgs>(gqlAllow);

    const quillData = useReactiveVar(GlobalVars.quill.createGoods);

    //*------------------------------------------------------------*/
    // 다음 경고를 무시하게 한다. 
    // 해당 경고를 수정했을 경우 InputQuill이 제대로 동작하지 않는다.
    // (※경고를 수정했으나 InputQuill이 수정되지 않는 문제가 발생하여
    //    소스를 원상복구한다.)
    //---------------------------------------------------------------
    // React Hook useEffect has a missing dependency: 'input'.
    // Either include it or remove the dependency array  react-hooks/exhaustive-deps
    //*------------------------------------------------------------*/
    useEffect(() => {

        const copy = CopyUtil.copyAll(input);
        if (!copy.input.hasOwnProperty(quillData.langCode)) {
            return;
        }

        copy.input[quillData.langCode].companyDetail = quillData.content;
        setInput(copy);
    // eslint-disable-next-line
    }, [quillData]);

    // 에디터 초기화
    useEffect(() => {
        return () => {
            GlobalVars.quill.createGoods({langCode: '', content: ''})
        }
    }, [])


    const handler = {
        onCreateCompany: () => {
            let copy = CopyUtil.copyAll(input);
            copy.input = CompanyDataUtil.sync(copy.input);

            const validation = CompanyDataUtil.validate(copy.input);
            if (!validation.isOk) {
                SwalUtil.ok({
                    msg: t(validation.tValue),
                    icon: 'error',
                })
                return;
            }

            SwalUtil.yn({
                msg: t('mypageJoin.askJoin'),
                icon: 'question',
                ok: () => {
                    mutRequest({
                        variables: copy
                    }).then((res) => {
                        if(res.data!.requestCreateCompany === 0) {
                            SwalUtil.ok({
                                msg: t("mypageCompany.swalCreateCompanyRequestIdError"),
                                icon: 'error',
                            })
                            return;
                        }

                        const companyCreateRequestId = res.data!.requestCreateCompany;
                        mutAllow({
                            variables: {
                                companyCreateRequestId: companyCreateRequestId
                            }
                        }).then(() => {
                            SwalUtil.ok({
                                msg: t('mypageCompany.swalRegistrationSuccess'),
                                icon: 'success'
                            });
    
                            navigate('/');
                        })
                    }).catch(ApolloCatch({}))
                }
            })
        }
    };

    const otherInputData: OtherInputTabsData = {};
    otherInputData['en'] = {
        title: <>English <span style={{color: 'red'}}>{t('mypageJoin.required')}</span></>,
        children: <MyPageCompanyCreateInput
            input={input.input['en']}
            onChangeInput={v => {
                let copy = CopyUtil.copyAll(input);
                copy.input['en'] = v;
                copy.input = CompanyDataUtil.sync(copy.input);
                setInput(copy);
            }}/>
    }

    const otherInputLangCode = Object.keys(input.input).filter(v => {
        return v !== 'en';
    })

    for (let langCode of otherInputLangCode) {
        otherInputData[langCode] =
            {
                title: Pipe.langNm(langCode),
                children: <MyPageCompanyCreateOtherInput
                    input={input.input[langCode]}
                    onChangeInput={v => {
                        const copy = CopyUtil.copyAll(input);
                        copy.input[langCode] = v;
                        setInput(copy);
                    }}/>
            }
    }

    return (
        <MyPageLayout activatedSuffixRouter={"/company/create"}>
            <OtherInputTab
                langCode={tabLangCode}
                onChangeLangCode={setTabLangCode}
                data={otherInputData}/>

            <InputQuill
                title={t("mypageJoin.companyDetail")}
                langCode={tabLangCode}
                value={input.input[tabLangCode].companyDetail}
                from="company"
                onChangeValue={v => {
                    const copy = CopyUtil.copyAll(input);
                    copy.input[tabLangCode].companyDetail = v;
                    setInput(copy);
                }}/>


            <BtnBox>
                <button
                    className='btn btn-sm btn-success'
                    onClick={handler.onCreateCompany}>{t('mypageJoin.btnJoin')}</button>
            </BtnBox>
        </MyPageLayout>
    )
}

const gqlRequest = gql`
    mutation Request($input: CompanyDataMap!) {
        requestCreateCompany(input: $input)
    }
`;


export const CreateCompanyInput = {
    dummy: (): MutationRequestCreateCompanyArgs => {
        const langCodeList = ReduxStore.getState().data.langCode;

        const res: any = {};
        for (let langCode of langCodeList) {
            res[langCode.code] = {
                nationCode: 'KR',
                companyTypeId: 1,
                logoUrl: "",
                // logoUrl: "https://timeedu.kr.object.ncloudstorage.com/goods/a778f285-a5fd-45cd-9792-aaf72e4ce790",
                companyNm: "Ciao's garage",
                ceo: "Ciao",
                category: "Develop",
                catchphrase: "abc",
                addr: "seoul",
                nationSales: [
                    {nationCode: 'KR', sales: 100},
                    {nationCode: 'US', sales: 200}
                ],
                nationUsage: [
                    {nationCode: 'KR', usage: 100},
                    {nationCode: 'US', usage: 200},
                ],
                totalSales: [
                    {year: 2001, sales: 100},
                    {year: 2002, sales: 500},
                    {year: 2003, sales: 300},
                ],
                hp: "www.naver.com",
                foundation: new Date(),
                member: 1000,
                companyFiles: [
                    {
                        src: 'https://timeedu.kr.object.ncloudstorage.com/goods/a778f285-a5fd-45cd-9792-aaf72e4ce790',
                        nm: 'json.jpg',
                        createdAt: new Date()
                    }
                ],
                priceUnit: '$ (USD)',
                introduceYoutubeUrl: 'www.youtube.com',
                companyDetail: '기관상세설명'
            }
        }
        return {
            input: res
        }
    },
    prod: (): MutationRequestCreateCompanyArgs => {
        const langCodeList = ReduxStore.getState().data.langCode;

        const res: any = {};
        for (let langCode of langCodeList) {
            res[langCode.code] = {
                nationCode: 'KR',
                companyTypeId: 1,
                logoUrl: "",
                companyNm: "",
                ceo: "",
                category: "",
                catchphrase: "",
                addr: "",
                nationSales: [],
                nationUsage: [],
                totalSales: [],
                hp: "",
                foundation: new Date(),
                member: 0,
                companyFiles: [],
                priceUnit: '$ (USD)',
                introduceYoutubeUrl: '',
                companyDetail: '',
            }
        }
        return {
            input: res
        }
    }
}

const gqlAllow = gql`
    mutation Allow($companyCreateRequestId: Int!){
        allowCreateCompany(companyCreateRequestId: $companyCreateRequestId)
    }
`;
