import React, {ReactNode} from "react";
import classNamesBind from "classnames/bind";
import styles from './CenterContents.module.scss'
import {useSpring, animated} from "react-spring";

const cx = classNamesBind.bind(styles);

export const CenterContents = (props: {
    children: ReactNode,
    animatedContents?: boolean,
    backgroundColor?: string;
}) => {
    const [contentsProps, setContentsProps] = useSpring(() => ({opacity: props.animatedContents ? 0 : 1}));
    setContentsProps({opacity: 1});


    return (
        <div className={cx('cont')} style={{background: props.backgroundColor ? props.backgroundColor : ''}}>
            <animated.div style={contentsProps}>
                {props.children}
            </animated.div>
        </div>
    )
}
