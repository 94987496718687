import React, {Fragment, useEffect, useState} from 'react';
import classNamesBind from "classnames/bind";
import styles from './CategWeb.module.scss';
import {Category} from '../../../../graphql/types'
import {CategUtil} from "./CategUtil";
import {ChevronRight} from "@material-ui/icons";
import {useSelector} from "react-redux";
import {navigate} from "hookrouter";
import {DataState, DataAction} from "../../../../redux/Data";
import {LayoutWebState} from "../../../../redux/LayoutWeb";
import {I18nState} from "../../../../redux/I18n";
import {I18nUtil} from "../../../../utils/i18n/i18n";
import { useTranslation } from 'react-i18next';
import { ReduxStore } from '../../../..';

const cx = classNamesBind.bind(styles);

export const CategWeb = () => {
    const [category, setCategory] = useState<Category[]>([]);
    const [childNode, setChildNode] = useState<Array<Category[]>>([]);
    const [firstNode, setFirstNode] = useState<Category[]>([]);
    const layoutWeb = useSelector<any, LayoutWebState>(state => state.layoutWeb);
    const data = useSelector<any, DataState>(state => state.data);
    const i18n = useSelector<any, I18nState>(state => state.i18n);
    const [t] = useTranslation('common');

    useEffect(() => {
        setCategory(data.category);
        setFirstNode(CategUtil.getSubWithParentId({parentId: 1, category: data.category}));
    }, [data.category])

    // 카테고리 뷰가 바뀔때 초기화
    useEffect(() => {
        if (layoutWeb.showCategory) {
            setChildNode([]);
        }
    }, [layoutWeb.showCategory])

    const clkFirstNode = (parentId: number) => {
        setChildNode([CategUtil.getSubWithParentId({parentId, category})])
    }

    const clkChildNode = (depth: number, parentId: number) => {
        let copy = CategUtil.copyChildNode(childNode);
        const childLength = depth - 1;
        while (childLength < copy.length) {
            copy.pop();
        }

        const appendCateg = CategUtil.getSubWithParentId({parentId, category});

        if (appendCateg) {
            copy.push(appendCateg);
        }

        setChildNode(copy);
    }

    // 에듀테크코리아페어 2024 카테고리 아이디
    const EDUTECH_KOREA_FAIR_2024_CATEGORY_ID = 10000002024;

    // 에듀테크코리아페어 2024 서브 카테고리 ALL 아이디
    const EDUTECH_KOREA_FAIR_2024_SUB_CATEGORY_ALL = 1;

    const onChangeCategory = (categoryId: number) : void => {
        ReduxStore.dispatch(
            DataAction.setCurrentSelectedGoodsCategory({
                id: categoryId, 
                listPage: {
                    pageStartId: 0,
                    currentPage: 0,
                    page: 0
                }
            })
        );

        if(categoryId === EDUTECH_KOREA_FAIR_2024_CATEGORY_ID) {
            navigate(`/edutechkoreafair/2024/list/${EDUTECH_KOREA_FAIR_2024_SUB_CATEGORY_ALL}`);
        } else {
            navigate(`/goods/category/${categoryId}`);
        }
    }

    return (
        <Fragment>
            {category &&
            <div className={cx('cont')}>
                <div className={cx('box-first-node')}>
                    <div key={EDUTECH_KOREA_FAIR_2024_CATEGORY_ID} className={cx('btn')}
                            onClick={() => {
                                onChangeCategory(EDUTECH_KOREA_FAIR_2024_CATEGORY_ID);
                            }}
                            onMouseEnter={event => {
                                event.preventDefault();
                                event.stopPropagation();
                                setChildNode([]);
                            }}>
                        <img src="/te-statics/category/edutech_korea_fair/2024/logo-r30.png" alt='2024-edutech-fair-logo'/>
                        <div>2024에듀테크코리아페어</div>
                    </div>

                    <div className={cx('btn')}
                        onClick={() => {
                            onChangeCategory(0);
                        }}
                        onMouseEnter={event => {
                            event.preventDefault();
                            event.stopPropagation();
                            setChildNode([]);
                        }}>
                                    
                        <img
                            src="/te-statics/category/certified_product_category/certified_product_category.png"
                            alt='logo'
                        />
                        <div>{t('goods.certifiedProduct')}</div>
                    </div>

                    {firstNode.map((value, index) => {
                        return (
                            <div key={index} className={cx('btn')}
                                 onClick={() => {
                                    onChangeCategory(value.id);
                                 }}
                                 onMouseEnter={event => {
                                     event.preventDefault();
                                     event.stopPropagation();
                                     clkFirstNode(value.id);
                                 }}>
                                <img src={value.img} alt='logo'/>
                                <div>{I18nUtil.value(i18n.langCode, value.nm)}</div>
                                <ChevronRight/>
                            </div>
                        )
                    })}
                </div>

                <div className={cx('box-child-node')}>
                    {childNode.map((node, idx) => {
                        if (node.length === 0) {
                            return null;
                        }
                        return (
                            <div key={idx} className={cx('box')}>
                                {node.map((categ, categIdx) => {
                                    return (
                                        <div key={categIdx}
                                             className={cx('btn-child-node')}
                                             onClick={() => {
                                                onChangeCategory(categ.id);
                                             }}
                                             onMouseEnter={event => {
                                                 event.preventDefault();
                                                 event.stopPropagation();
                                                 clkChildNode(categ.depth, categ.id);
                                             }}>
                                            {I18nUtil.value(i18n.langCode, categ.nm)}
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            </div>}
        </Fragment>
    )
}
