import React, {ReactNode, useEffect, useState} from 'react';
import {DataUtil} from "../../../utils/data/DataUtil";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";

export const BizRefreshSession = (props: {
    children: ReactNode
}) => {
    const [showView, setShowView] = useState(true);

    useEffect(() => {
        DataUtil
            .refreshSession()
            .then(res => {
                setShowView(false);
            })
            .catch(ApolloCatch({}));
    }, [])


    return (
        <>
            {!showView && props.children}
        </>
    )
}
