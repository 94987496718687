import React from 'react';
import {CompanyData} from "../../../../graphql/customScalars";
import {BizTitle} from "../../bizTitle/BizTitle";
import {BizNoDataTable} from "../../bizNoDataTable/BizNoDataTable";
import {useTranslation} from "react-i18next";

export const BizCompanyDataViewDetail = (props: {
    companyData: CompanyData
}) => {
    const [t] = useTranslation("common");
    return (
        <>
            <BizTitle>{t('company.companyDetail')}</BizTitle>
            {!props.companyData.companyDetail && <BizNoDataTable/>}
            <div ref={ref => {
                if (ref) {
                    ref.innerHTML = props.companyData.companyDetail;
                }
            }}></div>
        </>
    )
}
