import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {App} from "./components/App";
import {createStore} from "redux";
import {FB} from "./utils/fb/FB";
import {RootReducer} from "./redux";
import './styles/common.scss'
import {Provider} from "react-redux";
import {ApolloProvider} from "@apollo/client";
import {Apollo} from "./utils/apollo/apollo";
import {LoadingView} from "./components/shares/loadingView/LoadingView";
import {Suspense} from 'react';
import {SystemAction} from "./redux/System";
import {DataUtil} from "./utils/data/DataUtil";
import {QueryParamProvider} from 'use-query-params';

export const ReduxStore =
    createStore(RootReducer,
        (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION__());


DataUtil.init();
FB.initFirebase();

// 화면 크기 조정
ReduxStore.dispatch(SystemAction.setWindowWidth(window.innerWidth));
window.addEventListener("resize", () => {
    ReduxStore.dispatch(SystemAction.setWindowWidth(window.innerWidth));
})

ReactDOM.render(
    <Suspense fallback={<LoadingView isActivate={true}/>}>
        <Provider store={ReduxStore}>
            <ApolloProvider client={Apollo.client}>
                <QueryParamProvider>
                    <App/>
                </QueryParamProvider>
            </ApolloProvider>
        </Provider>
    </Suspense>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
