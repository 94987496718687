import React, {useState} from 'react';
import classNamesBind from "classnames/bind";
import styles from './BizCompanyDataView.module.scss';
import {CompanyDataMap} from "../../../graphql/customScalars";
import {BizCompanyDataViewHeader} from "./header/BizCompanyDataViewHeader";
import {useSelector} from "react-redux";
import {I18nState} from "../../../redux/I18n";
import {CompanyDataUtil} from "../../../utils/companyDataUtil/CompanyDataUtil";
import {CompanyConsultant, CompanyLikes, Kotra} from "../../../graphql/types";
import {BizCompanyDataViewLikes} from "./likes/BizCompanyDataViewLikes";
import {BizCompanyDataViewGraph} from "./graph/BizCompanyDataViewGraph";
import {BizCompanyDataViewCatchphrase} from "./catchphrase/BizCompanyDataViewCatchphrase";
import {BizCompanyDataConsultant} from "./consultant/BizCompanyDataConsultant";
import {BizCompanyDataViewDetail} from "./detail/BizCompanyDataViewDetail";
import {YoutubeViewer} from "../youtubeViewer/YoutubeViewer";
import {I18nKeys} from '../../../i18n/i18nKeys';
import {useTranslation} from "react-i18next";
import {BizAskBoardModal} from "../bizAskBoardModal/BizAskBoardModal";
import {OnChangeValue} from "../../Intefaces";

const cx = classNamesBind.bind(styles);

interface Props {
    companyDataMap: CompanyDataMap,
    companyLikes: CompanyLikes
    kotra?: Kotra,
    onCreateChatRoomWithConsultantId?: OnChangeValue<number>,
    onCreateChatRoomWithCompanyId?: OnChangeValue<number>
    consultant?: CompanyConsultant[]
    companyUserId?: number,
}

export const BizCompanyDataView = (props: Props) => {
    const [askBoardModal, setAskBoardModal] = useState(false);

    const i18n = useSelector<any, I18nState>(state => state.i18n);
    const companyData = CompanyDataUtil.getValue(i18n.langCode, props.companyDataMap);
    const [t] = useTranslation('common');

    const handler = {
        onShowAskBoardModal: () => {
            setAskBoardModal(true);
        },

    }

    return (
        <div className={cx('cont')}>
            {props.companyUserId &&
            <BizAskBoardModal
                isShow={askBoardModal}
                onCancel={() => setAskBoardModal(false)}
                companyId={props.companyUserId}/>
            }

            <BizCompanyDataViewHeader
                {...props}
                companyData={companyData}/>

            <BizCompanyDataViewLikes
                companyLikes={props.companyLikes}/>

            <BizCompanyDataViewGraph
                companyData={companyData}/>

            <BizCompanyDataViewCatchphrase
                companyData={companyData}/>

            {props.consultant &&
            <BizCompanyDataConsultant
                {...handler}
                onCreateChatRoomWithConsultantId={props.onCreateChatRoomWithConsultantId}
                consultant={props.consultant}/>
            }

            {companyData.introduceYoutubeUrl &&
            <YoutubeViewer
                title={t(I18nKeys.company.introduceYoutube.key)}
                link={companyData.introduceYoutubeUrl}/>
            }

            <BizCompanyDataViewDetail
                companyData={companyData}/>

        </div>
    )
}
