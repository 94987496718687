import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './AuthBackdoorInput.module.scss';
import {AuthBackdoorInputData} from "../AuthBackdoor";
import {OnChangeValue, OnVoidFunction} from "../../../Intefaces";
import {CenterContents} from "../../../shares/centerContents/CenterContents";
import {useTranslation} from "react-i18next";

const cx = classNamesBind.bind(styles);

export const AuthBackdoorInput = (props: {
    userId: number,
    value: AuthBackdoorInputData,
    onChange: OnChangeValue<AuthBackdoorInputData>,
    onLogin: OnVoidFunction
}) => {
    const [t] = useTranslation('common');

    return (
        <CenterContents animatedContents>
            <form
                className={cx('form')}
                onSubmit={ev => {
                ev.preventDefault();
                props.onLogin();
            }}>
                <p>UserId : {props.userId}</p>
                <input
                    placeholder={t("auth.email")}
                    className='form-control form-control-sm'
                    type='email'
                    value={props.value.email}
                    onChange={ev => props.onChange({...props.value, email: ev.target.value})}/>

                <input
                    placeholder={t("auth.secondPassword")}
                    className='form-control form-control-sm'
                    type='password'
                    value={props.value.pw}
                    onChange={ev => props.onChange({...props.value, pw: ev.target.value})}/>
                <hr/>
                <button
                    className='btn btn-sm btn-success btn-block'>
                    {t("auth.btnLogin")}
                </button>
            </form>
        </CenterContents>
    )
}
