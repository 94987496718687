import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './NotFound.module.scss';
import {navigate} from "hookrouter";
import {useTranslation} from "react-i18next";

const cx = classNamesBind.bind(styles);

export const NotFound = () => {
    const [t] = useTranslation("common");

    return (
        <div className={cx('cont')}>
            <div className={cx('box')}>
                <h4 style={{marginBottom: 30}}>{t("swal.notFound")}</h4>
                <button
                    className='btn btn-sm btn-outline-success'
                    onClick={event => {
                        navigate('/');
                    }}>{t("swal.toHome")}</button>
            </div>
        </div>
    )
}
