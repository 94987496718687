import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './MyPageConsultantDetailView.module.scss';
import {CompanyConsultant, CompanyConsultantPermission} from "../../../../../graphql/types";
import {I18nUtil} from "../../../../../utils/i18n/i18n";
import {useSelector} from "react-redux";
import {I18nState} from "../../../../../redux/I18n";
import moment from "moment";
import {useTranslation} from "react-i18next";

const cx = classNamesBind.bind(styles);

export const MyPageConsultantDetailView = (props: {
    consultant: CompanyConsultant
}) => {
    const langCode = useSelector<any, I18nState>(state => state.i18n).langCode;
    const [t] = useTranslation('common');

    return (
        <>
            <div className={cx('table-fluid')}>
                <div className={cx('col', 'col-label')}>{t("mypageConsultant.position")}</div>
                <div className={cx('col', 'col-value',
                    props.consultant.isActivate ? '' : 'line-through',
                    props.consultant.permission.includes(CompanyConsultantPermission.Position) ? 'activate' : 'inactivate')}>
                    {I18nUtil.value(langCode, props.consultant.position)}</div>

                <div className={cx('col', 'col-label')}>{t("mypageConsultant.nm")}</div>
                <div className={cx('col', 'col-value',
                    props.consultant.isActivate ? '' : 'line-through',
                    props.consultant.permission.includes(CompanyConsultantPermission.Nm) ? 'activate' : 'inactivate')}>
                    {I18nUtil.value(langCode, props.consultant.nm)}</div>

                <div className={cx('col', 'col-label')}>{t("mypageConsultant.email")}</div>
                <div className={cx('col', 'col-value',
                    props.consultant.isActivate ? '' : 'line-through',
                    props.consultant.permission.includes(CompanyConsultantPermission.Email) ? 'activate' : 'inactivate')}>
                    {props.consultant.email}</div>

                <div className={cx('col', 'col-label')}>{t("mypageConsultant.tel")}</div>
                <div className={cx('col', 'col-value',
                    props.consultant.isActivate ? '' : 'line-through',
                    props.consultant.permission.includes(CompanyConsultantPermission.Tel) ? 'activate' : 'inactivate')}>
                    {I18nUtil.value(langCode, props.consultant.tel)}</div>

                <div className={cx('col', 'col-label')}>{t("mypageConsultant.createDate")}</div>
                <div className={cx('col', 'col-value', 'text-left')}>
                    {moment(props.consultant.createdAt).format('YY.MM.DD')}
                </div>

                <div className={cx('col', 'col-label')}>{t("mypageConsultant.state")}</div>
                <div className={cx('col', 'col-value')}>
                    {props.consultant.isActivate && t("mypageConsultant.isActivate")}
                    {!props.consultant.isActivate && t("mypageConsultant.isNotActivate")}
                </div>
            </div>
        </>
    )
}
