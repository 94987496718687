import React, {useEffect, useState} from 'react';
import styles from './MyPageAskBoardView.module.scss';
import {BizSmTitle, BizTitle} from "../../../shares/bizTitle/BizTitle";
import classNames from "classnames";
import {Button, IconButton, Tooltip} from "@material-ui/core";
import {Add, AttachFile, DeleteOutline, Edit, Launch, SpellcheckOutlined} from "@material-ui/icons";
import {gql, useMutation, useQuery} from "@apollo/client";
import {
    AskBoardReply,
    AskBoardStatus,
    ChatWriter,
    CreateAskBoardReply,
    Mutation,
    MutationCreateMyAskBoardReplyArgs,
    MutationDeleteMyAskBoardReplyArgs,
    MutationUpdateMyAskBoardContactArgs,
    MutationUpdateMyAskBoardStatusArgs,
    Query
} from "../../../../graphql/types";
import {BizNoDataTable} from "../../../shares/bizNoDataTable/BizNoDataTable";
import moment from "moment";
import {useSelector} from "react-redux";
import {I18nState} from "../../../../redux/I18n";
import {I18nUtil} from "../../../../utils/i18n/i18n";
import {GoodsDataMapUtil} from "../../../../utils/goodsDataMapUtil/goodsDataMapUtil";
import {DataUtil} from "../../../../utils/data/DataUtil";
import {navigate} from "hookrouter";
import {CopyUtil} from "../../../../utils/copyUtil/copyUtil";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import {ApolloCatch} from "../../../../utils/apollo/apolloCatch";
import {OnChangeValue} from "../../../Intefaces";
import {MultiLang} from "../../../../graphql/customScalars";
import {BoardLayout} from "../../BoardLayout";
import {useTranslation} from "react-i18next";
import {I18nKeys} from "../../../../i18n/i18nKeys";
import {BadgeAskBoardStatus} from "../../../shares/badgeAskBoardStatus/BadgeAskBoardStatus";

export const MyPageAskBoardView = (props: {
    askBoardId: number,
}) => {
    const {data, error, refetch} = useQuery<Query>(gqlData, {
        variables: props
    });
    const [reply, setReply] = useState<CreateAskBoardReply>({
        askBoardId: props.askBoardId,
        attachFiles: [],
        contents: ""
    });
    const [mutCreateReply] = useMutation<Mutation, MutationCreateMyAskBoardReplyArgs>(gqlCreateReply);
    const [mutUpdateStatus] = useMutation<Mutation, MutationUpdateMyAskBoardStatusArgs>(gqlUpdateStatus);
    const [mutDeleteReply] = useMutation<Mutation, MutationDeleteMyAskBoardReplyArgs>(gqlDeleteReply);
    const [mutUpdateContact] = useMutation<Mutation, MutationUpdateMyAskBoardContactArgs>(gqlUpdateContact);

    const langCode = useSelector<any, I18nState>(state => state.i18n).langCode;
    const [t] = useTranslation('common');

    useEffect(() => {
        setReply({
            askBoardId: props.askBoardId,
            attachFiles: [],
            contents: ""
        });
    }, [props.askBoardId]);


    const handler = {
        onAddReplyFile: () => {
            DataUtil.uploadFile().then(res => {
                const copy = CopyUtil.copyAll(reply);
                for (let file of res) {
                    copy.attachFiles.push({
                        nm: file.nm,
                        src: file.src,
                    })
                }
                setReply(copy);
            })
        },
        onCreateReply: () => {
            if (!reply.contents) {
                SwalUtil.ok({
                    msg: t(I18nKeys.board.swalBlankReplyContent.key),
                    icon: 'error'
                });
                return;
            }

            SwalUtil.ynPromise({
                msg: t(I18nKeys.board.swalCreateReply.key)
            }).then(() => {
                return mutCreateReply({
                    variables: {
                        input: reply
                    }
                })
            }).then(() => {
                return refetch();
            }).then(() => {
                SwalUtil.ok({
                    msg: t(I18nKeys.board.swalCreateReplyOk.key),
                    icon: 'success',
                });
                setReply({
                    askBoardId: props.askBoardId,
                    attachFiles: [],
                    contents: ""
                });
            }).catch(ApolloCatch({}));
        },
        onDeleteReply: (id: number) => {
            SwalUtil.ynPromise({
                msg: t(I18nKeys.board.swalDeleteReply.key)
            }).then(() => {
                return mutDeleteReply({
                    variables: {
                        askBoardReplyId: id
                    }
                })
            }).then(() => {
                return refetch()
            }).then(() => {
                SwalUtil.ok({
                    msg: t(I18nKeys.board.swalDeleteReplyOk.key),
                    icon: 'success',
                })
            }).catch(ApolloCatch({}));
        },
        onUpdateDone: () => {
            SwalUtil.ynPromise({
                msg: t(I18nKeys.board.swalUpdateDone.key)
            }).then(() => {
                return mutUpdateStatus({
                    variables: {
                        askBoardId: props.askBoardId,
                        askBoardStatus: AskBoardStatus.Done
                    }
                })
            }).then(() => {
                return refetch();
            }).then(() => {
                SwalUtil.ok({
                    msg: t(I18nKeys.board.swalUpdateDoneOk.key),
                    icon: 'success'
                })
            }).catch(ApolloCatch({}));
        },
        onNavigateGoods: (isCurrentGoods?: boolean, allEduCode?: string) => {
            if (isCurrentGoods) {
                navigate(`/goods/detail/${allEduCode}`);
            } else {
                SwalUtil.yn({
                    msg: t(I18nKeys.board.swalNavigateGoods.key),
                    icon: 'question',
                    ok: () => {
                        navigate(`/goods/detail/${allEduCode}`);
                    }
                });
            }
        },
        onChangeContact: () => {
            SwalUtil.stringPromise({
                msg: t(I18nKeys.board.swalChangeContact.key)
            }).then(contact => {
                return mutUpdateContact({
                    variables: {
                        askBoardId: props.askBoardId,
                        contact
                    }
                })
            }).then(() => {
                return refetch();
            }).then(() => {
                SwalUtil.ok({
                    msg: t(I18nKeys.board.swalChangeStatusDone.key),
                    icon: 'success'
                })
            }).catch(ApolloCatch({}));
        },
        onHistoryBack: () => {
            window.history.back();
        }
    }

    return (
        <BoardLayout
            activatedRouterSuffix={'/ask/board/list'}>
            {data &&
            <>
                <BizTitle>{t(I18nKeys.board.titleQuestion.key)} : {data.myAskBoardWithAskBoardId.title}</BizTitle>
                <div className={styles.table}>
                    <div className={classNames(styles.colLabel, styles.header)}>{t(I18nKeys.board.status.key)}</div>
                    <div
                        className={classNames(styles.colValue, styles.col)}>
                        <BadgeAskBoardStatus status={data.myAskBoardWithAskBoardId.status}/></div>
                    <div className={classNames(styles.colLabel, styles.header)}>{t(I18nKeys.board.createDate.key)}</div>
                    <div
                        className={classNames(styles.colValue, styles.col)}>{moment(data.myAskBoardWithAskBoardId.createdAt).format('YY.MM.DD - hh:mm a')}</div>
                </div>

                <div className={styles.table}>
                    <div
                        className={classNames(styles.colLabel, styles.header)}>{t(I18nKeys.board.companyName.key)}</div>
                    <div
                        className={classNames(styles.colValue, styles.col)}>
                        <div>{I18nUtil.value(langCode, data.myAskBoardWithAskBoardId.companyNm)}</div>
                    </div>
                    <div
                        className={classNames(styles.colLabel, styles.header)}>{t(I18nKeys.board.companyEmail.key)}</div>
                    <div
                        className={classNames(styles.colValue, styles.col)}>
                        <div>{data.myAskBoardWithAskBoardId.companyEmail}</div>
                    </div>

                </div>

                <div className={styles.table}>
                    <div className={classNames(styles.colLabel, styles.header)}>{t(I18nKeys.board.contact.key)}</div>
                    <div className={classNames(styles.colValue, styles.colContact)}>
                        <div className={styles.contact}>{data.myAskBoardWithAskBoardId.contact || '-'}</div>
                        <div className={styles.btnEdit}>
                            {data.myAskBoardWithAskBoardId.status === AskBoardStatus.InProcessing &&
                            <Tooltip title={t(I18nKeys.board.ttModify.key) || ""}>
                                <IconButton
                                    onClick={handler.onChangeContact}
                                    size='small'>
                                    <Edit/>
                                </IconButton>
                            </Tooltip>
                            }
                        </div>
                    </div>
                    <div
                        className={classNames(styles.colLabel, styles.header)}>{t(I18nKeys.board.productName.key)}</div>
                    <div className={classNames(styles.colValue, styles.col)}>
                        {(data.myAskBoardWithAskBoardId.allEduCode && data.myAskBoardWithAskBoardId.goodsInfoId && data.myAskBoardWithAskBoardId.goodsData) &&
                        <>
                            <div
                                className={styles.alleduCode}>{data.myAskBoardWithAskBoardId.allEduCode}-{data.myAskBoardWithAskBoardId.goodsInfoId}</div>
                            <div
                                className={styles.goodsNm}>
                                {GoodsDataMapUtil.getGoodsData(langCode, data.myAskBoardWithAskBoardId.goodsData).nm}
                                <span
                                    onClick={() => handler.onNavigateGoods(data.myAskBoardWithAskBoardId.isCurrentGoods!, data.myAskBoardWithAskBoardId.allEduCode!)}>
                                    <Tooltip title={t(I18nKeys.board.ttProductLink.key) || ""}>
                                        <Launch style={{color: '#0db3e6', fontSize: '1.2rem'}}/>
                                    </Tooltip>
                                </span>
                            </div>
                        </>
                        }

                        {/*{(data.myAskBoardWithAskBoardId.allEduCode && data.myAskBoardWithAskBoardId.goodsInfoId && data.myAskBoardWithAskBoardId.goodsData) || "-"}*/}
                    </div>
                </div>

                <div className={styles.contents}
                     ref={ref => {
                         if (ref) {
                             ref.innerHTML = data.myAskBoardWithAskBoardId.content;
                         }
                     }}/>

                {data.myAskBoardWithAskBoardId.attachFiles.length !== 0 &&
                <>
                    <div className={styles.boxAttachFile}>
                        <div className={styles.fileTitle}>{t(I18nKeys.board.attachment.key)}</div>
                        {data.myAskBoardWithAskBoardId.attachFiles.map((value, index) => (
                            <div className={styles.fileItem}
                                 key={index}>
                                <span onClick={() => DataUtil.downloadFile(value.src)}>{index + 1}. {value.nm}</span>

                            </div>
                        ))}
                    </div>
                </>
                }
                {data.myAskBoardWithAskBoardId.status === AskBoardStatus.InProcessing &&
                <div className={styles.boxFunc}>
                    <Button
                        onClick={handler.onUpdateDone}
                        startIcon={<SpellcheckOutlined/>}>{t(I18nKeys.board.statusComplete.key)}</Button>
                </div>}

                <div style={{height: 50}}/>
                <BizSmTitle>{t(I18nKeys.board.titleReply.key)} ({data.myAskBoardReplyWithAskBoardId.length})</BizSmTitle>
                {data.myAskBoardReplyWithAskBoardId.map((value, index) => (
                    <div key={index}>
                        {value.writer === ChatWriter.Company &&
                        <CompanyComment
                            companyNm={data.myAskBoardWithAskBoardId.companyNm}
                            askBoardReply={value}
                        />}
                        {value.writer === ChatWriter.User &&
                        <UserComment
                            {...handler}
                            isDone={data.myAskBoardWithAskBoardId.status === AskBoardStatus.Done}
                            userEmail={data.myAskBoardWithAskBoardId.userEmail}
                            askBoardReply={value}/>}
                    </div>
                ))}


                {data.myAskBoardWithAskBoardId.status === AskBoardStatus.InProcessing &&
                <>
                    <div className={classNames(styles.table, styles.tableReply)}>
                        <div className={styles.colLabel}>
                            <div className={styles.email}>{t(I18nKeys.board.titleReply.key)}</div>
                        </div>
                        <div className={styles.colValue}>
                        <textarea
                            value={reply.contents}
                            className="form-control"
                            onChange={ev => setReply({...reply, contents: ev.target.value})}/>
                        </div>
                        <div className={styles.colFunc}>
                            <Button
                                onClick={handler.onCreateReply}
                                startIcon={<Add/>}>
                                {t(I18nKeys.board.btnAdd.key)}
                            </Button>
                            <Button
                                onClick={handler.onAddReplyFile}
                                startIcon={<AttachFile/>}>
                                {t(I18nKeys.board.btnFile.key)}
                            </Button>
                        </div>
                    </div>
                    {reply.attachFiles.length !== 0 &&
                    <div className={classNames(styles.table, styles.tableReply)}>
                        <div className={styles.colLabel}>
                            <div className={styles.email}>{t(I18nKeys.board.attachment.key)}</div>
                        </div>
                        <div className={styles.colValue}>
                            {reply.attachFiles.map((value, index) => (
                                <div className={styles.fileItem}
                                     key={index}
                                     onClick={() => DataUtil.downloadFile(value.src)}>{index + 1}. {value.nm}</div>
                            ))}
                        </div>
                    </div>
                    }
                </>
                }

                <hr/>
                <div style={{height: 50}}/>
                <div className='text-right'>
                    <button
                        onClick={handler.onHistoryBack}
                        className='btn btn-sm btn-outline-secondary'>{t(I18nKeys.board.btnHistoryBack.key)}</button>
                </div>
                <div style={{height: 100}}/>
            </>
            }

            {error && <BizNoDataTable/>}


        </BoardLayout>
    )
}

const UserComment = (props: {
    userEmail: string,
    askBoardReply: AskBoardReply,
    onDeleteReply: OnChangeValue<number>,
    isDone: boolean,
}) => {
    const [t] = useTranslation('common');

    return (
        <div className={classNames(styles.table, styles.tableReply)}>
            <div className={styles.colLabel}>
                <div
                    className={classNames(styles.email, styles.colorBlue)}>{t(I18nKeys.board.replyWriterUser.key)}</div>
                {/*<div className={styles.email}>{t(I18nKeys.board.userUser.key)}</div>*/}
                <div
                    className={classNames(styles.companyNm)}>{moment(props.askBoardReply.createdAt).format('YY.MM.DD hh:mm a')}</div>
            </div>
            <div className={styles.colValue}>
                <div
                    className={styles.boxContents}
                    ref={ref => {
                        if (ref) {
                            ref.innerHTML = props.askBoardReply.content;
                        }
                    }}/>
                {props.askBoardReply.attachFiles.length !== 0 &&
                <>
                    <div className={styles.boxAttachFile}>
                        <div className={styles.fileTitle}>{t(I18nKeys.board.attachment.key)}</div>
                        {props.askBoardReply.attachFiles.map((value, index) => (
                            <div className={styles.fileItem}
                                 key={index}>
                                <span onClick={() => DataUtil.downloadFile(value.src)}>{index + 1}. {value.nm}</span>
                            </div>
                        ))}
                    </div>
                </>
                }
            </div>
            <div className={styles.colFunc}>
                {!props.isDone &&
                <Button
                    onClick={() => props.onDeleteReply(props.askBoardReply.id)}
                    size='small'
                    startIcon={<DeleteOutline/>}>
                    {t(I18nKeys.board.btnDelete.key)}
                </Button>
                }
            </div>
        </div>
    );
}

const CompanyComment = (props: {
    companyNm: MultiLang,
    askBoardReply: AskBoardReply,
}) => {
    const [t] = useTranslation('common');

    return (
        <div className={classNames(styles.table, styles.tableReply)}>
            <div className={styles.colLabel}>
                {/*<div className={styles.email}>{I18nUtil.value(langCode, props.companyNm)}</div>*/}
                <div className={styles.email}>{t(I18nKeys.board.replyWriterCompany.key)}</div>
                <div
                    className={styles.companyNm}>{moment(props.askBoardReply.createdAt).format('YY.MM.DD hh:mm a')}</div>
            </div>
            <div className={styles.colValue}>
                <div
                    className={styles.boxContents}
                    ref={ref => {
                        if (ref) {
                            ref.innerText = props.askBoardReply.content
                        }
                    }}/>

                {props.askBoardReply.attachFiles.length !== 0 &&
                <>
                    <div className={styles.boxAttachFile}>
                        <div className={styles.fileTitle}>{t(I18nKeys.board.attachment.key)}</div>
                        {props.askBoardReply.attachFiles.map((value, index) => (
                            <div className={styles.fileItem}>
                                <span onClick={() => DataUtil.downloadFile(value.src)}>{index + 1}. {value.nm}</span>
                            </div>
                        ))
                        }

                    </div>
                </>
                }

            </div>
        </div>
    );
}

const gqlData = gql`
    query Data($askBoardId: Int!) {
        myAskBoardWithAskBoardId(askBoardId: $askBoardId) {
            id
            allEduCode
            companyNm
            content
            createdAt
            goodsData
            goodsInfoId
            lastReplyWriter
            status
            title
            userCompanyNm
            userEmail
            attachFiles {
                nm
                src
            }
            companyEmail
            contact
            isCurrentGoods
        }
        myAskBoardReplyWithAskBoardId(askBoardId: $askBoardId) {
            id
            attachFiles {
                nm
                src
            }
            content
            createdAt
            writer
        }
    }
`;

const gqlCreateReply = gql`
    mutation CreateReply($input: CreateAskBoardReply!) {
        createMyAskBoardReply(input: $input)
    }
`;

const gqlDeleteReply = gql`
    mutation DeleteReply($askBoardReplyId: Int!) {
        deleteMyAskBoardReply(askBoardReplyId: $askBoardReplyId)
    }
`;

const gqlUpdateStatus = gql`
    mutation UpdateStatus($askBoardId: Int!, $askBoardStatus: AskBoardStatus!) {
        updateMyAskBoardStatus(askBoardId: $askBoardId, askBoardStatus: $askBoardStatus)
    }
`;

const gqlUpdateContact = gql`
    mutation UpdateContact($askBoardId: Int!, $contact: String!) {
        updateMyAskBoardContact(askBoardId: $askBoardId, contact: $contact)
    }
`;
