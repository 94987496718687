import React, {Fragment, useState} from 'react';
import styles from './ChatV1Writer.module.scss';
import {gql, useLazyQuery, useMutation} from "@apollo/client";
import {
    ChatContent,
    ChatContentOptions,
    ChatContentType,
    ChatDirection,
    ChatRoomInfo,
    ChatRoomPosition,
    ChatUser,
    Mutation,
    MutationCreateChatContentArgs,
    Query
} from "../../../../../graphql/types";
import {useDispatch, useSelector} from "react-redux";
import {I18nState} from "../../../../../redux/I18n";
import {I18nUtil} from "../../../../../utils/i18n/i18n";
import {CopyUtil} from "../../../../../utils/copyUtil/copyUtil";
import {OnChangeValue, OnVoidFunction} from "../../../../Intefaces";
import {ApolloCatch} from "../../../../../utils/apollo/apolloCatch";
import {FadeIn} from "../../../../shares/fadeIn/FadeIn";
import {UserState} from "../../../../../redux/User";
import classNames from "classnames";
import moment from "moment";
import {DataUtil} from "../../../../../utils/data/DataUtil";
import {ChatAction, ChatState} from "../../../../../redux/Chat";
import {SystemState, ViewMode} from "../../../../../redux/System";
import {useTranslation} from "react-i18next";
import {I18nKeys} from "../../../../../i18n/i18nKeys";
import { useDeepCompareEffect } from 'use-deep-compare';

export const ChatV1Writer = (props: {
    tab: string,
    chatRoomIdx: number,
}) => {
    const [contentList, setContentList] = useState<ChatContent[]>([]);
    const [chatDirection, setChatDirection] = useState<ChatDirection>(ChatDirection.Bottom);
    const [getData, resData] = useLazyQuery<Query, {
        chatIdxId: number,
        options: ChatContentOptions,
    }>(gqlData, {
        context: {
            loadingView: false,
        }
    });

    const [mutCreateContent] = useMutation<Mutation, MutationCreateChatContentArgs>(gqlCreateContent);
    const dispatch = useDispatch();
    const chatRefetch = useSelector<any, ChatState>(state => state.chat);

    const handler = {
        onCreateChatContent: (params: {
            v: string, contentType: ChatContentType
        }) => {
            if (!resData.refetch) {
                return;
            }

            if (!params.v) {
                return;
            }

            mutCreateContent({
                variables: {
                    chatIdxId: props.chatRoomIdx,
                    content: {
                        content: params.v,
                        contentType: params.contentType,
                    }
                }
            }).then(() => {
                handler.onRefetch(ChatDirection.Bottom);
                dispatch(ChatAction.chatList(true));
                dispatch(ChatAction.refetchTotalNotice(true));
            }).catch(ApolloCatch({}));
        },
        onUploadFile: () => {
            DataUtil.uploadFile().then(res => {
                let content = "";

                for (let file of res) {
                    content += `<a href='${file.src}' target="_blank">${file.nm}</a><br/>`;
                }

                handler.onCreateChatContent({
                    v: content,
                    contentType: ChatContentType.Html
                });
            })
        },
        onLoadPrev: () => {
            handler.onRefetch(ChatDirection.Top);
        },
        onRefetch: (direction: ChatDirection) => {
            if (!resData.refetch) {
                return;
            }

            setChatDirection(direction);

            resData.refetch({
                chatIdxId: props.chatRoomIdx,
                options: {
                    chatIdxId: props.chatRoomIdx,
                    existChatContentId: contentList.map(value => (value.id)),
                    loadCount: 20,
                    chatDirection: direction,
                }
            }).catch(ApolloCatch({}))
        }
    }

    useDeepCompareEffect(() => {
        setContentList([]);
        setChatDirection(ChatDirection.Bottom);
        getData({
            variables: {
                chatIdxId: props.chatRoomIdx,
                options: {
                    chatIdxId: props.chatRoomIdx,
                    existChatContentId: [],
                    loadCount: 20,
                    chatDirection: ChatDirection.Bottom,
                }
            }
        });
    }, [props.chatRoomIdx, getData]);

    useDeepCompareEffect(() => {
        if (chatRefetch.refetchChatContent) {
            handler.onRefetch(ChatDirection.Bottom);
        }
    }, [chatRefetch, handler]);

    useDeepCompareEffect(() => {
        if (!resData.data) {
            return;
        }

        if (resData.data.chatContents.length === 0) {
            return;
        }


        let copy = CopyUtil.copyAll(contentList);
        for (let chatContent of resData.data.chatContents) {
            const idx = copy.findIndex(value => {
                return value.id === chatContent.id;
            })

            if (idx === -1) {
                copy.push(chatContent);
            }
        }

        copy = copy.sort((a, b) => {
            return a.id - b.id
        });

        if (copy.length === contentList.length) {
            return;
        }

        setContentList(copy);
    }, [resData.data, contentList]);

    


    return (
        <Fragment>
            {resData.data &&
            <FadeIn>
                <Header
                    tab={props.tab}
                    chatRoomInfo={resData.data.chatRoomInfo}/>

                <Writer
                    {...handler}
                    chatUserList={resData.data.chatRoomInfo.chatUserList}
                    chatContent={contentList}
                    chatDirection={chatDirection}
                />
            </FadeIn>
            }
        </Fragment>
    )
}

const Writer = (props: {
    chatContent: ChatContent[],
    chatUserList: ChatUser[],
    onCreateChatContent: OnChangeValue<{ v: string, contentType: ChatContentType }>,
    chatDirection: ChatDirection,
    onLoadPrev: OnVoidFunction,
    onUploadFile: OnVoidFunction,
}) => {
    const [content, setContent] = useState("");
    const [contentRef, setContentRef] = useState<HTMLDivElement | null>(null);
    const [t] = useTranslation('common');

    useDeepCompareEffect(() => {
        if (!contentRef) {
            return;
        }

        switch (props.chatDirection) {
            case ChatDirection.Top :
                contentRef.scrollTo(0, 0);
                break;
            case ChatDirection.Bottom:
                contentRef.scrollTo(0, contentRef.scrollHeight);
                break;
        }
    }, [props.chatContent, contentRef]);

    return (
        <div className={styles.contWriter}>
            <div ref={ref => setContentRef(ref)} className={styles.content}>

                <button
                    onClick={props.onLoadPrev}
                    className='btn btn-sm btn-outline-secondary btn-block'>
                    {t(I18nKeys.chat.loadPrevChat.key)}
                </button>

                {props.chatContent.map((value, index) => {
                    let hasDayDivider = false;
                    if (index !== 0) {
                        const nowDay = moment(props.chatContent[index].createdAt).day();
                        const nextDay = moment(props.chatContent[index - 1].createdAt).day();
                        if (nowDay !== nextDay) {
                            hasDayDivider = true;
                        }
                    } else if (index === 0) {
                        hasDayDivider = true;
                    }

                    return (
                        <ChatBubble
                            hasDayDivider={hasDayDivider}
                            key={index}
                            chatContent={value}
                            chatUserList={props.chatUserList}/>
                    )
                })}
            </div>

            <form className={styles.inputChat} onSubmit={ev => {
                ev.preventDefault();
                props.onCreateChatContent({
                    v: content,
                    contentType: ChatContentType.String,
                });
                setContent("");
            }}>
                <input
                    value={content}
                    onChange={ev => setContent(ev.target.value)}
                    className='form-control form-control-sm'/>
                <button
                    className='btn btn-sm btn-success'>{t(I18nKeys.chat.btnSend.key)}
                </button>

                <button
                    onClick={props.onUploadFile}
                    type='button'
                    className='btn btn-sm btn-outline-success'>{t(I18nKeys.chat.btnFile.key)}
                </button>
            </form>
        </div>
    )
}

const ChatBubble = (props: {
    hasDayDivider: boolean,
    chatContent: ChatContent,
    chatUserList: ChatUser[],
}) => {
    const writerUser = useSelector<any, UserState>(state => state.user);
    const [t] = useTranslation('common');
    const contentWriter = props.chatUserList.find(value => {
        return props.chatContent.userId === value.userId
    });

    return (
        <>
            {props.hasDayDivider &&
            <div className={styles.contDayDivider}>
                <div className={styles.line}/>
                <div className={styles.day}>{moment(props.chatContent.createdAt).format('YYYY.MM.DD')}</div>
                <div className={styles.line}/>
            </div>
            }

            <div className={classNames(
                styles.contChatBubble,
                writerUser.user.id === props.chatContent.userId ? styles.right : styles.left
            )}>
                <div className={classNames(
                    styles.defStyle,
                    writerUser.user.id === props.chatContent.userId ? styles.writer : styles.party)
                }>

                    <div className={styles.chatWriter}>
                        {contentWriter &&
                        <>
                            {contentWriter.role === ChatRoomPosition.User && t(I18nKeys.chat.chatRoleUser.key)}
                            {contentWriter.role === ChatRoomPosition.Company && t(I18nKeys.chat.chatRoleCompany.key)}
                            {contentWriter.role === ChatRoomPosition.Consultant && t(I18nKeys.chat.chatRoleConsultant.key)}
                        </>
                        }

                        <span className={styles.createdAt}>
                            {moment(props.chatContent.createdAt).format('hh:mm a')}
                        </span>
                    </div>


                    {props.chatContent.chatContentType === ChatContentType.String &&
                    <div className={styles.chatContent}>{props.chatContent.content}</div>
                    }

                    {props.chatContent.chatContentType === ChatContentType.Html &&
                    <div className={styles.chatContent}
                         ref={ref => {
                             if (ref) {
                                 ref.innerHTML = props.chatContent.content;
                             }
                         }}
                    />
                    }


                </div>
            </div>
        </>
    )
}


const Header = (props: {
    chatRoomInfo: ChatRoomInfo,
    tab: string,
}) => {
    const [t] = useTranslation('common');
    const system = useSelector<any, SystemState>(state => state.system);
    const langCode = useSelector<any, I18nState>(state => state.i18n).langCode;
    const userImg = I18nUtil.value(langCode, props.chatRoomInfo.user.imgSrc);
    let boxWidth = '50%';

    if (props.chatRoomInfo.goods) {
        boxWidth = '30%';
    }

    if (system.viewMode === ViewMode.Mobile) {
        boxWidth = '100%';
    }

    return (
        <>
            {props.tab === 'user' &&
            <div className={styles.contInfo}>
                <div className={styles.boxInfo} style={{width: boxWidth}}>
                    <div className={styles.img}>
                        <img src={I18nUtil.value(langCode, props.chatRoomInfo.company.imgSrc)} alt='logo'/>
                    </div>
                    <div className={styles.info}>
                        <div className={styles.title}>{t(I18nKeys.chat.replyCompany.key)}</div>
                        <div className={styles.email}>{I18nUtil.value(langCode, props.chatRoomInfo.company.nm)}</div>
                    </div>
                </div>
                {props.chatRoomInfo.goods &&
                <div className={styles.boxInfo} style={{width: boxWidth}}>
                    <div className={styles.img}>
                        <img src={I18nUtil.value(langCode, props.chatRoomInfo.goods.imgSrc)} alt='logo'/>
                    </div>
                    <div className={styles.info}>
                        <div className={styles.title}>{t(I18nKeys.chat.goods.key)}</div>
                        <div className={styles.email}>{I18nUtil.value(langCode, props.chatRoomInfo.goods.nm)}</div>
                    </div>
                </div>

                }
                <div className={styles.boxInfo} style={{width: boxWidth}}>
                    {userImg &&
                    <div className={styles.img}>
                        <img src={userImg} alt='logo'/>
                    </div>
                    }

                    <div className={styles.info}>
                        <div className={styles.title}>{t(I18nKeys.chat.askUser.key)}</div>
                        <div className={styles.email}>{props.chatRoomInfo.user.email}</div>
                        <div className={styles.companyNm}>{I18nUtil.value(langCode, props.chatRoomInfo.user.nm)}</div>
                    </div>
                </div>
            </div>
            }

            {props.tab === 'consult' &&
            <div className={styles.contInfo}>
                <div className={styles.boxInfo} style={{width: boxWidth}}>
                    {userImg &&
                    <div className={styles.img}>
                        <img src={userImg} alt='logo'/>
                    </div>
                    }

                    <div className={styles.info}>
                        <div className={styles.title}>{t(I18nKeys.chat.askUser.key)}</div>
                        <div className={styles.email}>{props.chatRoomInfo.user.email}</div>
                        <div className={styles.companyNm}>{I18nUtil.value(langCode, props.chatRoomInfo.user.nm)}</div>
                    </div>
                </div>
                {props.chatRoomInfo.goods &&
                <div className={styles.boxInfo} style={{width: boxWidth}}>
                    <div className={styles.img}>
                        <img src={I18nUtil.value(langCode, props.chatRoomInfo.goods.imgSrc)} alt='logo'/>
                    </div>
                    <div className={styles.info}>
                        <div className={styles.title}>{t(I18nKeys.chat.goods.key)}</div>
                        <div className={styles.email}>{I18nUtil.value(langCode, props.chatRoomInfo.goods.nm)}</div>
                    </div>
                </div>
                }
                <div className={styles.boxInfo} style={{width: boxWidth}}>
                    <div className={styles.img}>
                        <img src={I18nUtil.value(langCode, props.chatRoomInfo.company.imgSrc)} alt='logo'/>
                    </div>
                    <div className={styles.info}>
                        <div className={styles.title}>{t(I18nKeys.chat.replyCompany.key)}</div>
                        <div className={styles.email}>{I18nUtil.value(langCode, props.chatRoomInfo.company.nm)}</div>
                    </div>
                </div>
            </div>
            }
        </>
    )
}

const gqlCreateContent = gql`
    mutation Create ($chatIdxId: Int!, $content: CreateChatContent!){
        createChatContent(chatIdxId: $chatIdxId, content: $content)
    }
`;


const gqlData = gql`
    query GetData($chatIdxId: Int!, $options: ChatContentOptions!) {
        chatRoomInfo(chatIdxId: $chatIdxId) {
            id
            chatUserList {
                userId
                email
                imgSrc
                nm
                role
            }
            user {
                userId
                nm
                email
                imgSrc
            }
            company {
                nm
                imgSrc
                companyId
            }
            goods {
                nm
                imgSrc
                goodsId
                goodsInfoId
            }
        }
        chatContents(options: $options) {
            id
            userId
            content
            chatContentType
            createdAt
        }
    }
`;
