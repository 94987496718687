import React, {Fragment} from 'react';
import classNamesBind from "classnames/bind";
import styles from './MyPageCompanyCreateOtherInput.module.scss';
import {CompanyData} from "../../../../../graphql/customScalars";
import {OnChangeValue} from "../../../../Intefaces";
import {CopyUtil} from "../../../../../utils/copyUtil/copyUtil";
import {InputText} from "../../../share/inputs/inputText/InputText";
import {useTranslation} from "react-i18next";
import {InputLogo} from "../../../share/inputs/inputLogo/InputLogo";

const cx = classNamesBind.bind(styles);

interface Props {
    input: CompanyData,
    onChangeInput: OnChangeValue<CompanyData>
}

export const MyPageCompanyCreateOtherInput = (props: Props) => {
    return (
        <Fragment>
            <div className='web-view'><Web {...props}/></div>
            <div className='mobile-view'><Mobile {...props}/></div>
        </Fragment>
    )
}

const Web = (props: Props) => {
    const [t] = useTranslation('common');

    return (
        <>
            <div className={cx('cont')}>
                <div className={cx('box', 'sm')}>
                    <InputLogo {...props}/>
                </div>

                <div className={cx('box', 'sm')}>
                    <InputText
                        title={t('mypageJoin.ceo')}
                        value={props.input.ceo}
                        onChange={v => {
                            const copy = CopyUtil.copyAll(props.input);
                            copy.ceo = v;
                            props.onChangeInput(copy);
                        }}/>
                </div>

                <div className={cx('box', 'sm')}>
                    <InputText
                        title={t('mypageJoin.nm')}
                        value={props.input.companyNm}
                        onChange={v => {
                            const copy = CopyUtil.copyAll(props.input);
                            copy.companyNm = v;
                            props.onChangeInput(copy);
                        }}/>
                </div>

                <div className={cx('box', 'sm')}>
                    <InputText
                        title={t('mypageJoin.category')}
                        value={props.input.category}
                        onChange={v => {
                            const copy = CopyUtil.copyAll(props.input);
                            copy.category = v;
                            props.onChangeInput(copy);
                        }}/>
                </div>

                <div className={cx('box', 'sm')}>
                    <InputText
                        title={t('mypageJoin.addr')}
                        value={props.input.addr}
                        onChange={v => {
                            const copy = CopyUtil.copyAll(props.input);
                            copy.addr = v;
                            props.onChangeInput(copy);
                        }}/>
                </div>

                <div className={cx('box', 'sm')}>
                    <InputText
                        title={t('mypageJoin.catchphrase')}
                        value={props.input.catchphrase}
                        onChange={v => {
                            const copy = CopyUtil.copyAll(props.input);
                            copy.catchphrase = v;
                            props.onChangeInput(copy);
                        }}/>
                </div>

                <div className={cx('box', 'sm')}>
                    <InputText
                        title={t('mypageJoin.hp')}
                        value={props.input.hp}
                        onChange={v => {
                            const copy = CopyUtil.copyAll(props.input);
                            copy.hp = v;
                            props.onChangeInput(copy);
                        }}/>
                </div>

                <div className={cx('box', 'sm')}>
                    <InputText
                        title={t('mypageJoin.introduceYoutubeUrl')}
                        value={props.input.introduceYoutubeUrl}
                        onChange={v => {
                            const copy = CopyUtil.copyAll(props.input);
                            copy.introduceYoutubeUrl = v;
                            props.onChangeInput(copy);
                        }}/>
                </div>
            </div>
        </>
    )
}

const Mobile = (props: Props) => {
    return (
        <><Web {...props}/></>
    )
}
