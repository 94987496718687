import React, {ReactNode, useCallback, useEffect} from 'react';
import {useSelector} from "react-redux";
import {UserState} from "../../../redux/User";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {navigate} from "hookrouter";
import {useTranslation} from "react-i18next";

export const BizCheckUserInfo = (props: {
    children?: ReactNode;
}) => {
    const user = useSelector<any, UserState>(state => state.user);
    const [t] = useTranslation('common');

    const showNoneUserInfoMsgBox = useCallback(() => {
        SwalUtil.ok({
            msg: t("mypage.swalHaveNotUserInfo"),
            icon: "warning",
        });
    }, [t]);
    
    useEffect(() => {
        if ((user.isLogin) && (!user.user.hasUserInfo)) {
            showNoneUserInfoMsgBox();
            navigate('/mypage/info');
        }
    }, [user, props.children, showNoneUserInfoMsgBox]);

    return (
        <>
            {props.children}
        </>
    )
}
