import React, {Fragment, useEffect} from 'react';
import {ChatWriter} from "../../../graphql/types";
import {navigate} from "hookrouter";
import {BizBackdrop} from "../../shares/bizBackdrop/BizBackdrop";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {DataUtil} from "../../../utils/data/DataUtil";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../graphql/errors";

export const AskBoardOutLink = (props: {
    chatWriter: ChatWriter,
    askBoardId: number,
}) => {
    useEffect(() => {
        DataUtil
            .refreshSession()
            .then(user => {
                switch (props.chatWriter) {
                    case ChatWriter.User:
                        navigate(`/ask/board/view/${props.askBoardId}`);
                        break;
                    case ChatWriter.Company:
                        navigate(`/ask/company/board/view/${props.askBoardId}`);
                        break;
                    default:
                        SwalUtil.ok({
                            msg: '찾을수 없는 게시글 입니다.',
                            icon: 'error'
                        });
                        navigate('/');
                }
        }).catch(ApolloCatch({
            [BizErrors.needLogin]: () => {
                debugger;
                navigate('/auth/login');
            }
        }))
    }, [props.chatWriter, props.askBoardId]);

    return (
        <Fragment>
            <BizBackdrop loading={true}/>
        </Fragment>
    )
}
