import React, {ReactNode} from 'react';
import {animated, useSpring} from "react-spring";

export const FadeIn = (props: {
    children: ReactNode,
    defaultTopMargin?: boolean
}) => {
    const [contProps, setContProps] = useSpring(() => ({opacity: 0}));
    setContProps({opacity: 1})

    return (
        <animated.div style={contProps}>
            {props.defaultTopMargin && <div style={{height: 30}}/>}
            {props.children}
        </animated.div>
    )
}
