import React, {ReactNode} from 'react';
import classNamesBind from "classnames/bind";
import styles from './InputText.module.scss';
import {OnChangeValue} from "../../../../Intefaces";

const cx = classNamesBind.bind(styles);

export const InputText = (props: {
    title: ReactNode,
    value: string,
    onChange: OnChangeValue<string>,
    pattern?: RegExp,
    exceptPattern?: string,
}) => {


    return (
        <>
            <div className={cx('title')}>{props.title}</div>
            <div className={cx('box')}>
                <div className={cx('label')}/>
                <input
                    className='form-control form-control-sm'
                    type='text'
                    value={props.value}
                    onChange={event => {
                        if (event.target.value === "") {
                            props.onChange("");
                            return;
                        }

                        if (props.pattern) {
                            const regex = new RegExp(props.pattern);
                            if (regex.test(event.target.value)) {
                                props.onChange(event.target.value);
                            }
                        } else {
                            props.onChange(event.target.value);
                        }
                    }}/>
            </div>
        </>

    )
}
