import React, {Fragment} from 'react';
import classNamesBind from "classnames/bind";
import styles from './BizCompanyDataViewCatchphrase.module.scss';
import {ViewSelector} from '../../viewSelector/ViewSelector';
import {CompanyData} from "../../../../graphql/customScalars";
import {useTranslation} from "react-i18next";

const cx = classNamesBind.bind(styles);

interface Props {
    companyData: CompanyData
}

export const BizCompanyDataViewCatchphrase = (props: Props) => {
    return (
        <Fragment>
            <ViewSelector
                web={<Web {...props}/>}
                mobile={<Mobile {...props}/>}/>
        </Fragment>
    )
}

const Web = (props: Props) => {
    const [t] = useTranslation('common');

    return (
        <div className={cx('cont')}>
            <h5>{t('company.catchphrase')}</h5>
            <p ref={ref => {
                if (ref) {
                    ref.innerText = props.companyData.catchphrase
                }
            }}/>
        </div>
    )
}

const Mobile = (props: Props) => {
    return (
        <><Web {...props}/></>
    )
}
