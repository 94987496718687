import {GoodsDataMap, MultiLang, CompanyDataMap} from './customScalars'
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Time: Date;
  GoodsDataMap: GoodsDataMap;
  AllEduCode: string;
  Map: any;
  CertificateInfo: any;
  Upload: any;
  IdList: number[];
  Decimal: number;
  MultiLang: MultiLang;
  CompanyDataMap: CompanyDataMap;
};

export type AskBoardFile = {
  __typename?: 'AskBoardFile';
  id: Scalars['Int'];
  nm: Scalars['String'];
  src: Scalars['String'];
};

export type CertificateInfoInput = {
  businessLicense?: Maybe<Scalars['Upload']>;
  financialStatements?: Maybe<Scalars['Upload']>;
};

export type I18nContents = {
  __typename?: 'I18nContents';
  id: Scalars['Int'];
  mainKey: Scalars['String'];
  subKey: Scalars['String'];
  contents: Scalars['MultiLang'];
};

export type InvitationCompanyConsultant = {
  __typename?: 'InvitationCompanyConsultant';
  id: Scalars['Int'];
  userEmail: Scalars['String'];
  createdAt: Scalars['Time'];
};

export type NewsletterList = {
  __typename?: 'NewsletterList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<Newsletter>;
};

export type ConsultantMyPage = {
  __typename?: 'ConsultantMyPage';
  userId: Scalars['Int'];
  companyId: Scalars['Int'];
};

export type DefaultList = {
  __typename?: 'DefaultList';
  listPage: ListPage;
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<Goods>;
};

export type BidAuctionGoods = {
  auctionId: Scalars['Int'];
  goodsId: Scalars['Int'];
  bid: Scalars['Int'];
};

export type AuctionGoodsReceipt = {
  __typename?: 'AuctionGoodsReceipt';
  id: Scalars['Int'];
  status: AuctionStatus;
  bid: Scalars['Int'];
  auctionGoods: AuctionGoods;
  createdAt: Scalars['Time'];
};

export type UpdateGoodsRetry = {
  goodsUpdateRequestId: Scalars['Int'];
  categoryId: Scalars['Int'];
  data: Scalars['GoodsDataMap'];
};

export enum IndexDataMode {
  Goods = 'Goods',
  Image = 'Image'
}

export type ChatRoomList = {
  __typename?: 'ChatRoomList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<ChatRoom>;
};

export type QnaReply = {
  __typename?: 'QnaReply';
  id: Scalars['Int'];
  writer: ChatWriter;
  adminEmail: Scalars['String'];
  adminNickname: Scalars['String'];
  contents: Scalars['String'];
  createdAt: Scalars['Time'];
};

export type IndexGoods = {
  __typename?: 'IndexGoods';
  indexIdxId: Scalars['Int'];
  order: Scalars['Int'];
  id: Scalars['Int'];
  nm: Scalars['MultiLang'];
  allEduCode: Scalars['String'];
  isActivate: Scalars['Boolean'];
  companyUserId: Scalars['Int'];
  categoryId: Scalars['Int'];
  paidBalance: Scalars['Decimal'];
  isCertificated: Scalars['String'];
  imageSrc: Scalars['MultiLang'];
  catchphrase: Scalars['MultiLang'];
  price: Scalars['MultiLang'];
  noPrice: Scalars['MultiLang'];
  priceUnit: Scalars['MultiLang'];
};

export enum IndexImageViewMode {
  Half = 'Half',
  Third = 'Third',
  Quarter = 'Quarter'
}

export type AskBoardReply = {
  __typename?: 'AskBoardReply';
  id: Scalars['Int'];
  writer: ChatWriter;
  content: Scalars['String'];
  attachFiles: Array<AskBoardFile>;
  createdAt: Scalars['Time'];
};


export type AuctionGoodsReceiptList = {
  __typename?: 'AuctionGoodsReceiptList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<AuctionGoodsReceipt>;
};


export type IndexIdx = {
  __typename?: 'IndexIdx';
  id: Scalars['Int'];
  nm: Scalars['MultiLang'];
  dataMode: IndexDataMode;
  order: Scalars['Int'];
  goodsViewMode?: Maybe<IndexGoodsViewMode>;
  imageViewMode?: Maybe<IndexImageViewMode>;
  goodsAutoGenerator: Scalars['Int'];
};

export type Region = {
  __typename?: 'Region';
  id: Scalars['Int'];
  region: Scalars['String'];
};

export type CompanyLikes = {
  __typename?: 'CompanyLikes';
  views: Scalars['Int'];
  likes: Scalars['Int'];
  goodsLikes: Scalars['Int'];
};

export enum CompanyUpdateStatus {
  Requested = 'Requested',
  Accepted = 'Accepted',
  Denied = 'Denied',
  Cancel = 'Cancel',
  Done = 'Done'
}

export type FaqList = {
  __typename?: 'FaqList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<Faq>;
};

export type CreateChatContent = {
  content: Scalars['String'];
  contentType: ChatContentType;
};

export type ChatUser = {
  __typename?: 'ChatUser';
  userId: Scalars['Int'];
  email: Scalars['String'];
  nm: Scalars['MultiLang'];
  imgSrc: Scalars['MultiLang'];
  role: ChatRoomPosition;
};

export type BoundConsultant = {
  __typename?: 'BoundConsultant';
  langCode: Scalars['String'];
  consultant: CompanyConsultant;
};

export type QnaList = {
  __typename?: 'QnaList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<Qna>;
};

export type CompanyConsultViews = {
  __typename?: 'CompanyConsultViews';
  date: Scalars['Time'];
  view: Scalars['Int'];
};

export type AuctionCompany = {
  __typename?: 'AuctionCompany';
  id: Scalars['Int'];
  minBid: Scalars['Int'];
  dueAt: Scalars['Time'];
  endAt: Scalars['Time'];
  isBid: Scalars['Boolean'];
};

export type Init = {
  __typename?: 'Init';
  fbKey: Scalars['String'];
};

export type ChatRoom = {
  __typename?: 'ChatRoom';
  id: Scalars['Int'];
  notice: Scalars['Int'];
  nm: Scalars['MultiLang'];
  imgSrc: Scalars['MultiLang'];
  lastChatContent: Scalars['String'];
  updatedAt: Scalars['Time'];
};

export type CreateGoodsRetry = {
  goodsCreateRequestId: Scalars['Int'];
  categoryId: Scalars['Int'];
  data: Scalars['GoodsDataMap'];
};

export enum GoodsCreateRequestStatus {
  Requested = 'Requested',
  AdminConfirm = 'AdminConfirm',
  AdminCancel = 'AdminCancel',
  UserCancel = 'UserCancel',
  Done = 'Done'
}

export type CreateAskBoard = {
  title: Scalars['String'];
  companyId: Scalars['Int'];
  contents: Scalars['String'];
  attachFiles: Array<CreateAskBoardFile>;
  contact: Scalars['String'];
  goodsId?: Maybe<Scalars['Int']>;
  goodsInfoId?: Maybe<Scalars['Int']>;
};

export enum AskBoardStatus {
  InProcessing = 'InProcessing',
  Done = 'Done'
}

export type BidAuctionCompany = {
  id: Scalars['Int'];
  bid: Scalars['Int'];
};

export type GoodsList = {
  __typename?: 'GoodsList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<Goods>;
};

export type InvitationForRegisterConsultant = {
  __typename?: 'InvitationForRegisterConsultant';
  id: Scalars['Int'];
  companyId: Scalars['Int'];
  companyData: Scalars['CompanyDataMap'];
};

export type IndexCarousel = {
  __typename?: 'IndexCarousel';
  src: Scalars['String'];
  order: Scalars['Int'];
  viewMode: ViewMode;
  langCode: Scalars['String'];
  link?: Maybe<Scalars['String']>;
};


export type ChatContent = {
  __typename?: 'ChatContent';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  chatContentType: ChatContentType;
  content: Scalars['String'];
  createdAt: Scalars['Time'];
};

export type CompanyUpdateRequest = {
  __typename?: 'CompanyUpdateRequest';
  id: Scalars['Int'];
  memo: Scalars['String'];
  status: CompanyUpdateStatus;
  prevCompany: Scalars['CompanyDataMap'];
  nextCompany: Scalars['CompanyDataMap'];
  createdAt: Scalars['Time'];
};

export type CompanyConsultantStatics = {
  __typename?: 'CompanyConsultantStatics';
  views: Array<CompanyConsultViews>;
};

export type BoardFile = {
  __typename?: 'BoardFile';
  fileNm: Scalars['String'];
  fileSrc: Scalars['String'];
};

export type CreateGoods = {
  categoryId: Scalars['Int'];
  data: Scalars['GoodsDataMap'];
};

export type ChatContentOptions = {
  chatIdxId: Scalars['Int'];
  existChatContentId: Array<Scalars['Int']>;
  chatDirection: ChatDirection;
  loadCount: Scalars['Int'];
};

export type ChatCompany = {
  __typename?: 'ChatCompany';
  companyId: Scalars['Int'];
  nm: Scalars['MultiLang'];
  imgSrc: Scalars['MultiLang'];
};

export type CompanyConsultantList = {
  __typename?: 'CompanyConsultantList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<CompanyConsultant>;
};

export type GoodsCreateRequest = {
  __typename?: 'GoodsCreateRequest';
  id: Scalars['Int'];
  goodsId?: Maybe<Scalars['Int']>;
  chargedPoint: Scalars['Decimal'];
  categoryId: Scalars['Int'];
  goods: Goods;
  status: GoodsCreateRequestStatus;
  data: Scalars['GoodsDataMap'];
  memo: Scalars['String'];
  createdAt: Scalars['Time'];
};

export type CreateAskBoardReply = {
  askBoardId: Scalars['Int'];
  contents: Scalars['String'];
  attachFiles: Array<CreateAskBoardFile>;
};

export type AskBoard = {
  __typename?: 'AskBoard';
  id: Scalars['Int'];
  status: AskBoardStatus;
  title: Scalars['String'];
  userEmail: Scalars['String'];
  userCompanyNm?: Maybe<Scalars['MultiLang']>;
  companyNm: Scalars['MultiLang'];
  content: Scalars['String'];
  createdAt: Scalars['Time'];
  lastReplyWriter: ChatWriter;
  attachFiles: Array<AskBoardFile>;
  companyEmail: Scalars['String'];
  contact: Scalars['String'];
  goodsId?: Maybe<Scalars['Int']>;
  goodsData?: Maybe<Scalars['GoodsDataMap']>;
  goodsInfoId?: Maybe<Scalars['Int']>;
  allEduCode?: Maybe<Scalars['String']>;
  isCurrentGoods?: Maybe<Scalars['Boolean']>;
};


export type MyGoodsHistory = {
  __typename?: 'MyGoodsHistory';
  goodsInfoId: Scalars['Int'];
  goodsData: Scalars['GoodsDataMap'];
  categoryId: Scalars['Int'];
  createdAt: Scalars['Time'];
};

export enum GoodsUpdateRequestStatus {
  Requested = 'Requested',
  AdminConfirm = 'AdminConfirm',
  AdminCancel = 'AdminCancel',
  UserCancel = 'UserCancel',
  Done = 'Done'
}

export type MyPageProcess = {
  __typename?: 'MyPageProcess';
  registerUserInfo: Scalars['Boolean'];
  registerCompany: Scalars['Boolean'];
  registerConsultant: Scalars['Boolean'];
  registerGoods: Scalars['Boolean'];
};

export enum UserType {
  User = 'User',
  Company = 'Company',
  Consultant = 'Consultant'
}

export type InputCompanyConsultant = {
  email: Scalars['String'];
  nm: Scalars['MultiLang'];
  tel: Scalars['MultiLang'];
  position: Scalars['MultiLang'];
  permission: Array<CompanyConsultantPermission>;
};

export type CompanyUpdateRequestList = {
  __typename?: 'CompanyUpdateRequestList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<CompanyUpdateRequest>;
};

export type Qna = {
  __typename?: 'Qna';
  id: Scalars['Int'];
  isOpened: Scalars['Boolean'];
  title: Scalars['String'];
  contents: Scalars['String'];
  createdAt: Scalars['Time'];
};

export type MyGoods = {
  __typename?: 'MyGoods';
  id: Scalars['Int'];
  allEduCode: Scalars['AllEduCode'];
  goodsInfoId: Scalars['Int'];
  isActivate: Scalars['Boolean'];
  companyUserId: Scalars['Int'];
  categoryId: Scalars['Int'];
  goodsPermission: Array<GoodsPermission>;
  paidBalance: Scalars['Decimal'];
  goodsData: Scalars['GoodsDataMap'];
  companyData: Scalars['CompanyDataMap'];
  history: Array<MyGoodsHistory>;
  consultant: Array<BoundConsultant>;
  productWarranty: Scalars['String'];
  isCertificated: Scalars['String'];
  certificateBeginDate?: Maybe<Scalars['Time']>;
  certificateEndDate?: Maybe<Scalars['Time']>;
  createdAt: Scalars['Time'];
};

export enum ChatDirection {
  Top = 'Top',
  Bottom = 'Bottom'
}

export type Category = {
  __typename?: 'Category';
  id: Scalars['Int'];
  img: Scalars['String'];
  depth: Scalars['Int'];
  nm: Scalars['MultiLang'];
  order: Scalars['Int'];
  parentId: Scalars['Int'];
  superId: Array<Scalars['Int']>;
};

export type CreateAskBoardFile = {
  nm: Scalars['String'];
  src: Scalars['String'];
};

export type AuctionGoods = {
  __typename?: 'AuctionGoods';
  id: Scalars['Int'];
  minBid: Scalars['Int'];
  dueAt: Scalars['Time'];
  endAt: Scalars['Time'];
  hasBidGoods: Scalars['Int'];
};


export enum ChatContentType {
  String = 'String',
  Html = 'Html'
}

export type CompanyConsultant = {
  __typename?: 'CompanyConsultant';
  id: Scalars['Int'];
  isActivate: Scalars['Boolean'];
  nm: Scalars['MultiLang'];
  email: Scalars['String'];
  tel: Scalars['MultiLang'];
  position: Scalars['MultiLang'];
  totalView: Scalars['Int'];
  permission: Array<CompanyConsultantPermission>;
  isRegisteredAccount: Scalars['Boolean'];
  createdAt: Scalars['Time'];
};

export type Notice = {
  __typename?: 'Notice';
  id: Scalars['Int'];
  title: Scalars['String'];
  contents: Scalars['String'];
  views: Scalars['Int'];
  fileList: Array<BoardFile>;
  createdAt: Scalars['Time'];
  updatedAt: Scalars['Time'];
};

export type EdutechKoreaFair2024Area = {
  __typename?: 'EdutechKoreaFair2024Area';
  categoryName: Scalars['String'];
  boothNumber: Scalars['String'];
};

export type UploadedFile = {
  __typename?: 'UploadedFile';
  id: Scalars['Int'];
  nm: Scalars['String'];
  src: Scalars['String'];
  createdAt: Scalars['Time'];
};


export type ChatRoomInfo = {
  __typename?: 'ChatRoomInfo';
  id: Scalars['Int'];
  chatUserList: Array<ChatUser>;
  user: ChatUser;
  company: ChatCompany;
  goods?: Maybe<ChatGoods>;
  createdAt: Scalars['Time'];
};

export type Kotra = {
  __typename?: 'Kotra';
  nationCode: Scalars['String'];
  data: Scalars['MultiLang'];
};

export enum CompanyConsultantPermission {
  Nm = 'Nm',
  Email = 'Email',
  Tel = 'Tel',
  Position = 'Position'
}

export type NoticeList = {
  __typename?: 'NoticeList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<Notice>;
};

export type MyGoodsWithConsultantList = {
  __typename?: 'MyGoodsWithConsultantList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<MyGoodsWithConsultant>;
};

export type MyGoodsWithConsultant = {
  __typename?: 'MyGoodsWithConsultant';
  langCode: Scalars['String'];
  goods: Goods;
};

export enum ChatWriter {
  Admin = 'Admin',
  User = 'User',
  Company = 'Company',
  Consultant = 'Consultant',
  None = 'None'
}

export type IndexImage = {
  __typename?: 'IndexImage';
  indexIdxId: Scalars['Int'];
  link: Scalars['String'];
  src: Scalars['String'];
  order: Scalars['Int'];
};

export type LangCode = {
  __typename?: 'LangCode';
  code: Scalars['String'];
  nm: Scalars['String'];
};

export enum AuctionStatus {
  Bid = 'BID',
  Canceled = 'CANCELED',
  Applied = 'APPLIED'
}

export type ChatRoomFindOptions = {
  findWord?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['Int']>;
  goodsId?: Maybe<Scalars['Int']>;
  goodsInfoId?: Maybe<Scalars['Int']>;
};

export enum CompanyCreateStatus {
  Requested = 'Requested',
  Accepted = 'Accepted',
  Denied = 'Denied',
  Cancel = 'Cancel',
  Done = 'Done'
}

export type EdutechKoreaFair2024Company = {
  __typename?: 'EdutechKoreaFair2024Company';
  id: Scalars['Int'];
  categoryId: Scalars['Int'];
  isPaid: Scalars['Boolean'];
  companyData: Scalars['CompanyDataMap'];
};

export enum GoodsPermission {
  Update = 'Update',
  Display = 'Display',
  UpdateWait = 'UpdateWait'
}

export enum ViewMode {
  Web = 'Web',
  Mobile = 'Mobile'
}


export type Query = {
  __typename?: 'Query';
  user: User;
  userInfo?: Maybe<UserInfo>;
  init: Init;
  langCode: Array<LangCode>;
  nationCode: Array<NationCode>;
  category: Array<Category>;
  companyType: Array<CompanyType>;
  region: Array<Region>;
  priceUnit: Array<Scalars['String']>;
  i18n: Array<I18nContents>;
  myAskBoardInProcessing: Array<AskBoard>;
  myAskBoardDone: AskBoardList;
  myAskBoardWithAskBoardId: AskBoard;
  myAskBoardReplyWithAskBoardId: Array<AskBoardReply>;
  companyAskBoardInProcessing: Array<AskBoard>;
  companyAskBoardInDone: AskBoardList;
  companyAskBoardWithAskBoardId: AskBoard;
  companyAskBoardReplyWithAskBoardId: Array<AskBoardReply>;
  auctionCompany: Array<AuctionCompany>;
  auctionCompanyReceiptList: AuctionCompanyReceiptList;
  auctionGoods: Array<AuctionGoods>;
  auctionGoodsIdx: AuctionGoodsIdx;
  auctionGoodsReceipt: AuctionGoodsReceiptList;
  chatNotice: Scalars['Int'];
  chatRoomList: ChatRoomList;
  chatRoomConsultList: ChatRoomList;
  chatRoomInfo: ChatRoomInfo;
  chatContents: Array<ChatContent>;
  myCompany: Scalars['CompanyDataMap'];
  companyCreateRequestList: Array<CompanyCreateRequest>;
  companyUpdateRequestList: CompanyUpdateRequestList;
  myCompanyConsultant: Array<CompanyConsultant>;
  myCompanyConsultantWithId: CompanyConsultant;
  myCompanyConsultantStatics: CompanyConsultantStatics;
  myCompanyConsultantList: CompanyConsultantList;
  boundConsultantWithMyCompany: Array<BoundConsultant>;
  isRegisteredCompanyConsultantWithId: Scalars['Boolean'];
  invitationCompanyConsultant: Array<InvitationCompanyConsultant>;
  companyWithRegion: CompanyList;
  companyWithFirstLetter: CompanyList;
  companyWithId: Company;
  companyLikes: CompanyLikes;
  companyWithNm: CompanyList;
  companyConsultantWithId: Array<CompanyConsultant>;
  kotraWithId?: Maybe<Kotra>;
  isLikeCompany: Scalars['Boolean'];
  noticeList: NoticeList;
  noticeWithId: Notice;
  newsletterList: NewsletterList;
  newsletterWithId: Newsletter;
  faqList: FaqList;
  faqWithId: Faq;
  openedQna: Array<Qna>;
  qnaList: QnaList;
  qnaWithId: Qna;
  qnaReplyWithQnaId: Array<QnaReply>;
  edutechKoreaFair2024Category: Array<EdutechKoreaFair2024Category>;
  edutechKoreaFair2024Company: Array<EdutechKoreaFair2024Company>;
  edutechKoreaFair2024CompanyCategory: EdutechKoreaFair2024Area;
  myGoods: Goods;
  myGoodsList: MyGoodsList;
  myGoodsWithId: Goods;
  myGoodsWithGoodsInfoId: Goods;
  goodsCreateRequest: GoodsCreateRequest;
  goodsUpdateRequest: GoodsUpdateRequest;
  goodsCreateRequestList: GoodsCreateRequestList;
  goodsUpdateRequestList: GoodsUpdateRequestList;
  boundConsultantWithGoods: Array<BoundConsultant>;
  myGoodsWithConsultant: MyGoodsWithConsultantList;
  checkCertificateFiles: CertificateFilesCheckResult;
  goodsWithId: Goods;
  goodsWithAllEduCode: Goods;
  goodsConsultantWithAllEduCode: Array<CompanyConsultant>;
  goodsConsultantWithId: Array<CompanyConsultant>;
  goodsWithCompanyUserId: GoodsList;
  goodsWithCategoryId: DefaultList;
  goodsWithNm: GoodsList;
  categoryGoodsCount: CategoryGoodsCount;
  isLikeGoods: Scalars['Boolean'];
  indexCarousel: Array<IndexCarousel>;
  indexIdx: Array<IndexIdx>;
  indexGoods: Scalars['Map'];
  indexImage: Scalars['Map'];
  userLikeCompany: CompanyList;
  userLikeGoods: GoodsList;
  invitationForRegisterConsultant: Array<InvitationForRegisterConsultant>;
  consultantMyPage: Scalars['Boolean'];
  myPageProcess: MyPageProcess;
  myPageCreateCompanyRequestStatus?: Maybe<CompanyCreateStatus>;
  myPageUpdateCompanyRequestStatus?: Maybe<CompanyUpdateStatus>;
  terms: Scalars['String'];
};


export type QueryMyAskBoardDoneArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryMyAskBoardWithAskBoardIdArgs = {
  askBoardId: Scalars['Int'];
};


export type QueryMyAskBoardReplyWithAskBoardIdArgs = {
  askBoardId: Scalars['Int'];
};


export type QueryCompanyAskBoardInDoneArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryCompanyAskBoardWithAskBoardIdArgs = {
  askBoardId: Scalars['Int'];
};


export type QueryCompanyAskBoardReplyWithAskBoardIdArgs = {
  askBoardId: Scalars['Int'];
};


export type QueryAuctionCompanyReceiptListArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryAuctionGoodsIdxArgs = {
  auctionGoodsId: Scalars['Int'];
};


export type QueryAuctionGoodsReceiptArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryChatRoomListArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
  findOption?: Maybe<ChatRoomFindOptions>;
};


export type QueryChatRoomConsultListArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
  findOption?: Maybe<ChatRoomFindOptions>;
};


export type QueryChatRoomInfoArgs = {
  chatIdxId: Scalars['Int'];
};


export type QueryChatContentsArgs = {
  options: ChatContentOptions;
};


export type QueryCompanyUpdateRequestListArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryMyCompanyConsultantWithIdArgs = {
  companyConsultantId: Scalars['Int'];
};


export type QueryMyCompanyConsultantStaticsArgs = {
  companyConsultantId: Scalars['Int'];
  stDate: Scalars['Int'];
  edDate: Scalars['Int'];
};


export type QueryMyCompanyConsultantListArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryIsRegisteredCompanyConsultantWithIdArgs = {
  companyConsultantId: Scalars['Int'];
};


export type QueryCompanyWithRegionArgs = {
  region: Scalars['String'];
  nationCode?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryCompanyWithFirstLetterArgs = {
  firstLetter: Array<Scalars['String']>;
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryCompanyWithIdArgs = {
  companyUserId: Scalars['Int'];
};


export type QueryCompanyLikesArgs = {
  companyUserId: Scalars['Int'];
};


export type QueryCompanyWithNmArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
  word: Scalars['String'];
};


export type QueryCompanyConsultantWithIdArgs = {
  companyUserId: Scalars['Int'];
};


export type QueryKotraWithIdArgs = {
  companyUserId: Scalars['Int'];
};


export type QueryIsLikeCompanyArgs = {
  companyUserId: Scalars['Int'];
};


export type QueryNoticeListArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryNoticeWithIdArgs = {
  noticeId: Scalars['Int'];
};


export type QueryNewsletterListArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryNewsletterWithIdArgs = {
  newsletterId: Scalars['Int'];
};


export type QueryFaqListArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryFaqWithIdArgs = {
  faqId: Scalars['Int'];
};


export type QueryQnaListArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryQnaWithIdArgs = {
  qnaId: Scalars['Int'];
};


export type QueryQnaReplyWithQnaIdArgs = {
  qnaId: Scalars['Int'];
};


export type QueryEdutechKoreaFair2024CompanyArgs = {
  subCategoryId: Scalars['Int'];
};


export type QueryEdutechKoreaFair2024CompanyCategoryArgs = {
  id: Scalars['Int'];
};


export type QueryMyGoodsArgs = {
  goodsId: Scalars['Int'];
};


export type QueryMyGoodsListArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
  options: GoodsFindOptions;
};


export type QueryMyGoodsWithIdArgs = {
  goodsId: Scalars['Int'];
  permission: GoodsPermission;
};


export type QueryMyGoodsWithGoodsInfoIdArgs = {
  goodsInfoId: Scalars['Int'];
};


export type QueryGoodsCreateRequestArgs = {
  id: Scalars['Int'];
};


export type QueryGoodsUpdateRequestArgs = {
  id: Scalars['Int'];
};


export type QueryGoodsCreateRequestListArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryGoodsUpdateRequestListArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryBoundConsultantWithGoodsArgs = {
  goodsId: Scalars['Int'];
};


export type QueryMyGoodsWithConsultantArgs = {
  companyConsultantId: Scalars['Int'];
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryCheckCertificateFilesArgs = {
  goodsId: Scalars['Int'];
};


export type QueryGoodsWithIdArgs = {
  goodsId: Scalars['Int'];
};


export type QueryGoodsWithAllEduCodeArgs = {
  allEduCode: Scalars['AllEduCode'];
};


export type QueryGoodsConsultantWithAllEduCodeArgs = {
  allEduCode: Scalars['AllEduCode'];
};


export type QueryGoodsConsultantWithIdArgs = {
  goodsId: Scalars['Int'];
};


export type QueryGoodsWithCompanyUserIdArgs = {
  companyUserId: Scalars['Int'];
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryGoodsWithCategoryIdArgs = {
  categoryId: Scalars['Int'];
  listPage: ListPageParams;
  size: Scalars['Int'];
};


export type QueryGoodsWithNmArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
  word: Scalars['String'];
};


export type QueryCategoryGoodsCountArgs = {
  categoryId: Scalars['Int'];
};


export type QueryIsLikeGoodsArgs = {
  goodsId: Scalars['Int'];
};


export type QueryUserLikeCompanyArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryUserLikeGoodsArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryTermsArgs = {
  key: Scalars['String'];
};

export type GoodsFindOptions = {
  langCode: Scalars['String'];
  nm?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['Int']>;
};

export type MyGoodsList = {
  __typename?: 'MyGoodsList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<MyGoods>;
};


export type UpdateCompanyConsultantPw = {
  pw: Scalars['String'];
};

export type CompanyList = {
  __typename?: 'CompanyList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<Company>;
};

export type GoodsUpdateRequestList = {
  __typename?: 'GoodsUpdateRequestList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<GoodsUpdateRequest>;
};

export type GoodsUpdateRequest = {
  __typename?: 'GoodsUpdateRequest';
  id: Scalars['Int'];
  goodsId: Scalars['Int'];
  categoryId: Scalars['Int'];
  chargedPoint: Scalars['Decimal'];
  status: GoodsUpdateRequestStatus;
  memo: Scalars['String'];
  data: Scalars['GoodsDataMap'];
  createdAt: Scalars['Time'];
};

export enum IndexDisplayType {
  Carousel = 'Carousel',
  Slider = 'Slider',
  Index = 'Index',
  List = 'List'
}


export type GoodsCreateRequestList = {
  __typename?: 'GoodsCreateRequestList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<GoodsCreateRequest>;
};

export type NationCode = {
  __typename?: 'NationCode';
  code: Scalars['String'];
  nm: Scalars['String'];
  shortNm: Scalars['String'];
  region: Scalars['String'];
};

export type AuctionCompanyReceiptList = {
  __typename?: 'AuctionCompanyReceiptList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<AuctionCompanyReceipt>;
};

export type CompanyCreateRequest = {
  __typename?: 'CompanyCreateRequest';
  id: Scalars['Int'];
  companyDataMap: Scalars['CompanyDataMap'];
  status: CompanyCreateStatus;
  chargedPoint: Scalars['Decimal'];
  memo: Scalars['String'];
};

export type CompanyType = {
  __typename?: 'CompanyType';
  id: Scalars['Int'];
  nm: Scalars['MultiLang'];
};

export type ListPageParams = {
  pageStartId: Scalars['Int'];
  currentPage: Scalars['Int'];
  page: Scalars['Int'];
};

export enum IndexGoodsViewMode {
  List = 'List',
  Carousel = 'Carousel'
}

export enum JoinType {
  Email = 'Email',
  Facebook = 'Facebook',
  Google = 'Google',
  Kakao = 'Kakao',
  Naver = 'Naver'
}


export type UserInfo = {
  __typename?: 'UserInfo';
  nm: Scalars['MultiLang'];
  position: Scalars['MultiLang'];
  department: Scalars['MultiLang'];
  tel: Scalars['MultiLang'];
  certificateInfo?: Maybe<Scalars['CertificateInfo']>;
};

export type BindConsultant = {
  companyConsultantId: Scalars['Int'];
  langCode: Scalars['String'];
  order: Scalars['Int'];
};

export type Company = {
  __typename?: 'Company';
  id: Scalars['Int'];
  isPaid: Scalars['Boolean'];
  company: Scalars['CompanyDataMap'];
};

export type Newsletter = {
  __typename?: 'Newsletter';
  id: Scalars['Int'];
  title: Scalars['String'];
  contents: Scalars['String'];
  views: Scalars['Int'];
  fileList: Array<BoardFile>;
  createdAt: Scalars['Time'];
  updatedAt: Scalars['Time'];
};

export type UpdateGoods = {
  goodsId: Scalars['Int'];
  categoryId: Scalars['Int'];
  data: Scalars['GoodsDataMap'];
};

export enum CompanyPermission {
  Block = 'Block',
  CreateWait = 'CreateWait',
  UpdateWait = 'UpdateWait',
  Dashboard = 'Dashboard',
  CreateCompany = 'CreateCompany',
  UpdateCompany = 'UpdateCompany',
  RegistryGoods = 'RegistryGoods',
  AuctionGoods = 'AuctionGoods',
  AuctionCompany = 'AuctionCompany',
  MngGoods = 'MngGoods',
  UserChat = 'UserChat',
  MngConsultant = 'MngConsultant'
}

export type Mutation = {
  __typename?: 'Mutation';
  getUserJoinType: JoinType;
  join: Scalars['Boolean'];
  login: Scalars['String'];
  loginWithSocial: Scalars['String'];
  loginWithUid: Scalars['String'];
  logout: Scalars['Boolean'];
  updateUserInfo: Scalars['Boolean'];
  backdoorLogin: Scalars['String'];
  createMyAskBoard: Scalars['Boolean'];
  createMyAskBoardReply: Scalars['Boolean'];
  updateMyAskBoardStatus: Scalars['Boolean'];
  deleteMyAskBoardReply: Scalars['Boolean'];
  updateMyAskBoardContact: Scalars['Boolean'];
  createCompanyAskBoardReply: Scalars['Boolean'];
  updateCompanyAskBoardStatus: Scalars['Boolean'];
  deleteCompanyAskBoardReply: Scalars['Boolean'];
  bidAuctionCompany: Scalars['Boolean'];
  cancelAuctionCompany: Scalars['Boolean'];
  bidAuctionGoods: Scalars['Boolean'];
  cancelAuctionGoods: Scalars['Boolean'];
  hasChatRoomWithCompanyId: Scalars['Int'];
  hasChatRoomWithGoodsInfoId: Scalars['Int'];
  createChatRoomWithCompanyId: Scalars['Int'];
  createChatRoomWithCompanyIdAndConsultantId: Scalars['Int'];
  createChatRoomWithGoodsInfoId: Scalars['Int'];
  createChatRoomWithGoodsInfoIdAndConsultantId: Scalars['Int'];
  participateConsultantToChatRoom: Scalars['Boolean'];
  createChatContent: Scalars['Boolean'];
  requestCreateCompany: Scalars['Int'];
  requestUpdateCompany: Scalars['Int'];
  allowCreateCompany: Scalars['Boolean'];
  cancelCreateCompany: Scalars['Boolean'];
  allowUpdateCompany: Scalars['Boolean'];
  cancelUpdateCompany: Scalars['Boolean'];
  createCompanyConsultant: Scalars['Boolean'];
  updateCompanyConsultant: Scalars['Boolean'];
  toggleCompanyConsultant: Scalars['Boolean'];
  bindMyCompanyWithConsultant: Scalars['Boolean'];
  registerCompanyConsultant: Scalars['Boolean'];
  isRegisteredCompanyConsultantWithEmail: Scalars['Boolean'];
  findUserForRegisterConsultant: Scalars['Boolean'];
  inviteUserForRegisterConsultant: Scalars['Boolean'];
  kickCompanyConsultant: Scalars['Boolean'];
  unbindGoodsWithConsultant: Scalars['Boolean'];
  unbindGoodsAllWithConsultant: Scalars['Boolean'];
  likeCompany: Scalars['Boolean'];
  dislikeCompany: Scalars['Boolean'];
  inquiryCompanyConsultant: Scalars['Boolean'];
  createQna: Scalars['Boolean'];
  appendQnaReply: Scalars['Boolean'];
  deleteQnaReply: Scalars['Boolean'];
  resolveQna: Scalars['Boolean'];
  createGoods: Scalars['Boolean'];
  createGoodsRetry: Scalars['Boolean'];
  updateGoods: Scalars['Boolean'];
  updateGoodsRetry: Scalars['Boolean'];
  requestGoodsCertificate: Scalars['Boolean'];
  uploadFile: Array<UploadedFile>;
  uploadProductWarrantyFile: UploadedFile;
  allowCreateGoodsRequest: Scalars['Boolean'];
  cancelCreateGoodsRequest: Scalars['Boolean'];
  allowUpdateGoodsRequest: Scalars['Boolean'];
  cancelUpdateGoodsRequest: Scalars['Boolean'];
  toggleGoodsDisplay: Scalars['Boolean'];
  bindConsultantWithGoods: Scalars['Boolean'];
  likeGoods: Scalars['Boolean'];
  dislikeGoods: Scalars['Boolean'];
  acceptInvitationConsultant: Scalars['Boolean'];
  cancelInvitationConsultant: Scalars['Boolean'];
  countUpLangCode: Scalars['Boolean'];
  countUpFindWord: Scalars['Boolean'];
  countUpIndexBanner: Scalars['Boolean'];
  countUpCategoryId: Scalars['Boolean'];
  countUpCompanyLike: Scalars['Boolean'];
  countUpCompanyAskBoard: Scalars['Boolean'];
  countUpCompanyShare: Scalars['Boolean'];
  countUpCompanyIntroduceYoutube: Scalars['Boolean'];
  countUpCompanyAttachFile: Scalars['Boolean'];
  countUpCompanyConsultant: Scalars['Boolean'];
  countUpGoodsLike: Scalars['Boolean'];
  countUpGoodsAskBoard: Scalars['Boolean'];
  countUpGoodsShare: Scalars['Boolean'];
  countUpGoodsIntroduceYoutube: Scalars['Boolean'];
  countUpGoodsAttachFile: Scalars['Boolean'];
  countUpGoodsConsultant: Scalars['Boolean'];
  countUpGoodsShopUrl: Scalars['Boolean'];
  countUpGoodsTryUrl: Scalars['Boolean'];
};


export type MutationGetUserJoinTypeArgs = {
  email: Scalars['String'];
};


export type MutationJoinArgs = {
  email: Scalars['String'];
  pw: Scalars['String'];
  joinType: JoinType;
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  pw: Scalars['String'];
  joinType: JoinType;
};


export type MutationLoginWithSocialArgs = {
  email: Scalars['String'];
  pw: Scalars['String'];
  joinType: JoinType;
};


export type MutationLoginWithUidArgs = {
  uid: Scalars['String'];
};


export type MutationUpdateUserInfoArgs = {
  input: UpdateUserInfo;
  certificateInfoData?: Maybe<Scalars['CertificateInfo']>;
};


export type MutationBackdoorLoginArgs = {
  userId: Scalars['Int'];
  email: Scalars['String'];
  pw: Scalars['String'];
};


export type MutationCreateMyAskBoardArgs = {
  input: CreateAskBoard;
};


export type MutationCreateMyAskBoardReplyArgs = {
  input: CreateAskBoardReply;
};


export type MutationUpdateMyAskBoardStatusArgs = {
  askBoardId: Scalars['Int'];
  askBoardStatus: AskBoardStatus;
};


export type MutationDeleteMyAskBoardReplyArgs = {
  askBoardReplyId: Scalars['Int'];
};


export type MutationUpdateMyAskBoardContactArgs = {
  askBoardId: Scalars['Int'];
  contact: Scalars['String'];
};


export type MutationCreateCompanyAskBoardReplyArgs = {
  input: CreateAskBoardReply;
};


export type MutationUpdateCompanyAskBoardStatusArgs = {
  askBoardId: Scalars['Int'];
  askBoardStatus: AskBoardStatus;
};


export type MutationDeleteCompanyAskBoardReplyArgs = {
  askBoardReplyId: Scalars['Int'];
};


export type MutationBidAuctionCompanyArgs = {
  input: BidAuctionCompany;
};


export type MutationCancelAuctionCompanyArgs = {
  id: Scalars['Int'];
};


export type MutationBidAuctionGoodsArgs = {
  input: BidAuctionGoods;
};


export type MutationCancelAuctionGoodsArgs = {
  auctionGoodsId: Scalars['Int'];
  goodsId: Scalars['Int'];
};


export type MutationHasChatRoomWithCompanyIdArgs = {
  companyId: Scalars['Int'];
};


export type MutationHasChatRoomWithGoodsInfoIdArgs = {
  goodsInfoId: Scalars['Int'];
};


export type MutationCreateChatRoomWithCompanyIdArgs = {
  companyId: Scalars['Int'];
};


export type MutationCreateChatRoomWithCompanyIdAndConsultantIdArgs = {
  companyId: Scalars['Int'];
  consultantId: Scalars['Int'];
};


export type MutationCreateChatRoomWithGoodsInfoIdArgs = {
  goodsInfoId: Scalars['Int'];
};


export type MutationCreateChatRoomWithGoodsInfoIdAndConsultantIdArgs = {
  goodsInfoId: Scalars['Int'];
  consultantId: Scalars['Int'];
};


export type MutationParticipateConsultantToChatRoomArgs = {
  chatIdxId: Scalars['Int'];
  consultantId: Scalars['Int'];
};


export type MutationCreateChatContentArgs = {
  chatIdxId: Scalars['Int'];
  content: CreateChatContent;
};


export type MutationRequestCreateCompanyArgs = {
  input: Scalars['CompanyDataMap'];
};


export type MutationRequestUpdateCompanyArgs = {
  input: Scalars['CompanyDataMap'];
};


export type MutationAllowCreateCompanyArgs = {
  companyCreateRequestId: Scalars['Int'];
};


export type MutationCancelCreateCompanyArgs = {
  companyCreateRequestId: Scalars['Int'];
};


export type MutationAllowUpdateCompanyArgs = {
  companyUpdateRequestId: Scalars['Int'];
};


export type MutationCancelUpdateCompanyArgs = {
  companyUpdateRequestId: Scalars['Int'];
};


export type MutationCreateCompanyConsultantArgs = {
  input: Array<InputCompanyConsultant>;
};


export type MutationUpdateCompanyConsultantArgs = {
  companyConsultantId: Scalars['Int'];
  input: InputCompanyConsultant;
};


export type MutationToggleCompanyConsultantArgs = {
  companyConsultantId: Scalars['Int'];
  swt: Scalars['Boolean'];
};


export type MutationBindMyCompanyWithConsultantArgs = {
  input: Array<BindConsultant>;
};


export type MutationRegisterCompanyConsultantArgs = {
  companyConsultantId: Scalars['Int'];
  email: Scalars['String'];
  pw: Scalars['String'];
};


export type MutationIsRegisteredCompanyConsultantWithEmailArgs = {
  email: Scalars['String'];
};


export type MutationFindUserForRegisterConsultantArgs = {
  email: Scalars['String'];
};


export type MutationInviteUserForRegisterConsultantArgs = {
  email: Scalars['String'];
};


export type MutationKickCompanyConsultantArgs = {
  companyConsultantId: Scalars['Int'];
};


export type MutationUnbindGoodsWithConsultantArgs = {
  consultantId: Scalars['Int'];
  goodsId: Scalars['Int'];
};


export type MutationUnbindGoodsAllWithConsultantArgs = {
  consultantId: Scalars['Int'];
};


export type MutationLikeCompanyArgs = {
  id: Scalars['Int'];
};


export type MutationDislikeCompanyArgs = {
  id: Scalars['Int'];
};


export type MutationInquiryCompanyConsultantArgs = {
  companyConsultantId: Scalars['Int'];
};


export type MutationCreateQnaArgs = {
  title: Scalars['String'];
  contents: Scalars['String'];
};


export type MutationAppendQnaReplyArgs = {
  qnaId: Scalars['Int'];
  contents: Scalars['String'];
};


export type MutationDeleteQnaReplyArgs = {
  qnaReplyId: Scalars['Int'];
};


export type MutationResolveQnaArgs = {
  qnaId: Scalars['Int'];
};


export type MutationCreateGoodsArgs = {
  input: CreateGoods;
};


export type MutationCreateGoodsRetryArgs = {
  input: CreateGoodsRetry;
};


export type MutationUpdateGoodsArgs = {
  input: UpdateGoods;
};


export type MutationUpdateGoodsRetryArgs = {
  input: UpdateGoodsRetry;
};


export type MutationRequestGoodsCertificateArgs = {
  goodsId: Scalars['Int'];
};


export type MutationUploadFileArgs = {
  input: Array<Scalars['Upload']>;
};


export type MutationUploadProductWarrantyFileArgs = {
  goodsId: Scalars['Int'];
  input: Scalars['Upload'];
};


export type MutationAllowCreateGoodsRequestArgs = {
  createGoodsRequestId: Scalars['Int'];
};


export type MutationCancelCreateGoodsRequestArgs = {
  createGoodsRequestId: Scalars['Int'];
};


export type MutationAllowUpdateGoodsRequestArgs = {
  updateGoodsRequestId: Scalars['Int'];
};


export type MutationCancelUpdateGoodsRequestArgs = {
  updateGoodsRequestId: Scalars['Int'];
};


export type MutationToggleGoodsDisplayArgs = {
  goodsId: Scalars['Int'];
  swt: Scalars['Boolean'];
};


export type MutationBindConsultantWithGoodsArgs = {
  goodsId: Scalars['Int'];
  input: Array<BindConsultant>;
};


export type MutationLikeGoodsArgs = {
  goodsId: Scalars['Int'];
};


export type MutationDislikeGoodsArgs = {
  goodsId: Scalars['Int'];
};


export type MutationAcceptInvitationConsultantArgs = {
  invitationConsultantId: Scalars['Int'];
};


export type MutationCancelInvitationConsultantArgs = {
  invitationConsultantId: Scalars['Int'];
};


export type MutationCountUpLangCodeArgs = {
  langCode: Scalars['String'];
};


export type MutationCountUpFindWordArgs = {
  word: Scalars['String'];
};


export type MutationCountUpIndexBannerArgs = {
  link: Scalars['String'];
};


export type MutationCountUpCategoryIdArgs = {
  id: Scalars['Int'];
};


export type MutationCountUpCompanyLikeArgs = {
  companyId: Scalars['Int'];
};


export type MutationCountUpCompanyAskBoardArgs = {
  companyId: Scalars['Int'];
};


export type MutationCountUpCompanyShareArgs = {
  companyId: Scalars['Int'];
  shareType: Scalars['String'];
};


export type MutationCountUpCompanyIntroduceYoutubeArgs = {
  companyId: Scalars['Int'];
  youtubeLink: Scalars['String'];
};


export type MutationCountUpCompanyAttachFileArgs = {
  companyId: Scalars['Int'];
  link: Scalars['String'];
};


export type MutationCountUpCompanyConsultantArgs = {
  companyId: Scalars['Int'];
  consultantId: Scalars['Int'];
};


export type MutationCountUpGoodsLikeArgs = {
  goodsId: Scalars['Int'];
};


export type MutationCountUpGoodsAskBoardArgs = {
  goodsId: Scalars['Int'];
};


export type MutationCountUpGoodsShareArgs = {
  goodsId: Scalars['Int'];
  shareType: Scalars['String'];
};


export type MutationCountUpGoodsIntroduceYoutubeArgs = {
  goodsId: Scalars['Int'];
  youtubeLink: Scalars['String'];
};


export type MutationCountUpGoodsAttachFileArgs = {
  goodsId: Scalars['Int'];
  link: Scalars['String'];
};


export type MutationCountUpGoodsConsultantArgs = {
  goodsId: Scalars['Int'];
  consultantId: Scalars['Int'];
};


export type MutationCountUpGoodsShopUrlArgs = {
  goodsId: Scalars['Int'];
  link: Scalars['String'];
};


export type MutationCountUpGoodsTryUrlArgs = {
  goodsId: Scalars['Int'];
  link: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['Int'];
  email: Scalars['String'];
  companyPermission: Array<CompanyPermission>;
  joinType: JoinType;
  userType: UserType;
  hasUserInfo: Scalars['Boolean'];
  joinAt: Scalars['Time'];
};

export type ChatGoods = {
  __typename?: 'ChatGoods';
  goodsId: Scalars['Int'];
  goodsInfoId: Scalars['Int'];
  nm: Scalars['MultiLang'];
  imgSrc: Scalars['MultiLang'];
};

export enum ChatRoomPosition {
  User = 'User',
  Consultant = 'Consultant',
  Company = 'Company'
}

export type CertificateFilesCheckResult = {
  __typename?: 'CertificateFilesCheckResult';
  result: Scalars['Boolean'];
  goodsId: Scalars['Int'];
};

export type CategoryGoodsCount = {
  __typename?: 'CategoryGoodsCount';
  categoryId: Scalars['Int'];
  registered: Scalars['Int'];
  show: Scalars['Int'];
};

export type ListPage = {
  __typename?: 'ListPage';
  pageStartId: Scalars['Int'];
  currentPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type UpdateUserInfo = {
  nm: Scalars['MultiLang'];
  position: Scalars['MultiLang'];
  department: Scalars['MultiLang'];
  tel: Scalars['MultiLang'];
  certificateInfo?: Maybe<CertificateInfoInput>;
};

export type Faq = {
  __typename?: 'Faq';
  id: Scalars['Int'];
  title: Scalars['String'];
  contents: Scalars['String'];
  views: Scalars['Int'];
  fileList: Array<BoardFile>;
  createdAt: Scalars['Time'];
  updatedAt: Scalars['Time'];
};

export type EdutechKoreaFair2024Category = {
  __typename?: 'EdutechKoreaFair2024Category';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type Goods = {
  __typename?: 'Goods';
  id: Scalars['Int'];
  allEduCode: Scalars['AllEduCode'];
  goodsInfoId: Scalars['Int'];
  isActivate: Scalars['Boolean'];
  companyUserId: Scalars['Int'];
  categoryId: Scalars['Int'];
  goodsPermission: Array<GoodsPermission>;
  isPaid: Scalars['Boolean'];
  data: Scalars['GoodsDataMap'];
  company: Scalars['CompanyDataMap'];
  isCertificateRequest: Scalars['String'];
  isCertificated: Scalars['String'];
  productWarranty: Scalars['String'];
  companyNames: Scalars['MultiLang'];
};

export type AskBoardList = {
  __typename?: 'AskBoardList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<AskBoard>;
};

export type AuctionGoodsIdx = {
  __typename?: 'auctionGoodsIdx';
  auctionGoods: AuctionGoods;
};

export type AuctionCompanyReceipt = {
  __typename?: 'AuctionCompanyReceipt';
  id: Scalars['Int'];
  status: AuctionStatus;
  bid: Scalars['Int'];
  createdAt: Scalars['Time'];
  auctionCompany: AuctionCompany;
};



      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": []
  }
};
      export default result;
    
