import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './BizGoodsIconInTable.module.scss';
import {BizCategoryBreadCrumbs} from "../bizCategoryBreadcrumbs/BizCategoryBreadCrumbs";
import {GoodsDataMap} from "../../../graphql/customScalars";
import {useSelector} from "react-redux";
import {I18nState} from "../../../redux/I18n";
import {GoodsDataMapUtil} from "../../../utils/goodsDataMapUtil/goodsDataMapUtil";

const cx = classNamesBind.bind(styles);

export const BizGoodsIconInTable = (props: {
    goodsDataMap: GoodsDataMap,
    categoryId: number,
}) => {
    const i18n = useSelector<any, I18nState>(state => state.i18n);
    const goodsData = GoodsDataMapUtil.getGoodsData(i18n.langCode, props.goodsDataMap);

    return (
        <div className={cx('box-goods')}>
            <div className={cx('img')}>
                <img src={goodsData.goodsImage[0].src} alt='img'/>
            </div>
            <div className={cx('goods-info')}>
                <div className={cx('goods-nm', 'ellipsis')}>{goodsData.nm}</div>
                <BizCategoryBreadCrumbs categoryId={props.categoryId}/>
            </div>
        </div>
    )
}
