import React from "react";
import {Query} from "../../../../graphql/types";
import {BizTitle} from "../../../shares/bizTitle/BizTitle";
import {useSelector} from "react-redux";
import {DataState} from "../../../../redux/Data";
import {gql, useQuery} from "@apollo/client";
import {FadeIn} from "../../../shares/fadeIn/FadeIn";
import {Pager} from "../../../shares/pager/Pager";
import {navigate} from "hookrouter";
import {BizCompanyDataIdx} from "../../../shares/bizCompanyDataIdx/BizCompanyDataIdx";
import {useTranslation} from "react-i18next";

export const NationMainList = (props: {
    region: string,
    page: number,
    size: number,
    nationCode: string | undefined,
}) => {
    const [t] = useTranslation('common');
    const dataState = useSelector<any, DataState>(state => state.data);
    const nationList = dataState.nationCode.filter(value => {
        return value.region === props.region
    });

    const {data} = useQuery<Query, {
        region: string,
        size: number,
        page: number,
        nationCode: string | undefined,
    }>(gqlData, {
        variables: props
    });


    const onChangePage = (page: number) => {
        if (props.nationCode) {
            navigate(`/nation/${props.region}/${page}/${props.nationCode}`)
        } else {
            navigate(`/nation/${props.region}/${page}`)
        }
    }

    const onChangeNationCode = (nationCode: string) => {
        if (nationCode) {
            navigate(`/nation/${props.region}/0/${nationCode}`)
        } else {
            navigate(`/nation/${props.region}/0`)
        }
    }

    return (
        <>
            {data &&
            <FadeIn>
                <BizTitle
                    desc={<select
                        style={{width: 150}}
                        value={props.nationCode}
                        onChange={event => {
                            onChangeNationCode(event.target.value);
                        }}
                        className='form-control form-control-sm'>
                        <option value={""}>{t("nation.wholeList")}</option>
                        {nationList.map((value, index) => {
                            return (
                                <option
                                    key={index}
                                    value={value.code}>({value.code}) {value.shortNm}</option>
                            )
                        })}
                    </select>}
                >{props.region.replace("_", " ")}</BizTitle>

                <BizCompanyDataIdx companyList={data.companyWithRegion.list}/>

                <Pager
                    page={data.companyWithRegion.page}
                    size={data.companyWithRegion.size}
                    total={data.companyWithRegion.total}
                    onChange={onChangePage}/>
                <div style={{height: 30}}/>
            </FadeIn>
            }
        </>
    )
}

const gqlData = gql`
    query Data ($page: Int!, $region: String!, $nationCode: String) {
        companyWithRegion(page: $page, size: 20, region: $region, nationCode: $nationCode){
            page
            size
            total
            list {
                id
                company
                isPaid
            }
        }
    }
`;
