import React, {useState} from 'react';
import classNamesBind from "classnames/bind";
import styles from './CsQnaView.module.scss';
import {CsLayout} from "../../layout/CsLayout";
import {gql} from "@apollo/client/core";
import {useMutation, useQuery} from "@apollo/client";
import {
    ChatWriter,
    Mutation,
    MutationAppendQnaReplyArgs, MutationDeleteQnaReplyArgs,
    MutationResolveQnaArgs,
    Query
} from "../../../../graphql/types";
import {FadeIn} from "../../../shares/fadeIn/FadeIn";
import {BizSmTitle, BizTitle} from "../../../shares/bizTitle/BizTitle";
import moment from "moment";
import {BizNoDataTable} from "../../../shares/bizNoDataTable/BizNoDataTable";
import {Pipe} from "../../../../utils/pipe/pipe";
import {CopyUtil} from "../../../../utils/copyUtil/copyUtil";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import {ApolloCatch} from "../../../../utils/apollo/apolloCatch";
import {Button, IconButton, Tooltip} from "@material-ui/core";
import {Close, PlaylistAddCheck} from "@material-ui/icons";
import {useTranslation} from "react-i18next";
import {I18nKeys} from "../../../../i18n/i18nKeys";

const cx = classNamesBind.bind(styles);

export const CsQnaView = (props: {
    qnaId: number
}) => {
    const {data, refetch} = useQuery<Query>(gqlData, {
        variables: props
    });
    const [t] = useTranslation('common');

    const [mutAppend] = useMutation<Mutation, MutationAppendQnaReplyArgs>(gqlAppend);
    const [mutResolve] = useMutation<Mutation, MutationResolveQnaArgs>(gqlResolve);
    const [mutDelete] = useMutation<Mutation, MutationDeleteQnaReplyArgs>(gqlDelete);

    const [reply, setReply] = useState<MutationAppendQnaReplyArgs>({
        qnaId: props.qnaId,
        contents: ''
    })

    const handler = {
        onAppendReply: () => {
            if (!reply.contents) {
                SwalUtil.ok({
                    msg: t("cs.swalNoReplyContent"),
                    icon: 'warning'
                });
                return;
            }

            SwalUtil.ynPromise({
                msg: t("cs.swalRegistReplyQuestion")
            }).then(() => {
                return mutAppend({
                    variables: reply
                })
            }).then(() => {
                return refetch()
            }).then(() => {
                SwalUtil.ok({
                    msg: t("cs.swalRegistReplyOk"),
                    icon: 'success'
                })
                setReply({
                    qnaId: props.qnaId,
                    contents: ''
                })
            }).catch(ApolloCatch({}));
        },
        onResolve: () => {
            SwalUtil.ynPromise({
                msg: t("cs.swalResolveQuestion")
            }).then(() => {
                return mutResolve({
                    variables: props
                })
            }).then(() => {
                return refetch()
            }).then(() => {
                SwalUtil.ok({
                    msg: t("cs.swalResolveOk"),
                    icon: 'success'
                })
            }).catch(ApolloCatch({}));
        },
        onDelete: (qnaReplyId: number) => {
            SwalUtil.ynPromise({
                msg: t("cs.swalDeleteReplyQuestion")
            }).then(() => {
                return mutDelete({
                    variables: {qnaReplyId}
                });
            }).then(() => {
                return refetch()
            }).then(() => {
                SwalUtil.ok({
                    msg: t("cs.swalDeleteReplyOk"),
                    icon: 'success'
                })
            }).catch(ApolloCatch({}));
        }
    }

    return (
        <CsLayout activatedSuffixRouter={'/qna/list'}>
            {data &&
            <FadeIn>
                <BizTitle>{t("cs.qnaContentsTitle")}</BizTitle>
                <div className={cx('table-fluid')}>
                    <div className={cx('col', 'col-header', 'col-label')}>
                        {t("cs.status")}
                    </div>
                    <div className={cx('col', 'col-value')}>
                        {data.qnaWithId.isOpened &&
                        <span className='badge badge-warning'>{t("cs.badgeInProgress")}</span>}
                        {!data.qnaWithId.isOpened && <span className='badge badge-success'>{t("cs.badgeClose")}</span>}
                    </div>
                    <div className={cx('col', 'col-header', 'col-label')}>
                        {t("cs.questionDate")}
                    </div>
                    <div className={cx('col', 'col-value')}>
                        {moment(data.qnaWithId.createdAt).format('YY.MM.DD - hh:mm a')}
                    </div>
                    <div className={cx('col', 'col-header', 'col-label')}>
                        {t("cs.title")}
                    </div>
                    <div className={cx('col', 'col-full', 'text-left')}>
                        {data.qnaWithId.title}
                    </div>
                </div>

                <div className={cx('table')}>
                    <div className={cx('col', 'col-full', 'text-left')}
                         ref={ref => {
                             if (ref) {
                                 ref.innerHTML = data.qnaWithId.contents;
                             }
                         }}
                    />
                </div>


                <div style={{height: 50}}/>
                {data.qnaWithId.isOpened &&
                <div className={cx('table')}>
                    <div className={cx('col-full', 'text-right')}>
                        <Button
                            startIcon={<PlaylistAddCheck/>}
                            onClick={handler.onResolve}>
                            {t("cs.btnQnaResolve")}
                        </Button>
                    </div>
                </div>
                }


                <div style={{height: 50}}/>
                <BizSmTitle>{t("cs.replyTitle")} ({Pipe.toSeparatorNumber(data.qnaReplyWithQnaId.length)}) {t("cs.cases")}</BizSmTitle>
                {data.qnaReplyWithQnaId.length === 0 && <BizNoDataTable/>}
                {data.qnaReplyWithQnaId.map((value, index) => (
                    <div key={index} className={cx('table')}>
                        <div className={cx('col', 'col-label')}>
                            {value.writer === ChatWriter.Admin &&
                            <div>{t(I18nKeys.cs.allEduAdmin.key)}</div>
                            }
                            {value.writer === ChatWriter.User && t("cs.badgeUser")}
                        </div>
                        <div
                            className={cx('col', 'col-textarea', 'text-left')}
                            ref={ref => {
                                if (ref) {
                                    ref.innerText = value.contents
                                }
                            }}/>
                        <div className={cx('col', 'col-btn')}>
                            {(value.writer === ChatWriter.User && data.qnaWithId.isOpened) &&
                            <Tooltip title={t("cs.ttDelete") || ""}>
                                <IconButton onClick={() => handler.onDelete(value.id)}>
                                    <Close/>
                                </IconButton>
                            </Tooltip>
                            }
                        </div>
                    </div>


                ))}

                {data.qnaWithId.isOpened &&
                <div className={cx('table')}>
                    <div className={cx('col', 'col-label')}>{t("cs.replyTitle")}</div>
                    <div className={cx('col-textarea')}>
                        <textarea
                            value={reply.contents}
                            onChange={ev => {
                                const copy = CopyUtil.copyAll(reply);
                                copy.contents = ev.target.value;
                                setReply(copy);
                            }}
                            className='form-control form-control-sm'/>
                    </div>
                    <div className={cx('col', 'col-btn')}>
                        <button
                            className='btn btn-sm btn-outline-success'
                            onClick={handler.onAppendReply}>
                            {t("cs.btnReplyAdd")}
                        </button>
                    </div>
                </div>
                }

                <div style={{height: 50}}/>
            </FadeIn>
            }

        </CsLayout>
    )
}


const gqlData = gql`
    query Data($qnaId: Int!) {
        qnaWithId(qnaId: $qnaId) {
            id
            isOpened
            title
            contents
            createdAt
        }
        qnaReplyWithQnaId(qnaId: $qnaId) {
            id
            writer
            contents
            createdAt
            adminEmail
            adminNickname
        }
    }
`

const gqlAppend = gql`
    mutation Append($qnaId: Int!, $contents: String!) {
        appendQnaReply(qnaId: $qnaId, contents: $contents)
    }
`;

const gqlResolve = gql`
    mutation Close($qnaId: Int!) {
        resolveQna(qnaId: $qnaId)
    }
`;

const gqlDelete = gql`
    mutation Delete($qnaReplyId: Int!) {
        deleteQnaReply(qnaReplyId: $qnaReplyId)
    }
`;
