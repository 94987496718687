export class LocalStorage {
    static IS_NAVER_LOGINED = 'IS_NAVER_LOGINED';
    static GOODS_CREATE_LANG_CODE = "GOOD_CREATE_LANG_CODE";

    static getIsNaverLogined(): boolean {
        const value = localStorage.getItem(this.IS_NAVER_LOGINED);
        return value === 'true';
    }

    static setIsNaverLogined(swt: boolean) {
        localStorage.setItem(this.IS_NAVER_LOGINED, swt ? 'true' : 'false');
    }

    static setSession(token: string) {
        localStorage.setItem('api-token', token);
    }

    static getSession(): string {
        return localStorage.getItem('api-token') || '';
    }

    static clearSession() {
        localStorage.removeItem('api-token');
    }

    static setLangCode(langCode: string) {
        localStorage.setItem("langCode", langCode);
    }

    static getLangCode(): string {
        let value = localStorage.getItem("langCode");

        if (value == null) {
            const langCodes = ["cn", "jp", "ko", "vn"];
            value = navigator.language.substring(0, 2);

            if (!langCodes.includes(value)) {
                value = "en";
            }
        }

        return value;
    }

    static goodsCreateQuillLangCode(): string {
        return localStorage.getItem(this.GOODS_CREATE_LANG_CODE) || '';
    }

    static setGoodsCreateQuillLangCode(value: string) {
        localStorage.setItem(this.GOODS_CREATE_LANG_CODE, value);
    }
}
