import React, {useEffect, useState} from 'react';
import {gql, useMutation, useQuery, useReactiveVar} from "@apollo/client";
import {CreateGoods, Query} from "../../../../../graphql/types";
import {MyPageLayout} from "../../../layout/MyPageLayout";
import {InputCreateGoods} from "./input/InputCreateGoods";
import {useTranslation} from "react-i18next";
import {BizTitle} from "../../../../shares/bizTitle/BizTitle";
import {SwalUtil} from "../../../../../utils/swal/swalUtil";
import {navigate} from "hookrouter";
import {ApolloCatch} from "../../../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../../../graphql/errors";
import {CopyUtil} from "../../../../../utils/copyUtil/copyUtil";
import {MyPageGoodsTabs, MyPageGoodsTabsData} from "../../share/tabs/MyPageGoodsTabs";
import {GetEnv} from "../../../../../env/env";
import {Pipe} from "../../../../../utils/pipe/pipe";
import {InputCategory} from '../../../share/inputs/inputCategory/InputCategory';
import {ReduxStore} from "../../../../../index";
import {GoodsDataMap} from "../../../../../graphql/customScalars";
import {InputQuill} from "../../../share/inputs/inputQuill/InputQuill";
import {GlobalVars} from "../../../../../globalVars/globalVars";
import {GoodsDataMapUtil} from "../../../../../utils/goodsDataMapUtil/goodsDataMapUtil";
import {FadeIn} from "../../../../shares/fadeIn/FadeIn";

export const MyPageGoodsRegistry = () => {
    const {data} = useQuery<Query>(gqlData);
    const [createGoods, setCreateGoods] = useState<CreateGoods>(GetEnv().env === 'dev' ? GenerateDummyCreateGoods() : GenerateCreateGoods());
    const [mutCreateGoods] = useMutation<{ createGoods: boolean }, { input: CreateGoods }>(gqlCreateGoods);
    const [langCode, setLangCode] = useState('en');
    const [t] = useTranslation('common');
    const quillData = useReactiveVar(GlobalVars.quill.createGoods);

    //*------------------------------------------------------------*/
    // 다음 경고를 무시하게 한다. 
    // 해당 경고를 수정했을 경우 InputQuill이 제대로 동작하지 않는다.
    // (※경고를 수정했으나 InputQuill이 수정되지 않는 문제가 발생하여
    //    소스를 원상복구한다.)
    //---------------------------------------------------------------
    // React Hook useEffect has a missing dependency: 'createGoods'.
    // Either include it or remove the dependency array  react-hooks/exhaustive-deps
    //*------------------------------------------------------------*/
    useEffect(() => {
        const copy = CopyUtil.copyAll(createGoods);
        if (!copy.data.hasOwnProperty(quillData.langCode)) {
            return;
        }

        copy.data[quillData.langCode].contents = quillData.content;
        setCreateGoods(copy);
    // eslint-disable-next-line
    }, [quillData]);

    // 에디터 초기화
    useEffect(() => {
        return () => {
            GlobalVars.quill.createGoods({langCode: '', content: ''})
        }
    }, [])


    const handler = {
        onCreateGoods: () => {
            const res = GoodsDataMapUtil.validate(createGoods.data);

            if (!res.isOk) {
                SwalUtil.ok({
                    msg: t(res.tValue),
                    icon: 'error',
                })
                return;
            }

            if (createGoods.categoryId < 2) {
                SwalUtil.ok({
                    msg: t('mypageGoods.errCategoryId'),
                    icon: 'error',
                })
                return;
            }


            SwalUtil.ynPromise({
                msg: t('mypageGoods.askCreateGoods'),
            }).then(() => {
                return mutCreateGoods({variables: {input: createGoods}})
            }).then(res => {
                SwalUtil.ok({
                    msg: t('mypageGoods.createGoodsResult'),
                    icon: "success"
                });
                navigate('/mypage/goods/list');
            }).catch(ApolloCatch({
                [BizErrors.default]: SwalUtil.apolloError(),
                [BizErrors.noHasPermission]: SwalUtil.apolloCatch({
                    msg: t('mypageGoods.noHasPermission'),
                    icon: 'error'
                })
            }))
        }
    };

    const goodsTabsData: MyPageGoodsTabsData = {};
    goodsTabsData['en'] = {
        title:
            <>
                English <span style={{color: 'red'}}> * ({t('mypageGoods.required')})</span>
            </>,
        children: <InputCreateGoods
            value={createGoods.data['en']}
            onChangeValue={v => {
                const copy = CopyUtil.copyAll(createGoods);
                copy.data['en'] = v;
                setCreateGoods(copy);
            }}/>
    }


    for (let langCode of Object.keys(createGoods.data).filter(value => {
        return value !== 'en';
    })) {
        goodsTabsData[langCode] = {
            title: Pipe.langNm(langCode),
            children: <InputCreateGoods
                value={createGoods.data[langCode]}
                onChangeValue={v => {
                    const copy = CopyUtil.copyAll(createGoods);
                    copy.data[langCode] = v;
                    setCreateGoods(copy);
                }}/>
        }
    }

    return (
        <MyPageLayout activatedSuffixRouter={'/goods/registry'}>
            {data &&
            <FadeIn>
                <BizTitle>{t('mypageGoods.titleCreateGoods')}</BizTitle>

                <div style={{height: 20}}/>
                <InputCategory
                    title={t('mypageGoods.category')}
                    value={createGoods.categoryId}
                    onChange={v => {
                        const copy = CopyUtil.copyAll(createGoods);
                        copy.categoryId = v;
                        setCreateGoods(copy);
                    }}/>

                <div style={{height: 20}}/>
                <MyPageGoodsTabs
                    langCode={langCode}
                    data={goodsTabsData}
                    onChangeLangCode={setLangCode}/>

                <InputQuill
                    title={t("mypageGoods.titleQuill")}
                    langCode={langCode}
                    value={createGoods.data[langCode].contents}
                    from="goods"
                    onChangeValue={v => {
                        const copy = CopyUtil.copyAll(createGoods);
                        copy.data[langCode].contents = v;
                        setCreateGoods(copy);
                    }}/>

                <hr/>
                <div className='text-right' style={{marginBottom: 100}}>
                    <button
                        style={{marginRight: 10}}
                        className='btn btn-sm btn-success'
                        onClick={() => {
                            handler.onCreateGoods();
                        }}
                    >{t('mypageGoods.btnUpload')}
                    </button>
                </div>

            </FadeIn>
            }
        </MyPageLayout>

    )
}

const gqlCreateGoods = gql`
mutation CreateGoods($input: CreateGoods!)
{
    createGoods(input
:
    $input
)
}
`;


const gqlData = gql`
query Consultant
{
    myCompanyConsultant
    {
        id
        isActivate
        nm
        email
        tel
        position
        permission
        createdAt
    }
}
`;

export const GenerateCreateGoods = (): CreateGoods =>
{
    const langList = ReduxStore.getState().data.langCode;
    const priceUnit = ReduxStore.getState().data.priceUnit[0];
    const res: GoodsDataMap = {};
    for (let elem of langList) {
        res[elem.code] = {
            goodsPrice: {
                noPrice: true,
                price: 0,
                priceUnit: priceUnit
            },
            purchaseUrl: "https://",
            tryGoodsUrl: "https://",
            youtubeUrl: "",
            award: "",
            certification: "",
            contents: "",
            catchphrase: "",
            goodsCode: "",
            goodsImage: [],
            goodsInfo: [],
            goodsFile: [],
            originNationCode: "KR",
            nm: "",
        }
    }
    return {
        categoryId: 1,
        data: res
    };
}

const GenerateDummyCreateGoods = (): CreateGoods =>
{
    const langList = ReduxStore.getState().data.langCode;
    const priceUnit = ReduxStore.getState().data.priceUnit[0];
    const res: GoodsDataMap = {};
    for (let elem of langList) {
        res[elem.code] = {
            goodsPrice: {
                noPrice: false,
                price: 10,
                priceUnit: priceUnit
            },
            purchaseUrl: "https://www.naver.com",
            tryGoodsUrl: "https://www.youtube.com",
            youtubeUrl: "https://www.youtube.com",
            award: elem.code,
            certification: elem.code,
            contents: elem.code,
            catchphrase: elem.code,
            goodsCode: elem.code,
            goodsImage: [{
                src: 'https://timeedu.kr.object.ncloudstorage.com/goods/a778f285-a5fd-45cd-9792-aaf72e4ce790',
                createdAt: new Date(),
            }],
            goodsInfo: [{
                key: 'a',
                value: 'b'
            }],
            goodsFile: [{
                nm: 'abc.jpg',
                src: 'https://timeedu.kr.object.ncloudstorage.com/goods/a778f285-a5fd-45cd-9792-aaf72e4ce790',
                createdAt: new Date()
            }],
            originNationCode: "KR",
            nm: elem.code,
        }
    }
    return {
        categoryId: 1,
        data: res
    };
}
