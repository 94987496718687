import React, {Fragment} from 'react';
import {IndexGoods, IndexGoodsViewMode, IndexIdx} from "../../../../graphql/types";
import { MainBizGoodsIdxList, MainBizGoodsIdxWithCarousel } from './MainBizGoodsIdxList';



export const IndexMainGoods = (props: {
    indexIdx: IndexIdx,
    indexGoodsList: Array<IndexGoods>,
}) => {
    return (
        <Fragment>
            {props.indexIdx.goodsViewMode === IndexGoodsViewMode.List &&
            <>
                <MainBizGoodsIdxList goods={props.indexGoodsList}/>
            </>}
            {props.indexIdx.goodsViewMode === IndexGoodsViewMode.Carousel &&
            <MainBizGoodsIdxWithCarousel goods={props.indexGoodsList}/>}
        </Fragment>
    )
}